import { useState } from "react";
import CarEmailPopup from "../../Component/Desktop/Car/QuotePage/CarEmailPopup/CarEmailPopup";
import { CAR_SERVICES } from "../../Services/CAR/CarServices";
import {
  CarEmailQuotesDTO,
  CarQuotesData,
} from "../../Services/DTO/CarDTO/EmailQuotesDTO";
import { useAppSelector } from "../../State/hooks";
import { calculate_premium_for_car } from "../../SupportingFiles/CarPermiumCal";
import {
  validateEmail,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";

export const CarEmailPopupContainer = ({
  open,
  setOpen,
  marginClass,
}: //   addon_values,

{
  open: boolean;
  setOpen: any;
  marginClass: string;
  //   addon_values: TAddonsValueType;
}) => {
  interface EmailFields {
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
  }
  const isMobile = useIsMobile();
  const {
    ALL_QUOTES_DATA,
    ADD_FORM_RESPONSE,
    ADDON_STATUS,
    ADDON_USER_VALUE,
    PLAN_TYPE,
  } = useAppSelector((state) => state.Car);

  const [sendQuoteFields, setSendQuoteFields] = useState<EmailFields>({
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
  });
  const [showThanksMessage, setShowThanksMessage] = useState(false);

  const handleShowMessage = () => {
    setShowThanksMessage(true);
    setTimeout(() => {
      setShowThanksMessage(false);
      setOpen(false);
    }, 2000);
  };
  const fieldsUpdateState = (attrName: keyof EmailFields, value: any) => {
    setSendQuoteFields({
      ...sendQuoteFields,
      [attrName]: {
        ...sendQuoteFields[attrName],
        value: value,
        warning: !(validateEmail(value) || validateMobileNumber(value)),
      },
    });
  };
  const validateForm = () => {
    let emailQuoteFieldData = { ...sendQuoteFields };
    emailQuoteFieldData.email.warning = !(
      validateMobileNumber(emailQuoteFieldData.mobile.value) &&
      validateEmail(emailQuoteFieldData.email.value)
    );
    let data = emailQuoteFieldData;
    data = {
      ...data,
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
    };

    setSendQuoteFields({
      ...data,
    });
    if (emailQuoteFieldData.email.warning === false) {
      onSubmit();
    }
  };

  let emailSendData: CarQuotesData[] = ALL_QUOTES_DATA.map((quoteData) => {
    const calculatedPrice = `${calculate_premium_for_car(
      quoteData.quotationDetail,
      ADDON_STATUS,
      ADDON_USER_VALUE.passengerCover.value,
      PLAN_TYPE
    )}`;
    const idv = quoteData.quotationDetail?.premiumDetails.IDV?.toString() || "";
    const product_id =
      quoteData.quotationDetail?.productDetails.id?.toString() || "";
    const logo = quoteData.quotationDetail?.CompanyDetails.logo || "";

    return {
      price: calculatedPrice,
      logo: logo,
      idv: idv,
      product_id: product_id,
    };
  });
  let param: CarEmailQuotesDTO = {
    quotes: emailSendData,
    mobile: validateMobileNumber(sendQuoteFields.mobile.value)
      ? sendQuoteFields.mobile.value
      : "",
    email: validateEmail(sendQuoteFields.email.value)
      ? sendQuoteFields.email.value
      : "",
    quote_no: ADD_FORM_RESPONSE.quote_no,
  };
  const onSubmit = () => {
    const onSuccess = (res: any) => {
      handleShowMessage();
    };
    const onError = (err: any) => {};
    CAR_SERVICES.SEND_QUOTE_VIA_EMAIL(onSuccess, onError, param);
  };

  return (
    <>
      {isMobile ? (
        <CarEmailPopup
          marginClass={marginClass}
          open={open}
          setOpen={setOpen}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          sendQuoteFields={sendQuoteFields}
        />
      ) : (
        <CarEmailPopup
          marginClass={marginClass}
          open={open}
          setOpen={setOpen}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          sendQuoteFields={sendQuoteFields}
        />
      )}
    </>
  );
};
