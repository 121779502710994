export const CAR_PATH = {
  QUOTE_PAGE: "/car/quotes",
  POLICY_DETAILS: "/car-insurance/policy-details",
  PROPOSAL_PAGE: "/car-insurance/proposal-page",
  OWNER_DETAILS: "owner-details",
  VEHICLE_DETAILS: "vehicle-details",
  NOMINEE_DETAILS: "nominee-details",
  ADDRESS_DETAILS: "address-details",
  REVIEW_PAGE: "preview-details",
  POLICY_CONFIRMATION: "/tw-insurance/payment-response",
};
