import { useEffect, useState } from "react";
import TWQuotePage from "../../../../Page/Desktop/TW/QuotePage/TWQuotePage";
import MTWQuotePage from "../../../../Page/Mobile/TwoWheeler/MQuotePage/MTWQuotePage";
import { TW_ADD_FORM_SERVICES } from "../../../../Services/TW/TWAddFormServices";
import { TW_SERVICES } from "../../../../Services/TW/TWServices";
import { TNCB } from "../../../../Services/Type/TCarSlice";
import { TTWAddForm } from "../../../../Services/Type/TTWSlice";
import { TTWProduct } from "../../../../Services/Type/TwoWheeler/TTWProduct";
import { TTWSingleQuoteDetail } from "../../../../Services/Type/TwoWheeler/TTWSingleQuoteDetail";
import { TWSlice } from "../../../../State/Slice_Reducer/TW/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../../State/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateDateDifferenceInDays,
  calculate_age,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TW_PREMIUM_CALC } from "../../../../SupportingFiles/TWPremiumCal";
import { TSearchableDropdown } from "../../../../State/Types/TSearchableDropdown";
import { UpdateTWDTO } from "../../../../Services/DTO/TWDTO/UpdateTWDTO";

function TWQuoteContainer() {
  const isMobile = useIsMobile();
  const {
    QUOTE_LOADER,
    ADDON_STATUS,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    PLAN_TYPE,
    IDV_VALUE,
    IDVApply_Status,
  } = useAppSelector((state) => state.TW);
  const [idv_value, set_idv_value] = useState<{
    value: any;
    warning: boolean;
  }>(IDV_VALUE);
  const { TW } = useAppSelector((state) => state);
  const [allProducts, setAllProducts] = useState<TTWSingleQuoteDetail[]>([]);
  const [quote_list_data, set_quote_list_data] = useState<TTWProduct[]>([
    {
      buy_online_code: "",
      company_code: "",
      id: 0,
      loader: true,
      logo: "https://devstatic.masuraksha.com/images/Go-Digit-General-Insurance.svg",
      product_code: "",
      product_type: "Comprehensive",
    },
  ]);
  const [IDVApplyStatus, setIDVApplyStatus] =
    useState<boolean>(IDVApply_Status);
  const [openModifyDetails, setOpenModifyDetails] = useState(false);
  const [upadte, setupadte] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [min_max_value, setMin_max_value] = useState<{
    lowest: number;
    highest: number;
  }>({
    lowest: 0,
    highest: 0,
  });
  const [quoteNo, setQuoteNo] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [formData, setFormData] = useState<TTWAddForm>(ADD_FORM);

  useEffect(() => {
    setFormData(ADD_FORM);
  }, [ADD_FORM]);

  const RESET_ALL_ADDONS = () => {
    const onSuccess = () => {};
    const onError = () => {};

    TW_SERVICES.BULK_UPDATE_ADDON_STATUS(onSuccess, onError, {
      quote_no: quoteNo,
      type: "TW",
      driver_cover: 0,
      pa_owner_driver: 1,
      passernger_cover: 0,
      road_side_assistance: 0,
    });
  };

  useEffect(() => {
    GET_MAKE_MODEL_LIST();
    GET_FUEL_TYPE_LIST();
    GET_VARIANT_LIST();
    dispatch(TWSlice.actions.ADD_FORM_DATA(formData));
  }, []);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    const id = params.get("id");
    if (id) {
      setQuoteNo(id);
      GET_DETAILS_BY_QUOTE_NO(id);
      setupadte(false);
    }
  }, []);

  useEffect(() => {
    if (upadte && !isEmpty(quoteNo)) {
      get_product_list();
    }

    setupadte(true);
  }, [PLAN_TYPE, quoteNo]);

  useEffect(() => {
    if (!isEmpty(ADD_FORM.business_type) && !isEmpty(quoteNo)) {
      if (ADD_FORM.business_type === "New") {
        dispatch(TWSlice.actions.UPDATE_PLAN_TYPE("Bundle Policy"));
        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "PLAN_TYPE",
            value: [{ key: "Bundle Policy", value: "Bundle Policy" }],
          })
        );
      } else {
        if (calculateDateDifferenceInDays(ADD_FORM.reg_date.value) < 1460) {
          dispatch(TWSlice.actions.UPDATE_PLAN_TYPE("SAOD"));
          dispatch(
            TWSlice.actions.UPDATE_DROPDOWN_MASTER({
              key: "PLAN_TYPE",
              value: [
                { key: "SAOD", value: "SAOD" },
                { key: "Third Party", value: "Third Party" },
              ],
            })
          );
        } else if (
          calculateDateDifferenceInDays(ADD_FORM.reg_date.value) > 1460 &&
          calculateDateDifferenceInDays(ADD_FORM.reg_date.value) < 1825
        ) {
          dispatch(TWSlice.actions.UPDATE_PLAN_TYPE("SAOD"));
          dispatch(
            TWSlice.actions.UPDATE_DROPDOWN_MASTER({
              key: "PLAN_TYPE",
              value: [
                { key: "SAOD", value: "SAOD" },
                { key: "Third Party", value: "Third Party" },
                { key: "Comprehensive", value: "Comprehensive" },
              ],
            })
          );
        } else {
          dispatch(TWSlice.actions.UPDATE_PLAN_TYPE("Comprehensive"));
          dispatch(
            TWSlice.actions.UPDATE_DROPDOWN_MASTER({
              key: "PLAN_TYPE",
              value: [
                { key: "Comprehensive", value: "Comprehensive" },
                { key: "Third Party", value: "Third Party" },
              ],
            })
          );
        }
      }
    }
  }, [ADD_FORM, ADD_FORM_RESPONSE]);

  const filter_product_list = (data: TTWSingleQuoteDetail[]) => {
    set_quote_list_data([]);
    let array_products: TTWProduct[] = [];
    if (data.length > 0) {
      data.forEach((dataValue: TTWSingleQuoteDetail) => {
        if (dataValue) {
          const { product_code, id, buy_online_code } =
            dataValue.productDetails;
          const { logo, company_code } = dataValue.CompanyDetails;
          if (ADD_FORM.business_type === "New") {
            if (
              !`${product_code}`.startsWith("TP") &&
              `${product_code}`.startsWith("TW")
            ) {
              array_products.push({
                company_code: company_code,
                logo: logo,
                id: id,
                product_code: `${product_code}`,
                buy_online_code: buy_online_code,
                loader: true,
                product_type: "Bundle Policy",
              });
            }
          } else if (PLAN_TYPE === "Third Party") {
            if (`${product_code}`.startsWith("TP")) {
              array_products.push({
                company_code: company_code,
                logo: logo,
                id: id,
                product_code: `${product_code}`,
                buy_online_code: buy_online_code,
                loader: true,
                product_type: "Third Party",
              });
            }
          } else if (!`${product_code}`.startsWith("TP")) {
            if (
              Number(calculate_age(`${ADD_FORM.reg_date.value}`)) <= 5 &&
              PLAN_TYPE === "SAOD"
            ) {
              if (`${product_code}`.startsWith("SA")) {
                array_products.push({
                  company_code: company_code,
                  logo: logo,
                  id: id,
                  product_code: `${product_code}`,
                  buy_online_code: buy_online_code,
                  loader: true,
                  product_type: "SAOD",
                });
              }
            } else if (!`${product_code}`.startsWith("SA")) {
              if (
                `${product_code}`.startsWith("TW") &&
                PLAN_TYPE == "Comprehensive"
              ) {
                array_products.push({
                  company_code: company_code,
                  logo: logo,
                  id: id,
                  product_code: `${product_code}`,
                  buy_online_code: buy_online_code,
                  loader: true,
                  product_type: "Comprehensive",
                });
              }
            }
          }
        }
      });
      set_quote_list_data(array_products);
      let chunkSize = 3;
      for (let i = 0; i < array_products.length; i += chunkSize) {
        let chunk = array_products
          .slice(i, i + chunkSize)
          .map((item) => item.id);
        console.log(chunk, "hhhhh");
        single_quote_detail(chunk);
      }
    }
  };

  // single quote detail
  const single_quote_detail = (product_ids: number[]) => {
    const onSuccess = (res: any) => {
      const response: TTWSingleQuoteDetail[] = res.quoteLists;
      setQuoteDataInArray(product_ids, response);

      dispatch(TWSlice.actions.UPDATE_QUOTE_LOADER(false));
    };

    const onError = (err: any) => {
      console.log("----false", err);

      setQuoteDataInArray(product_ids);
    };

    if (isEmpty(quoteNo)) {
      return;
    }
    let param = {
      product_ids: product_ids,
      quote_no: quoteNo,
    };

    switch (PLAN_TYPE) {
      case "Comprehensive":
        TW_SERVICES.GET_SINGLE_QUOTE_COMPREHENSIVE(onSuccess, onError, param);
        break;

      case "Third Party":
        TW_SERVICES.GET_SINGLE_QUOTE_TP(onSuccess, onError, param);
        break;

      case "SAOD":
        TW_SERVICES.GET_SINGLE_QUOTE_SAOD(onSuccess, onError, param);
        break;
      case "Bundle Policy":
        TW_SERVICES.GET_SINGLE_QUOTE_COMPREHENSIVE(onSuccess, onError, param);
        break;
      default:
        break;
    }
  };
  // quotation data in an array
  const setQuoteDataInArray = (
    product_ids: number[],
    quoteResponses?: TTWSingleQuoteDetail[]
  ) => {
    set_quote_list_data((prev_quote_data: TTWProduct[]) => {
      let updatedQuoteData = [...prev_quote_data];

      product_ids.forEach((product_id, index) => {
        const quoteResponse = quoteResponses && quoteResponses[index];
        const existingProductIndex = updatedQuoteData.findIndex(
          (product) => product.id === product_id
        );

        // Check if the existing product index is valid
        if (existingProductIndex !== -1) {
          // Check if quoteResponse and netPremium property exist and netPremium is not zero
          if (
            quoteResponse?.premiumDetails?.netPremium !== undefined &&
            quoteResponse.premiumDetails.netPremium !== 0
          ) {
            // Update the existing product in updatedQuoteData array with quoteResponse details
            updatedQuoteData[existingProductIndex] = {
              ...updatedQuoteData[existingProductIndex],
              loader: false,
              quotationDetail: quoteResponse,
            };
          } else {
            // If netPremium is zero or not available, remove the existing product from updatedQuoteData array
            updatedQuoteData.splice(existingProductIndex, 1);
          }
        }
      });
      return updatedQuoteData;
    });
  };

  useEffect(() => {
    let lowest = Number.MAX_SAFE_INTEGER;
    let highest = 0;

    quote_list_data.forEach((data: TTWProduct) => {
      if (!data.loader) {
        const { premiumDetails } = data.quotationDetail || {};
        const { minimumIDV, maximumIDV } = premiumDetails || {};

        if (minimumIDV !== undefined && minimumIDV < lowest) {
          lowest = minimumIDV;
        }

        if (maximumIDV !== undefined && maximumIDV > highest) {
          highest = maximumIDV;
        }
      }
    });

    setMin_max_value({ lowest, highest });
  }, [quote_list_data]);
  /// get product list
  const get_product_list = () => {
    const onSuccess = (res: any) => {
      let responseArr = res.quoteLists;
      setAllProducts(responseArr);
      filter_product_list(responseArr);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    dispatch(TWSlice.actions.UPDATE_QUOTE_LOADER(true));
    TW_SERVICES.GET_PRODUCT_LIST(onSuccess, onError);
  };
  // check idv value for valid or not
  const check_valid_idv = () => {
    if (
      idv_value.value >= min_max_value.lowest &&
      idv_value.value <= min_max_value.highest
    ) {
      setIDVApplyStatus(true);
      update_idv(idv_value.value);
      set_idv_value((prev) => ({ ...prev, warning: false }));
      dispatch(
        TWSlice.actions.IDV_UPDATE({
          value: `${idv_value.value}`,
          warning: false,
        })
      );
    } else {
      set_idv_value((prev) => ({ ...prev, warning: true }));
    }
  };

  const update_idv = (idv_value: any) => {
    const onSuccess = () => {
      get_product_list();
    };

    const onError = () => {};
    TW_ADD_FORM_SERVICES.UPDATE_IDV(onSuccess, onError, {
      cusIDV: idv_value === "reset" ? "0" : `${idv_value}`,
      type: "TW",
      quote_no: quoteNo,
    });
  };

  useEffect(() => {
    if (upadte && !isEmpty(quoteNo)) {
      sort_quotation();
    }
  }, [ADDON_STATUS]);

  const sort_quotation = () => {
    const sortedData: TTWProduct[] = [...quote_list_data].sort((a, b) => {
      if (a.quotationDetail && !b.quotationDetail) {
        return -1; // Move object 'a' to a lower index (before 'b')
      } else if (!a.quotationDetail && b.quotationDetail) {
        return 1; // Move object 'a' to a higher index (after 'b')
      } else if (!a.quotationDetail && !b.quotationDetail) {
        return 0; // Keep the order of 'a' and 'b' unchanged
      } else {
        if (a.quotationDetail && b.quotationDetail) {
          const premiumA = TW_PREMIUM_CALC(a.quotationDetail, ADDON_STATUS);
          const premiumB = TW_PREMIUM_CALC(b.quotationDetail, ADDON_STATUS);

          return premiumA - premiumB; // Sort in low to high order
        } else {
          return 0; // Keep the order of 'a' and 'b' unchanged
        }
      }
    });

    set_quote_list_data(sortedData);
  };

  useEffect(() => {
    dispatch(TWSlice.actions.SAVE_ALL_QUOTES_DATA(quote_list_data));
  }, [QUOTE_LOADER]);

  const GET_DETAILS_BY_QUOTE_NO = (id: any) => {
    get_product_list();
    const onError = (err: any) => {
      console.log("err", err);
    };
    const onSuccess = (res: any) => {
      const response = res.response;
      const manipulated_data_add_form: TTWAddForm = {
        reg_no: { value: response.registration_no, warning: false },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(response.registration_date),
          warning: false,
        },
        business_type: response.business_type,
        make: { value: "", warning: false },
        model: { value: response.make_model, warning: false },
        make_model: {
          value: { code: response.make_model, label: response.make_model },
          warning: false,
        },
        fuel_type: {
          value: {
            code: response.fuel_type,
            label: response.fuel_type,
          },
          warning: false,
        },
        variant_cc: {
          value: { code: response.variant_cc, label: response.variant_cc },
          warning: false,
        },
        previous_insurer: { value: response.previous_insurer, warning: false },
        mobile: { value: "", warning: false },
        claimed: response.claim_made as "Yes" | "No",
        prev_ncb: response.previous_ncb as TNCB,
        rto: { value: response.rto_code, warning: false },
        policy_expiry_date: {
          value: FORMAT_DATE_DD_MM_YYYY(response.policy_expiry_date),
          warning: false,
        },
        name: { value: "", warning: false },
        new_ncb: { value: response.new_ncb, warning: false },
      };

      dispatch(
        TWSlice.actions.BULK_UPDATE({
          ...TW,
          ADD_FORM: { ...manipulated_data_add_form },
          ADD_FORM_RESPONSE: { ...TW.ADD_FORM_RESPONSE, quote_no: id },
          ADDON_STATUS: {
            zero_depreciation: {
              ...TW.ADDON_STATUS.zero_depreciation,
              value: response.depreciation_cover === 1,
            },
            pa_owner_cover: {
              ...TW.ADDON_STATUS.pa_owner_cover,
              value: response.pa_owner_driver === 1,
            },
            rsa: {
              ...TW.ADDON_STATUS.rsa,
              value: response.road_side_assistance === 1,
            },
            driver_cover: {
              ...TW.ADDON_STATUS.driver_cover,
              value: response.driver_cover === 1,
            },
          },
        })
      );
    };

    TW_SERVICES.GET_DETAILS_BY_QUOTE_NO(onSuccess, onError, id);
  };

  const updateAddOnStatus = (addon: string, value: boolean) => {
    const onSuccess = () => {};

    const onError = () => {};

    const Params = {
      quote_no: quoteNo,
      type: "TW",
      addon: addon,
      value: value ? 1 : 0,
    };

    TW_SERVICES.UPDATE_ADDON_STATUS(onSuccess, onError, Params);
  };

  const updateAddonAndValue = (addonKey: string, value: boolean) => {
    switch (addonKey) {
      case "Driver Cover":
        updateAddOnStatus("driver_cover", value);
        break;

      case "PA Owner Cover":
        break;

      case "24x7 Roadside Assistance":
        updateAddOnStatus("road_side_assistance", value);
        break;

      case "Zero Depreciation":
        updateAddOnStatus("depreciation_cover", value);
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let data: TTWAddForm = formData;

    data = {
      ...data,
      variant_cc: {
        ...data.variant_cc,
        warning: isEmpty(formData.variant_cc.value.label),
      },
    };

    setFormData({ ...data });
    dispatch(TWSlice.actions.ADD_FORM_DATA(data));

    if (data.variant_cc.warning === false) {
      UPDATE_FORM_DETAILS();
    }
  };

  const GET_MAKE_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const results = res.data.results;
      const error = results.error;
      let data: TSearchableDropdown[] = [];
      if (error === false) {
        const response = results.response;
        data = Object.values(response).map((item: any) => ({
          code: item,
          label: item,
        }));
      }

      dispatch(
        TWSlice.actions.SET_DROPDOWN_DATA({
          key: "MAKE_MODEL_LIST",
          value: data,
        })
      );
    };
    const onError = (err: any) => {
      console.log("--false", err);
    };
    TW_ADD_FORM_SERVICES.GET_MAKE_LIST(onError, onSuccess);
  };

  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      let data: TSearchableDropdown[] = [];
      const response = res.results.response;
      const response_array = Object.entries(response);

      response_array?.forEach((elment) => {
        data.push({
          code: `${elment[1]}`,
          label: `${elment[1]}`,
        });
      });

      dispatch(
        TWSlice.actions.SET_DROPDOWN_DATA({
          key: "FUEL_TYPE_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    TW_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onSuccess,
      onError,
      isEmpty(formData.make_model.value.label)
        ? `${formData.model.value}`
        : `${formData.make_model.value.label}`
    );
  };

  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      let data = Object.values(res.results.response).map((item: any) => ({
        code: item,
        label: item,
      }));
      dispatch(
        TWSlice.actions.SET_DROPDOWN_DATA({
          key: "VARIANT_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    TW_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onSuccess,
      onError,

      `${formData.fuel_type.value.label}`,
      `${formData.make_model.value.label}`
    );
  };

  const fieldsUpdateState = (attrName: string, value: any) => {
    if (attrName === "claimed") {
      if (value === "Yes") {
        setFormData((prev) => ({
          ...prev,
          [attrName]: value,
          prev_ncb: "0",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [attrName]: value,
        }));
      }
    } else if (attrName === "prev_ncb" || attrName === "business_type") {
      setFormData((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (
      attrName === "make" ||
      attrName === "model" ||
      attrName === "make_model" ||
      attrName === "variant_cc" ||
      attrName === "fuel_type" ||
      attrName === "rto"
    ) {
      if (attrName === "make_model") {
        setFormData((prev) => ({
          ...prev,
          fuel_type: { value: { code: "", label: "" }, warning: false },
          variant_cc: { value: { code: "", label: "" }, warning: false },
        }));
      } else if (attrName === "fuel_type") {
        setFormData((prev) => ({
          ...prev,
          variant_cc: { value: { code: "", label: "" }, warning: false },
        }));
      }
      if (value === null) {
        setFormData((prev) => ({
          ...prev,
          [attrName]: { value: "", warning: isEmpty(value) },
        }));
        return;
      } else {
        setFormData((prev) => ({
          ...prev,
          [attrName]: { value: value, warning: isEmpty(value) },
        }));
      }
    } else {
      if (attrName === "reg_date") {
        setFormData((prev) => ({
          ...prev,
          policy_expiry_date: {
            value: "",
            warning: false,
          },
          reg_date: {
            value: value,
            warning: isEmpty(value),
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [attrName]: { value: value, warning: isEmpty(value) },
        }));
      }
    }
  };

  const UPDATE_FORM_DETAILS = () => {
    setOpenModifyDetails(false);
    const onSuccess = (res: any) => {
      const results = res.results;
      get_product_list();
      const error = results.error;

      if (error === false) {
        const response = results.response;
        dispatch(TWSlice.actions.ADD_FORM_RESPONSE(response));
      }
    };

    const onError = (err: any) => {
      console.log("updatefalse", err);
    };
    let param: UpdateTWDTO = {
      quote_no: quoteNo,
      type: "TW",
      variant_cc: `${formData.variant_cc.value.label}`,
    };

    TW_SERVICES.UPDATE_DETAILS(onSuccess, onError, param);
  };
  return (
    <>
      {QUOTE_LOADER ? <div className="quoteoverlay" /> : null}
      {isMobile ? (
        <MTWQuotePage
          IDVApplyStatus={IDVApplyStatus}
          check_valid_idv={check_valid_idv}
          get_product_list={get_product_list}
          idv_value={idv_value}
          min_max_value={min_max_value}
          quote_list_data={quote_list_data}
          setIDVApplyStatus={setIDVApplyStatus}
          set_idv_value={set_idv_value}
          update_idv={update_idv}
          updateAddonAndValue={updateAddonAndValue}
          UPDATE_FORM_DETAILS={UPDATE_FORM_DETAILS}
          fieldsUpdateState={fieldsUpdateState}
          formData={formData}
          loader={loader}
          validateForm={validateForm}
          setFormData={setFormData}
          openModifyDetails={openModifyDetails}
          setOpenModifyDetails={setOpenModifyDetails}
        />
      ) : (
        <TWQuotePage
          updateAddonAndValue={updateAddonAndValue}
          IDVApplyStatus={IDVApplyStatus}
          check_valid_idv={check_valid_idv}
          get_product_list={get_product_list}
          idv_value={idv_value}
          min_max_value={min_max_value}
          quote_list_data={quote_list_data}
          setIDVApplyStatus={setIDVApplyStatus}
          set_idv_value={set_idv_value}
          update_idv={update_idv}
          allProducts={allProducts}
          UPDATE_FORM_DETAILS={UPDATE_FORM_DETAILS}
          fieldsUpdateState={fieldsUpdateState}
          formData={formData}
          loader={loader}
          validateForm={validateForm}
          setFormData={setFormData}
          openModifyDetails={openModifyDetails}
          setOpenModifyDetails={setOpenModifyDetails}
          RESET_ALL_ADDONS={RESET_ALL_ADDONS}
        />
      )}
    </>
  );
}

export default TWQuoteContainer;
