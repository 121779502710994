export const DOMAIN = "https://instantbeema.com";
export const DOMAIN2 = "https://nodeapi.instantbeema.com";
export const DOMAIN_CONSTANTS = {
  DEV_URL: "https://uatstatic.insurance.motilaloswal.com",
  PRODUCTION_URL: "https://api.insurance.motilaloswal.com",
};
export const HOME_PAGE_URL = {
  DEV: "https://insuranceuat.motilaloswal.com/",
  PRODUCTION: "https://insurance.motilaloswal.com/",
};
// https://devproposal.evervent.in/term-proposal/basic-details/riders-benefits?type=TM&quote_no=QB1712118881768489525&product_id=1257
