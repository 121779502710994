import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { calculateAgeInDays } from "../../../../../SupportingFiles/HelpingFunction";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import DatePicker from "../../../../InputFields/DatePicker/DatePicker";
import NativeSelectDropdown from "../../../../InputFields/NativeSelectDropdown/NativeSelectDropdown";
import SearchDropdown from "../../../../InputFields/SearchDropdown/SearchDropdown";
import "./../../../../../CommonSCSS/ModalPopup.scss";
import "./../../../../../Component/InputFields/FieldTypes.scss";
import { useAppSelector } from "../../../../../State/hooks";
import { TTWAddForm } from "../../../../../Services/Type/TTWSlice";

const MTWModifyDetails = ({
  open,
  setOpen,
  formData,
  fieldsUpdateState,
  validateForm,
  setFormData,
  loader,
}: {
  open: boolean;
  setOpen: Function;
  loader: boolean;
  formData: TTWAddForm;
  fieldsUpdateState: Function;
  validateForm: Function;
  setFormData: Function;
}) => {
  const { ADD_FORM, DROPDOWN_DATA } = useAppSelector((state) => state.TW);

  return (
    <Modal open={open}>
      <Box className="modalWrapperMobile">
        <Box className="modalContent">
          <Grid className="title" container alignItems="center">
            <Grid xs={10}>
              <h3>{`${ADD_FORM.reg_no.value}`}</h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Button className="closePopup" onClick={() => setOpen(false)} />
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={3}
            marginBottom={"16px"}
          >
            <Grid xs={12}>
              <SearchDropdown
                disabled={true}
                class_name="inputField"
                title="Make and Model"
                value={`${formData.make_model.value.label}`}
                attrName={"make_model"}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                warn_status={formData.make_model.warning}
                error_message={"select Make and Model"}
              />
            </Grid>
            <Grid xs={12}>
              <NativeSelectDropdown
                disabled={true}
                class_name="inputField nativeSelect"
                title="Fuel Type"
                value={formData.fuel_type.value.label}
                attrName={"fuel_type"}
                value_update={fieldsUpdateState}
                data={[{ key: "Petrol", value: "Petrol" }]}
                warn_status={formData.fuel_type.warning}
                error_message={"select fuelType"}
              />
            </Grid>
            <Grid xs={12}>
              <SearchDropdown
                class_name="inputField"
                title="Variant"
                value={formData.variant_cc.value.label}
                attrName={"variant_cc"}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.VARIANT_LIST}
                warn_status={formData.variant_cc.warning}
                error_message={"select Variant"}
              />
            </Grid>
            {/* <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"Reg. Date"}
                value={formData.reg_date.value}
                attrName={"reg_date"}
                value_update={fieldsUpdateState}
                error_message={"select Reg. Date"}
                warn_status={formData.reg_date.warning}
                min_date={15 * 12}
                max_date={3}
              />
            </Grid> */}
            {/* {formData?.previous_insurer?.value === "Do not remember" ? null : (
              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title={"Policy Expiry"}
                  value={formData?.policy_expiry_date.value}
                  attrName={"policy_expiry_date"}
                  value_update={fieldsUpdateState}
                  error_message={"select Policy Expiry"}
                  warn_status={formData?.policy_expiry_date?.warning}
                  min_date={
                    calculateAgeInDays(`${formData.reg_date.value}`) - 89
                  }
                  max_date={-89}
                />
              </Grid>
            )}
            <Grid xs>
              <NativeSelectDropdown
                class_name="inputField nativeSelect"
                title="Previous Insurer"
                value={formData.previous_insurer.value}
                attrName={"previous_insurer"}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                warn_status={formData.previous_insurer.warning}
                error_message={"select previousInsurer"}
              />
            </Grid> */}
          </Grid>
          {/* {formData?.previous_insurer?.value === "Do not remember" ? (
            ""
          ) : (
            <>
              <Grid
                container
                columnSpacing={3}
                rowSpacing={3}
                alignItems="center"
                marginBottom={"16px"}
              >
                <Grid xs={12} textAlign="center">
                  <h3 className="mb-3">Claimed in the last year?</h3>
                  <ToggleButtonGroup
                    className="radioBox"
                    value={formData.claimed}
                    exclusive
                    onChange={(e, new_value) =>
                      fieldsUpdateState("claimed", new_value)
                    }
                  >
                    <ToggleButton value="No">No</ToggleButton>
                    <ToggleButton value="Yes">Yes</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </>
          )} */}
          {/* {formData?.previous_insurer?.value ===
          "Do not remember" ? null : formData.claimed === "No" ? (
            <Grid
              container
              columnSpacing={3}
              rowSpacing={3}
              marginBottom={"16px"}
            >
              <Grid xs={12} textAlign="center">
                <h3 className="mb-2">Previous No Claim Bonus (NCB)</h3>
                <p>(Check it in your last policy.)</p>
                <ToggleButtonGroup
                  className="radioBox mt-4"
                  value={formData.prev_ncb}
                  exclusive
                  onChange={(e, new_value) =>
                    fieldsUpdateState("prev_ncb", new_value)
                  }
                  sx={{ justifyContent: "center" }}
                >
                  <ToggleButton value="0">0%</ToggleButton>
                  <ToggleButton value="20">20%</ToggleButton>
                  <ToggleButton value="25">25%</ToggleButton>
                  <ToggleButton value="35">35%</ToggleButton>
                  <ToggleButton value="45">45%</ToggleButton>
                  <ToggleButton value="50">50%</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          ) : null} */}

          <Grid
            container
            columnSpacing={3}
            rowSpacing={3}
            marginBottom={"16px"}
          >
            <Grid xs={12} textAlign="center" className="ctaBtn">
              <CustomButton
                text_name={"Update Details"}
                class_name="primaryMdBtn"
                onClickFunction={validateForm}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default MTWModifyDetails;
