import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";

function PaymentFailed({ status }: { status: string }) {
  const navigate = useNavigate();

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <img
        style={{ width: "80%", maxWidth: "600px" }}
        src={"./images/warning.svg"}
      />

      <p>Your Transaction was not processed</p>
      <p>Failure</p>
      <Button
        variant="contained"
        onClick={() => {
          navigate(TERM_PATH.PROPOSER_DETAILS);
        }}
      >
        Go Back & Try Again
      </Button>
    </div>
  );
}

export default PaymentFailed;
