import Masonry from "@mui/lab/Masonry";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../../../CommonSCSS/MProposalForms.scss";
import { TAddressDetails } from "../../../../../../State/Types/TAddressDetails";
import { useAppSelector } from "../../../../../../State/hooks";
import {
  CURRENCY_FORMAT,
  FORMAT_NUMBER,
} from "../../../../../../SupportingFiles/HelpingFunction";
import Footer from "../../../../../../Component/Mobile/Term/ProposalForm/Common/Footer/Footer";
import PreviewDetailBox from "../../../../../../Component/Mobile/Term/PreviewDetails/PreviewDetailBox/PreviewDetailBox";
import AddressDetailBox from "../../../../../../Component/Mobile/Term/PreviewDetails/AddressDetailsBox/AddressDetailsBox";

const MPreviewDetails = ({
  loader,
  PreviewDetails,
  permanentAddressDetails,
  mailingAddressDetails,
  address_details,
  continueFunction,
}: {
  loader: boolean;
  PreviewDetails: Array<any>;
  permanentAddressDetails: any;
  mailingAddressDetails: any;
  address_details: TAddressDetails;
  continueFunction: Function;
}) => {
  const selectedQuote = useAppSelector((state) => state.Term.selectedQuote);

  return (
    <Box className="proposalPreviewMobile">
      <h5 className="sectionTitle">Just make sure, all details are correct</h5>
      <Box className="previewDetailBox">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <img src={selectedQuote.logo} alt="logo" />
            <p className="mt-3">{selectedQuote.product_name}</p>
            <p>
              Policy Tenure: <span>{`${selectedQuote.term} Years`}</span>
            </p>
          </Grid>
          <Grid xs={6} textAlign="right">
            <p className="premium">
              Premium: <span>{CURRENCY_FORMAT(selectedQuote.premium)}</span>
            </p>
            <p>
              Sum Assured:{" "}
              <span>{FORMAT_NUMBER(selectedQuote.sum_insured)}</span>
            </p>
            <p>
              Payment Mode: <span>{selectedQuote.payment_mode}</span>
            </p>
          </Grid>
        </Grid>
      </Box>
      <Masonry columns={1} spacing={2} className="px-0 mx-0">
        {PreviewDetails.map((data, index) => (
          <PreviewDetailBox
            editFunction={data.editFunction}
            title={data.title}
            data={data.detailView}
          />
        ))}
        <AddressDetailBox
          editFunction={permanentAddressDetails.editFunction}
          permanentAddress={permanentAddressDetails.detailView}
          mailingAddress={mailingAddressDetails.detailView}
          permanent_mailing_address_same={
            address_details.is_mailing_and_permanent_address_same
          }
        />
      </Masonry>
      <Footer loader={loader} continueFunction={continueFunction} />
    </Box>
  );
};
export default MPreviewDetails;
