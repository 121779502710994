import Masonry from "@mui/lab/Masonry";
import { Box } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import "../../../../../../CommonSCSS/ProposalForms.scss";
import AddressDetailBox from "../../../../../../Component/Desktop/Term/PreviewDetails/AddressDetailsBox/AddressDetailsBox";
import PreviewDetailBox from "../../../../../../Component/Desktop/Term/PreviewDetails/PreviewDetailBox/PreviewDetailBox";
import Footer from "../../../../../../Component/Desktop/Term/ProposalForm/Common/Footer/Footer";
import VerifyOTPContainer from "../../../../../../Container/Term/ICICI/ProposalPage/ProposerDetailsContainer/VerifyOTPContainer/VerifyOTPContainer";
import { ICICI_PROPOSAL_SERVICES } from "../../../../../../Services/TermServices/ICICIProposalServices";
import { TAddressDetails } from "../../../../../../State/Types/TAddressDetails";
import { useAppSelector } from "../../../../../../State/hooks";

const PreviewDetails = ({
  PreviewDetails,
  permanentAddressDetails,
  mailingAddressDetails,
  address_details,
  continueFunction,
  loader,
}: {
  PreviewDetails: Array<any>;
  permanentAddressDetails: any;
  mailingAddressDetails: any;
  address_details: TAddressDetails;
  continueFunction: Function;
  loader: boolean;
}) => {
  const { proposerDetails } = useAppSelector((state) => state.Term);
  const [openOTP, setOpenOTP] = useState<boolean>(false);

  const SEND_OTP = () => {
    const onSuccess = (data: any) => {
      toast.success("OTP has been sent successfully");
      setOpenOTP(true);
    };
    const onError = (err: any) => {
      toast.error("Limit exceeded");
    };

    ICICI_PROPOSAL_SERVICES.SEND_OTP(onSuccess, onError, {
      mobile: `${proposerDetails.mobile.value}`,
      name: `${proposerDetails.full_name.value}`,
    });
  };

  return (
    <Box className="proposalPreview">
      <VerifyOTPContainer
        open={openOTP}
        setOpen={setOpenOTP}
        continue_function={continueFunction}
      />
      <h5 className="sectionTitle">Just make sure, all details are correct</h5>
      <Masonry columns={2} spacing={3} className="px-0 mx-0">
        {PreviewDetails.map((data, index) => (
          <PreviewDetailBox
            editFunction={data.editFunction}
            title={data.title}
            data={data.detailView}
            hideEditButton={index === 0 || index === 1 ? true : false}
          />
        ))}
        <AddressDetailBox
          editFunction={permanentAddressDetails.editFunction}
          permanentAddress={permanentAddressDetails.detailView}
          mailingAddress={mailingAddressDetails.detailView}
          permanent_mailing_address_same={
            address_details.is_mailing_and_permanent_address_same
          }
        />
      </Masonry>
      <Footer
        loading={loader}
        continueFunction={() => {
          SEND_OTP();
        }}
      />
    </Box>
  );
};

export default PreviewDetails;
