import React, { useState } from "react";
import "../../../../CommonSCSS/MQuotePages.scss";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import NativeSelectDropdown from "../../../../Component/InputFields/NativeSelectDropdown/NativeSelectDropdown";
import MDiscountPopup from "../../../../Component/Mobile/Car/QuotePage/MDiscountPopup/MDiscountPopup";
import MTWQuoteCard from "../../../../Component/Mobile/TwoWheeler/QuotePage/MTWQuoteCard/MTWQuoteCard";
import MTWAddons from "../../../../Component/Mobile/TwoWheeler/QuotePage/MTWAddons/MTWAddons";
import { useAppDispatch, useAppSelector } from "../../../../State/hooks";
import {
  CAL_NEW_NCB,
  FORMAT_DATE_DD_MM_YYYY,
  CURRENCY_FORMAT,
} from "../../../../SupportingFiles/HelpingFunction";
import EmailPopup from "../../../../Component/Desktop/Common/EmailPopup/EmailPopup";
import { TProductType } from "../../../../Services/Type/Common/TProductType";
import TWModifyDetailsContainer from "../../../../Container/TW/Quote/TWModifyContainer/TWModifyDetailsContainer";
import { TWSlice } from "../../../../State/Slice_Reducer/TW/TWSlice";
import { TTWProduct } from "../../../../Services/Type/TwoWheeler/TTWProduct";

function MTWQuotePage({
  get_product_list,
  idv_value,
  set_idv_value,
  quote_list_data,
  min_max_value,
  check_valid_idv,
  IDVApplyStatus,
  setIDVApplyStatus,
  updateAddonAndValue,
  update_idv,
  UPDATE_FORM_DETAILS,
  fieldsUpdateState,
  formData,
  loader,
  validateForm,
  setFormData,
  openModifyDetails,
  setOpenModifyDetails,
}: {
  get_product_list: Function;
  setOpenModifyDetails: Function;
  update_idv: Function;
  updateAddonAndValue: Function;
  UPDATE_FORM_DETAILS: Function;
  fieldsUpdateState: Function;
  setFormData: Function;
  validateForm: Function;
  loader: boolean;
  openModifyDetails: any;
  formData: any;
  idv_value: {
    value: number;
    warning: boolean;
  };
  set_idv_value: Function;
  quote_list_data: TTWProduct[];
  min_max_value: {
    lowest: number;
    highest: number;
  };
  check_valid_idv: Function;
  IDVApplyStatus: boolean;
  setIDVApplyStatus: Function;
}) {
  const dispatch = useAppDispatch();
  const {
    QUOTE_LOADER,
    ADDON_STATUS,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    PLAN_TYPE,
    DROPDOWN_DATA,
  } = useAppSelector((state) => state.TW);
  const [fieldValues, setFieldValues] = useState<{
    planType: { value: string; warning: boolean };
  }>({
    planType: { value: "Comprehensive Plans", warning: false },
  });

  const [openAddon, setOpenAddon] = useState<boolean>(false);
  const [openEmail, setOpenEmail] = useState<boolean>(false);

  if (openAddon) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(inputValue) || inputValue === "") {
      set_idv_value((prev: any) => ({
        ...prev,
        value: inputValue,
      }));
    }
  };
  const handleImageClick = () => {
    window.location.href =
      "https://dev.insurance.motilaloswal.com/car-insurance/ckyc-verification";
  };
  return (
    <Box className="quoteWrapperMobile scrollSection">
      <TWModifyDetailsContainer
        open={openModifyDetails}
        setOpen={setOpenModifyDetails}
        UPDATE_FORM_DETAILS={UPDATE_FORM_DETAILS}
        fieldsUpdateState={fieldsUpdateState}
        formData={formData}
        loader={loader}
        validateForm={validateForm}
        setFormData={setFormData}
      />
      <MTWAddons
        open={openAddon}
        setOpen={setOpenAddon}
        updateAddonAndValue={updateAddonAndValue}
      />
      <EmailPopup
        open={openEmail}
        setOpen={setOpenEmail}
        marginClass={"email"}
      />
      <Box className="quoteHeader">
        <Grid
          container
          columnSpacing={3}
          display="flex"
          alignItems="center"
          height={"100%"}
        >
          <Grid xs={6} display={"flex"} alignItems={"center"}>
            <Link className="back"></Link>
            <img
              onClick={handleImageClick}
              src="../images/motilalOswal.svg"
              alt=""
              height={34}
            />
          </Grid>
          <Grid
            xs={6}
            display="flex"
            justifyContent="right"
            alignItems="center"
          >
            <Button
              className="redbtn btn-sm ml-2"
              onClick={() => setOpenEmail(true)}
            >
              <EmailRoundedIcon className="mail" />
              Email Quotes
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className="infoSection mb-3">
        <Grid container columnSpacing={3} display="flex" alignItems="center">
          <Grid xs={12}>
            <Box className="detailBox">
              <Box>
                <h6>{`${ADD_FORM.make_model.value.label}`}</h6>
                <p className="subdetail_p">
                  {" "}
                  {`${ADD_FORM.fuel_type.value.code}, ${ADD_FORM.variant_cc.value.label}`}
                </p>
                <p className="reg_date">
                  Reg. {`${FORMAT_DATE_DD_MM_YYYY(ADD_FORM.reg_date.value)}`}{" "}
                  {/* <span>
                    {" "}
                    {FORMAT_DATE_DD_MM_YYYY(
                      ADD_FORM?.policy_expiry_date.value
                    )}{" "}
                  </span>{" "}
                  New NCB: <span>{ADD_FORM.prev_ncb}% </span> */}
                  <span className="pl-1">
                    {(() => {
                      switch (true) {
                        case ADD_FORM.previous_insurer.value ===
                          "Do not remember":
                          return (
                            <>
                              <span>{`${ADD_FORM.previous_insurer.value}`}</span>
                              <span className="px-2">
                                New NCB:{0}
                                {/* {CAL_NEW_NCB(
                                  ADD_FORM.prev_ncb,
                                  ADD_FORM.claimed,
                                  PLAN_TYPE
                                )} */}
                                %
                              </span>
                            </>
                          );
                        case PLAN_TYPE === "Third Party":
                          return (
                            <>
                              <span>
                                Policy Expiry{" "}
                                {FORMAT_DATE_DD_MM_YYYY(
                                  ADD_FORM?.policy_expiry_date.value
                                )}
                              </span>
                            </>
                          );
                        default:
                          return (
                            <>
                              <span>
                                {PLAN_TYPE === "Bundle Policy" ? null : (
                                  <>
                                    Policy Expiry:
                                    {FORMAT_DATE_DD_MM_YYYY(
                                      ADD_FORM?.policy_expiry_date.value
                                    )}
                                  </>
                                )}
                              </span>
                              {PLAN_TYPE === "Bundle Policy" ? null : (
                                <>
                                  <span className="px-2">
                                    {PLAN_TYPE === "Third Party" ? (
                                      `New NCB:${0}%`
                                    ) : (
                                      <>
                                        New NCB: {`${ADD_FORM.new_ncb.value}`}%
                                      </>
                                    )}
                                  </span>
                                </>
                              )}
                            </>
                          );
                      }
                    })()}
                  </span>
                </p>
              </Box>

              <Box>
                <Link
                  className="modify_link"
                  onClick={() => {
                    setOpenModifyDetails(true);
                  }}
                >
                  Edit
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="filterQuote mb-3">
        <Grid container rowSpacing={2} columnSpacing={2} paddingLeft={"0"}>
          <Grid xs={8} sx={{ borderRight: "1px solid #dee2e6" }}>
            {PLAN_TYPE === "Third Party" ? null : (
              <Box className="idvbox">
                <p>
                  {QUOTE_LOADER ? (
                    <p className="">Choose IDV ₹ ___ - ₹ ___</p>
                  ) : (
                    <p className="">{`Choose IDV ${CURRENCY_FORMAT(
                      quote_list_data.length === 0
                        ? 0
                        : min_max_value.lowest
                        ? min_max_value.lowest
                        : 0
                    )} - ${CURRENCY_FORMAT(
                      min_max_value.highest ? min_max_value.highest : 0
                    )}`}</p>
                  )}
                </p>
                <Box display={"flex"} alignItems={"center"}>
                  <span>₹ </span>
                  <input
                    className="idvinput"
                    type="text"
                    placeholder="Enter IDV"
                    value={idv_value.value === 0 ? "" : idv_value.value}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </Box>
                {idv_value.warning ? (
                  <span className="error">Enter correct IDV</span>
                ) : null}
                <Button
                  className="apply"
                  onClick={() => {
                    check_valid_idv();
                    dispatch(TWSlice.actions.IDVAPPLY_STATUS(true));
                  }}
                  disabled={!idv_value.value}
                >
                  Apply
                </Button>
                {IDVApplyStatus ? (
                  <Link
                    className="reset"
                    onClick={() => {
                      setIDVApplyStatus(false);
                      set_idv_value({
                        value: "",
                        warning: false,
                      });
                      update_idv("reset");
                      dispatch(TWSlice.actions.IDVAPPLY_STATUS(false));
                    }}
                  >
                    Reset
                  </Link>
                ) : null}
              </Box>
            )}
          </Grid>
          <Grid xs={4} alignSelf={"center"}>
            <Button className="addonsBtn" onClick={() => setOpenAddon(true)}>
              Add-on Covers
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className="mplanFound_sec">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="pf_box">
              <Box className="pf_box_left py-1">
                {!QUOTE_LOADER ? (
                  <>
                    <h5>
                      <span>{quote_list_data.length}</span> great plans found
                      for you!
                    </h5>
                    <p>Prices shown are inclusive of GST.</p>
                  </>
                ) : (
                  <h6>Fetching Quotes...</h6>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="quote_number_box">
              <p>
                Quote No <span>{ADD_FORM_RESPONSE.quote_no}</span>
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="quotation-section">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            {quote_list_data.length != 0
              ? quote_list_data.map((data: any) => (
                  <MTWQuoteCard quote={data} />
                ))
              : null}
            {!QUOTE_LOADER && quote_list_data.length === 0 && (
              <div className="no-quote-box">
                <img src="../images/no-quotes.svg" alt="" />
                <h5>Sorry no quotes found!</h5>
                <p>
                  We can't find the quotes as per your enterd details. Please
                  try again.
                </p>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box className="plan_type_section">
        <Grid container>
          <Grid xs={12} alignSelf={"center"}>
            <Box className="planTypeBox">
              <NativeSelectDropdown
                class_name="inputField nativeSelect"
                title="Plan Type"
                value={PLAN_TYPE}
                attrName={["PLAN_TYPE"]}
                value_update={(key: string, value: string) => {
                  const a_value = value as TProductType;
                  dispatch(
                    TWSlice.actions.UPDATE_PLAN_TYPE(a_value as TProductType)
                  );
                }}
                data={DROPDOWN_DATA.PLAN_TYPE}
                warn_status={false}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default MTWQuotePage;
