import { TTWAddonStatus } from "../Services/Type/TwoWheeler/TTWAddonStatus";
import {
  TTWPremiumDetails,
  TTWSingleQuoteDetail,
} from "../Services/Type/TwoWheeler/TTWSingleQuoteDetail";

export const TW_PREMIUM_CALC = (
  quote_details: TTWSingleQuoteDetail | undefined,
  addon_status: TTWAddonStatus
) => {
  if (quote_details === undefined) return 0;

  const premium_details = quote_details.premiumDetails;
  const netPremium =
    premium_details?.netPremium === undefined ? 0 : premium_details.netPremium;
  const paOwnerDriver =
    premium_details?.paOwnerDriver === undefined
      ? 0
      : premium_details.paOwnerDriver;
  const depreciationCover =
    premium_details?.depreciationCover === undefined
      ? 0
      : premium_details.depreciationCover;
  const roadSideAssistance =
    premium_details.roadSideAssistance === undefined
      ? 0
      : premium_details.roadSideAssistance; ;
  const driverCover =
    premium_details?.driverCover === undefined
      ? 0
      : premium_details.driverCover;

  let premium: number =
    netPremium -
    paOwnerDriver -
    depreciationCover -
    roadSideAssistance -
    driverCover;

  if (
    addon_status.pa_owner_cover.value === true ||
    premium_details?.includedPAOwnerDriver === 1
  ) {
    premium = parseFloat(`${premium}`) + parseFloat(`${paOwnerDriver}`);
  }

  if (addon_status.zero_depreciation.value === true) {
    premium = parseFloat(`${premium}`) + parseFloat(`${depreciationCover}`);
  }

  if (
    addon_status.rsa.value === true ||
    premium_details?.freeRoadSideAssistance == 1
  ) {
    premium = parseFloat(`${premium}`) + parseFloat(`${roadSideAssistance}`);
  }

  if (addon_status.driver_cover.value === true) {
    premium = parseFloat(`${premium}`) + parseFloat(`${driverCover}`);
  }

  var tax_amount = (premium / 100) * 18;

  var final_premium = premium + tax_amount;
  let round_final_perium = Math.trunc(final_premium);
  return round_final_perium;
};

export const cal_gst = (value: number) => {
  const gst_amount = (value / 118) * 18;

  return gst_amount;
};

export const shouldDisplayAddonTW = (
  label: string,
  premiumDetails: TTWPremiumDetails | undefined
) => {
  if (premiumDetails === undefined) return false;
  switch (label) {
    case "PA Owner Cover":
      return premiumDetails?.paOwnerDriver > 0;
    case "Zero Depreciation":
      return premiumDetails?.depreciationCover > 0;
    case "24x7 Roadside Assistance":
      return premiumDetails?.roadSideAssistance > 0;
    case "Driver Cover":
      return premiumDetails?.driverCover > 0;
    default:
      return false;
  }
};
