import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LeftSidebar from "../../../Component/Desktop/Term/ProposalForm/LeftSidebar/LeftSidebar";
import Navbar from "../../../Component/Desktop/Term/ProposalForm/Navbar/Navbar";
import MNavbar from "../../../Component/Mobile/Term/ProposalForm/Navbar/MNavbar";
import ProposerDetailsContainer from "../../../Container/Term/MaxLife/ProposalPage/ProposerDetailsContainer/ProposerDetailsContainer";
import RidersBenefitsContainer from "../../../Container/Term/MaxLife/ProposalPage/RidersBenefitsContainer/RidersBenefitsContainer";
import { TERM_PATH } from "../../../RoutesPath/TermPath";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

const MAX_LIFE_ROUTES = ({ frequency }: { frequency: string }) => {
  return (
    <Routes>
      <Route
        path={TERM_PATH.RIDERS_BENEFITS}
        element={
          frequency === "5" ? (
            <ProposerDetailsContainer />
          ) : (
            <RidersBenefitsContainer />
          )
        }
      />
      <Route
        path={TERM_PATH.PROPOSER_DETAILS}
        element={<ProposerDetailsContainer />}
      />
    </Routes>
  );
};

function MaxLifeRoutes({ frequency }: { frequency: string }) {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <BrowserRouter>
          <Box className="proposalWrapperMobile">
            <MNavbar />
            <Grid container spacing={0} className="d-non">
              <Grid xs={12} className="proposalContentSection">
                {MAX_LIFE_ROUTES({ frequency: frequency })}
              </Grid>
            </Grid>
          </Box>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Box className="proposalWrapper">
            <Navbar />
            <Grid container spacing={3} className="pb-0">
              <LeftSidebar class_name="termIcon" />
              <Grid xs className="proposalContentSection">
                {MAX_LIFE_ROUTES({ frequency: frequency })}
              </Grid>
            </Grid>
          </Box>
        </BrowserRouter>
      )}
    </>
  );
}

export default MaxLifeRoutes;
