import EmailIcon from "@mui/icons-material/Email";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "react-circular-progressbar/dist/styles.css";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import { CURRENCY_FORMAT } from "../../../../../SupportingFiles/HelpingFunction";
import {
  TW_PREMIUM_CALC,
  cal_gst,
} from "../../../../../SupportingFiles/TWPremiumCal";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import "./../../../../../CommonSCSS/ModalPopup.scss";
import { TBUYACTION } from "../../../../../Services/Type/TwoWheeler/TWBuyaction";
import { useEffect, useState } from "react";
import { TWSlice } from "../../../../../State/Slice_Reducer/TW/TWSlice";
import { TW_SERVICES } from "../../../../../Services/TW/TWServices";
import { TTWProduct } from "../../../../../Services/Type/TwoWheeler/TTWProduct";

const MTWPolicyDetail = ({
  open,
  setOpen,
  quote,
}: {
  open: boolean;
  setOpen: any;
  quote: TTWProduct;
}) => {
  const {
    ADD_FORM,
    SELECTED_QUOTE_DATA,
    ADDON_STATUS,
    PLAN_TYPE,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.TW);
  const [premium, setPremium] = useState<number>(0);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const premium_details = quote.quotationDetail;

    if (premium_details) {
      const premium_with_tax = TW_PREMIUM_CALC(premium_details, ADDON_STATUS);
      setPremium(premium_with_tax);
    }
  }, [ADDON_STATUS, quote]);
  const handle = () => {
    const onSuccess = () => {};

    const onError = () => {};

    const Params: TBUYACTION = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "TW",
      product_code: quote.product_code,
      productId: quote.id,
      company_code: quote.company_code,
      IDV: quote.quotationDetail?.premiumDetails.IDV,
      finalPremium: premium,
    };

    TW_SERVICES.BUY_ACTION(onSuccess, onError, Params);
    dispatch(TWSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
    window.location.href = `https://dev.insurance.motilaloswal.com/two-wheeler-insurance/ckyc-verification/${btoa(
      `${quote.quotationDetail?.productDetails.id}-${
        ADD_FORM_RESPONSE.business_type === "New" ? "3" : "1"
      }-${ADD_FORM_RESPONSE.quote_no}`
    )}`;
  };
  return (
    <Modal open={open}>
      <Box className="modalWrapperMobile">
        <Box className="modalContent">
          <Grid className="title" container alignItems="center">
            <Grid xs={10} className="ctaBtn">
              <h3>
                Policy Details
                <CustomButton
                  text_name={"Buy"}
                  onClickFunction={handle}
                  class_name="primarySmBtn"
                />
                <CustomButton
                  text_name={<EmailIcon />}
                  class_name="primarySmBtn"
                />
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Button className="closePopup" onClick={() => setOpen(false)} />
            </Grid>
          </Grid>
          <Box className="policyDetail_Popup">
            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid xs={12}>
                <Box className="policyDetailBox">
                  <Grid container spacing={0} alignItems={"center"}>
                    <Grid xs={6}>
                      <img
                        src={SELECTED_QUOTE_DATA?.logo}
                        alt=""
                        className="logo"
                      />
                      {/* <p className="type_tag">Private Bike</p> */}
                    </Grid>
                    <Grid xs={6} textAlign={"right"}>
                      <p className="idv">
                        IDV <span className="grey_txt">(Incl. CNG)</span>{" "}
                        <span className="blue_txt">
                          {" "}
                          {CURRENCY_FORMAT(
                            parseInt(
                              `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV}`
                            )
                          )}
                        </span>
                      </p>
                      <p className="premium">
                        Premium <span className="grey_txt">(Incl. GST)</span>
                        <span className="red_txt">
                          {" "}
                          {CURRENCY_FORMAT(
                            TW_PREMIUM_CALC(
                              SELECTED_QUOTE_DATA?.quotationDetail,
                              ADDON_STATUS
                            )
                          )}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box className="tabs-section">
                  <Box className="tabPanel">
                    <Grid container columnSpacing={0} rowSpacing={0}>
                      <Grid xs={12}>
                        <h5>Vehicle Own Damage</h5>
                        <ul className="addonList mt-3">
                          {PLAN_TYPE === "Bundle Policy" ? null : (
                            <>
                              <p className="tag_p">New NCB</p>
                              <h5 className="mb-4">
                                {PLAN_TYPE === "Third Party"
                                  ? `${0}%`
                                  : `${ADD_FORM.new_ncb.value}%`}
                              </h5>
                            </>
                          )}
                          <li>
                            Basic OD:{" "}
                            <span>
                              {" "}
                              {CURRENCY_FORMAT(
                                parseFloat(
                                  `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicOD}`
                                )
                              )}
                            </span>
                          </li>
                          {parseFloat(
                            `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.ncbDiscount}`
                          ) > 0 ? (
                            <li>
                              NCB Discount:{" "}
                              <span>
                                {" "}
                                {CURRENCY_FORMAT(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.ncbDiscount}`
                                  )
                                )}
                              </span>
                            </li>
                          ) : null}
                          {ADDON_STATUS.zero_depreciation.value &&
                          parseFloat(
                            `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.depreciationCover}`
                          ) > 0 ? (
                            <li>
                              Zero Depreciation:{" "}
                              <span>
                                {" "}
                                {CURRENCY_FORMAT(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.depreciationCover}`
                                  )
                                )}
                              </span>
                            </li>
                          ) : null}

                          {ADDON_STATUS.rsa.value &&
                          parseFloat(
                            `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.roadSideAssistance}`
                          ) > 0 ? (
                            <li>
                              24x7 Roadside Assistance :{" "}
                              <span>
                                {" "}
                                {CURRENCY_FORMAT(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.roadSideAssistance}`
                                  )
                                )}
                              </span>
                            </li>
                          ) : null}

                          {/* <li className="blue_list">
                              Your Net Basic Premium :{" "}
                              <span>
                                {" "}
                                {CURRENCY_FORMAT(
                                  TW_PREMIUM_CALC(
                                    SELECTED_QUOTE_DATA.quotationDetail,
                                    ADDON_STATUS
                                  )
                                )}
                              </span>
                            </li> */}
                        </ul>
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container columnSpacing={0} rowSpacing={0}>
                      <Grid xs={12}>
                        <h5>Third Party Damage</h5>
                        <ul className="addonList mt-3">
                          <li>
                            Basic TP:{" "}
                            <span>
                              {" "}
                              {CURRENCY_FORMAT(
                                parseFloat(
                                  `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicTP}`
                                )
                              )}
                            </span>
                          </li>
                          {ADDON_STATUS.driver_cover.value &&
                          parseFloat(
                            `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.driverCover}`
                          ) > 0 ? (
                            <li>
                              Driver Cover:{" "}
                              <span>
                                {" "}
                                {CURRENCY_FORMAT(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.driverCover}`
                                  )
                                )}
                              </span>
                            </li>
                          ) : null}
                          {ADDON_STATUS.pa_owner_cover.value &&
                          parseFloat(
                            `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.paOwnerDriver}`
                          ) > 0 ? (
                            <li>
                              PA Owner Cover:{" "}
                              <span>
                                {" "}
                                {CURRENCY_FORMAT(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.paOwnerDriver}`
                                  )
                                )}
                              </span>
                            </li>
                          ) : null}
                        </ul>
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container columnSpacing={0} rowSpacing={0}>
                      <Grid xs={12}>
                        <ul className="addonList">
                          <li>
                            Net Premium:{" "}
                            <span>
                              {" "}
                              {CURRENCY_FORMAT(
                                TW_PREMIUM_CALC(
                                  SELECTED_QUOTE_DATA.quotationDetail,
                                  ADDON_STATUS
                                ) -
                                  cal_gst(
                                    TW_PREMIUM_CALC(
                                      SELECTED_QUOTE_DATA?.quotationDetail,
                                      ADDON_STATUS
                                    )
                                  )
                              )}
                            </span>
                          </li>
                          <li>
                            GST 18%:{" "}
                            <span>
                              {" "}
                              {CURRENCY_FORMAT(
                                cal_gst(
                                  TW_PREMIUM_CALC(
                                    SELECTED_QUOTE_DATA?.quotationDetail,
                                    ADDON_STATUS
                                  )
                                )
                              )}
                            </span>
                          </li>
                        </ul>
                        <h6 className="mt-3">
                          Total Premium:
                          <span style={{ float: "right" }}>
                            {CURRENCY_FORMAT(
                              TW_PREMIUM_CALC(
                                SELECTED_QUOTE_DATA.quotationDetail,
                                ADDON_STATUS
                              )
                            )}
                          </span>
                        </h6>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MTWPolicyDetail;
