import EmailIcon from "@mui/icons-material/Email";
import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import "../../../../../CommonSCSS/ModalPopup.scss";
import { TW_SERVICES } from "../../../../../Services/TW/TWServices";
import { TTWProduct } from "../../../../../Services/Type/TwoWheeler/TTWProduct";
import { TBUYACTION } from "../../../../../Services/Type/TwoWheeler/TWBuyaction";
import { TWSlice } from "../../../../../State/Slice_Reducer/TW/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import { CURRENCY_FORMAT } from "../../../../../SupportingFiles/HelpingFunction";
import {
  TW_PREMIUM_CALC,
  cal_gst,
} from "../../../../../SupportingFiles/TWPremiumCal";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import { EMotorCompCode } from "../../../../../State/Enum/EMotorCompCode";
import { CLIENTS } from "../../../../../Shared/Clients";
import { TW_PATH } from "../../../../../RoutesPath/TWPath";
import { CKYC_ROUTES } from "../../../../../RoutesPath/CKYCRoutes";

const TWPolicyDetails = ({
  open,
  setOpen,
  quote,
}: {
  open: boolean;
  setOpen: any;
  quote: TTWProduct;
}) => {
  const {
    ADD_FORM_RESPONSE,
    ADDON_STATUS,
    SELECTED_QUOTE_DATA,
    ADD_FORM,
    PLAN_TYPE,
  } = useAppSelector((state) => state.TW);
  const dispatch = useAppDispatch();
  const [premium, setPremium] = useState<number>(0);
  useEffect(() => {
    const premium_details = quote.quotationDetail;

    if (premium_details) {
      const premium_with_tax = TW_PREMIUM_CALC(premium_details, ADDON_STATUS);
      setPremium(premium_with_tax);
    }
  }, [ADDON_STATUS, quote]);
  const handle = () => {
    const onSuccess = () => {};

    const onError = () => {};

    const Params: TBUYACTION = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "TW",
      product_code: quote.product_code,
      productId: quote.id,
      company_code: quote.company_code,
      IDV: quote.quotationDetail?.premiumDetails.IDV,
      finalPremium: premium,
    };

    TW_SERVICES.BUY_ACTION(onSuccess, onError, Params);
    dispatch(TWSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
    if (
      quote.quotationDetail?.CompanyDetails.company_code ===
        EMotorCompCode.BAJAJ ||
      quote.quotationDetail?.CompanyDetails.company_code === EMotorCompCode.ACKO
    ) {
      window.location.href = `https://${CLIENTS.MOTILAL}${TW_PATH.PROPOSAL_PAGE}/${TW_PATH.OWNER_DETAILS}?type=CAR&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote.quotationDetail?.productDetails.id}`;
    } else {
      window.location.href = `https://${CLIENTS.MOTILAL}${TW_PATH.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=CAR&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote.quotationDetail?.productDetails.id}`;
    }
  };
  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Grid
            className="title mx-0"
            container
            columnSpacing={3}
            alignItems="center"
          >
            <Button className="closePopup" onClick={() => setOpen(false)} />
            <Grid xs={12}>
              <h3>Policy Details</h3>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={0}
            className="quotepolicyDetail mx-3"
          >
            <Grid xs={3}>
              <img
                src={SELECTED_QUOTE_DATA?.quotationDetail?.CompanyDetails.logo}
                alt="Insurer Logo"
                className="insurerLogo"
              />
              <p className="mb-4">Bike Insurance</p>
              {PLAN_TYPE === "Third Party" ? null : (
                <>
                  <p className="tag_p">Vehicle IDV</p>
                  <h5 className="mb-4">
                    {" "}
                    {CURRENCY_FORMAT(
                      parseInt(
                        `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV}`
                      )
                    )}
                  </h5>
                </>
              )}

              <p className="tag_p">Premium (Incl. GST)</p>
              <h5 className="mb-4">
                {" "}
                {CURRENCY_FORMAT(
                  TW_PREMIUM_CALC(
                    SELECTED_QUOTE_DATA?.quotationDetail,
                    ADDON_STATUS
                  )
                )}
              </h5>
              {PLAN_TYPE === "Bundle Policy" ||
              PLAN_TYPE === "Third Party" ? null : (
                <>
                  <p className="tag_p">New NCB</p>
                  <h5 className="mb-4">{`${ADD_FORM.new_ncb.value}`}%</h5>
                </>
              )}

              <Box className="ctaBtn mb-4" onClick={handle}>
                <CustomButton class_name="primaryMdBtn" text_name={"Buy Now"} />
              </Box>

              <Link className="emailLink mb-4">
                <EmailIcon /> Send on Email
              </Link>
            </Grid>
            <Grid xs={9}>
              <Box className="tabPanel">
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <h4>Vehicle Own Damage</h4>
                    <ul className="addonList mt-4">
                      <li>
                        Basic OD:{" "}
                        <span>
                          {CURRENCY_FORMAT(
                            parseFloat(
                              `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicOD}`
                            )
                          )}
                        </span>
                      </li>
                      {parseFloat(
                        `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.ncbDiscount}`
                      ) > 0 ? (
                        <li>
                          NCB Discount (-):{" "}
                          <span>
                            {CURRENCY_FORMAT(
                              parseFloat(
                                `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.ncbDiscount}`
                              )
                            )}
                          </span>
                        </li>
                      ) : null}
                      {parseFloat(
                        `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.odDiscount}`
                      ) > 0 ? (
                        <li>
                          OD Discount (-):{" "}
                          <span>
                            {CURRENCY_FORMAT(
                              parseFloat(
                                `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.odDiscount}`
                              )
                            )}
                          </span>
                        </li>
                      ) : null}
                      {ADDON_STATUS.zero_depreciation.value &&
                      parseFloat(
                        `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.depreciationCover}`
                      ) > 0 ? (
                        <li>
                          Zero Depreciation:{" "}
                          <span>
                            {CURRENCY_FORMAT(
                              parseFloat(
                                `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.depreciationCover}`
                              )
                            )}
                          </span>
                        </li>
                      ) : null}
                      {ADDON_STATUS.rsa.value &&
                      parseFloat(
                        `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.roadSideAssistance}`
                      ) > 0 ? (
                        <li>
                          24x7 Roadside Assistance :{" "}
                          <span>
                            {CURRENCY_FORMAT(
                              parseFloat(
                                `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.roadSideAssistance}`
                              )
                            )}
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  </Grid>
                  <Grid xs={6}>
                    <h4>Third Party Damage</h4>
                    <ul className="addonList mt-4">
                      <li>
                        Basic TP:{" "}
                        <span>
                          {CURRENCY_FORMAT(
                            parseFloat(
                              `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicTP}`
                            )
                          )}
                        </span>
                      </li>
                      <li>
                        {ADDON_STATUS.pa_owner_cover.value &&
                        parseFloat(
                          `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.paOwnerDriver}`
                        ) > 0 ? (
                          <li>
                            PA Owner Cover:{" "}
                            <span>
                              {CURRENCY_FORMAT(
                                parseFloat(
                                  `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.paOwnerDriver}`
                                )
                              )}
                            </span>
                          </li>
                        ) : null}
                        {ADDON_STATUS.driver_cover.value &&
                        parseFloat(
                          `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.driverCover}`
                        ) > 0 ? (
                          <li>
                            Driver Cover:{" "}
                            <span>
                              {CURRENCY_FORMAT(
                                parseFloat(
                                  `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.driverCover}`
                                )
                              )}
                            </span>
                          </li>
                        ) : null}
                      </li>
                    </ul>
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={3}>
                  <Grid xs={6}></Grid>
                  <Grid xs={6}>
                    <ul className="addonList">
                      <li>
                        Net Premium:
                        <span>
                          {CURRENCY_FORMAT(
                            TW_PREMIUM_CALC(
                              SELECTED_QUOTE_DATA.quotationDetail,
                              ADDON_STATUS
                            ) -
                              cal_gst(
                                TW_PREMIUM_CALC(
                                  SELECTED_QUOTE_DATA?.quotationDetail,
                                  ADDON_STATUS
                                )
                              )
                          )}
                        </span>
                      </li>
                      <li>
                        GST 18%:{" "}
                        <span>
                          {CURRENCY_FORMAT(
                            cal_gst(
                              TW_PREMIUM_CALC(
                                SELECTED_QUOTE_DATA?.quotationDetail,
                                ADDON_STATUS
                              )
                            )
                          )}
                        </span>
                      </li>
                    </ul>
                    <h4>
                      Total Premium:{" "}
                      <span style={{ float: "right" }}>
                        {CURRENCY_FORMAT(
                          TW_PREMIUM_CALC(
                            SELECTED_QUOTE_DATA.quotationDetail,
                            ADDON_STATUS
                          )
                        )}
                      </span>
                    </h4>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default TWPolicyDetails;
