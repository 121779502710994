import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { parse, subDays, subMonths } from "date-fns";
import subYears from "date-fns/subYears";
import React, { useEffect, useState } from "react";
import {
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";

/**
 * DatePicker component to display a mobile-friendly date picker.
 *
 * @param title - The title or label for the DatePicker.
 * @param value - The current value of the DatePicker.
 * @param value_update - A function to update the value of the DatePicker.
 * @param attrName - The attribute name used to identify the DatePicker.
 * @param error_message - An optional error message to display when there is an error.
 * @param warn_status - A boolean indicating whether to display a warning status.
 * @param class_name - The CSS class name for styling purposes.
 * @param min_date - The minimum number of years to subtract from the current date (default: 100).
 * @param max_date - The maximum number of years to subtract from the current date (default: 18).
 * @param dateValidation - A boolean indicating whether to validate the selected date within a range (default: false).
 * @returns The DatePicker component.
 */
const DatePicker = ({
  title,
  value,
  value_update,
  attrName,
  error_message = "Select",
  warn_status,
  class_name,
  min_date = 100,
  max_date = 18,
  date_validation_type = "YEARS",
  tooltip = "",
  disabled = false,
  tooltip_status = false,
}: {
  title: string;
  value: any;
  attrName: any;
  value_update: Function;
  error_message?: string;
  warn_status?: boolean;
  class_name?: string;
  min_date?: number | Date;
  max_date?: number | Date;
  date_validation_type?: "YEARS" | "MONTHS" | "DAYS" | "DATE";
  tooltip_status?: boolean;
  tooltip?: string;
  disabled?: boolean;
}) => {
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateValidation, setDateValidation] = useState<boolean>(false);
  const [convertedValue, setConvertedValue] = useState<Date | null>(value);

  useEffect(() => {
    if (!isEmpty(min_date) && !isEmpty(max_date)) {
      setDateValidation(true);
    } else {
      setDateValidation(false);
    }
  }, [min_date, max_date]);

  useEffect(() => {
    switch (date_validation_type) {
      case "YEARS":
        if (typeof min_date === "number")
          setMinDate(subYears(new Date(), min_date));
        break;
      case "MONTHS":
        if (typeof min_date === "number")
          setMinDate(subMonths(new Date(), min_date));
        break;
      case "DAYS":
        if (typeof min_date === "number")
          setMinDate(subDays(new Date(), min_date));
        break;
      case "DATE":
        setMinDate(min_date as Date);
        break;
      default:
        break;
    }

    switch (date_validation_type) {
      case "YEARS":
        if (typeof max_date === "number")
          setMaxDate(subYears(new Date(), max_date));
        break;
      case "MONTHS":
        if (typeof max_date === "number")
          setMaxDate(subMonths(new Date(), max_date));
        break;
      case "DAYS":
        if (typeof max_date === "number")
          setMaxDate(subDays(new Date(), max_date));
        break;
      case "DATE":
        setMaxDate(max_date as Date);
        break;
      default:
        break;
    }
  }, [min_date, max_date, dateValidation]);

  useEffect(() => {
    if (!isEmpty(value)) {
      if (`${value}`.includes("/")) {
        setConvertedValue(parse(value, "dd/MM/yyyy", new Date()));
      } else if (`${value}`.includes("-")) {
        setConvertedValue(parse(value, "dd-MM-yyyy", new Date()));
      } else {
        setConvertedValue(value); // Handle unrecognized format or empty value
      }
    }
  }, [value]);

  return (
    <div className={`inputField datepicker ${class_name}`}>
      <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          disabled={disabled}
          label={title}
          value={convertedValue}
          onChange={(e) => {
            setConvertedValue(e);
          }}
          onClose={() => {
            value_update(attrName, convertedValue);
          }}
          inputFormat="dd-MM-yyyy"
          renderInput={(params) => (
            <React.Fragment>
              <TextField fullWidth {...params} error={false} />
              {tooltip_status ? (
                <Tooltip title={tooltip}>
                  <IconButton
                    aria-label="info"
                    color="primary"
                    style={{ marginLeft: 8 }}
                  >
                    <InfoOutlinedIcon
                      style={{ fontSize: 16, color: "black" }}
                    />
                  </IconButton>
                </Tooltip>
              ) : null}
            </React.Fragment>
          )}
          showToolbar={false}
          closeOnSelect
          defaultCalendarMonth={new Date()}
          minDate={dateValidation ? minDate : null} // Minimum date validation managed by the props; using subYears function to subtract a specified number of years from the current date
          maxDate={dateValidation ? maxDate : null} // Maximum date validation managed by the props; using subYears function to subtract a specified number of years from the current date
        />
        {warn_status ? <span className="error">{error_message}</span> : null}
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
