import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Modal from "@mui/material/Modal";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import "./../../../../../CommonSCSS/ModalPopup.scss";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import { UpdateCarDiscountDTO } from "../../../../../Services/DTO/CarDTO/UpdateCarDiscountDTO";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import { CAR_SERVICES } from "../../../../../Services/CAR/CarServices";
import { CarSlice } from "../../../../../State/Slice_Reducer/Car/CarSlice";

const MDiscountPopup = ({
  open,
  setOpen,
  UPDATE_CAR_DISCOUNT,
}: {
  open: boolean;
  setOpen: Function;
  UPDATE_CAR_DISCOUNT: Function;
}) => {
  const { ADD_FORM, ADD_FORM_RESPONSE } = useAppSelector((state) => state.Car);
  const dispatch = useAppDispatch();
  const [idvvalue, setIdvvalue] = useState({
    idv: { value: "", warning: false },
  });

  const [voluntayDeductible, setVoluntayDeductible] = useState<string | null>(
    "0"
  );
  const [memberAutomobileAssociation, setMemberAutomobileAssociation] =
    useState<string | null>("no");
  const [araiApprovedVehicle, setAraiApprovedVehicle] = useState<string | null>(
    "no"
  );
  const [vehicleOwned, setVehicleOwned] = useState<string | null>("individual");

  const handlevoluntaryDeductible = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setVoluntayDeductible(newAlignment);
  };
  const handleMemberAutomobileAssociation = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setMemberAutomobileAssociation(newAlignment);
  };
  const handlearaiApprovedVehicle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAraiApprovedVehicle(newAlignment);
  };
  const handleVehicleOwned = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setVehicleOwned(newAlignment);
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapperMobile">
        <Box className="modalContent">
          <Grid className="title" container alignItems="center">
            <Grid xs={10}>
              <h3>Apply Discount</h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Button className="closePopup" onClick={() => setOpen(false)} />
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={3}
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <p className="discountTxt_p">Choose a Voluntary Deductible</p>
              <ToggleButtonGroup
                className="radioBox"
                value={ADD_FORM.deductible}
                exclusive
                // onChange={handlevoluntaryDeductible}
                onChange={(e, a) => {
                  dispatch(
                    CarSlice.actions.UPDATE_FORM_DATA([
                      {
                        key: "deductible",
                        value: a,
                      },
                    ])
                  );
                }}
                style={{ justifyContent: "flex-start" }}
              >
                <ToggleButton value="0">₹ 0</ToggleButton>
                <ToggleButton value="2500">₹ 2,500</ToggleButton>
                <ToggleButton value="5000">₹ 5,000</ToggleButton>
                <ToggleButton value="7500">₹ 7,500</ToggleButton>
                <ToggleButton value="15000">₹ 15,500</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {/* <Grid xs={12}>
              <p className="discountTxt_p">
                Is the owner a member of Automobile Association of India?
              </p>
              <ToggleButtonGroup
                className="radioBox"
                value={ADD_FORM.automobile_association}
                exclusive
                // onChange={handleMemberAutomobileAssociation}
                onChange={(e, a) => {
                  dispatch(
                    CarSlice.actions.UPDATE_FORM_DATA([
                      {
                        key: "automobile_association",
                        value: a,
                      },
                    ])
                  );
                }}
              >
                <ToggleButton value="0">No</ToggleButton>
                <ToggleButton value="1">Yes</ToggleButton>
              </ToggleButtonGroup>
            </Grid> */}
            <Grid xs={12}>
              <p className="discountTxt_p">
                Is the vehicle fitted with ARAI approved anti-theft device?
              </p>
              <ToggleButtonGroup
                className="radioBox"
                value={ADD_FORM.anti_theft_device}
                exclusive
                // onChange={handlearaiApprovedVehicle}
                onChange={(e, a) => {
                  dispatch(
                    CarSlice.actions.UPDATE_FORM_DATA([
                      {
                        key: "anti_theft_device",
                        value: a,
                      },
                    ])
                  );
                }}
              >
                <ToggleButton value="no">No</ToggleButton>
                <ToggleButton value="yes">Yes</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid xs={12}>
              <p className="discountTxt_p">Vehicle Owned by</p>
              <ToggleButtonGroup
                className="radioBox"
                value={ADD_FORM.vehicle_owned}
                exclusive
                // onChange={handleVehicleOwned}
                onChange={(e, a) => {
                  dispatch(
                    CarSlice.actions.UPDATE_FORM_DATA([
                      {
                        key: "vehicle_owned",
                        value: a,
                      },
                    ])
                  );
                }}
              >
                <ToggleButton value="Individual">Individual</ToggleButton>
                <ToggleButton value="Organization">Organization</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={3}
            marginBottom={"16px"}
          >
            <Grid xs={12} textAlign="center" className="ctaBtn">
              <CustomButton
                text_name={"Apply Discount"}
                class_name="primaryMdBtn"
                onClickFunction={() => {
                  UPDATE_CAR_DISCOUNT();
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default MDiscountPopup;
