import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router";
import SelectDropdown from "../../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import Footer from "../../../../../../Component/Mobile/Term/ProposalForm/Common/Footer/Footer";
import MAddonCoverQuestion from "../../../../../../Component/Mobile/Term/ProposalForm/MAddonCoverQuestion/MAddonCoverQuestion";
import { TERM_PATH } from "../../../../../../RoutesPath/TermPath";
import { TermSlice } from "../../../../../../State/Slice_Reducer/Term/TermSlice";
import { TDropdown } from "../../../../../../State/Types/TDropdown";
import { useAppDispatch, useAppSelector } from "../../../../../../State/hooks";
import {
  CURRENCY_FORMAT,
  FORMAT_NUMBER,
} from "../../../../../../SupportingFiles/HelpingFunction";
import "./../../../../../../CommonSCSS/MProposalForms.scss";

const MRidersBenefits = ({
  loaderTf001,
  loaderTf003,
  loaderTf004,
  tf001Data,
  tf003Data,
  showWOP,
}: {
  loaderTf001: boolean;
  loaderTf003: boolean;
  loaderTf004: boolean;
  tf001Data: TDropdown[];
  tf003Data: TDropdown[];
  showWOP: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { RIDERS_BENEFITS, selectedQuote } = useAppSelector(
    (state) => state.Term
  );

  return (
    <Box className="proposalPreview">
      <h5 className="sectionTitle">Addon Details</h5>
      <Box className="previewDetailBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid xs={6}>
            <img src={selectedQuote.logo} alt="logo" />
            <p className="mt-3 product_name">{selectedQuote.product_name}</p>
          </Grid>
          <Grid xs={6} textAlign="right">
            <p className="premium mb-2">
              Premium:{" "}
              <span>
                {CURRENCY_FORMAT(
                  RIDERS_BENEFITS.riders_benefits_response.finalPremium
                )}
              </span>
            </p>
            <p className=" mb-2">
              Sum Assured:{" "}
              <span>{FORMAT_NUMBER(selectedQuote.sum_insured)}</span>
            </p>
            <p className="mb-2">
              Payment Mode: <span>{selectedQuote.payment_mode}</span>
            </p>
            <p>
              Policy Tenure: <span>{`${selectedQuote.term} Years`}</span>
            </p>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3}>
        <Grid xs={12}>
          {selectedQuote.age >= 55 ? null : (
            <MAddonCoverQuestion
              loader={loaderTf001}
              addon_amount={`${RIDERS_BENEFITS.riders_benefits_response.riders.TF001?.premium}`}
              question_name="Critical Illness and Disability Rider"
              desc="The critical illness benefit pays claim amount on first diagnosis of any of the 64 critical illnesses. It means the life assured will be paid the critical illness benefit amount immediately without any hospital bills."
              toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf001Status",
                        value: v,
                      },
                    ],
                  })
                );
              }}
              sub_details={
                <Box
                  sx={{
                    maxWidth: "250px",
                    paddingBottom: "16px",
                    paddingTop: "16px",
                  }}
                >
                  <SelectDropdown
                    attrName={""}
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf001Value}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf001Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf001Data}
                    warn_status={false}
                  />
                </Box>
              }
            />
          )}
          <MAddonCoverQuestion
            loader={loaderTf003}
            addon_amount={`${RIDERS_BENEFITS.riders_benefits_response.riders.TF003?.premium}`}
            question_name="Accidental Death"
            desc="Provides extra security in the event of the life insured being disabled due to an accident. In the event of Total Permanent Disability due to accident, a regular monthly income equal to 1% of rider Sum Assured is paid to the life assured for a fixed period of 10 years."
            toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf003Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
            sub_details={
              <Box
                sx={{
                  maxWidth: "250px",
                  paddingBottom: "16px",
                  paddingTop: "16px",
                }}
              >
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf003Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf003Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf003Data}
                  warn_status={false}
                />
              </Box>
            }
          />
          {showWOP ? (
            <MAddonCoverQuestion
              loader={loaderTf004}
              addon_amount={`${RIDERS_BENEFITS.riders_benefits_response.riders.TF004?.premium}`}
              question_name="Waiver of Premium"
              desc="All future premiums are waived on Accidental Total Permanent Disability, and the policy will continue"
              toggle_status={RIDERS_BENEFITS.local_value.tf004Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf004Status",
                        value: false,
                      },
                    ],
                  })
                );
              }}
            />
          ) : null}
          {/* <MAddonCoverQuestion
            loader={false}
            addon_amount={`${RIDERS_BENEFITS.riders_benefits_response.riders.TF005?.premium}`}
            question_name="Terminal Illness"
            desc={
              "Terminal illnesses such as AIDS are not only life threatening but can also spell financial ruin. This plan helps you give them that last fight, paying your insurance money before death. The terminal illnesses benefit is available with this plan and it covers AIDS too. Terminal Illness refers to the high likeliness of death within the next six months as diagnosed by medical practitioners that specialise in the same."
            }
            show_premium={false}
            free={true}
            toggle_status={RIDERS_BENEFITS.local_value.tf005Status}
            value_update={(a: any, v: any) => {}}
          /> */}
        </Grid>
      </Grid>
      <Footer
        continueFunction={() => {
          navigate(TERM_PATH.PROPOSER_DETAILS);
        }}
      />
    </Box>
  );
};

export default MRidersBenefits;
