import { BrowserRouter, Route, Routes } from "react-router-dom";
import TWQuoteContainer from "../../Container/TW/Quote/TWQuoteContainer/TWQuoteContainer";
import { TW_PATH } from "../../RoutesPath/TWPath";

function TWRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={TW_PATH.QUOTE} element={<TWQuoteContainer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default TWRoutes;
