// import { URL_CONSTANTS } from "../../Shared/URLS";
import { URL_CONSTANTS } from "../../Shared/URLS";
import { EMasterDropdown } from "../../State/Enum/EMasterDropdown";
import { GetAPI, PostAPI } from "../AxiosAPIInstance/API";
import { OccupationAPIDTO } from "../DTO/Term/OccupationAPIDTO";

const getDetailsViaQuoteNo = (
  onSuccess: Function,
  onError: Function,
  query?: any
) => {
  GetAPI.call(
    URL_CONSTANTS.GET_QUOTE_DETAILS,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const getOccupationList = (
  onSuccess: Function,
  onError: Function,
  query?: OccupationAPIDTO
) => {
  GetAPI.call(
    URL_CONSTANTS.GET_OCCUPATION_LIST,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const getMaritalStatusList = (
  onSuccess: Function,
  onError: Function,
  query?: OccupationAPIDTO
) => {
  GetAPI.call(
    URL_CONSTANTS.GET_MARITAL_STATUS,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const createPaymentLink = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.CREATE_PAYMENT_LINK,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const hdfcCreatePaymentLink = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  GetAPI.call(
    URL_CONSTANTS.HDFC_TERM_CREATE_PAYMENT_LINK,
    (res: any) => onSuccess(res.data),
    {
      params: dto,
    },
    onError
  );
};

const getPremiumMax = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  GetAPI.call(
    URL_CONSTANTS.getPremiumMax,
    (res: any) => onSuccess(res.data),
    {
      params: dto,
    },
    onError
  );
};

const updateProposalPageDetails = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.UPDATE_PROPOSAL_PAGE_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const updateProposalPageHDFCDetails = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.UPDATE_PROPOSAL_PAGE_HDFC_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const getNomineeRelationshipList = (
  onSuccess: Function,
  onError: Function,
  query?: any
) => {
  GetAPI.call(
    URL_CONSTANTS.GET_NOMINEE_RELATIONSHIP_LIST,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const getCityStateOnBasisOfPincode = (
  onSuccess: Function,
  onError: Function,
  query?: any
) => {
  GetAPI.call(
    URL_CONSTANTS.GET_CITY_STATE,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const getDropdownDataMaster = (
  onSuccess: Function,
  onError: Function,
  product_code: string,
  type: EMasterDropdown,
  buy_online_code: string
) => {
  GetAPI.call(
    URL_CONSTANTS.GET_MASTER_DATA,
    (res: any) => onSuccess(res.data),
    {
      params: {
        product_code: product_code,
        type: type,
        buy_online_code: buy_online_code,
      },
    },
    onError
  );
};

const getDropdownDataMasterICICI = (
  onSuccess: Function,
  onError: Function,
  product_code: string,
  buy_online_code: string
) => {
  GetAPI.call(
    URL_CONSTANTS.GET_MASTER_DATA_ICICI,
    (res: any) => onSuccess(res.data),
    {
      params: {
        product_code: product_code,
        buy_online_code: buy_online_code,
      },
    },
    onError
  );
};

const getInsuranceCompanyMaster = (
  onSuccess: Function,
  onError: Function,
  company_code: string,
  product_type_code: string
) => {
  GetAPI.call(
    URL_CONSTANTS.INSURANCE_COMPANY_MASTER,
    (res: any) => onSuccess(res.data),
    {
      params: {
        company_code: company_code,
        product_type_code: product_type_code,
      },
    },
    onError
  );
};

const getCountryMaster = (onSuccess: Function, onError: Function) => {
  GetAPI.call(
    URL_CONSTANTS.COUNTRY_MASTER,
    (res: any) => onSuccess(res.data),
    {},
    onError
  );
};

const investmentProposalSubmission = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.INVESTMENT_PROPOSAL_SUB,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const sendPaymentConfirmationData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.SEND_PAYMENT_CONFIRMATION_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const getProposalDetails = (
  onSuccess: Function,
  onError: Function,
  query: any
) => {
  GetAPI.call(
    URL_CONSTANTS.GET_TERM_PROPOSAL_DETAILS,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const getTermLandingPage = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.TERM_LANDING_PAGE,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const termGetPremiumICICI = (
  onSuccess: Function,
  onError: Function,
  query: any
) => {
  GetAPI.call(
    URL_CONSTANTS.ICICI_TERM_GET_PREMIUM,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

const GET_EDUCATION_MASTER = (
  onSuccess: Function,
  onError: Function,
  query: any
) => {
  GetAPI.call(
    URL_CONSTANTS.EDUCATION_MASTER,
    (res: any) => onSuccess(res.data),
    {
      params: query,
    },
    onError
  );
};

export const PROPOSAL_PAGE_SERVICES = {
  GET_EDUCATION_MASTER,
  termGetPremiumICICI,
  getDetailsViaQuoteNo,
  getOccupationList,
  getMaritalStatusList,
  updateProposalPageDetails,
  getNomineeRelationshipList,
  getCityStateOnBasisOfPincode,
  createPaymentLink,
  hdfcCreatePaymentLink,
  getDropdownDataMaster,
  getInsuranceCompanyMaster,
  getCountryMaster,
  investmentProposalSubmission,
  updateProposalPageHDFCDetails,
  sendPaymentConfirmationData,
  getProposalDetails,
  getTermLandingPage,
  getDropdownDataMasterICICI,
  getPremiumMax,
};
