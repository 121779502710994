const DOMAIN = "https://dev.insurance.motilaloswal.com";
const DOMAIN2 = "https://nodemofsl.evervent.in";
const NODE_DOMAIN = "https://nodemofsl.evervent.in";
export const CAR_URLS = {
  GET_DETAILS_BY_QUOTE_NO: `${DOMAIN2}/api/v1/premium/quotedetail`,
  ADD_FORM_DATA: `${DOMAIN2}/api/car-insurances/add.json`,
  GET_PRODUCT_LIST: `${DOMAIN2}/api/v1/premium/get-product-lists.json?type=CAR`,
  GET_CASHLESS_GARAGE_LIST: `${DOMAIN2}/api/cashless-hospital-details/get-cashless-garage-details.json`,
  GET_VEHICLE_NO_INFO: `${DOMAIN2}/api/car-insurances/get-vehicle-info.json`,
  GET_PREVIOUS_INSURER_LIST: `${DOMAIN}/api/previous-insurers/get-lists.json`,
  GET_VARIANT_LIST: `${DOMAIN}/api/car-master-datas/get-variant-lists.json`,
  GET_FUEL_TYPE_LIST: `${DOMAIN}/api/car-master-datas/get-fuels.json`,
  GET_MODEL_LIST: `${DOMAIN2}/api/car-master-datas/get-models-by-make.json`,
  GET_MAKE_LIST: `${DOMAIN2}/api/car-master-datas/get-lists.json`,
  GET_RTO_LIST: `${DOMAIN2}/api/rto-product-maps/get-lists.json`,
  GET_MAKE_MODEL: `${DOMAIN}/api/car-master-datas/get-lists.json`,
  UPDATE_DETAILS: `${DOMAIN2}/api/v1/premium/update-variant`,
  GET_SINGLE_QUOTE_COMPREHENSIVE: `${DOMAIN2}/api/v1/premium/car`,
  GET_SINGLE_QUOTE_SAOD: `${DOMAIN2}/api/v1/premium/car/saod`,
  GET_SINGLE_QUOTE_TP: `${DOMAIN2}/api/v1/premium/car/tp`,
  UPDATE_ADDON_STATUS: `${DOMAIN2}/api/v1/premium/update-addon`,
  UPDATE_IDV: `${DOMAIN2}/api/v1/premium/cusIDV`,
  SEND_QUOTE_VIA_EMAIL: `${DOMAIN2}/api/car-insurances/send-email-quote-page.json `,
  PREMIUM_RECALCULATION: `${DOMAIN2}/api/car-insurances/recalculate-premium.json`,
  GET_CITY_STATE_BY_PINCODE: `${DOMAIN2}/api/state-city-maps/get-details.json`,
  UPDATE_CAR_ACCESSORIES: `${DOMAIN2}/api/v1/premium/update-accessories`,
  UPDATE_CAR_DISCOUNT: `${DOMAIN2}/api/v1/premium/update-discount-details`,
  CAPTURE_DATA: `${DOMAIN2}/api/car-proposals/capture-record.json`,
  BUY_ACTION: `${DOMAIN2}/api/v1/premium/buy-action`,
  UPDATE_CNG_KIT: `${DOMAIN2}/api/v1/premium/update-cng-kit`,
  UPDATE_PASSENGER_COVER: `${DOMAIN2}/api/v1/premium/update-passenger-cover`,
  BULK_UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-bulk-addon`,
};
