import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import "../../../../CommonSCSS/MQuotePages.scss";
import NativeSelectDropdown from "../../../../Component/InputFields/NativeSelectDropdown/NativeSelectDropdown";
import MCarAddons from "../../../../Component/Mobile/Car/QuotePage/MCarAddons/MCarAddons";
import MCarQuoteCard from "../../../../Component/Mobile/Car/QuotePage/MCarQuoteCard/MCarQuoteCard";
import CarDiscountPopupContainer from "../../../../Container/CarDiscountPopup/CarDiscountPopupContainer";
import { CarEmailPopupContainer } from "../../../../Container/CarEmailPopupContainer/CarEmailPopupContainer";
import { CarModifyDetailsContainer } from "../../../../Container/CarModifyDetailsContainer/CarModifyDetailsContainer";
import { TAddonsValueType } from "../../../../Services/Type/Car/TAddonsValueType";
import { TCarProduct } from "../../../../Services/Type/Car/TCarProduct";
import { TProductType } from "../../../../Services/Type/Common/TProductType";
import { CarSlice } from "../../../../State/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../State/hooks";
import {
  CURRENCY_FORMAT,
  FORMAT_DATE_DD_MM_YYYY,
} from "../../../../SupportingFiles/HelpingFunction";

function MCarQuotePage({
  get_product_list,
  fieldData,
  setFieldData,
  idv_value,
  set_idv_value,
  quote_list_data,
  min_max_value,
  check_valid_idv,
  IDVApplyStatus,
  setIDVApplyStatus,
  updateMasterState,
  updateAddonAndValue,
  accessories_value_quote_load,
  cng_kit_quote_load,
  update_idv,
}: {
  get_product_list: Function;
  fieldData: TAddonsValueType;
  update_idv: Function;
  updateAddonAndValue: Function;
  setFieldData: Function;
  idv_value: {
    value: number;
    warning: boolean;
  };
  set_idv_value: Function;
  quote_list_data: TCarProduct[];
  min_max_value: {
    lowest: number;
    highest: number;
  };
  check_valid_idv: Function;
  IDVApplyStatus: boolean;
  setIDVApplyStatus: Function;
  updateMasterState: Function;
  accessories_value_quote_load: Function;
  cng_kit_quote_load: Function;
}) {
  const [openModifyDetails, setOpenModifyDetails] = useState<boolean>(false);
  const [openAddon, setOpenAddon] = useState<boolean>(false);
  const [openEmail, setOpenEmail] = useState<boolean>(false);
  const [openDiscountPopup, setOpenDiscountPopup] = useState(false);

  const dispatch = useAppDispatch();
  const {
    QUOTE_LOADER,
    ADDON_STATUS,
    ADD_FORM,
    DROPDOWN_DATA,
    PLAN_TYPE,
    ADD_FORM_RESPONSE,
    IDVApply_Status,
  } = useAppSelector((state) => state.Car);
  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(inputValue) || inputValue === "") {
      set_idv_value((prev: any) => ({
        ...prev,
        value: inputValue,
      }));
    }
  };
  const handleImageClick = () => {
    window.location.href =
      "https://dev.insurance.motilaloswal.com/car-insurance/ckyc-verification";
  };
  return (
    <Box className="quoteWrapperMobile scrollSection">
      {openModifyDetails ? (
        <CarModifyDetailsContainer
          open={openModifyDetails}
          setOpen={setOpenModifyDetails}
          get_product_list={get_product_list}
        />
      ) : null}
      <CarDiscountPopupContainer
        open={openDiscountPopup}
        setOpen={setOpenDiscountPopup}
        get_product_list={get_product_list}
      />
      <MCarAddons
        open={openAddon}
        setOpen={setOpenAddon}
        updateAddonAndValue={updateAddonAndValue}
        updateMasterState={updateMasterState}
        fieldData={fieldData}
        setFieldData={setFieldData}
        accessories_value_quote_load={accessories_value_quote_load}
        cng_kit_quote_load={cng_kit_quote_load}
        get_product_list={get_product_list}
      />
      <CarEmailPopupContainer
        open={openEmail}
        setOpen={setOpenEmail}
        marginClass="email"
      />
      <Box className="quoteHeader">
        <Grid
          container
          columnSpacing={3}
          display="flex"
          alignItems="center"
          height={"100%"}
        >
          <Grid xs={6} display={"flex"} alignItems={"center"}>
            <Link className="back"></Link>
            <img
              onClick={handleImageClick}
              src="../images/motilalOswal.svg"
              alt=""
              height={34}
            />
          </Grid>
          <Grid
            xs={6}
            display="flex"
            justifyContent="right"
            alignItems="center"
          >
            <Button
              className="redbtn btn-sm ml-2"
              onClick={() => setOpenEmail(true)}
            >
              <EmailRoundedIcon className="mail" />
              Email Quotes
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className="infoSection mb-3">
        <Grid container columnSpacing={3} display="flex" alignItems="center">
          <Grid xs={12}>
            <Box className="detailBox">
              <Box>
                <h6> {`${ADD_FORM.make_model.value.label}`} </h6>
                <p className="subdetail_p">
                  {`${ADD_FORM.fuel_type.value.label},${ADD_FORM.variant_cc.value.label}`}{" "}
                </p>
                <p className="reg_date">
                  Reg. Date:
                  <span>
                    {FORMAT_DATE_DD_MM_YYYY(`${ADD_FORM.reg_date.value}`)}
                  </span>{" "}
                  <br />
                  {PLAN_TYPE === "Bundle Policy" ? null : (
                    <span style={{ marginRight: "15px" }}>
                      <>
                        Policy Expiry:
                        {FORMAT_DATE_DD_MM_YYYY(
                          ADD_FORM?.policy_expiry_date.value
                        )}
                      </>
                    </span>
                  )}
                  {PLAN_TYPE === "Bundle Policy" ? null : (
                    <>
                      <span style={{ marginRight: "15px" }}>
                        {PLAN_TYPE === "Third Party" ? (
                          `New NCB:${0}%`
                        ) : (
                          <>
                            New NCB: <span>{`${ADD_FORM.new_ncb.value}`}%</span>
                          </>
                        )}
                      </span>
                    </>
                  )}
                </p>
              </Box>

              <Box>
                <Link
                  className="modify_link"
                  onClick={() => {
                    setOpenModifyDetails(true);
                  }}
                >
                  Edit Details
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="filterQuote mb-3">
        <Grid container rowSpacing={2} columnSpacing={2} paddingLeft={"0"}>
          {PLAN_TYPE === "Third Party" ? null : (
            <Grid xs={8} sx={{ borderRight: "1px solid #dee2e6" }}>
              <Box className="idvbox">
                {QUOTE_LOADER ? (
                  <p>
                    Vehicle IDV <span>₹ ___ - ₹ ___</span>
                  </p>
                ) : (
                  <p>
                    Vehicle IDV{" "}
                    <span>
                      {CURRENCY_FORMAT(
                        quote_list_data.length === 0
                          ? 0
                          : min_max_value.lowest
                          ? min_max_value.lowest
                          : 0
                      )}{" "}
                      -{" "}
                      {CURRENCY_FORMAT(
                        min_max_value.highest ? min_max_value.highest : 0
                      )}
                    </span>
                  </p>
                )}
                <Box display={"flex"} alignItems={"center"}>
                  <span>₹ </span>
                  <input
                    className="idvinput"
                    type="text"
                    placeholder="Enter IDV"
                    value={idv_value.value === 0 ? "" : idv_value.value}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </Box>
                {idv_value.warning ? (
                  <span className="error">Enter correct IDV</span>
                ) : null}

                <Button
                  onClick={() => {
                    check_valid_idv();
                  }}
                  className="apply"
                  disabled={!idv_value.value}
                >
                  Apply
                </Button>
                {IDVApplyStatus ? (
                  <Link
                    onClick={() => {
                      set_idv_value({
                        value: 0,
                        warning: false,
                      });
                      setIDVApplyStatus(false);
                      check_valid_idv("reset");
                      update_idv("reset");
                      get_product_list();
                      dispatch(
                        CarSlice.actions.IDV_UPDATE({
                          value: `${""}`,
                          warning: false,
                        })
                      );
                    }}
                    className="reset"
                  >
                    Reset
                  </Link>
                ) : null}
              </Box>
            </Grid>
          )}

          <Grid xs={4} alignSelf={"center"}>
            <Button className="addonsBtn" onClick={() => setOpenAddon(true)}>
              Add-on Covers
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className="mplanFound_sec">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="pf_box">
              <Box className="pf_box_left">
                <h5>{quote_list_data.length} great plans found for you!</h5>
                <p>Price shown are inclusive of GST.</p>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="quote_number_box">
              <p>
                Quote No <span>{ADD_FORM_RESPONSE.quote_no}</span>
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="quotation-section">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            {quote_list_data.length != 0 ? (
              quote_list_data.map((data) => (
                <MCarQuoteCard quote={data} addon_values={fieldData} />
              ))
            ) : (
              <div className="no-quote-box">
                <img src="../images/no-quotes.svg" alt="" />
                <h5>Sorry no quotes found!</h5>
                <p>
                  We can't find the quotes as per your enterd details. Please
                  try again.
                </p>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box className="plan_type_section">
        <Grid container spacing={3} columnSpacing={0}>
          <Grid xs={8} alignSelf={"center"}>
            <Box className="planTypeBox">
              <NativeSelectDropdown
                class_name="inputField nativeSelect"
                title="Plan Type"
                value={PLAN_TYPE}
                attrName={["PLAN_TYPE"]}
                value_update={(key: string, value: string) => {
                  const a_value = value as TProductType;
                  dispatch(CarSlice.actions.UPDATE_PLAN_TYPE(a_value));
                }}
                data={DROPDOWN_DATA.PLAN_TYPE}
                warn_status={false}
              />
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box className="mdiscount_box">
              <p>Discount</p>
              <Link
                className="apply"
                onClick={() => {
                  setOpenDiscountPopup(true);
                }}
              >
                Apply
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default MCarQuotePage;
