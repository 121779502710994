import { PayloadAction } from "@reduxjs/toolkit";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";
import { TCKYCDetails } from "../../../Types/TCKYCDetails";

const SET_ICICI_CKYC_DETAILS = (
  state: TTermSlice,
  action: PayloadAction<TCKYCDetails>
) => {
  const data: TTermSlice = { ...state, ckycDetails: action.payload };
  return data;
};

export const TERM_CKYC_REDUCER = { SET_ICICI_CKYC_DETAILS };
