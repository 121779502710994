import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { CAR_PATH } from "../../RoutesPath/CarPath";
import { TW_PATH } from "../../RoutesPath/TWPath";
import { PROPOSAL_PAGE_SERVICES } from "../../Services/TermServices/ProposalPageServices";
import { ETermCompanyCode } from "../../State/Enum/ETermCompanyCode";
import { TermSlice } from "../../State/Slice_Reducer/Term/TermSlice";
import { TCKYCDetails } from "../../State/Types/TCKYCDetails";
import { useAppSelector } from "../../State/hooks";
import {
  FORMAT_DD_MM_YYYY,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import HDFCRoutes from "./HDFC/HDFCRoutes";
import ICICIRoutes from "./ICICI/ICICIRoutes";
import MaxLifeRoutes from "./MaxLife/MaxLifeRoutes";

function TERM_ROUTES() {
  const dispatch = useDispatch();
  const { selectedQuote, proposerDetails } = useAppSelector(
    (state) => state.Term
  );
  const [HDFCMedicalData, setHDFCMedicalData] = useState({});
  const [ICICIMedicalData, setICICIMedicalData] = useState<{
    health_details: any;
    covid_details: any;
    question_details: any;
  }>({ health_details: {}, covid_details: {}, question_details: {} });

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");

    if (
      window.location.pathname !== CAR_PATH.QUOTE_PAGE &&
      window.location.pathname !== TW_PATH.QUOTE
    ) {
      if (id) {
        sessionStorage.setItem("id", id);
        getDetailsViaQuoteNo(id);
      } else {
        getDetailsViaQuoteNo(`${sessionStorage.getItem("id")}`);
      }
    }
  }, []);

  const getDetailsViaQuoteNo = (quote_no: string) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (!error) {
        dispatch(
          TermSlice.actions.setQuoteDetails({
            ...selectedQuote,
            ...response,
          })
        );
        getProposerPageDetails(response);
      } else {
        dispatch(
          TermSlice.actions.setQuoteDetails(
            TermSlice.getInitialState().selectedQuote
          )
        );
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };

    const data = {
      quote_no: quote_no,
    };

    dispatch(
      TermSlice.actions.setQuoteDetails(
        TermSlice.getInitialState().selectedQuote
      )
    );
    PROPOSAL_PAGE_SERVICES.getDetailsViaQuoteNo(onSuccess, onError, data);
  };

  const getProposerPageDetails = (data_previous: any) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const proposer_details =
          response.proposer_details && JSON.parse(response.proposer_details);
        const nominee_details =
          response.nominee_details && JSON.parse(response.nominee_details);
        const address_details =
          response.address_details && JSON.parse(response.address_details);
        const medical_details =
          response.underwriting_details &&
          JSON.parse(response.underwriting_details);
        const ckyc_details =
          response.ckyc_details && JSON.parse(response.ckyc_details);

        //Proposer Details
        if (`${response.proposer_details}`.length > 0) {
          dispatch(
            TermSlice.actions.setProposerDetails({
              ...proposerDetails,
              is_proposer_is_insured_member: !!(
                proposer_details.proposer_relationship === "Yes" ||
                proposer_details.proposer_relationship === undefined
              ),
              education: {
                value: proposer_details.proposer_education,
                warning: false,
              },
              full_name: {
                value: proposer_details.proposer_name,
                warning: false,
              },
              dob: {
                value: FORMAT_DD_MM_YYYY(proposer_details.proposer_dob),
                warning: false,
              },
              gender: {
                value: proposer_details.proposer_gender,
                warning: false,
              },
              mobile: {
                value: proposer_details.proposer_mobile,
                warning: false,
              },
              email: { value: proposer_details.proposer_email, warning: false },
              aadhar_number: {
                value: proposer_details.aadhar_number,
                warning: false,
              },
              emergency_number: {
                value: proposer_details.proposer_emergency_no,
                warning: false,
              },
              marital_status: {
                value: proposer_details.proposer_marital_status,
                warning: false,
              },
              annual_income: {
                value: proposer_details.proposer_annual_income,
                warning: false,
              },
              occupation: {
                value: proposer_details.proposer_occupation,
                warning: false,
              },
              org_name: {
                value: proposer_details.proposer_nameOfOrg,
                warning: false,
              },
              org_description: {
                value: proposer_details.proposer_nameOfOrgDesc,
                warning: false,
              },
              my_profession: {
                value: proposer_details.proposer_myProf,
                warning: false,
              },
              industry_type: {
                value: proposer_details.proposer_indsType,
                warning: false,
              },
              industry_type_desc: {
                value: proposer_details.proposer_indsTypeDesc,
                warning: false,
              },
              org_type: {
                value: proposer_details.proposer_orgType,
                warning: false,
              },
              org_type_desc: {
                value: proposer_details.proposer_orgTypeDesc,
                warning: false,
              },
              occupation_desc: {
                value: proposer_details.proposer_occDesc,
                warning: false,
              },
              obj_of_buying_policy: {
                value: proposer_details.proposer_occDesc,
                warning: false,
              },
            })
          );
          dispatch(
            TermSlice.actions.setInsuredDetails({
              full_name: {
                value: proposer_details.insured_name,
                warning: false,
              },
              dob: {
                value: FORMAT_DD_MM_YYYY(proposer_details.insured_dob),
                warning: false,
              },
              gender: {
                value: proposer_details.insured_gender,
                warning: false,
              },
              mobile: {
                value: proposer_details.insured_mobile,
                warning: false,
              },
              email: { value: proposer_details.insured_email, warning: false },
              aadhar_number: {
                value: proposer_details.insured_aadhar,
                warning: false,
              },
              marital_status: {
                value: proposer_details.insured_marital_status,
                warning: false,
              },
              occupation: {
                value: proposer_details.insured_occupation,
                warning: false,
              },
              org_name: {
                value: proposer_details.insured_nameOfOrg,
                warning: false,
              },
              org_desc: {
                value: proposer_details.insured_nameOfOrgDesc,
                warning: false,
              },
              education: {
                value: proposer_details.insured_education,
                warning: false,
              },
            })
          );
        } else {
          dispatch(
            TermSlice.actions.setInsuredDetails(
              TermSlice.getInitialState().insuredDetails
            )
          );
          dispatch(
            TermSlice.actions.setProposerDetails({
              ...TermSlice.getInitialState().proposerDetails,
            })
          );
        }

        //Nominee Details
        if (response.nominee_details) {
          dispatch(
            TermSlice.actions.setNomineeDetails({
              dob: { value: nominee_details.nominee_dob, warning: false },
              full_name: {
                value: nominee_details.nominee_name,
                warning: false,
              },
              gender: { value: nominee_details.nominee_gender, warning: false },
              relationship: {
                value: nominee_details.nominee_relationship,
                warning: false,
              },
              is_beneficiary: false,
              beneficiary: [
                {
                  date_of_birth: { value: "", warning: false },
                  full_name: { value: "", warning: false },
                  relation_to_proposer: { value: "", warning: false },
                  share: { value: "", warning: false },
                },
              ],
              trustee_details: {
                address: { value: "", warning: false },
                city: { value: "", warning: false },
                dob: { value: "", warning: false },
                email: { value: "", warning: false },
                mobileNo: { value: "", warning: false },
                name: { value: "", warning: false },
                panNo: { value: "", warning: false },
                pincode: { value: "", warning: false },
                state: { value: "", warning: false },
                trusteeType: { value: "", warning: false },
              },
            })
          );
        } else {
          dispatch(
            TermSlice.actions.setNomineeDetails(
              TermSlice.getInitialState().nomineeDetails
            )
          );
        }

        //Address Details
        if (response.address_details) {
          dispatch(
            TermSlice.actions.setAddressDetails({
              is_mailing_and_permanent_address_same:
                address_details.address_mailing_same === "Yes" ||
                address_details.address_mailing_same === undefined
                  ? true
                  : false,
              mailing_address: {
                pincode: {
                  value: address_details.address_pincode,
                  warning: false,
                },
                city: address_details.address_city,
                state: address_details.address_state,
                house_no_building: {
                  value: address_details.house_address,
                  warning: false,
                },
                area_town_locality: {
                  value: address_details.address_area,
                  warning: false,
                },
              },
              permanent_address: {
                pincode: {
                  value: address_details.address_mpincode,
                  warning: false,
                },
                city: address_details.address_mcity,
                state: address_details.address_mstate,
                area_town_locality: {
                  value: address_details.address_marea,
                  warning: false,
                },
                house_no_building: {
                  value: address_details.house_maddress,
                  warning: false,
                },
              },
            })
          );
        } else {
          dispatch(
            TermSlice.actions.setAddressDetails({
              is_mailing_and_permanent_address_same: true,
              mailing_address: {
                pincode: { value: "", warning: false },
                city: data_previous.city,
                state: data_previous.state,
                house_no_building: { value: "", warning: false },
                area_town_locality: { value: "", warning: false },
              },
              permanent_address: {
                pincode: { value: data_previous.pincode, warning: false },
                city: "",
                state: "",
                area_town_locality: { value: "", warning: false },
                house_no_building: { value: "", warning: false },
              },
            })
          );
        }

        //HDFC CKYC Details
        if (
          response.ckyc_details &&
          data_previous.company_code === ETermCompanyCode.HDFC
        ) {
          dispatch(
            TermSlice.actions.SET_HDFC_CKYC_DETAILS({
              ckyc_no: { value: ckyc_details.ckyc_no, warning: false },
              father_full_name: {
                value:
                  ckyc_details.father_first_name +
                  " " +
                  ckyc_details.father_last_name,
                warning: false,
              },
              mother_full_name: {
                value:
                  ckyc_details.mother_first_name +
                  " " +
                  ckyc_details.mother_last_name,
                warning: false,
              },
              occupation: {
                value: ckyc_details.cat_occupation,
                warning: false,
              },
              spouse_name: {
                value:
                  ckyc_details.spouse_first_name +
                  " " +
                  ckyc_details.spouse_last_name,
                warning: false,
              },
              employer_name: {
                value: ckyc_details.employer_name,
                warning: false,
              },
              employer_address: {
                value: ckyc_details.employer_address,
                warning: false,
              },
            })
          );
        } else {
          dispatch(
            TermSlice.actions.SET_HDFC_CKYC_DETAILS(
              TermSlice.getInitialState().HDFCCKYCDetails
            )
          );
        }

        //ICICI CKYC Details
        if (
          response.ckyc_details &&
          data_previous.company_code === ETermCompanyCode.ICICI
        ) {
          const data: TCKYCDetails = {
            age_proof: { value: ckyc_details.age_proof, warning: false },
            age_proof_doc_no: {
              value: ckyc_details.age_proof_doc_no,
              warning: false,
            },
            age_proof_exp_date: {
              value: ckyc_details.age_proof_exp_date,
              warning: false,
            },
            address_proof: {
              value: ckyc_details.address_proof,
              warning: false,
            },
            address_proof_doc_no: {
              value: ckyc_details.address_proof_doc_no,
              warning: false,
            },
            address_proof_exp_date: {
              value: ckyc_details.address_proof_exp_date,
              warning: false,
            },
            identity_proof: {
              value: ckyc_details.identity_proof,
              warning: false,
            },
            identity_proof_doc_no: {
              value: ckyc_details.identity_proof_doc_no,
              warning: false,
            },
            identity_proof_exp_date: {
              value: ckyc_details.identity_proof_exp_date,
              warning: false,
            },
            it_proof: {
              value: ckyc_details.it_proof,
              warning: false,
            },
          };

          dispatch(TermSlice.actions.SET_ICICI_CKYC_DETAILS(data));
        } else {
          dispatch(
            TermSlice.actions.SET_HDFC_CKYC_DETAILS(
              TermSlice.getInitialState().HDFCCKYCDetails
            )
          );
        }

        //HDFC Medical Details
        if (data_previous.company_code === ETermCompanyCode.HDFC) {
          if (!isEmpty(response.underwriting_details)) {
            setHDFCMedicalData(medical_details);
          } else {
            dispatch(
              TermSlice.actions.SET_HDFC_MEDICAL_QUESTION(
                TermSlice.getInitialState().TermHDFCMedicalQuestion
              )
            );
          }
        }

        //ICICI Medical Details
        if (data_previous.company_code === ETermCompanyCode.ICICI) {
          if (
            !isEmpty(response.health_details) &&
            !isEmpty(response.covid_details) &&
            !isEmpty(response.question_details)
          ) {
            const health_details =
              response.health_details && JSON.parse(response.health_details);
            const covid_details =
              response.covid_details && JSON.parse(response.covid_details);
            const question_details =
              response.question_details &&
              JSON.parse(response.question_details);

            setICICIMedicalData({
              covid_details: covid_details,
              health_details: health_details,
              question_details: question_details,
            });
          } else {
            dispatch(
              TermSlice.actions.SET_ICICI_MEDICAL_QUESTION(
                TermSlice.getInitialState().TermICICIMedicalQuestion
              )
            );
          }
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getProposalDetails(onSuccess, onError, {
      quote_no: btoa(data_previous.quote_no),
    });
  };

  return (
    <>
      {selectedQuote.company_code === ETermCompanyCode.HDFC ? (
        <HDFCRoutes medicalData={HDFCMedicalData} />
      ) : null}
      {selectedQuote.company_code === ETermCompanyCode.ICICI ? (
        <ICICIRoutes
          covid_details={ICICIMedicalData.covid_details}
          health_details={ICICIMedicalData.health_details}
          question_details={ICICIMedicalData.question_details}
        />
      ) : null}
      {selectedQuote.company_code === ETermCompanyCode.MAX_LIFE ? (
        <MaxLifeRoutes frequency={`${selectedQuote.frequency}`} />
      ) : null}
    </>
  );
}

export default TERM_ROUTES;
