import EditIcon from "@mui/icons-material/Edit";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./PreviewDetailBox.scss";

const PreviewDetailBox = ({
  title,
  data,
  editFunction,
}: {
  editFunction: Function;
  title: string;
  data: Array<{
    label: string;
    label_value: string;
  }>;
}) => {
  return (
    <Box className="previewBox">
      <Grid container spacing={0}>
        <Grid xs={8}>
          <p className="previewBoxTitle">{title}</p>
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            className="previewBoxEdit"
            onClick={() => {
              editFunction();
            }}>
            <EditIcon /> Edit
          </Link>
        </Grid>
        <Grid xs={12}>
          <ul>
            {data.map((data) => (
              <li>
                <p>
                  {data.label}: <span>{data.label_value}</span>
                </p>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreviewDetailBox;
