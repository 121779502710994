// import { URL_CONSTANTS } from "../../Shared/URLS";
import { URL_CONSTANTS } from "../../Shared/URLS";
import { GetAPI, PostAPI } from "../AxiosAPIInstance/API";

const ckyc = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.HDFC_CKYC,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const term_application_submission = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.HDFC_TERM_APP_SUBMISSION,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const get_term_requirement = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.HDFC_TERM_GET_REQUIREMENT,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const get_dropdown_master = (
  onSuccess: Function,
  onError: Function,
  under_writing_code: string
) => {
  GetAPI.call(
    URL_CONSTANTS.HDFC_DROPDOWN_MASTER,
    (res: any) => onSuccess(res.data),
    {
      params: {
        under_writing_code: under_writing_code,
      },
    },
    onError
  );
};

const uploda_doc_image = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.HDFC_UPLOAD_DOCUMENT,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

export const HDFC_PROPOSAL_PAGE_SERVICES = {
  ckyc,
  term_application_submission,
  get_term_requirement,
  get_dropdown_master,
  uploda_doc_image,
};
