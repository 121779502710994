export enum ETermLandingPage {
  PROPOSER_DETAILS = "proposer_page",
  NOMINEE_DETAILS = "nominee_page",
  ADDRESS_DETAILS = "address_page",
  CKYC_DETAILS = "ckyc_page",
  MEDICAL_PAGE = "underwriting_page",
  PERVIEW_PAGE = "preview_page",
  UPLOAD_DOCUMENT = "upload_document",
  POLICY_CONFIRMATION = "policy_confirmation",
}
