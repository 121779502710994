export const ECompanyCode = {
  GO_DIGIT: "G033",
  IFFCO_TOKIO: "G003",
  LIBERTY_VIDEOCON: "G030",
  UNIVERSAL_SOMPO: "G018",
  KOTAK: "G031",
  FUTURE_GENERALI: "G017",
  STAR: "G015",
  HDFC: "L003",
  MAX_LIFE: "L007",
};
