import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UploadDocument from "../../../../../Page/Desktop/Term/ICICI/Proposal/UploadDocument/UploadDocument";
import MUploadDocuments from "../../../../../Page/Mobile/Term/ICICI/Proposal/UploadDocument/MUploadDocuments";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { ETermLandingPage } from "../../../../../State/Enum/ETermLandingPage";
import { useAppSelector } from "../../../../../State/hooks";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

function UploadDocumentContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { selectedQuote } = useAppSelector((state) => state.Term);
  const { HDFCUploadDocDetails } = useAppSelector((state) => state.Term);

  const continueFunction = () => {
    let isValid = true;
    for (const element of HDFCUploadDocDetails.data) {
      const data = element;

      if (data.upload_status === false) {
        isValid = false;
        toast.error("Please upload all documents.");
        return;
      }
    }

    if (isValid) {
      navigate(TERM_PATH.POLICY_CONFIRMATION);
    }
  };

  useEffect(() => {
    getTermLandingPage(
      selectedQuote.quote_no,
      selectedQuote.buy_online_code,
      selectedQuote.company_code
    );
  }, []);

  const getTermLandingPage = (
    quote_no: string,
    buy_online_code: string,
    company_code: string
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (response.currentPage) {
          case ETermLandingPage.POLICY_CONFIRMATION:
            navigate(TERM_PATH.POLICY_CONFIRMATION);
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getTermLandingPage(onSuccess, onError, {
      quote_no: btoa(quote_no),
      buy_online_code: buy_online_code,
    });
  };

  return (
    <>
      {isMobile ? (
        <MUploadDocuments continueFunction={continueFunction} />
      ) : (
        <UploadDocument continueFunction={continueFunction} />
      )}
    </>
  );
}

export default UploadDocumentContainer;
