import { useState } from "react";
import { toast } from "react-toastify";
import VerifyOTP from "../../../../../../Component/Desktop/Common/Buttons/VerifyOTP/VerifyOTP";
import { ICICI_PROPOSAL_SERVICES } from "../../../../../../Services/TermServices/ICICIProposalServices";
import useIsMobile from "../../../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../../../State/hooks";
import MVerifyOTP from "../../../../../../Component/Mobile/Common/VerifyOTP/MVerifyOTP";
import { TOTPDetails } from "../../../../../../State/Types/TOTPDetails";
import { TermSlice } from "../../../../../../State/Slice_Reducer/Term/TermSlice";

const VerifyOTPContainer = ({
  open,
  setOpen,
  continue_function,
}: {
  open: boolean;
  setOpen: any;
  continue_function: Function;
}) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { proposerDetails } = useAppSelector((state) => state.Term);
  const updateMasterState = (attrName: any, value: any) => {
    setOtpField((prev) => ({
      ...prev,
      value: value,
      warning: !(`${value}`.length === 6),
    }));
  };
  const [otpField, setOtpField] = useState<{ value: string; warning: boolean }>(
    { value: "", warning: false }
  );

  const RESEND_OTP = () => {
    const onSuccess = (data: any) => {
      toast.success("OTP has been resent successfully");
    };
    const onError = (err: any) => {
      toast.error("Limit exceeded");
    };

    ICICI_PROPOSAL_SERVICES.RESEND_OTP(onSuccess, onError, {
      mobile: `${proposerDetails.mobile.value}`,
      name: `${proposerDetails.full_name.value}`,
    });
  };

  const validate_otp = () => {
    const { value, warning } = otpField;
    setOtpField((prev) => ({ ...prev, warning: `${value}`.length !== 6 }));
    if (`${value}`.length === 6) {
      VERIFY_OTP();
    }
  };

  const VERIFY_OTP = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const response: TOTPDetails = results.response;

      dispatch(TermSlice.actions.setOTPData(response));
      setOpen(false);
      continue_function(response);
    };
    const onError = (err: any) => {
      console.log(err);
    };

    ICICI_PROPOSAL_SERVICES.VERIFY_OTP(onSuccess, onError, {
      mobile: `${proposerDetails.mobile.value}`,
      receivedOTP: `${otpField.value}`,
    });
  };

  return (
    <>
      {isMobile ? (
        <MVerifyOTP
          open={open}
          setOpen={setOpen}
          otpField={otpField}
          updateMasterState={updateMasterState}
          RESEND_OTP={RESEND_OTP}
          validate_otp={validate_otp}
        />
      ) : (
        <VerifyOTP
          open={open}
          setOpen={setOpen}
          otpField={otpField}
          updateMasterState={updateMasterState}
          RESEND_OTP={RESEND_OTP}
          validate_otp={validate_otp}
        />
      )}
    </>
  );
};

export default VerifyOTPContainer;
