import { TW_URLs } from "../../Shared/URLS/TW/TWURLs";
import { GetAPI, PatchAPI, PostAPI } from "../AxiosAPIInstance/API";
import { EmailQuotesDTO } from "../DTO/TWDTO/EmailQuotesDTO";
import { UpdateTWDTO } from "../DTO/TWDTO/UpdateTWDTO";
import { TBUYACTION } from "../Type/TwoWheeler/TWBuyaction";

const UPDATE_DETAILS = (
  onSuccess: Function,
  onError: Function,
  dto: UpdateTWDTO
) => {
  PatchAPI.call(
    TW_URLs.UPDATE_VARIANTS_CC,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const BULK_UPDATE_ADDON_STATUS = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    type: "TW";
    road_side_assistance: 0 | 1;
    driver_cover: 0 | 1;
    pa_owner_driver: 0 | 1;
    passernger_cover: 0 | 1;
  }
) => {
  PatchAPI.call(
    TW_URLs.BULK_UPDATE_ADDON_STATUS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_SINGLE_QUOTE_COMPREHENSIVE = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    TW_URLs.GET_SINGLE_QUOTE_COMPREHENSIVE,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_SINGLE_QUOTE_SAOD = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    TW_URLs.GET_SINGLE_QUOTE_SAOD,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_SINGLE_QUOTE_TP = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    TW_URLs.GET_SINGLE_QUOTE_TP,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_ADDON_STATUS = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    addon: string;
    value: number;
  }
) => {
  PatchAPI.call(
    TW_URLs.UPDATE_ADDON_STATUS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_IDV = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    cusIDV: string;
  }
) => {
  PostAPI.call(
    TW_URLs.UPDATE_IDV,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_PRODUCT_LIST = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void
) => {
  GetAPI.call(
    TW_URLs.GET_PRODUCT_LIST,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_DETAILS_BY_QUOTE_NO = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  quote_no: string
) => {
  GetAPI.call(
    `${TW_URLs.GET_DETAILS_BY_QUOTE_NO}?type=TW&quote_no=${quote_no}`,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const SEND_QUOTE_VIA_EMAIL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: EmailQuotesDTO
) => {
  PostAPI.call(
    TW_URLs.SEND_QUOTE_VIA_EMAIL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_CASHLESS_GARAGE_LIST = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  company_code: string
) => {
  PostAPI.call(
    TW_URLs.GET_CASHLESS_GARAGE_LIST,
    {
      company_code: company_code,
    },
    (res: any) => onSuccess(res.data),
    onError
  );
};

const Premium_Recalculation = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_code: string;
    quote_no: string;
    car_master_data_id: number;
  }
) => {
  PostAPI.call(
    TW_URLs.PREMIUM_RECALCULATION,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GetCityStateByPin = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_code: string;
    pincode: string;
  }
) => {
  PostAPI.call(
    TW_URLs.GET_CITY_STATE_BY_PINCODE,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const BUY_ACTION = (
  onSuccess: Function,
  onError: Function,
  dto: TBUYACTION
) => {
  PostAPI.call(
    TW_URLs.BUY_ACTION,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
export const TW_SERVICES = {
  GET_DETAILS_BY_QUOTE_NO,
  UPDATE_DETAILS,
  GET_SINGLE_QUOTE_COMPREHENSIVE,
  GET_SINGLE_QUOTE_SAOD,
  GET_SINGLE_QUOTE_TP,
  UPDATE_ADDON_STATUS,
  UPDATE_IDV,
  GET_PRODUCT_LIST,
  SEND_QUOTE_VIA_EMAIL,
  GET_CASHLESS_GARAGE_LIST,
  Premium_Recalculation,
  GetCityStateByPin,
  BUY_ACTION,
  BULK_UPDATE_ADDON_STATUS,
};
