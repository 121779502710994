import { createSlice } from "@reduxjs/toolkit";
import { TW_QUOTE_REDUCERS } from "./TWQuoteReducer";
import { TW_REDUCERS } from "./TWReducer";
import { TTWSlice } from "../../../Services/Type/TTWSlice";

const intitialState: TTWSlice = {
  SELECTED_QUOTE_DATA: {
    buy_online_code: "",
    company_code: "",
    id: 0,
    loader: false,
    logo: "",
    product_code: "",
    product_type: "Comprehensive",
    quotationDetail: {
      premiumDetails: {
        exshowroomPrice: "",
        minimumIDV: 0,
        maximumIDV: 0,
        freeRoadSideAssistance: 0,
        product_code: "",
        company_code: "",
        includedPAOwnerDriver: 0,
        basicOD: 0,
        basicTP: 0,
        depreciationCover: 0,
        driverCover: 0,
        IDV: 0,
        ncbDiscount: 0,
        netPremium: 0,
        odDiscount: 0,
        paOwnerDriver: 0,
        roadSideAssistance: 0,
        secondYear: null,
        serviceTax: 0,
        thirdYear: null,
      },
      CompanyDetails: {
        company_code: "",
        name: "",
        short_desc: "",
        logo: "",
        claim_ratio: "",
      },
      QuoteNo: "",
      productDetailLists: "",
      productDetails: {
        id: 0,
        product_name: "",
        brochure: "",
        product_code: "",
        quote_action: "",
        rate_calc_method: "",
        proposal_method: "",
        tax_calc_method: "",
        discount_calc_method: "",
        rating: 0,
        product_desc: "",
        buy_online_code: "",
        policy_covers: "",
        company_product_map: {
          company_code: "",
        },
      },
    },
  },

  PLAN_TYPE: "Comprehensive",
  SORT_BY: "LOW_TO_HIGH",
  DROPDOWN_DATA: {
    SORT_BY: [
      { key: "LOW_TO_HIGH", value: "Low to High" },
      { key: "HIGH_TO_LOW", value: "High to low" },
    ],
    MAKE_MODEL_LIST: [],
    PASSENGER_COVER_DATA: [
      { key: "50000", value: "50,000" },
      { key: "60000", value: "60,000" },
      { key: "70000", value: "70,000" },
      { key: "80000", value: "80,000" },
      { key: "90000", value: "90,000" },
      { key: "100000", value: "1,00,000" },
      { key: "110000", value: "1,10,000" },
      { key: "120000", value: "1,20,000" },
      { key: "130000", value: "1,30,000" },
      { key: "140000", value: "1,40,000" },
      { key: "150000", value: "1,50,000" },
      { key: "160000", value: "1,60,000" },
      { key: "170000", value: "1,70,000" },
      { key: "180000", value: "1,80,000" },
      { key: "190000", value: "1,90,000" },
      { key: "200000", value: "2,00,000" },
    ],
    POPULAR_MAKE: [
      "Hyundai",
      "Maruti",
      "Volkswagen",
      "Tata",
      "Mahindra",
      "Honda",
      "Kia",
      "Skoda",
      "Toyota",
      "Audi",
      "BMW",
      "Mercedes",
    ],
    RTO_LIST: [],
    NCB: ["0", "20", "25", "35", "45", "50"],
    FUEL_TYPE_LIST: [],
    MAKE_LIST: [],
    MODEL_LIST: [],
    PREVIOUS_INSURER_LIST: [],
    VARIANT_LIST: [],
    PLAN_TYPE: [
      { key: "Comprehensive", value: "Comprehensive" },
      { key: "Third Party", value: "Third Party" },
    ],
  },
  ADD_FORM: {
    name: { value: "", warning: false },
    business_type: "Rollover",
    claimed: "No",
    make_model: { value: { code: "", label: "" }, warning: false },
    fuel_type: { value: { code: "", label: "" }, warning: false },
    make: { value: "", warning: false },
    mobile: { value: "", warning: false },
    model: { value: "", warning: false },
    prev_ncb: "0",
    previous_insurer: { value: "", warning: false },
    reg_date: { value: "", warning: false },
    reg_no: { value: "", warning: false },
    variant_cc: { value: { code: "", label: "" }, warning: false },
    rto: { value: "", warning: false },
    policy_expiry_date: { value: null, warning: false },
    new_ncb: {value:"", warning:false}
  },
  ADD_FORM_RESPONSE: {
    business_type: "",
    car_master_data_id: 0,
    claim_made: "No",
    cubic_capacity: "",
    fuel_type: "",
    insurance_type: "",
    make: "",
    make_model: "",
    manufacture_date: "",
    model: "",
    new_ncb: "0",
    new_policy_date: "",
    policy_expiry_date: "",
    previous_insurer: "",
    previous_ncb: "0",
    quote_no: "",
    registration_date: "",
    registration_no: "",
    rto_code: "",
    seating_capacity: "",
    tp_policy_start_date: "",
    tp_previous_insurer: "",
    variant: "",
    variant_cc: "",
    vehicle_owned: "",
    idv: "",
    actual_reg_no: "",
  },
  ADDON_STATUS: {
    pa_owner_cover: {
      label: "PA Owner Cover",
      value: true,
      ToolTipdata:
        "This add-on provides personal accident coverage for the owner of the insured vehicle.",
    },
    zero_depreciation: {
      label: "Zero Depreciation",
      value: false,
      ToolTipdata:
        "This add-on gives you complete cover on any body parts of the car excluding tyres and batteries.",
    },
    rsa: {
      label: "24x7 Roadside Assistance",
      value: false,
      ToolTipdata:
        "This add-on provides 24/7 roadside assistance in case of breakdown in the middle of the journey",
    },
    driver_cover: {
      label: "Driver Cover",
      value: false,
      ToolTipdata:
        "This add-on provides personal accident coverage for the paid driver of the insured vehicle.",
    },
  },
  OWNER_DETAILS: {
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    dob: { value: "", warning: false },
    gender: { value: "", warning: false },
    maritalStatus: { value: "", warning: false },
    pacover: { value: "", warning: false },
    GST: { value: "", warning: false },
    validPuc: true,
    vehicleRegistered: false,
    isPolicyComprehensive: true,
    includePAOwner: false,
  },
  CKYC_DETAILS: {
    propFullName: { value: "", warning: false },
    ProposerDob: { value: "", warning: false },
    docID: { value: "", warning: false },
    cusdtomertype: { value: "", warning: false },
    verificationDoc: { value: "", warning: false },
    addressDoc: { value: "", warning: false },
    verificationDocFile: { name: "", value: "", warning: false },
    addressDocFile: { name: "", value: "", warning: false },
    gender: { value: "", warning: false },
  },
  NOMINEE_DETAILS: {
    fullName: { value: "", warning: false },
    dob: { value: "", warning: false },
    relationship: { value: "", warning: false },
  },
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    cityState: { value: "", warning: false },
    mailingAddresscityState: { value: "", warning: false },
    mailingAddresspincode: { value: "", warning: false },
    mailingAddressaddressLineOne: { value: "", warning: false },
    mailingAddressaddressLineTwo: { value: "", warning: false },
    isMailingAddressSame: false,
  },
  VEHICLE_DETAILS: {
    chasis_no: { value: "", warning: false },
    engine_no: { value: "", warning: false },
    bank_name: { value: "", warning: false },
    city: { value: "", warning: false },
    mfg_year: { value: "", warning: false },
    policy_exp_date: { value: "", warning: false },
    prev_insurer: { value: "", warning: false },
    prev_policy_no: { value: "", warning: false },
    reg_date: { value: "", warning: false },
    is_vehicle_on_loan: false,
  },

  QUOTE_LOADER: false,
  ALL_QUOTES_DATA: [],
  PAGE_STATUS: false,
  QUOTE_STATUS: false,
    IDV_VALUE:{
    value : "" , warning : false},
  IDVApply_Status: false
};

export const TWSlice = createSlice({
  name: "TWO_WHEELER",
  initialState: intitialState,
  reducers: { ...TW_REDUCERS, ...TW_QUOTE_REDUCERS },
});
