import { Box, Button, Collapse, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TTermMedicalQuestionDetails } from "../../../../../../State/Types/TTermMedicalQuestionDetails";
import { useAppSelector } from "../../../../../../State/hooks";
import RKTextField from "../../../../../../Component/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../../../Component/InputFields/DatePicker/DatePicker";
import Footer from "../../../../../../Component/Desktop/Term/ProposalForm/Common/Footer/Footer";
import MedicalQuestion from "../../../../../../Component/Desktop/Term/ProposalForm/MedicalQuestion/MedicalQuestion";

function MedicalPage({
  medicalQuestion,
  updateMasterState,
  updateMasterStatePreviousPolicy,
  continue_function,
  loader,
}: {
  medicalQuestion: TTermMedicalQuestionDetails;
  updateMasterState: Function;
  updateMasterStatePreviousPolicy: Function;
  continue_function: Function;
  loader: boolean;
}) {
  const { occupation } = useAppSelector((state) => state.Term.proposerDetails);
  const dropdownData = useAppSelector((state) => state.Term.dropdownData);
  const { gender } = useAppSelector((state) => state.Term.insuredDetails);

  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        <Grid container spacing={3}>
          <Grid xs={3} className="weightField">
            <span className="weightIcon"></span>
            <RKTextField
              class_name="inputField"
              title={"Weight (Kg)"}
              value={medicalQuestion.weight_kg.value}
              attrName={["weight_kg"]}
              value_update={updateMasterState}
              warn_status={medicalQuestion.weight_kg.warning}
              number_only={true}
              max_length={3}
            />
          </Grid>
          <Grid xs={5} className="heightField">
            <span className="heightIcon"></span>
            <SelectDropdown
              class_name="inputField mr-2"
              title="Feet"
              value={medicalQuestion.height_ft.value}
              attrName={["height_ft"]}
              value_update={updateMasterState}
              data={dropdownData.heightFeetData}
              warn_status={medicalQuestion.height_ft.warning}
            />
            <SelectDropdown
              class_name="inputField"
              title="Inches"
              value={medicalQuestion.height_inches.value}
              attrName={["height_inches"]}
              value_update={updateMasterState}
              data={dropdownData.heightInchesData}
              warn_status={medicalQuestion.height_inches.warning}
            />
          </Grid>
          {medicalQuestion.question.map((data, index) => {
            const isSpecialOccupation = ["SPVT", "BSEM", "SPRO"].includes(
              `${occupation.value}`
            );

            if (data.main_question.code === "PQ01" && !isSpecialOccupation) {
              return null;
            }
            if (data.main_question.code === "HQ61" && gender.value === "M") {
              return null;
            }

            return (
              <MedicalQuestion
                main_class="medicalQuestion borderBottom"
                icon_class={data.main_question.icon_class}
                question_name={data.main_question.title}
                toggle_button_status={data.main_question.status}
                attrName={["main_question", index, "toggle_status"]}
                value_update={updateMasterState}
                any_question_or_sub_question={
                  <>
                    {data.main_question.code === "PQ03" &&
                    !data.main_question.status ? (
                      <Grid xs={6}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Repository Type"
                          value={medicalQuestion.repository_type.value}
                          attrName={["repository_type"]}
                          value_update={updateMasterState}
                          data={dropdownData.eia}
                          warn_status={medicalQuestion.repository_type.warning}
                        />
                      </Grid>
                    ) : null}
                    <Collapse
                      in={data.main_question.status}
                      className="medicalQuestion-SubSection"
                    >
                      <Grid
                        container
                        columnSpacing={3}
                        rowSpacing={0}
                        className="pr-2 py-0"
                      >
                        {data.main_question.field_data &&
                          data.main_question.field_data.map(
                            (data_fields, index_fields) => (
                              <Grid
                                xs={
                                  data_fields.column === undefined
                                    ? 4
                                    : data_fields.column
                                }
                              >
                                {data_fields.field_type === "textField" ? (
                                  data_fields.code === "HQ167-1" ? (
                                    data.sub_question?.some(
                                      (item) => item.status === true
                                    ) ? (
                                      <RKTextField
                                        class_name="inputField mb-5"
                                        title={data_fields.title}
                                        attrName={[
                                          "main_question",
                                          index,
                                          index_fields,
                                          "textfield",
                                        ]}
                                        value={data_fields.value}
                                        warn_status={data_fields.warning}
                                        value_update={updateMasterState}
                                        number_only={data_fields.numbers_only}
                                      />
                                    ) : null
                                  ) : (
                                    <RKTextField
                                      class_name="inputField mb-5"
                                      title={data_fields.title}
                                      attrName={[
                                        "main_question",
                                        index,
                                        index_fields,
                                        "textfield",
                                      ]}
                                      value={data_fields.value}
                                      warn_status={data_fields.warning}
                                      value_update={updateMasterState}
                                      number_only={data_fields.numbers_only}
                                    />
                                  )
                                ) : data_fields.field_type === "dropdown" ? (
                                  <SelectDropdown
                                    class_name="inputField mb-5"
                                    title={data_fields.title}
                                    value={data_fields.value}
                                    attrName={[
                                      "main_question",
                                      index,
                                      index_fields,
                                      "textfield",
                                    ]}
                                    value_update={updateMasterState}
                                    data={
                                      data_fields.code === "HQ05-1"
                                        ? dropdownData.tobacco
                                        : data_fields.code === "HQ06-1"
                                        ? dropdownData.alcohol
                                        : data_fields.code === "PQ04-1"
                                        ? dropdownData.insuranceComp
                                        : data_fields.code === "CHQ38-1" ||
                                          data_fields.code === "CHQ43-1" ||
                                          data_fields.code === "CHQ43-SQ1-1" ||
                                          data_fields.code === "CHQ43-SQ2-1" ||
                                          data_fields.code === "CHQ56-1" ||
                                          data_fields.code === "CHQ56-SQ1-1" ||
                                          data_fields.code === "CHQ56-SQ2-1"
                                        ? dropdownData.country
                                        : data_fields.code === "PQ01-1"
                                        ? dropdownData.indsType
                                        : data_fields.code === "PQ03-2"
                                        ? dropdownData.eia
                                        : data_fields.code === "CHQ30-2" ||
                                          data_fields.code === "CHQ33-2"
                                        ? dropdownData.vaccine
                                        : []
                                    }
                                    warn_status={data_fields.warning}
                                  />
                                ) : data_fields.field_type === "datePicker" ? (
                                  <DatePicker
                                    class_name="inputField mb-5"
                                    title={data_fields.title}
                                    value={data_fields.value}
                                    attrName={[
                                      "main_question",
                                      index,
                                      index_fields,
                                      "textfield",
                                    ]}
                                    value_update={updateMasterState}
                                    warn_status={data_fields.warning}
                                    min_date={
                                      data_fields.code === "CHQ38-3"
                                        ? 1
                                        : data_fields.code === "CHQ56-3" ||
                                          data_fields.code === "CHQ56-4"
                                        ? 0
                                        : data_fields.code === "CHQ43-3" ||
                                          data_fields.code === "CHQ43-4"
                                        ? 14
                                        : data_fields.code === "CHQ30-1"
                                        ? new Date(2020, 0, 1)
                                        : 100
                                    }
                                    date_validation_type={
                                      data_fields.code === "CHQ56-3" ||
                                      data_fields.code === "CHQ56-4"
                                        ? "MONTHS"
                                        : data_fields.code === "CHQ43-3" ||
                                          data_fields.code === "CHQ43-4"
                                        ? "DAYS"
                                        : data_fields.code === "CHQ30-1"
                                        ? "DATE"
                                        : "YEARS"
                                    }
                                    max_date={
                                      data_fields.code === "CHQ38-3"
                                        ? 0
                                        : data_fields.code === "CHQ56-3" ||
                                          data_fields.code === "CHQ56-4"
                                        ? -3
                                        : data_fields.code === "CHQ43-3" ||
                                          data_fields.code === "CHQ43-4"
                                        ? 0
                                        : data_fields.code === "CHQ30-1"
                                        ? new Date()
                                        : -5
                                    }
                                  />
                                ) : null}
                              </Grid>
                            )
                          )}
                        {data.sub_question &&
                          data.sub_question.map(
                            (data_sub_question, index_sub_question) => (
                              <MedicalQuestion
                                main_class="medicalQuestion borderBottom"
                                icon_class={data_sub_question.icon_class}
                                question_name={data_sub_question.title}
                                toggle_button_status={data_sub_question.status}
                                attrName={[
                                  "sub_question",
                                  index,
                                  index_sub_question,
                                  "toggle_status",
                                ]}
                                value_update={updateMasterState}
                                any_question_or_sub_question={
                                  data_sub_question.status === true ? (
                                    <Collapse
                                      in={data_sub_question.status}
                                      className="medicalQuestion-SubSection"
                                    >
                                      <Grid
                                        container
                                        spacing={3}
                                        className="pr-2"
                                      >
                                        {data_sub_question.field_data &&
                                          data_sub_question.field_data.map(
                                            (
                                              data_sub_question_fields,
                                              index_sub_question_fields
                                            ) => (
                                              <Grid
                                                xs={
                                                  data_sub_question_fields.column ===
                                                  undefined
                                                    ? 4
                                                    : data_sub_question_fields.column
                                                }
                                              >
                                                {data_sub_question_fields.field_type ===
                                                "textField" ? (
                                                  <RKTextField
                                                    class_name="inputField mb-5"
                                                    title={
                                                      data_sub_question_fields.title
                                                    }
                                                    attrName={[
                                                      "sub_question",
                                                      index,
                                                      index_sub_question,
                                                      index_sub_question_fields,
                                                      "textfield",
                                                    ]}
                                                    value={
                                                      data_sub_question_fields.value
                                                    }
                                                    warn_status={
                                                      data_sub_question_fields.warning
                                                    }
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    number_only={
                                                      data_sub_question_fields.numbers_only
                                                    }
                                                  />
                                                ) : data_sub_question_fields.field_type ===
                                                  "dropdown" ? (
                                                  <SelectDropdown
                                                    class_name="inputField mb-5"
                                                    title={
                                                      data_sub_question_fields.title
                                                    }
                                                    value={
                                                      data_sub_question_fields.value
                                                    }
                                                    attrName={[
                                                      "sub_question",
                                                      index,
                                                      index_sub_question,
                                                      index_sub_question_fields,
                                                      "textfield",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={
                                                      data_sub_question_fields.code ===
                                                      "HQ05-1"
                                                        ? dropdownData.tobacco
                                                        : data_sub_question_fields.code ===
                                                          "HQ06-1"
                                                        ? dropdownData.alcohol
                                                        : data_sub_question_fields.code ===
                                                          "PQ04-1"
                                                        ? dropdownData.insuranceComp
                                                        : data_sub_question_fields.code ===
                                                            "CHQ38-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ43-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ43-SQ1-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ43-SQ2-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-1"
                                                        ? dropdownData.country
                                                        : data_sub_question_fields.code ===
                                                          "PQ01-1"
                                                        ? dropdownData.indsType
                                                        : data_sub_question_fields.code ===
                                                            "CHQ30-2" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ33-2"
                                                        ? dropdownData.vaccine
                                                        : []
                                                    }
                                                    warn_status={
                                                      data_sub_question_fields.warning
                                                    }
                                                  />
                                                ) : data_sub_question_fields.field_type ===
                                                  "datePicker" ? (
                                                  <DatePicker
                                                    class_name="inputField mb-5"
                                                    title={
                                                      data_sub_question_fields.title +
                                                      data_sub_question_fields.code
                                                    }
                                                    value={
                                                      data_sub_question_fields.value
                                                    }
                                                    attrName={[
                                                      "sub_question",
                                                      index,
                                                      index_sub_question,
                                                      index_sub_question_fields,
                                                      "textfield",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      data_sub_question_fields.warning
                                                    }
                                                    min_date={
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ1-3" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ1-4" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ2-3" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ2-4"
                                                        ? 14
                                                        : data_sub_question_fields.code ===
                                                            "CHQ33-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ14-SQ1-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ14-SQ1-2"
                                                        ? new Date(2020, 0, 1)
                                                        : data_sub_question_fields.code ===
                                                            "CHQ56-SQ1-3" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ1-4" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ2-3" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ2-4"
                                                        ? 0
                                                        : 100
                                                    }
                                                    date_validation_type={
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ1-3" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ1-4" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ2-3" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ2-4"
                                                        ? "DAYS"
                                                        : data_sub_question_fields.code ===
                                                            "CHQ33-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ14-SQ1-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ14-SQ1-2"
                                                        ? "DATE"
                                                        : data_sub_question_fields.code ===
                                                            "CHQ56-SQ1-3" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ1-4" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ2-3" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ2-4"
                                                        ? "MONTHS"
                                                        : "YEARS"
                                                    }
                                                    max_date={
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ1-3" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ1-4" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ2-3" ||
                                                      data_sub_question_fields.code ===
                                                        "CHQ43-SQ2-4"
                                                        ? 0
                                                        : data_sub_question_fields.code ===
                                                            "CHQ33-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ14-SQ1-1" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ14-SQ1-2"
                                                        ? new Date()
                                                        : data_sub_question_fields.code ===
                                                            "CHQ56-SQ1-3" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ1-4" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ2-3" ||
                                                          data_sub_question_fields.code ===
                                                            "CHQ56-SQ2-4"
                                                        ? -3
                                                        : -5
                                                    }
                                                  />
                                                ) : null}
                                              </Grid>
                                            )
                                          )}
                                      </Grid>
                                    </Collapse>
                                  ) : null
                                }
                              />
                            )
                          )}
                      </Grid>
                    </Collapse>
                  </>
                }
              />
            );
          })}
          <MedicalQuestion
            main_class="medicalQuestion borderBottom"
            icon_class={"insurancepolicy"}
            question_name={
              "Details of Life Insurance/Mediclaim/Health/Personal Accident policies of the life to be Assured held/ applied with ICICI Prudential/ other companies."
            }
            sub_details="Have any such proposals on your life / application for reinstatement ever been accepted with extra premium, postponement, decline, withdrawal, non completion, been offered on modified terms. If Yes, please provide details."
            toggle_button_status={
              medicalQuestion.previous_policy.main_question_status
            }
            attrName={"main_question_status"}
            value_update={updateMasterStatePreviousPolicy}
          />
          {medicalQuestion.previous_policy.main_question_status === true ? (
            <>
              {medicalQuestion.previous_policy.details.map((data, index) => (
                <>
                  <Grid xs={10}>
                    <h6>{index + 1}. Previous Policy</h6>
                  </Grid>
                  {index > 0 ? (
                    <Grid xs={2} textAlign={"right"}>
                      <Link
                        color={"#EA4559"}
                        fontWeight={700}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          updateMasterStatePreviousPolicy([
                            "remove_previous_policy_detail",
                            index,
                          ]);
                        }}
                      >
                        Remove
                      </Link>
                    </Grid>
                  ) : null}
                  <Grid xs={6}>
                    <SelectDropdown
                      class_name="inputField mr-2"
                      title="Company Name"
                      value={data.company_name.value}
                      attrName={["company_name", index]}
                      value_update={updateMasterStatePreviousPolicy}
                      data={dropdownData.insuranceComp}
                      warn_status={data.company_name.warning}
                      error_message="Please select company name"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Base Sum Assured (in Rs)"}
                      value={data.base_sum_assured.value}
                      attrName={["base_sum_assured", index]}
                      value_update={updateMasterStatePreviousPolicy}
                      error_message="Please enter sum assured DOB"
                      warn_status={data.base_sum_assured.warning}
                      number_only
                    />
                  </Grid>
                </>
              ))}
              {medicalQuestion.previous_policy.details.length < 5 ? (
                <Grid xs={10} textAlign={"center"}>
                  <Button
                    sx={{
                      backgroundColor: "#EA4559",
                      textTransform: "capitalize",
                      ":hover": { backgroundColor: "#EA4559" },
                    }}
                    variant="contained"
                    onClick={() => {
                      updateMasterStatePreviousPolicy(
                        ["add_previous_policy_detail"],
                        ""
                      );
                    }}
                  >
                    Add More
                  </Button>
                </Grid>
              ) : null}

              <MedicalQuestion
                main_class="medicalQuestion borderBottom"
                icon_class={"insurancepolicy"}
                question_name={
                  "Has any of your insurance application or reinstatement application on life, accident, medical or health, critical illness, or disability ever been declined, postponed or accepted at extra premium or modified terms? (If Yes, please provide the details)"
                }
                toggle_button_status={
                  medicalQuestion.previous_policy.sub_question_one
                }
                attrName={"sub_question_one"}
                value_update={updateMasterStatePreviousPolicy}
              />
              {medicalQuestion.previous_policy.sub_question_one ? (
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField mr-2"
                    title="Remarks"
                    value={
                      medicalQuestion.previous_policy.sub_question_one_remarks
                        .value
                    }
                    attrName={"sub_question_one_remarks"}
                    value_update={updateMasterStatePreviousPolicy}
                    warn_status={
                      medicalQuestion.previous_policy.sub_question_one_remarks
                        .warning
                    }
                    error_message="Please enter remarks"
                  />
                </Grid>
              ) : null}
              <MedicalQuestion
                main_class="medicalQuestion borderBottom"
                icon_class={"insurancepolicy"}
                question_name={
                  "Has any claim under any such policy or scheme ever been made? If so, please give full particulars detailing cause and amount of claim."
                }
                toggle_button_status={
                  medicalQuestion.previous_policy.sub_question_two
                }
                attrName={"sub_question_two"}
                value_update={updateMasterStatePreviousPolicy}
              />
              {medicalQuestion.previous_policy.sub_question_two ? (
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField mr-2"
                    title="Remarks"
                    value={
                      medicalQuestion.previous_policy.sub_question_two_remarks
                        .value
                    }
                    attrName={"sub_question_two_remarks"}
                    value_update={updateMasterStatePreviousPolicy}
                    warn_status={
                      medicalQuestion.previous_policy.sub_question_two_remarks
                        .warning
                    }
                    error_message="Please enter remarks"
                  />
                </Grid>
              ) : null}
            </>
          ) : null}
        </Grid>
      </>
      <Footer
        loading={loader}
        continueFunction={() => {
          continue_function();
        }}
      />
    </Box>
  );
}

export default MedicalPage;
