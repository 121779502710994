export enum PROPOSER_PAGE_STATE_STATUS {
  PROPOSAL_PAGE = "PROPOSAL_PAGE",
  JOURNEY_REQUIREMENT = "JOURNEY_REQUIREMENT",
  NOMINEE_DETAILS = "NOMINEE_DETAILS",
  ADDRESS_DETAILS = "ADDRESS_DETAILS",
  CKYC = "CKYC",
  MEDICAL_PAGE = "MEDICAL_PAGE",
  PREVIEW_PAGE = "PREVIEW_PAGE",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
}

export type TProposerPage = {
  page_name: PROPOSER_PAGE_STATE_STATUS;
  update_status: boolean;
  PAYMENT_URL: string;
};
