import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProposerDetails from "../../../../../Page/Desktop/Term/HDFC/Proposal/ProposerDetails/ProposerDetails";
import MProposerDetails from "../../../../../Page/Mobile/Term/HDFC/Proposal/ProposerDetails/MProposerDetails";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { EMasterDropdown } from "../../../../../State/Enum/EMasterDropdown";
import { ETermLandingPage } from "../../../../../State/Enum/ETermLandingPage";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";
import { TInsuredMemberDetails } from "../../../../../State/Types/TInsuredMemberDetails";
import {
  PROPOSAL_PAGE_SECTION_STATUS_API,
  TProposerDetails,
} from "../../../../../State/Types/TProposerDetails";
import { TSearchableDropdown } from "../../../../../State/Types/TSearchableDropdown";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const ProposerDetailsContainer = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const { selectedQuote, RIDERS_BENEFITS } = useAppSelector(
    (state) => state.Term
  );
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const state = useAppSelector((state) => state);
  const [proposerDetails, setProposerDetails] = useState<TProposerDetails>(
    state.Term.proposerDetails
  );
  const [insuredMemberDetails, setInsuredMemberDetails] =
    useState<TInsuredMemberDetails>(state.Term.insuredDetails);

  useEffect(() => {
    getOccupationList();
    getMaritalStatusList();
    getInsuranceCompanyMaster();
    getEducationMaster();
    getDropdownMaster(EMasterDropdown.MY_PROFESSION);
    getDropdownMaster(EMasterDropdown.ORG_TYPE);
    getDropdownMaster(EMasterDropdown.INDUSTRY_TYPE);
    getDropdownMaster(EMasterDropdown.OBJ_OF_BUYING_POLICY);

    getTermLandingPage(
      selectedQuote.quote_no,
      selectedQuote.buy_online_code,
      selectedQuote.company_code
    );

    setProposerDetails({
      ...state.Term.proposerDetails,
      full_name: { value: selectedQuote.name, warning: false },
      mobile: { value: selectedQuote.mobile, warning: false },
      email: { value: selectedQuote.email, warning: false },
      gender: { value: selectedQuote.gender, warning: false },
      dob: {
        value: `${selectedQuote?.dob_date}-${selectedQuote?.dob_month}-${selectedQuote?.dob_year}`,
        warning: false,
      },
    });
  }, [selectedQuote]);

  const getTermLandingPage = (
    quote_no: string,
    buy_online_code: string,
    company_code: string
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (response.currentPage) {
          case ETermLandingPage.PROPOSER_DETAILS:
            break;
          case ETermLandingPage.NOMINEE_DETAILS:
            navigate(TERM_PATH.NOMINEE_DETAILS);
            break;
          case ETermLandingPage.ADDRESS_DETAILS:
            navigate(TERM_PATH.ADDRESS_DETAILS);
            break;
          case ETermLandingPage.CKYC_DETAILS:
            navigate(TERM_PATH.CKYC);
            break;
          case ETermLandingPage.MEDICAL_PAGE:
            navigate(TERM_PATH.MEDICAL_PAGE);
            break;
          case ETermLandingPage.PERVIEW_PAGE:
            navigate(TERM_PATH.PREVIEW_PAGE);
            break;
          case ETermLandingPage.UPLOAD_DOCUMENT:
            navigate(TERM_PATH.UPLOAD_DOCUMENT);
            break;
          case ETermLandingPage.POLICY_CONFIRMATION:
            navigate(TERM_PATH.POLICY_CONFIRMATION);
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getTermLandingPage(onSuccess, onError, {
      quote_no: btoa(quote_no),
      buy_online_code: buy_online_code,
    });
  };

  const getOccupationList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const occupationData: Array<TSearchableDropdown> = response.map(
          (obj: any) => {
            return {
              code: obj.code,
              label: obj.title,
            };
          }
        );

        dispatch(TermSlice.actions.setOccupationData(occupationData));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getOccupationList(onSuccess, onError, {
      product_code: selectedQuote.product_code,
      product_type_code: selectedQuote.product_type_code,
      buy_online_code: selectedQuote.buy_online_code,
    });
  };

  const getDropdownMaster = (type: EMasterDropdown) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (type) {
          case EMasterDropdown.MY_PROFESSION:
            const data_prof: Array<TSearchableDropdown> = response.map(
              (obj: any) => {
                return {
                  code: obj.code,
                  label: obj.title,
                };
              }
            );
            dispatch(TermSlice.actions.setMyProfession(data_prof));
            break;
          case EMasterDropdown.ORG_TYPE:
            const data_org_type: Array<TSearchableDropdown> = response.map(
              (obj: any) => {
                return {
                  code: obj.code,
                  label: obj.title,
                };
              }
            );
            dispatch(TermSlice.actions.setICICIOrgType(data_org_type));
            break;
          case EMasterDropdown.INDUSTRY_TYPE:
            const data_ind_type: Array<TSearchableDropdown> = response.map(
              (obj: any) => {
                return {
                  code: obj.code,
                  label: obj.title,
                };
              }
            );
            dispatch(TermSlice.actions.setICICIIndustryType(data_ind_type));
            break;
          case EMasterDropdown.OBJ_OF_BUYING_POLICY:
            const data__buying_policy: Array<TSearchableDropdown> =
              response.map((obj: any) => {
                return {
                  code: obj.code,
                  value: obj.title,
                };
              });
            dispatch(TermSlice.actions.setObjBuyingPolicy(data__buying_policy));
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getDropdownDataMaster(
      onSuccess,
      onError,
      selectedQuote.product_code,
      type,
      selectedQuote.buy_online_code
    );
  };

  const getMaritalStatusList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const maritalStatusData: Array<TSearchableDropdown> = response.map(
          (obj: any) => {
            return {
              code: obj.code,
              label: obj.title,
            };
          }
        );

        dispatch(TermSlice.actions.setMaritalStatusData(maritalStatusData));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getMaritalStatusList(onSuccess, onError, {
      product_code: selectedQuote.product_code,
      product_type_code: selectedQuote.product_type_code,
      buy_online_code: selectedQuote.buy_online_code,
    });
  };

  const getEducationMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const data: Array<TSearchableDropdown> = response.map((obj: any) => {
          return {
            code: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermSlice.actions.setEducationMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.GET_EDUCATION_MASTER(onSuccess, onError, {
      product_type_code: selectedQuote.product_type_code,
      product_code: selectedQuote.product_code,
      buy_online_code: selectedQuote.buy_online_code,
    });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "is_proposer_is_insured_member") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: value,
      }));
    } else if (attrName[0] === "full_name") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName[0] === "mobile") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "emergency_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "email") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateEmail(value),
        },
      }));
    } else if (attrName[0] === "aadhar_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateAadharNumber(value),
        },
      }));
    } else if (attrName[0] === "occupation") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
      if (value === "BSEM") {
        attrName[1]((prev: any) => ({
          ...prev,
          ["org_name"]: {
            value: "Others",
            warning: false,
          },
        }));
      }
    } else {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
    }
  };

  const validateForm = () => {
    let proposer_details_data: TProposerDetails = { ...proposerDetails };
    let insured_member_data: TInsuredMemberDetails = {
      ...insuredMemberDetails,
    };

    proposer_details_data = {
      ...proposer_details_data,
      full_name: {
        ...proposer_details_data.full_name,
        warning: !validateFullName(proposer_details_data.full_name.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      dob: {
        ...proposer_details_data.dob,
        warning: isEmpty(proposer_details_data?.dob?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      gender: {
        ...proposer_details_data.gender,
        warning: isEmpty(proposer_details_data?.gender?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      mobile: {
        ...proposer_details_data.mobile,
        warning: !validateMobileNumber(proposer_details_data.mobile.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      email: {
        ...proposer_details_data.email,
        warning: !validateEmail(proposer_details_data.email.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      aadhar_number: {
        ...proposer_details_data.aadhar_number,
        warning: !validateAadharNumber(
          proposer_details_data.aadhar_number.value
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      emergency_number: {
        ...proposer_details_data.emergency_number,
        warning: !validateMobileNumber(
          proposer_details_data.emergency_number.value
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      marital_status: {
        ...proposer_details_data.marital_status,
        warning: isEmpty(proposer_details_data?.marital_status?.value?.label),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      annual_income: {
        ...proposer_details_data.annual_income,
        warning: isEmpty(proposer_details_data?.annual_income?.value?.label),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      occupation: {
        ...proposer_details_data.occupation,
        warning: isEmpty(proposer_details_data?.occupation?.value?.label),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      education: {
        ...proposer_details_data.education,
        warning: isEmpty(proposer_details_data?.education?.value?.label),
      },
    };

    if (!proposer_details_data.is_proposer_is_insured_member) {
      insured_member_data = {
        ...insured_member_data,
        full_name: {
          ...insured_member_data.full_name,
          warning: !validateFullName(insured_member_data.full_name.value),
        },
      };

      insured_member_data = {
        ...insured_member_data,
        dob: {
          ...insured_member_data.dob,
          warning: isEmpty(insured_member_data?.dob?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        gender: {
          ...insured_member_data.gender,
          warning: isEmpty(insured_member_data?.gender?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        aadhar_number: {
          ...insured_member_data.aadhar_number,
          warning: !validateAadharNumber(
            insured_member_data.aadhar_number.value
          ),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        marital_status: {
          ...insured_member_data.marital_status,
          warning: isEmpty(insured_member_data?.marital_status?.value?.label),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        occupation: {
          ...insured_member_data.occupation,
          warning: isEmpty(insured_member_data?.occupation?.value?.label),
        },
      };
    } else {
      insured_member_data = {
        education: proposerDetails.education,
        full_name: proposer_details_data.full_name,
        email: proposer_details_data.email,
        dob: proposer_details_data.dob,
        gender: proposer_details_data.gender,
        mobile: proposer_details_data.mobile,
        aadhar_number: proposer_details_data.aadhar_number,
        marital_status: proposer_details_data.marital_status,
        occupation: proposer_details_data.occupation,
        org_desc: proposer_details_data.org_description,
        org_name: proposer_details_data.org_name,
      };
    }
    setProposerDetails(proposer_details_data);
    setInsuredMemberDetails(insured_member_data);

    let hasError = false;

    if (
      proposer_details_data.full_name.warning ||
      proposer_details_data.dob.warning ||
      proposer_details_data.gender.warning ||
      proposer_details_data.mobile.warning ||
      proposer_details_data.email.warning ||
      proposer_details_data.aadhar_number.warning ||
      proposer_details_data.marital_status.warning ||
      proposer_details_data.annual_income.warning ||
      proposer_details_data.occupation.warning ||
      proposer_details_data.education.warning
    ) {
      hasError = true;
    }
    if (proposer_details_data.is_proposer_is_insured_member === false) {
      if (
        insured_member_data.full_name.warning ||
        insured_member_data.email.warning ||
        insured_member_data.dob.warning ||
        insured_member_data.gender.warning ||
        insured_member_data.mobile.warning ||
        insured_member_data.aadhar_number.warning ||
        insured_member_data.marital_status.warning ||
        insured_member_data.occupation.warning
      ) {
        hasError = true;
      }
    }

    if (!hasError) {
      dispatch(TermSlice.actions.setProposerDetails(proposer_details_data));
      dispatch(TermSlice.actions.setInsuredDetails(insured_member_data));

      updateProposerDetailsData(insured_member_data, proposer_details_data);
    }
    console.log("hasError", hasError);
  };

  const updateProposerDetailsData = (
    insuredMemberDetails: TInsuredMemberDetails,
    proposerDetails: TProposerDetails
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        hdfcCreatePaymentLink();
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data = {
      buy_online_code: selectedQuote.buy_online_code,
      quote_no: btoa(selectedQuote.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.PROPOSAL_DETAILS,
      details: {
        proposer_relationship: proposerDetails.is_proposer_is_insured_member
          ? "Yes"
          : "No",
        aadhar_number: proposerDetails.aadhar_number.value,
        insured_aadhar: insuredMemberDetails.aadhar_number.value,
        insured_dob: FORMAT_YYYY_MM_DD(insuredMemberDetails.dob.value),
        insured_email: insuredMemberDetails.email.value,
        insured_gender: insuredMemberDetails.gender.value,
        insured_marital_status: insuredMemberDetails.marital_status.value.code,
        insured_mobile: insuredMemberDetails.mobile.value,
        insured_name: insuredMemberDetails.full_name.value,
        insured_occupation: insuredMemberDetails.occupation.value.code,
        premium: selectedQuote.premium,
        proposer_annual_income: proposerDetails.annual_income.value.code,
        proposer_dob: FORMAT_YYYY_MM_DD(proposerDetails.dob.value),
        proposer_email: proposerDetails.email.value,
        proposer_emergency_no: proposerDetails.emergency_number.value,
        proposer_gender: proposerDetails.gender.value,
        proposer_marital_status: proposerDetails.marital_status.value.code,
        proposer_mobile: proposerDetails.mobile.value,
        proposer_name: proposerDetails.full_name.value,
        proposer_occupation: proposerDetails.occupation.value.code,
        proposer_occDesc: proposerDetails.occupation_desc.value,
        proposer_education: proposerDetails.education.value.code,
      },
    };
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.updateProposalPageHDFCDetails(
      onSuccess,
      onError,
      data
    );
  };

  const hdfcCreatePaymentLink = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        window.location.href = response.redirectionUrl;
        dispatch(
          TermSlice.actions.updateQuoteData([
            {
              key: "appno",
              value: response.appno,
            },
          ])
        );
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      toast.error("There are some technical error. Please contact to admin.");
      console.log(err);
    };

    const data = {
      buy_online_code: selectedQuote.buy_online_code,
      product_id: `${selectedQuote.id}`,
      quote_no: selectedQuote.quote_no,
      age: `${selectedQuote.age}`,
      city: selectedQuote.city,
      company_code: selectedQuote.company_code,
      cover_pay_upto: selectedQuote.cover_pay_upto,
      cover_upto: selectedQuote.cover_upto,
      frequency: `${selectedQuote.frequency}`,
      gender: selectedQuote.gender,
      pay_term: `${selectedQuote.pay_term}`,
      policy_covers: selectedQuote.policy_covers,
      product_code: selectedQuote.product_code,
      product_desc: selectedQuote.product_desc,
      smoker: selectedQuote.smoker,
      state: selectedQuote.state,
      sum_assured: selectedQuote.sum_insured,
      term: `${selectedQuote.term}`,
      name: `${proposerDetails.full_name.value}`,
      annual_income: `${proposerDetails.annual_income.value.code}`,
      request_for: "RIDERREC",
      dob: `${selectedQuote.dob_date}-${selectedQuote.dob_month}-${selectedQuote.dob_year}`,
      email: `${proposerDetails.email.value}`,
      criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      personalAccSelected:
        selectedQuote.product_code === "LTH101"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf003Status
          ? "Yes"
          : "No",
      cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      accidentalDeaSelected:
        selectedQuote.product_code === "LTH101"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf005Status
          ? "Yes"
          : "No",
      criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
        ? RIDERS_BENEFITS.local_value.tf001Value
        : RIDERS_BENEFITS.local_value.tf004Value,
      accidentalDisValue: RIDERS_BENEFITS.local_value.tf002Value,
      accidentalDeathValue:
        selectedQuote.product_code === "LTH101"
          ? ""
          : RIDERS_BENEFITS.local_value.tf003Status
          ? RIDERS_BENEFITS.local_value.tf003Value
          : RIDERS_BENEFITS.local_value.tf005Value,
      adb: RIDERS_BENEFITS.local_value.BNF001Status ? "Yes" : "No",
      rop: RIDERS_BENEFITS.local_value.BNF002Status ? "Yes" : "No",
      wop: RIDERS_BENEFITS.local_value.BNF003Status ? "Yes" : "No",
    };
    PROPOSAL_PAGE_SERVICES.hdfcCreatePaymentLink(onSuccess, onError, data);
  };

  const getInsuranceCompanyMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TSearchableDropdown[] = response.map((obj: any) => {
          return {
            code: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermSlice.actions.setInsuranceCompany(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getInsuranceCompanyMaster(
      onSuccess,
      onError,
      selectedQuote.company_code,
      selectedQuote.product_type_code
    );
  };

  return (
    <>
      {isMobile ? (
        <MProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <ProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default ProposerDetailsContainer;
