export enum EMasterDropdown {
  ALCOHOL = "alcohol",
  TOBACCO = "tobacco",
  AGE_PROOF = "ageProf",
  INCOME_PROOF = "incomeProf",
  INDUSTRY_TYPE = "indsType",
  INSURANCE_COMPANY_LIFE = "insuranceComp",
  ADDRESS_PROOF = "addressProf",
  MY_PROFESSION = "myProf",
  ORG_TYPE = "orgType",
  EIA_REPOSITORY_TYPE = "eia",
  OBJ_OF_BUYING_POLICY = "buyingPolicy",
  IDENTITY_PROOF = "identityProf",
  PURPOSE_TRAVEL = "purposeTravel",
  TYPE_RESIDENCE = "typeResidence",
  MODE_TRAVEL = "modeTravel",
}
