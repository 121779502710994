import { PayloadAction } from "@reduxjs/toolkit";
import { TPolicyDetails } from "../../../Types/TPolicyDetails";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";

function setPolicyDetails(
  state: TTermSlice,
  action: PayloadAction<TPolicyDetails>
) {
  const data: TTermSlice = { ...state, policyDetails: action.payload };
  return data;
}

export const TERM_POLICY_DETAILS_REDUCERS = {
  setPolicyDetails,
};
