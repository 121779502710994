import React, { useState } from "react";
import "./ToggleSwitch.scss";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
interface Props {
  toggle_status: boolean | undefined;
  value_update: Function | undefined;
  attrName: string | undefined;
}

const ToggleSwitch: React.FC<Props> = ({
  toggle_status,
  value_update,
  attrName,
}) => {
  const [value, setValue] = useState(false);
  return (
    <ToggleButtonGroup
      className="switch"
      value={toggle_status}
      exclusive
      onChange={(e, value) => {
        value_update?.(attrName, value);
        setValue(value);
      }}
    >
      <ToggleButton value={false}>No</ToggleButton>
      <ToggleButton value={true}>Yes</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleSwitch;
