import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import "./CustomCheckbox.scss";

interface CustomCheckboxProps {
  label: string;
  defaultChecked?: boolean;
  value?: boolean;
  attrName?: any;
  value_update?: Function;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  defaultChecked,
  value,
  value_update,
  attrName,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={
            <span
              style={{
                borderWidth: 1,
                borderRadius: 3,
                borderColor: "#000000",
                borderStyle: "solid",
                height: "18px",
                width: "18px",
              }}
            />
          } // Apply inline styles to the icon
          checkedIcon={
            <span
              className="checkIcon"
              style={{
                background: "#FF881C",
                borderWidth: 1,
                borderRadius: 3,
                borderColor: "#FF881C",
                borderStyle: "solid",
                height: "18px",
                width: "18px",
              }}
            />
          } // Apply inline styles to the checked icon
          checked={label == "PA Owner Cover" ? true : value}
          onChange={(e) => {
            value_update && value_update(attrName, e.target.checked);
          }}
        />
      }
      label={label}
      className="checkbox" // Apply the custom styles from the CSS module
      value={value}
    />
  );
};

export default CustomCheckbox;
