import { PayloadAction } from "@reduxjs/toolkit";
import { TTermMedicalQuestionDetails } from "../../../Types/TTermMedicalQuestionDetails";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";

function SET_ICICI_MEDICAL_QUESTION(
  state: TTermSlice,
  action: PayloadAction<TTermMedicalQuestionDetails>
) {
  const data: TTermSlice = {
    ...state,
    TermICICIMedicalQuestion: { ...action.payload },
  };
  return data;
}

export const TERM_ICICI_MEDICAL_QUESTION_REDUCERS = {
  SET_ICICI_MEDICAL_QUESTION,
};
