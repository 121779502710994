import { PayloadAction } from "@reduxjs/toolkit";
import { TTWSlice } from "../../../Services/Type/TTWSlice";
import { TTWProduct } from "../../../Services/Type/TwoWheeler/TTWProduct";

const SET_SELECTED_QUOTE_DATA = (
  state: TTWSlice,
  action: PayloadAction<TTWProduct>
) => {
  const data = {
    ...state,
    SELECTED_QUOTE_DATA: action.payload,
  };

  return data;
};

const SAVE_ALL_QUOTES_DATA = (
  state: TTWSlice,
  action: PayloadAction<TTWProduct[]>
) => {
  const data = {
    ...state,
    ALL_QUOTES_DATA: action.payload,
  };

  return data;
};

const UPDATE_SORT_BY = (
  state: TTWSlice,
  action: PayloadAction<"LOW_TO_HIGH" | "HIGH_TO_LOW">
) => {
  const data = {
    ...state,
    SORT_BY: action.payload,
  };

  return data;
};

export const TW_QUOTE_REDUCERS = {
  SET_SELECTED_QUOTE_DATA,
  SAVE_ALL_QUOTES_DATA,
  UPDATE_SORT_BY,
};

export type TWQuoteReducers = typeof TW_QUOTE_REDUCERS;
