import { useEffect } from "react";
import JourneyRequirements from "../../../../../Page/Desktop/Term/ICICI/Proposal/JourneyRequirement/JourneyRequirements";
import MJourneyRequirements from "../../../../../Page/Mobile/Term/ICICI/Proposal/JourneyRequirement/MJourneyRequirements";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { useAppSelector } from "../../../../../State/hooks";
import { useNavigate } from "react-router";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";

function JourneyRequirementContainer() {
  const isMobile = useIsMobile();
  const { proposerDetails } = useAppSelector((state) => state.Term);
  const navigate = useNavigate();

  useEffect(() => {
    const handleUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome compatibility
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const continue_function = () => {
    if (proposerDetails.nationality === "indian") {
      navigate(TERM_PATH.NOMINEE_DETAILS);
    } else {
      navigate(TERM_PATH.NRI_DETAILS);
    }
  };
  return (
    <>
      {isMobile ? (
        <MJourneyRequirements continueFunction={continue_function} />
      ) : (
        <JourneyRequirements continueFunction={continue_function} />
      )}
    </>
  );
}

export default JourneyRequirementContainer;
