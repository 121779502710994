import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../../CommonSCSS/ModalPopup.scss";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  Input,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import { CarSlice } from "../../../../../State/Slice_Reducer/Car/CarSlice";
import { CAR_SERVICES } from "../../../../../Services/CAR/CarServices";
import { UpdateCarDiscountDTO } from "../../../../../Services/DTO/CarDTO/UpdateCarDiscountDTO";

const DiscountPopup = ({
  open,
  setOpen,
  UPDATE_CAR_DISCOUNT,
  setUpdate,
  update,
}: {
  open: boolean;
  update: any;
  setOpen: Function;
  UPDATE_CAR_DISCOUNT: Function;
  setUpdate: Function;
}) => {
  const { ADD_FORM } = useAppSelector((state) => state.Car);
  const { Car } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const UPDATE_ADDON_STATUS = (value: string) => {
    const onSuccess = () => {};
    const onError = () => {};
    const Params: {
      quote_no: string;
      type: string;
      addon: string;
      value: "0" | "1" | any;
    } = {
      quote_no: Car.ADD_FORM_RESPONSE.quote_no,
      type: "CAR",
      addon: "pa_owner_driver",
      value: value === "Organization" ? "0" : "1",
    };
    CAR_SERVICES.UPDATE_ADDON_STATUS(onSuccess, onError, Params);
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Grid
            className="title mx-0"
            container
            columnSpacing={3}
            alignItems="center"
          >
            <Button className="closePopup" onClick={() => setOpen(false)} />
            <Grid xs={12}>
              <h3>Apply Discount</h3>
            </Grid>
          </Grid>
          <Grid container rowSpacing={0} columnSpacing={3} className="mx-3">
            <Grid xs={12}>
              <>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs={12}>
                    <h4 className="discountTxt_p mb-3">
                      Choose a Voluntary Deductible
                    </h4>
                    <ToggleButtonGroup
                      className="radioBox"
                      value={ADD_FORM.deductible}
                      exclusive
                      // onChange={handlevoluntaryDeductible}
                      onChange={(e, a) => {
                        dispatch(
                          CarSlice.actions.UPDATE_FORM_DATA([
                            {
                              key: "deductible",
                              value: a,
                            },
                          ])
                        );
                      }}
                    >
                      <ToggleButton value="0">₹ 0</ToggleButton>
                      <ToggleButton value="2500">₹ 2,500</ToggleButton>
                      <ToggleButton value="5000">₹ 5,000</ToggleButton>
                      <ToggleButton value="7500">₹ 7,500</ToggleButton>
                      <ToggleButton value="15000">₹ 15,000</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  {/* <Grid xs={12}>
                    <h4 className="discountTxt_p mb-3">
                      Is the owner a member of Automobile Association of India?
                    </h4>
                    <ToggleButtonGroup
                      className="radioBox"
                      value={ADD_FORM.automobile_association}
                      exclusive
                   
                      onChange={(e, a) => {
                        dispatch(
                          CarSlice.actions.UPDATE_FORM_DATA([
                            {
                              key: "automobile_association",
                              value: a,
                            },
                          ])
                        );
                      }}
                    >
                      <ToggleButton value="0">No</ToggleButton>
                      <ToggleButton value="1">Yes</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid> */}
                </Grid>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs={12}>
                    <h4 className="discountTxt_p mb-3">
                      Is the vehicle fitted with ARAI approved anti-theft
                      device?
                    </h4>
                    <ToggleButtonGroup
                      className="radioBox"
                      value={ADD_FORM.anti_theft_device}
                      exclusive
                      // onChange={handlearaiApprovedVehicle}
                      onChange={(e, a) => {
                        dispatch(
                          CarSlice.actions.UPDATE_FORM_DATA([
                            {
                              key: "anti_theft_device",
                              value: a,
                            },
                          ])
                        );
                      }}
                    >
                      <ToggleButton value="No">No</ToggleButton>
                      <ToggleButton value="Yes">Yes</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs={12}>
                    <h4 className="discountTxt_p mb-3">Vehicle Owned by</h4>
                    <ToggleButtonGroup
                      className="radioBox"
                      value={update}
                      exclusive
                      onChange={(e, a) => {
                        setUpdate(a);
                        UPDATE_ADDON_STATUS(a);
                      }}
                    >
                      <ToggleButton value="Individual">Individual</ToggleButton>
                      <ToggleButton value="Organization">
                        Organization
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton
                      text_name={"Apply Discount"}
                      class_name="primaryLgBtn"
                      onClickFunction={() => {
                        UPDATE_CAR_DISCOUNT();
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default DiscountPopup;
