import { TCarAddonStatus } from "../Services/Type/Car/TCarAddonStatus";
import {
  TCarPremiumDetails,
  TCarSingleQuoteDetail,
} from "../Services/Type/Car/TCarSingleQuoteDetail";
import { calculate_premium_for_car_go_digit } from "./CarPremiumCalGoDigit";
import { calculate_passenger_cover } from "./HelpingFunction";

function parse_value(value: any): number {
  if (isNaN(parseFloat(value))) {
    return 0;
  } else {
    return parseFloat(value);
  }
}

export function calculate_premium_for_car(
  quote_details: TCarSingleQuoteDetail | undefined,
  addon_status: TCarAddonStatus,
  passenger_cover_user_value: string,
  PLAN_TYPE: string
) {
  let premium: number = 0;
  let final_premium: number = 0;

  if (quote_details === undefined) return 0;

  if (quote_details.CompanyDetails.company_code === "G033") {
    final_premium = calculate_premium_for_car_go_digit(
      quote_details,
      addon_status,
      passenger_cover_user_value,
      PLAN_TYPE
    );
  } else {
    const netPremium = parse_value(quote_details.premiumDetails.netPremium);
    const paOwnerDriver = parse_value(
      quote_details.premiumDetails.paOwnerDriver
    );
    const depreciationCover = parse_value(
      quote_details.premiumDetails.depreciationCover
    );
    const invoicePriceCover = parse_value(
      quote_details.premiumDetails.invoicePriceCover
    );
    const roadSideAssistance = parse_value(
      quote_details.premiumDetails.roadSideAssistance
    );
    const engineGearBoxProtection = parse_value(
      quote_details.premiumDetails.engineGearBoxProtection
    );
    const noClaimBonusSameSlab = parse_value(
      quote_details.premiumDetails.noClaimBonusSameSlab
    );
    const consumablesCover = parse_value(
      quote_details.premiumDetails.consumablesCover
    );
    const keyReplacementCover = parse_value(
      quote_details.premiumDetails.keyReplacementCover
    );
    const driverCover = parse_value(quote_details.premiumDetails.driverCover);
    const personalBelonging = parse_value(
      quote_details.premiumDetails.personalBelonging
    );
    const tyreSecureCover = parse_value(
      quote_details.premiumDetails.tyreSecureCover
    );

    const cngKitOD = parse_value(quote_details.premiumDetails.cngKitOD);
    const cngKitTP = parse_value(quote_details.premiumDetails.cngKitTP);
    const passengerCover = parse_value(
      quote_details.premiumDetails.passerngerCover
    );

    premium =
      netPremium -
      paOwnerDriver -
      depreciationCover -
      invoicePriceCover -
      roadSideAssistance -
      engineGearBoxProtection -
      noClaimBonusSameSlab -
      consumablesCover -
      keyReplacementCover -
      driverCover -
      tyreSecureCover -
      cngKitOD -
      cngKitTP -
      passengerCover -
      personalBelonging;

    if (
      addon_status.pa_owner_cover.value ||
      quote_details.premiumDetails.includedPAOwnerDriver == 1
    ) {
      premium += paOwnerDriver;
    }

    if (
      addon_status.personalBelonging.value ||
      quote_details.premiumDetails.includedPersonalBelonging == 1
    ) {
      premium += personalBelonging;
    }

    if (addon_status.depreciation_cover.value) {
      premium += depreciationCover;
    }

    if (addon_status.invoice_cover.value) {
      premium += invoicePriceCover;
    }

    if (addon_status.rsa.value) {
      premium += roadSideAssistance;
    }

    if (addon_status.engine_cover.value) {
      premium += engineGearBoxProtection;
    }

    if (addon_status.ncb_cover.value) {
      premium += noClaimBonusSameSlab;
    }

    if (addon_status.consumable_cover.value) {
      premium += consumablesCover;
    }

    if (addon_status.key_replacement.value) {
      premium += keyReplacementCover;
    }

    if (addon_status.tyre_cover.value) {
      premium += tyreSecureCover;
    }

    if (addon_status.driver_cover.value) {
      premium += driverCover;
    }

    if (addon_status.cng_kit.value) {
      premium += cngKitOD;
      premium += cngKitTP;
    }

    if (addon_status.passenger_cover.value) {
      premium += calculate_passenger_cover(
        Number(`${passenger_cover_user_value}`),
        quote_details?.premiumDetails.seatingCapacity,
        PLAN_TYPE
      );
    }

    let tax_amount = (premium / 100) * 18;

    final_premium = premium + tax_amount;
  }

  return final_premium;
}

export const SHOULD_DISPLAY_ADDON = (
  AMOUNT: number,
  INCLUDE_STAUS: 0 | 1 = 0,
  FREE_STAUS: 0 | 1 = 0
) => {
  if (INCLUDE_STAUS === 1) {
    return "include";
  } else if (FREE_STAUS === 1) {
    return "free";
  } else {
    if (AMOUNT > 0) {
      return "yes";
    } else {
      return "no";
    }
  }
};

export const shouldDisplayAddon = (
  label: string,
  premiumDetails: TCarPremiumDetails | undefined
) => {
  if (premiumDetails === undefined) return "no";
  switch (label) {
    case "PA Owner Cover":
      if (premiumDetails.includedPAOwnerDriver === 1) {
        return "include";
      }
      return premiumDetails?.paOwnerDriver > 0 ? "yes" : "no";
    case "Zero Depreciation":
      return premiumDetails?.depreciationCover > 0 ? "yes" : "no";
    case "24x7 Roadside Assistance":
      if (premiumDetails.freeRoadSideAssistance === 1) {
        return "free";
      }
      return premiumDetails?.roadSideAssistance > 0 ? "yes" : "no";
    case "Driver Cover":
      return premiumDetails?.driverCover > 0 ? "yes" : "no";
    case "Consumable Cover":
      return premiumDetails?.consumablesCover > 0 ? "yes" : "no";
    case "Engine Cover":
      return premiumDetails?.engineGearBoxProtection > 0 ? "yes" : "no";
    case "Invoice Cover":
      return premiumDetails?.invoicePriceCover > 0 ? "yes" : "no";
    case "Key Replacement":
      return premiumDetails?.keyReplacementCover > 0 ? "yes" : "no";
    case "NCB Cover":
      return premiumDetails?.noClaimBonusSameSlab > 0 ? "yes" : "no";
    case "Tyre Cover":
      return premiumDetails?.tyreSecureCover > 0 ? "yes" : "no";
    case "Personal Belonging":
      return premiumDetails?.personalBelonging > 0 ? "yes" : "no";
    default:
      return "no";
  }
};

export const cal_gst = (value: number) => {
  const gst_amount = (value / 118) * 18;

  return gst_amount;
};
