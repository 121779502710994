export const TERM_PATH = {
  PROPOSER_DETAILS: "/",
  RIDERS_BENEFITS: "/riders-benefits",
  COFIRMATION_PAGE: "/confirmation-page",
  JOURNEY_REQUIREMENT: "/journey-requirement",
  NRI_DETAILS: "/nri-details",
  NOMINEE_DETAILS: "/nominee-details",
  ADDRESS_DETAILS: "/address-details",
  CKYC: "/ckyc-details",
  MEDICAL_PAGE: "/medical-details",
  PREVIEW_PAGE: "/preview-details",
  UPLOAD_DOCUMENT: "/upload-document",
  POLICY_CONFIRMATION: "/policy-confirmation",
};
