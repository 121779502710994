import { Box } from "@mui/material";
import { TCarProduct } from "../../../../../Services/Type/Car/TCarProduct";
import { useAppSelector } from "../../../../../State/hooks";
import { SHOULD_DISPLAY_ADDON } from "../../../../../SupportingFiles/CarPermiumCal";
import { DETERMINE_PLAN_TYPE_GO_DIGIT } from "../../../../../SupportingFiles/CarPremiumCalGoDigit";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

function CarAddonGoDigit({ quote }: { quote: TCarProduct }) {
  const { ADDON_STATUS, PLAN_TYPE, ADDON_USER_VALUE } = useAppSelector(
    (state) => state.Car
  );
  const plan_name = {
    "": "",
    Pro: "Pro",
    DPro: "D Pro",
    DCPro: "DC Pro",
    DCEPro: "DCE Pro",
    DCTPro: "DCT Pro",
    DCETPro: "DCET Pro",
    DCRTIPro: "DC-RTI Pro",
    DCERTIPro: "DCE-RTI Pro",
    DCTRTIPro: "DCT-RTI Pro",
    DCETRTIPro: "DCET-RTI Pro",
  };
  return (
    <Box
      className="addonsBox"
      display={"flex"}
      justifyContent={"space-between"}
    >
      {quote?.quotationDetail ? (
        <ul>
          {quote.product_type === "SAOD" ? null : (
            <>
              {ADDON_STATUS.pa_owner_cover.value ||
              quote.quotationDetail?.premiumDetails?.includedPAOwnerDriver ===
                1 ? (
                <li>
                  <p
                    className={SHOULD_DISPLAY_ADDON(
                      quote?.quotationDetail?.premiumDetails.paOwnerDriver,
                      quote?.quotationDetail?.premiumDetails
                        .includedPAOwnerDriver
                    )}
                  >
                    {ADDON_STATUS.pa_owner_cover.label}
                  </p>
                </li>
              ) : null}
            </>
          )}
          {isEmpty(DETERMINE_PLAN_TYPE_GO_DIGIT(ADDON_STATUS)) ? null : (
            <li>
              <p className={"yes"}>
                {plan_name[DETERMINE_PLAN_TYPE_GO_DIGIT(ADDON_STATUS)]}
              </p>
            </li>
          )}
          {ADDON_STATUS.ncb_cover.value ? (
            <li>
              <p
                className={SHOULD_DISPLAY_ADDON(
                  quote?.quotationDetail?.premiumDetails.noClaimBonusSameSlab,
                  0,
                  0
                )}
              >
                {ADDON_STATUS.ncb_cover.label}
              </p>
            </li>
          ) : null}
          {ADDON_STATUS.passenger_cover.value &&
          parseInt(ADDON_USER_VALUE.passengerCover.value) > 0 ? (
            <li>
              <p className={SHOULD_DISPLAY_ADDON(1000, 0, 0)}>
                {ADDON_STATUS.passenger_cover.label}
              </p>
            </li>
          ) : null}
        </ul>
      ) : null}
    </Box>
  );
}

export default CarAddonGoDigit;
