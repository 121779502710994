import { PayloadAction } from "@reduxjs/toolkit";
import { TAddonsValueType } from "../../../Services/Type/Car/TAddonsValueType";
import { TCarAddonStatus } from "../../../Services/Type/Car/TCarAddonStatus";
import { TCarProduct } from "../../../Services/Type/Car/TCarProduct";
import { TAddressDetails } from "../../../Services/Type/Common/TAddressDetails";
import { TFieldData } from "../../../Services/Type/Common/TFieldData";
import {
  DROPDOWN_DATA,
  IDV_VALUE,
  TCARACCESSEROIS,
  TCarAddForm,
  TCarAddFormResponse,
  TCarSlice,
  TCarUpdateFormResponse,
  TKycDetails,
  TNomineeDetails,
  TOwnerDetails,
  TVehicleDetails,
  Tcar_cng_kit,
} from "../../../Services/Type/TCarSlice";
import { TDropdown } from "../../Types/TDropdown";
import { TSearchableDropdown } from "../../Types/TSearchableDropdown";

function BULK_UPDATE(state: TCarSlice, action: PayloadAction<TCarSlice>) {
  const data: TCarSlice = { ...state, ...action.payload };

  return data;
}

function ADD_FORM_DATA(state: TCarSlice, action: PayloadAction<TCarAddForm>) {
  let data: TCarSlice = { ...state };

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, ...action.payload } };

  return data;
}

type keyTCarAddForm = keyof TCarAddForm;
function UPDATE_FORM_DATA(
  state: TCarSlice,
  action: PayloadAction<{ key: keyTCarAddForm; value: any }[]>
) {
  const updates = action.payload;
  let data: TCarSlice = { ...state };

  updates.forEach(({ key, value }) => {
    data = { ...data, ADD_FORM: { ...data.ADD_FORM, [key]: value } };
  });

  return data;
}

function ADD_FORM_RESPONSE(
  state: TCarSlice,
  action: PayloadAction<TCarAddFormResponse>
) {
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    ADD_FORM_RESPONSE: { ...data.ADD_FORM_RESPONSE, ...action.payload },
  };

  return data;
}
function UPDATE_FORM_RESPONSE(
  state: TCarSlice,
  action: PayloadAction<TCarUpdateFormResponse>
) {
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    UPDATE_FORM_RESPONSE: { ...data.UPDATE_FORM_RESPONSE, ...action.payload },
  };

  return data;
}
type valueTypeDropdown = keyof DROPDOWN_DATA;
function SET_DROPDOWN_DATA(
  state: TCarSlice,
  action: PayloadAction<{
    key: valueTypeDropdown;
    value: TSearchableDropdown[];
  }>
) {
  const { key, value } = action.payload;
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value },
  };

  return data;
}
type AddonUpdatePayload = { key: keyof TAddonsValueType; value: TFieldData };

const UPDATE_ADDON_USER_VALUE = (
  state: TCarSlice,
  action: PayloadAction<AddonUpdatePayload[]>
) => {
  let data: TCarSlice = {
    ...state,
  };

  for (const { key, value } of action.payload) {
    data = {
      ...data,
      ADDON_USER_VALUE: {
        ...state.ADDON_USER_VALUE,
        [key]: value,
      },
    };
  }

  return data;
};
type AddonAceesseriosPayload = {
  key: keyof TCARACCESSEROIS;
  value: any;
};

const UPDATE_ACCESSERIOS_USER_VALUE = (
  state: TCarSlice,
  action: PayloadAction<AddonAceesseriosPayload[]>
) => {
  let data: TCarSlice = {
    ...state,
  };

  for (const { key, value } of action.payload) {
    data = {
      ...data,
      ACCESSORIES_COVER: {
        ...state.ACCESSORIES_COVER,
        [key]: value,
      },
    };
  }

  return data;
};

type AddonCngkitPayload = {
  key: keyof Tcar_cng_kit;
  value: any;
};

const UPDATE_CNG_USER_VALUE = (
  state: TCarSlice,
  action: PayloadAction<AddonCngkitPayload[]>
) => {
  let data: TCarSlice = {
    ...state,
  };

  for (const { key, value } of action.payload) {
    console.log("value.value", key, value.value);
    data = {
      ...data,
      CNG_KIT_RESPONSE: {
        ...state.CNG_KIT_RESPONSE,
        [key]: value,
      },
    };
  }

  return data;
};
type valueTypeAddonStatus = keyof TCarAddonStatus;
function UPDATE_ADDON_STATUS(
  state: TCarSlice,
  action: PayloadAction<{ key: valueTypeAddonStatus; value: boolean }[]>
) {
  let data: TCarSlice = { ...state };

  for (const { key, value } of action.payload) {
    data = {
      ...data,
      ADDON_STATUS: {
        ...data.ADDON_STATUS,
        [key]: { ...data.ADDON_STATUS[key], value: value },
      },
    };
  }

  return data;
}

function updateQuoteLoaderStatus(
  state: TCarSlice,
  action: PayloadAction<boolean>
) {
  let data: TCarSlice = { ...state };

  data = { ...data, QUOTE_LOADER: action.payload };

  return data;
}

type KEY_DROPDOWN_MASTER = keyof DROPDOWN_DATA;
const UPDATE_DROPDOWN_MASTER = (
  state: TCarSlice,
  action: PayloadAction<{ key: KEY_DROPDOWN_MASTER; value: TDropdown[] }>
) => {
  const { key, value } = action.payload;
  const data = {
    ...state,
    DROPDOWN_DATA: {
      ...state.DROPDOWN_DATA,
      [key]: value,
    },
  };

  return data;
};

const UPDATE_PLAN_TYPE = (
  state: TCarSlice,
  action: PayloadAction<
    "Third Party" | "Comprehensive" | "Bundle Policy" | "SAOD"
  >
) => {
  const data = {
    ...state,
    PLAN_TYPE: action.payload,
  };

  return data;
};

const SET_SELECTED_QUOTE_DATA = (
  state: TCarSlice,
  action: PayloadAction<TCarProduct>
) => {
  const data = {
    ...state,
    SELECTED_QUOTE_DATA: action.payload,
  };

  return data;
};

const SAVE_ALL_QUOTES_DATA = (
  state: TCarSlice,
  action: PayloadAction<TCarProduct[]>
) => {
  const data = {
    ...state,
    ALL_QUOTES_DATA: action.payload,
  };

  return data;
};

const UPDATE_SORT_BY = (
  state: TCarSlice,
  action: PayloadAction<"LOW_TO_HIGH" | "HIGH_TO_LOW">
) => {
  const data = {
    ...state,
    SORT_BY: action.payload,
  };

  return data;
};

const UPDATE_QUOTE_NO = (state: TCarSlice, action: PayloadAction<string>) => {
  const data = {
    ...state,
    ADD_FORM_RESPONSE: { ...state.ADD_FORM_RESPONSE, quote_no: action.payload },
  };

  return data;
};

function OWNER_DETAILS(state: TCarSlice, action: PayloadAction<TOwnerDetails>) {
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    OWNER_DETAILS: { ...data.OWNER_DETAILS, ...action.payload },
  };

  return data;
}

type NomineeValueTypes = keyof TNomineeDetails;
function NOMINEE_DETAILS(
  state: TCarSlice,
  action: PayloadAction<{ key: NomineeValueTypes; value: any }[]>
) {
  const updatedState: TCarSlice = {
    ...state,
    NOMINEE_DETAILS: {
      ...state.NOMINEE_DETAILS,
    },
  };

  if (Array.isArray(action.payload)) {
    for (const { key, value } of action.payload) {
      updatedState.NOMINEE_DETAILS[key] = value;
    }
  } else {
    console.error("Invalid payload format. Expected an array.");
  }

  return updatedState;
}

const CHANGE_PAGE_STATUS = (
  state: TCarSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    PAGE_STATUS: action.payload,
  };

  return data;
};

function CKYC_DETAILS(state: TCarSlice, action: PayloadAction<TKycDetails>) {
  let data: TCarSlice = { ...state };

  data = {
    ...data,
    CKYC_DETAILS: { ...data.CKYC_DETAILS, ...action.payload },
  };

  return data;
}

type AddressValueTypes = keyof TAddressDetails;
function ADDRESS_DETAILS(
  state: TCarSlice,
  action: PayloadAction<TAddressDetails>
) {
  let updatedState: TCarSlice = {
    ...state,
    ADDRESS_DETAILS: {
      ...state.ADDRESS_DETAILS,
      ...action.payload,
    },
  };
  return updatedState;
}

type ValueTypes = keyof TVehicleDetails;
function UPDATE_VEHICLE_DEATILS(
  state: TCarSlice,
  action: PayloadAction<{ key: ValueTypes; value: any }[]>
) {
  let updatedState: TCarSlice = {
    ...state,
    VEHICLE_DETAILS: {
      ...state.VEHICLE_DETAILS,
    },
  };

  for (const { key, value } of action.payload) {
    updatedState = {
      ...updatedState,
      VEHICLE_DETAILS: { ...updatedState.VEHICLE_DETAILS, [key]: value },
    };
  }

  return updatedState;
}
const IDVAPPLY_STATUS = (state: TCarSlice, action: PayloadAction<boolean>) => {
  const data = {
    ...state,
    IDVApply_Status: action.payload,
  };
  return data;
};
const IDV_UPDATE = (state: TCarSlice, action: PayloadAction<IDV_VALUE>) => {
  const data = {
    ...state,
    IDV_VALUE: {
      ...action.payload,
    },
  };

  return data;
};
export const CAR_REDUCERS = {
  BULK_UPDATE,
  UPDATE_QUOTE_NO,
  ADD_FORM_DATA,
  UPDATE_FORM_DATA,
  ADD_FORM_RESPONSE,
  SET_DROPDOWN_DATA,
  UPDATE_ADDON_STATUS,
  updateQuoteLoaderStatus,
  UPDATE_DROPDOWN_MASTER,
  UPDATE_PLAN_TYPE,
  SET_SELECTED_QUOTE_DATA,
  SAVE_ALL_QUOTES_DATA,
  UPDATE_SORT_BY,
  IDV_UPDATE,
  IDVAPPLY_STATUS,
  OWNER_DETAILS,
  CKYC_DETAILS,
  ADDRESS_DETAILS,
  NOMINEE_DETAILS,
  CHANGE_PAGE_STATUS,
  UPDATE_VEHICLE_DEATILS,
  UPDATE_FORM_RESPONSE,
  UPDATE_ADDON_USER_VALUE,
  UPDATE_ACCESSERIOS_USER_VALUE,
  UPDATE_CNG_USER_VALUE,
};

export type CarReducers = typeof CAR_REDUCERS;
