import { createSlice } from "@reduxjs/toolkit";
import { CAR_REDUCERS, CarReducers } from "./CarReducer";
import { TCarSlice } from "../../../Services/Type/TCarSlice";

const intitialState: TCarSlice = {
  SORT_BY: "LOW_TO_HIGH",
  ALL_QUOTES_DATA: [],
  SELECTED_QUOTE_DATA: {
    buy_online_code: "",
    company_code: "",
    id: 0,
    loader: false,
    logo: "",
    product_code: "",
    product_type: "Comprehensive",
    quotationDetail: {
      premiumDetails: {
        includedPersonalBelonging: 0,
        personalBelonging: 0,
        passerngerCover: 0,
        error: false,
        freeRoadSideAssistance: 0,
        tyreSecureCover: 0,
        fg_plan_type: "",
        tariffDiscount: 0,
        exshowroomPrice: 0,
        minimumIDV: 0,
        maximumIDV: 0,
        IDV: 0,
        basicOD: 0,
        basicTP: 0,
        netPremium: 0,
        ncbDiscount: 0,
        odDiscount: 0,
        antiTheftDiscount: 0,
        associationDiscount: 0,
        ePolicyDiscount: 0,
        otherDiscount: 0,
        deductibleDiscount: 0,
        ambulanceChargesCover: 0,
        consumablesCover: 0,
        depreciationCover: 0,
        hospitalCashCover: 0,
        hydrostaticLockCover: 0,
        keyReplacementCover: 0,
        paOwnerDriver: 0,
        engineGearBoxProtection: 0,
        noClaimBonusSameSlab: 0,
        roadSideAssistance: 0,
        medicalExpenses: 0,
        accessoryCoverCharge: 0,
        llDriver: 0,
        paFamily: 0,
        invoicePriceCover: 0,
        driverCover: 0,
        seatingCapacity: 0,
        includedPAOwnerDriver: 0,
        cngKitOD: 0,
        cngKitTP: 0,
        GD_ProPlanPremium: 0,
        GD_DProPlanPremium: 0,
        GD_DCProPlanPremium: 0,
        GD_DCEProPlanPremium: 0,
        GD_DCTProPlanPremium: 0,
        GD_DCETProPlanPremium: 0,
        GD_DCRTIProPlanPremium: 0,
        GD_DCERTIProPlanPremium: 0,
        GD_DCTRTIProPlanPremium: 0,
        GD_DCETRTIProPlanPremium: 0,
        GD_ProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
        },
        GD_DProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
        },
        GD_DCProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
        },
        GD_DCEProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          engine: 0,
        },
        GD_DCERTIProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          engine: 0,
          invoice: 0,
        },
        GD_DCTProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          tyre: 0,
        },
        GD_DCTRTIProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          tyre: 0,
          invoice: 0,
        },
        GD_DCETProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          engine: 0,
          tyre: 0,
        },
        GD_DCETRTIProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          engine: 0,
          tyre: 0,
          invoice: 0,
        },
        GD_DCRTIProPlan: {
          personalbelonging: 0,
          roadside: 0,
          Key: 0,
          zerodeps: 0,
          consumable: 0,
          invoice: 0,
        },
      },
      CompanyDetails: {
        company_code: "",
        name: "",
        short_desc: "",
        logo: "",
        claim_ratio: "",
      },
      productDetails: {
        id: 0,
        product_name: "",
        brochure: "",
        product_code: "",
        rate_calc_method: "",
        proposal_method: "",
        tax_calc_method: "",
        discount_calc_method: "",
        rating: 3,
        product_desc: "",
        buy_online_code: "",
        policy_covers: "",
        company_product_map: {
          company_code: "",
        },
      },
      PLAN_TYPE: "COMP",
    },
  },
  DROPDOWN_DATA: {
    SORT_BY: [
      { key: "LOW_TO_HIGH", value: "Low to High" },
      { key: "HIGH_TO_LOW", value: "High to low" },
    ],
    MAKE_MODEL_LIST: [],
    PASSENGER_COVER_DATA: [
      { key: "50000", value: "50,000" },
      { key: "60000", value: "60,000" },
      { key: "70000", value: "70,000" },
      { key: "80000", value: "80,000" },
      { key: "90000", value: "90,000" },
      { key: "100000", value: "1,00,000" },
      { key: "110000", value: "1,10,000" },
      { key: "120000", value: "1,20,000" },
      { key: "130000", value: "1,30,000" },
      { key: "140000", value: "1,40,000" },
      { key: "150000", value: "1,50,000" },
      { key: "160000", value: "1,60,000" },
      { key: "170000", value: "1,70,000" },
      { key: "180000", value: "1,80,000" },
      { key: "190000", value: "1,90,000" },
      { key: "200000", value: "2,00,000" },
    ],
    POPULAR_MAKE: [
      "Hyundai",
      "Maruti",
      "Volkswagen",
      "Tata",
      "Mahindra",
      "Honda",
      "Kia",
      "Skoda",
      "Toyota",
      "Audi",
      "BMW",
      "Mercedes",
    ],
    RTO_LIST: [],
    NCB: ["0", "20", "25", "35", "45", "50"],
    FUEL_TYPE_LIST: [],
    MAKE_LIST: [],
    MODEL_LIST: [],
    PREVIOUS_INSURER_LIST: [],
    VARIANT_LIST: [],
    PLAN_TYPE: [
      { key: "Comprehensive", value: "Comprehensive" },
      { key: "Third Party", value: "Third Party" },
      { key: "SAOD", value: "SAOD" },
    ],
  },
  ADD_FORM: {
    personalBelonging: 0,
    name: { value: "", warning: false },
    business_type: "New",
    claimed: "No",
    make_model: { value: { code: "", label: "" }, warning: false },
    fuel_type: { value: { code: "", label: "" }, warning: false },
    make: { value: "", warning: false },
    mobile: { value: "", warning: false },
    model: { value: "", warning: false },
    prev_ncb: "0",
    previous_insurer: { value: "", warning: false },
    reg_date: { value: "", warning: false },
    reg_no: { value: "", warning: false },
    variant_cc: { value: { code: "", label: "" }, warning: false },
    rto: { value: "", warning: false },
    new_ncb: { value: "", warning: false },
    policy_expiry_date: { value: null, warning: false },
    Idv: 0,
    anti_theft_device: "Yes",
    automobile_association: "0",
    deductible: "2500",
    vehicle_owned: "Individual",
    cng_kit: "",
    consumables_cover: 0,
    depreciation_cover: 0,
    driver_cover: 0,
    electric_accessory: "",
    engine_gear_box_protection: 0,
    invoice_cover: 0,
    key_replacement_cover: 0,
    no_claim_bonus_same_slab: 0,
    non_electric_accessory: "",
    pa_owner_driver: 0,
    passernger_cover: 0,
    road_side_assistance: 0,
    tyre_secure_cover: 0,
  },
  ADD_FORM_RESPONSE: {
    business_type: "",
    car_master_data_id: 0,
    claim_made: "No",
    cubic_capacity: "",
    fuel_type: "",
    insurance_type: "",
    make: "",
    make_model: "",
    manufacture_date: "",
    model: "",
    new_ncb: "0",
    new_policy_date: "",
    policy_expiry_date: "",
    previous_insurer: "",
    previous_ncb: "0",
    quote_no: "",
    registration_date: "",
    registration_no: "",
    rto_code: "",
    seating_capacity: "",
    tp_policy_start_date: "",
    tp_previous_insurer: "",
    variant: "",
    variant_cc: "",
    vehicle_owned: "",
    idv: "",
    actual_reg_no: "",
  },
  CNG_KIT_RESPONSE: {
    quote_no: { value: "", warning: false },
    type: { value: "CAR", warning: false },
    cng_kit: { value: "", warning: false },
  },
  ACCESSORIES_COVER: {
    quote_no: { value: "", warning: false },
    electric_accessory: { value: "", warning: false },
    non_electric_accessory: { value: "", warning: false },
    type: { value: "CAR", warning: false },
  },
  PLAN_TYPE: "Comprehensive",
  QUOTE_LOADER: true,
  ADDON_STATUS: {
    pa_owner_cover: {
      label: "PA Owner Cover",
      value: true,
      tooltip:
        "This add-on provides personal accident coverage for the owner of the insured vehicle.",
    },
    personalBelonging: {
      label: "Personal Belonging",
      value: false,
      tooltip: "Need to add",
    },
    depreciation_cover: {
      label: "Zero Depreciation",
      value: false,
      tooltip:
        "This add-on gives you complete cover on any body parts of the car excluding tyres and batteries.",
    },
    rsa: {
      label: "24x7 Roadside Assistance",
      value: false,
      tooltip:
        "This add-on provides 24/7 roadside assistance in case of breakdown in the middle of the journey",
    },
    driver_cover: {
      label: "Driver Cover",
      value: false,
      tooltip:
        "This add-on provides personal accident coverage for the paid driver of the insured vehicle.",
    },
    consumable_cover: {
      label: "Consumable Cover",
      value: false,
      tooltip:
        "This add-on covers repair costs for consumable items like engine oil, brake fluid, ball bearings, and nuts and bolts.",
    },
    engine_cover: {
      label: "Engine Cover",
      value: false,
      tooltip:
        "This add-on provides coverage towards damages or loss to the insured vehicle’s engine.",
    },
    invoice_cover: {
      label: "Invoice Cover",
      value: false,
      tooltip:
        "This add-on covers the original invoice price of the insured vehicle in case of theft or damage beyond repair.",
    },
    key_replacement: {
      label: "Key Replacement",
      value: false,
      tooltip:
        "It covers the cost of car keys, lock replacement or locksmith charges in case your car key is lost, damaged, or stolen.",
    },
    ncb_cover: {
      label: "NCB Cover",
      value: false,
      tooltip:
        "It is designed to keep your No Claim Bonus component active even if you claim during the policy period.",
    },
    tyre_cover: {
      label: "Tyre Cover",
      value: false,
      tooltip:
        "This add-on covers the cost for the repair, replacement, rebalancing, removal and refitting of tyres",
    },
    accessories_cover: {
      label: "Accessories Cover (₹)",
      value: false,
      tooltip:
        "This add-on covers the cost of repairing or replacing damaged accessories.",
    },
    cng_kit: {
      label: "CNG Kit (₹)",
      value: false,
      tooltip:
        "This add-on covers the cost of repairing or replacing a damaged CNG kit in a CNG vehicle",
    },
    passenger_cover: {
      label: "Passenger Cover (₹)",
      value: false,
      tooltip:
        "This add-on provides personal accident coverage for the passengers of the insured vehicle.",
    },
  },
  OWNER_DETAILS: {
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    dob: { value: "", warning: false },
    gender: { value: "", warning: false },
    maritalStatus: { value: "", warning: false },
    pacover: { value: "", warning: false },
    validPuc: true,
    vehicleRegistered: false,
    isPolicyComprehensive: true,
    includePAOwner: true,
    gst: { value: "", warning: false },
  },
  CKYC_DETAILS: {
    propFullName: { value: "", warning: false },
    ProposerDob: { value: "", warning: false },
    docID: { value: "", warning: false },
    cusdtomertype: { value: "", warning: false },
    verificationDoc: { value: "", warning: false },
    addressDoc: { value: "", warning: false },
    verificationDocFile: { name: "", value: "", warning: false },
    addressDocFile: { name: "", value: "", warning: false },
    gender: { value: "", warning: false },
  },
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    city: "",
    state: "",
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    isMailingAddressSame: true,
    mailingPinCode: { value: "", warning: false },
    mailingCity: "",
    mailingState: "",
    mailingAddressLineOne: { value: "", warning: false },
    mailingAddressLineTwo: { value: "", warning: false },
  },
  NOMINEE_DETAILS: {
    fullName: { value: "", warning: false },
    dob: { value: "", warning: false },
    relationship: { value: "", warning: false },
  },
  VEHICLE_DETAILS: {
    chasis_no: { value: "", warning: false },
    engine_no: { value: "", warning: false },
    bank_name: { value: "", warning: false },
    city: { value: "", warning: false },
    mfg_year: { value: "", warning: false },
    policy_exp_date: { value: "", warning: false },
    prev_insurer: { value: "", warning: false },
    prev_policy_no: { value: "", warning: false },
    reg_date: { value: "", warning: false },
    is_vehicle_on_loan: false,
  },
  ADDON_USER_VALUE: {
    cngKitValue: { value: "", warning: false },
    electricalAcessories: { value: "", warning: false },
    nonElectricalAcessories: { value: "", warning: false },
    passengerCover: { value: "", warning: false },
  },
  IDVApply_Status: false,
  PAGE_STATUS: false,
  IDV_VALUE: {
    value: "",
    warning: false,
  },
};

export const CarSlice = createSlice<TCarSlice, CarReducers>({
  name: "CAR",
  initialState: intitialState,
  reducers: CAR_REDUCERS,
});
