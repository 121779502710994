import { DOMAIN_CONSTANTS, HOME_PAGE_URL } from "./Domain";

const DOMAIN = DOMAIN_CONSTANTS.DEV_URL;
const HOME_PAGE = HOME_PAGE_URL.DEV;

export const URL_CONSTANTS = {
  HOME_PAGE_URL: HOME_PAGE,

  GET_QUOTE_DETAILS: `${DOMAIN}/api/common/get-premium-detail`,
  GET_ACCESS_TOKKEN: `${DOMAIN}/api/generate-token`,
  GET_OCCUPATION_LIST: `${DOMAIN}/api/common/product-occupation-maps`,
  GET_MARITAL_STATUS: `${DOMAIN}/api/common/product-marital-status-maps`,
  UPDATE_PROPOSAL_PAGE_DATA: `${DOMAIN}/api/common/get-proposal-journey`,
  UPDATE_PROPOSAL_PAGE_HDFC_DATA: `${DOMAIN}/api/common/get-proposal-journey`,
  GET_NOMINEE_RELATIONSHIP_LIST: `${DOMAIN}/api/common/product-nominee-relationship-maps`,
  GET_CITY_STATE: `${DOMAIN}/api/common/get-city-state`,
  CREATE_PAYMENT_LINK: `${DOMAIN}/api/ipru/proposal-appno`,
  HDFC_TERM_CREATE_PAYMENT_LINK: `${DOMAIN}/api/hdfc/get-premium-c2ps`,
  ICICI_TERM_GET_PREMIUM: `${DOMAIN}/api/ipru/get-premium`,
  GET_MASTER_DATA: `${DOMAIN}/api/common/get-insurer-common-master`,
  GET_MASTER_DATA_ICICI: `${DOMAIN}/api/common/get-proposal-common-master`,
  INVESTMENT_PROPOSAL_SUB: `${DOMAIN}/api/ipru/proposal-sub`,
  INSURANCE_COMPANY_MASTER: `${DOMAIN}/api/common/get-product-organisation`,
  COUNTRY_MASTER: `${DOMAIN}/api/common/get-countries`,
  EDUCATION_MASTER: `${DOMAIN}/api/common/product-education-maps`,

  HDFC_CKYC: `${DOMAIN}/api/hdfc/term-ckyc`,
  HDFC_TERM_APP_SUBMISSION: `${DOMAIN}/api/hdfc/term-application-submission`,
  HDFC_TERM_GET_REQUIREMENT: `${DOMAIN}/api/hdfc/term-get-requirement`,
  HDFC_DROPDOWN_MASTER: `${DOMAIN}/api/hdfc/get-hdfc-underwriting-detail`,
  HDFC_UPLOAD_DOCUMENT: `${DOMAIN}/api/hdfc/term-document`,
  SEND_PAYMENT_CONFIRMATION_DATA: `${DOMAIN}/api/common/term-payment-confirmation`,
  TERM_LANDING_PAGE: `${DOMAIN}/api/common/term-landing-page`,
  GET_TERM_PROPOSAL_DETAILS: `${DOMAIN}/api/common/get-proposal-details`,

  ICICI_CONSENT_OTP_SEND: `${DOMAIN}/api/send-otp`,
  ICICI_CONSENT_OTP_VERIFY: `${DOMAIN}/api/verify-otp`,
  ICICI_CONSENT_OTP_RESEND: `${DOMAIN}/api/resend-otp`,

  MAX_CREATE_LEAD: `${DOMAIN}/api/max/create-lead`,
  MAX_SAVE_QUOTE: `${DOMAIN}/api/max/save-quote`,
  getPremiumMax: `${DOMAIN}/api/max/get-premium`,
};
