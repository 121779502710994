import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { TDropdown } from "../../../../../State/Types/TDropdown";
import { TRidersBenefitsResponse } from "../../../../../State/Types/TRidersBenefits";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";
import MRidersBenefits from "../../../../../Page/Mobile/Term/MaxLife/Proposal/RidersBenefits/MRidersBenefits";
import RidersBenefits from "../../../../../Page/Desktop/Term/MaxLife/Proposal/RidersBenefits/RidersBenefits";
import { CURRENCY_FORMAT } from "../../../../../SupportingFiles/HelpingFunction";

function RidersBenefitsContainer() {
  const isMobile = useIsMobile();

  const dispatch = useAppDispatch();
  const [tf001Data, setTf001Data] = useState<TDropdown[]>([]);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const { selectedQuote, proposerDetails, RIDERS_BENEFITS } = useAppSelector(
    (state) => state.Term
  );
  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);
  const [loaderTf004, setLoaderTf004] = useState<boolean>(false);

  useEffect(() => {
    GET_RIDERS_PREMIUM("RIDERREC");
  }, [RIDERS_BENEFITS.local_value]);

  const GET_RIDERS_PREMIUM = (REQUEST_FOR: "" | "RIDERREC") => {
    const onSuccess = (data: any) => {
      setLoaderTf001(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response: TRidersBenefitsResponse = results.response;

      if (!error) {
        dispatch(TermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoaderTf001(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data = {
      quote_no: selectedQuote.quote_no,
      product_id: selectedQuote.id,
      product_code: selectedQuote.product_code,
      company_code: selectedQuote.company_code,
      term: selectedQuote.term,
      pay_term: selectedQuote.pay_term,
      frequency: selectedQuote.frequency,
      smoker: selectedQuote.smoker,
      gender: selectedQuote.gender,
      age: selectedQuote.age,
      dob: `${selectedQuote.dob_date}-${selectedQuote.dob_month}-${selectedQuote.dob_year}`,
      state: selectedQuote.state,
      city: selectedQuote.city,
      sum_assured: selectedQuote.sum_insured,
      annual_income: `${selectedQuote.income}`,
      modal_factor: selectedQuote.modal_factor,
      tax_calc_method: selectedQuote.tax_calc_method,
      discount_calc_method: selectedQuote.discount_calc_method,
      product_desc: selectedQuote.product_desc,
      buy_online_code: selectedQuote.buy_online_code,
      monthly_payout: selectedQuote.monthly_payout,
      policy_covers: selectedQuote.policy_covers,
      increasing_level: "",
      income_period: "",
      cover_upto: selectedQuote.cover_upto,
      cover_pay_upto: selectedQuote.cover_pay_upto,
      tax_calc_action: selectedQuote.tax_calc_action,
      discount_calc_action: selectedQuote.discount_calc_action,
      name: `${proposerDetails.full_name.value}`,
      request_for: REQUEST_FOR,
      criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Value,
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      accidentalDeathValue: RIDERS_BENEFITS.local_value.tf003Value,
      wopSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
    };

    PROPOSAL_PAGE_SERVICES.getPremiumMax(onSuccess, onError, data);
  };

  useEffect(() => {
    const start_tf001 = 500000;
    const end_tf001 =
      parseInt(selectedQuote.sum_insured) / 2 < 5000000
        ? parseInt(selectedQuote.sum_insured) / 2
        : 5000000;

    let data_tf001: TDropdown[] = [];
    for (let i = start_tf001; i <= end_tf001; i += 100000) {
      data_tf001.push({ key: `${i}`, value: CURRENCY_FORMAT(`${i}`) });
    }

    setTf001Data(data_tf001);

    const start_tf003 = 500000;
    const end_tf003 =
      parseInt(selectedQuote.sum_insured) < 10000000
        ? parseInt(selectedQuote.sum_insured)
        : 10000000;

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 100000) {
      data_tf003.push({ key: `${i}`, value: CURRENCY_FORMAT(`${i}`) });
    }

    setTf003Data(data_tf003);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    setLoaderTf001(true);
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf001Value,
  ]);

  useEffect(() => {
    setLoaderTf003(true);
  }, [
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf003Value,
  ]);

  useEffect(() => {
    setLoaderTf004(true);
  }, [
    RIDERS_BENEFITS.local_value.tf004Status,
    RIDERS_BENEFITS.local_value.tf004Value,
  ]);
  const [showWOP, setShowWOP] = useState<boolean>(false);

  useEffect(() => {
    const premium = RIDERS_BENEFITS.riders_benefits_response.finalPremium;

    if (
      (selectedQuote.pay_term === 5 && premium < 1100000) ||
      (selectedQuote.pay_term === 6 && premium < 1000000) ||
      (selectedQuote.pay_term === 7 && premium < 850000) ||
      (selectedQuote.pay_term === 8 && premium < 800000) ||
      (selectedQuote.pay_term === 9 && premium < 700000) ||
      (selectedQuote.pay_term === 10 && premium < 650000) ||
      (selectedQuote.pay_term === 11 && premium < 600000) ||
      (selectedQuote.pay_term >= 12 &&
        selectedQuote.pay_term <= 15 &&
        premium < 500000) ||
      (selectedQuote.pay_term >= 16 &&
        selectedQuote.pay_term <= 24 &&
        premium < 400000) ||
      (selectedQuote.pay_term >= 25 &&
        selectedQuote.pay_term <= 36 &&
        premium < 350000) ||
      (selectedQuote.pay_term >= 37 &&
        selectedQuote.pay_term <= 52 &&
        premium < 300000)
    ) {
      setShowWOP(true);
    }
  }, [RIDERS_BENEFITS.riders_benefits_response.finalPremium]);

  return (
    <>
      {isMobile ? (
        <MRidersBenefits
          loaderTf001={loaderTf001}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          tf001Data={tf001Data}
          tf003Data={tf003Data}
          showWOP={showWOP}
        />
      ) : (
        <RidersBenefits
          loaderTf001={loaderTf001}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          tf001Data={tf001Data}
          tf003Data={tf003Data}
          showWOP={showWOP}
        />
      )}
    </>
  );
}

export default RidersBenefitsContainer;
