import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { CarSlice } from "./Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "./Slice_Reducer/Health/HealthSlice";
import { TWSlice } from "./Slice_Reducer/TW/TWSlice";
import { TermSlice } from "./Slice_Reducer/Term/TermSlice";

// Combine individual reducers into a root reducer
const rootReducer = combineReducers({
  TW: TWSlice.reducer,
  Car: CarSlice.reducer,
  Health: HealthSlice.reducer,
  Term: TermSlice.reducer,
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: "root",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
});

// Create the Redux Persist store
const persistor = persistStore(store);

export { persistor, store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
