import { PayloadAction } from "@reduxjs/toolkit";
import { TOTPDetails } from "../../../Types/TOTPDetails";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";

function setOTPData(state: TTermSlice, action: PayloadAction<TOTPDetails>) {
  const data: TTermSlice = { ...state, OTP_DETAILS: action.payload };

  return data;
}

export const TERM_OTP_DETAILS_REDUCERS = {
  setOTPData,
};
