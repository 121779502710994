import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import "./../QuoteHeader.scss";
import { CarEmailPopupContainer } from "../../../../../Container/CarEmailPopupContainer/CarEmailPopupContainer";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import { CarSlice } from "../../../../../State/Slice_Reducer/Car/CarSlice";
import { TCarAddonStatus } from "../../../../../Services/Type/Car/TCarAddonStatus";

const CarquoteHeader = () => {
  const [openEmailQuotes, setOpenEmailQuotes] = useState(false);
  const dispatch = useAppDispatch();
  const { ADDON_STATUS } = useAppSelector((state) => state.Car);
  const handleImageClick = () => {
    dispatch(
      CarSlice.actions.IDV_UPDATE({
        value: `${""}`,
        warning: false,
      })
    );
    const addonStatusKeys = Object.keys( ADDON_STATUS) as (keyof TCarAddonStatus)[];

    const addonStatusPayload = addonStatusKeys.map((key) => ({
      key: key,
      value: false,
    }));
    dispatch(CarSlice.actions.UPDATE_ADDON_STATUS(addonStatusPayload));

    dispatch(CarSlice.actions.IDVAPPLY_STATUS(false));
    window.location.href =
      "https://dev.insurance.motilaloswal.com/car-insurance/ckyc-verification";
  };

  return (
    <Box className="quoteNavbar">
      <CarEmailPopupContainer
        open={openEmailQuotes}
        setOpen={setOpenEmailQuotes}
        marginClass=""
      />
      <Grid container columnSpacing={3} alignItems="center">
        <Grid xs={6}>
          <img
            onClick={handleImageClick}
            src="/images/motilalOswal.svg"
            className="logo"
            alt="logo"
            style={{cursor:'pointer'}}
          />
        </Grid>
        <Grid xs={6} textAlign="right">
          <Button
            className="sendQuoteBtn"
            onClick={() => setOpenEmailQuotes(true)}
          >
            <MailOutlineIcon /> Email Quotes{" "}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CarquoteHeader;
