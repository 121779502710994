import { CAR_URLS } from "../../Shared/URLS/Car/CarURLs";
import { PatchAPI } from "../AxiosAPIInstance/API";
import { GetAPI, PostAPI } from "../AxiosAPIInstance/AxiosInstanceToken";
import { CarEmailQuotesDTO } from "../DTO/CarDTO/EmailQuotesDTO";
import { UpdateCarDetailsDTO } from "../DTO/CarDTO/UpdateCarDeatilsDTO";
import { UpdateCarDiscountDTO } from "../DTO/CarDTO/UpdateCarDiscountDTO";
import { TCARBUYACTION } from "../Type/Car/TCARBUYACTION";

const UPDATE_DETAILS = (
  onSuccess: Function,
  onError: Function,
  dto: UpdateCarDetailsDTO
) => {
  PatchAPI.call(
    CAR_URLS.UPDATE_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_SINGLE_QUOTE_COMPREHENSIVE = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    CAR_URLS.GET_SINGLE_QUOTE_COMPREHENSIVE,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_SINGLE_QUOTE_SAOD = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    CAR_URLS.GET_SINGLE_QUOTE_SAOD,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_SINGLE_QUOTE_TP = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    CAR_URLS.GET_SINGLE_QUOTE_TP,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_ADDON_STATUS = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    addon: string;
    value: "0" | "1" | any;
  }
) => {
  PatchAPI.call(
    CAR_URLS.UPDATE_ADDON_STATUS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const Capture_data = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    CAR_URLS.CAPTURE_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_IDV = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    cusIDV: string;
    type: string;
  }
) => {
  PatchAPI.call(
    CAR_URLS.UPDATE_IDV,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_ACCESSORIES_AMOUNT = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    electric_accessory: string;
    non_electric_accessory: string;
    type: string;
  }
) => {
  PatchAPI.call(
    CAR_URLS.UPDATE_CAR_ACCESSORIES,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const UPDATE_CNG_KIT = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    type: string;
    cng_kit: string;
  }
) => {
  PatchAPI.call(
    CAR_URLS.UPDATE_CNG_KIT,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const UPDATE_PASSENGER_COVER = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    type: string;
    passenger_cover: string;
  }
) => {
  PatchAPI.call(
    CAR_URLS.UPDATE_PASSENGER_COVER,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const UPDATE_DISCOUNT_DETAILS = (
  onSuccess: Function,
  onError: Function,
  dto: UpdateCarDiscountDTO
) => {
  PatchAPI.call(
    CAR_URLS.UPDATE_CAR_DISCOUNT,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_PRODUCT_LIST = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void
) => {
  GetAPI.call(
    CAR_URLS.GET_PRODUCT_LIST,
    (res: any) => onSuccess(res?.data),
    onError
  );
};

const GET_DETAILS_BY_QUOTE_NO = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  quote_no: string
) => {
  GetAPI.call(
    `${CAR_URLS.GET_DETAILS_BY_QUOTE_NO}?type=CAR&quote_no=${quote_no}`,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const SEND_QUOTE_VIA_EMAIL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: CarEmailQuotesDTO
) => {
  PostAPI.call(
    CAR_URLS.SEND_QUOTE_VIA_EMAIL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_CASHLESS_GARAGE_LIST = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  company_code: string
) => {
  PostAPI.call(
    CAR_URLS.GET_CASHLESS_GARAGE_LIST,
    {
      company_code: company_code,
    },
    (res: any) => onSuccess(res.data),
    onError
  );
};

const Premium_Recalculation = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_code: string;
    quote_no: string;
    car_master_data_id: number;
  }
) => {
  PostAPI.call(
    CAR_URLS.PREMIUM_RECALCULATION,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GetCityStateByPin = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_code: string;
    pincode: string;
  }
) => {
  PostAPI.call(
    CAR_URLS.GET_CITY_STATE_BY_PINCODE,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const BUY_ACTION = (
  onSuccess: Function,
  onError: Function,
  dto: TCARBUYACTION
) => {
  PostAPI.call(
    CAR_URLS.BUY_ACTION,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const BULK_UPDATE_ADDON_STATUS = (
  onSuccess: Function,
  onError: Function,
  dto: {
    quote_no: string;
    type: "CAR";
    invoice_cover: 0 | 1;
    depreciation_cover: 0 | 1;
    road_side_assistance: 0 | 1;
    consumables_cover: 0 | 1;
    no_claim_bonus_same_slab: 0 | 1;
    engine_gear_box_protection: 0 | 1;
    driver_cover: 0 | 1;
    pa_owner_driver: 1;
    key_replacement_cover: 0 | 1;
    tyre_secure_cover: 0 | 1;
    passernger_cover: 0 | 1;
    personal_belonging: 0 | 1;
    cng_kit: 0 | 1;
    electric_accessory: 0 | 1;
    non_electric_accessory: 0 | 1;
  }
) => {
  PatchAPI.call(
    CAR_URLS.BULK_UPDATE_ADDON_STATUS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const CAR_SERVICES = {
  GET_DETAILS_BY_QUOTE_NO,
  UPDATE_DETAILS,
  GET_SINGLE_QUOTE_COMPREHENSIVE,
  GET_SINGLE_QUOTE_SAOD,
  GET_SINGLE_QUOTE_TP,
  UPDATE_ADDON_STATUS,
  UPDATE_IDV,
  UPDATE_ACCESSORIES_AMOUNT,
  UPDATE_DISCOUNT_DETAILS,
  GET_PRODUCT_LIST,
  SEND_QUOTE_VIA_EMAIL,
  GET_CASHLESS_GARAGE_LIST,
  Premium_Recalculation,
  GetCityStateByPin,
  Capture_data,
  BUY_ACTION,
  UPDATE_CNG_KIT,
  UPDATE_PASSENGER_COVER,
  BULK_UPDATE_ADDON_STATUS,
};
