import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddressDetails from "../../../../../Page/Desktop/Term/ICICI/Proposal/AddressDetails/AddressDetails";
import MAddressDetails from "../../../../../Page/Mobile/Term/ICICI/Proposal/AddressDetails/MAddressDetails";
import { AddressDetailsAPIDTO } from "../../../../../Services/DTO/Term/AddressDetailsAPIDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { ETermCompanyCode } from "../../../../../State/Enum/ETermCompanyCode";
import { TAddressDetails } from "../../../../../State/Types/TAddressDetails";
import { PROPOSAL_PAGE_SECTION_STATUS_API } from "../../../../../State/Types/TProposerDetails";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import { validatePincode } from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";

function AddressDetailsContainer() {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const proposerPage = useAppSelector((state) => state.Term.proposerPage);
  const selectedQuote = useAppSelector((state) => state.Term.selectedQuote);
  const address_details = useAppSelector((state) => state.Term.addressDetails);
  const [loader, setLoader] = useState<boolean>(false);
  const [addressDetails, setAddressDetails] =
    useState<TAddressDetails>(address_details);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "pincode") {
      attrName[2]((prev: any) => ({
        ...prev,
        [attrName[1]]: {
          ...prev[attrName[1]],
          [attrName[0]]: {
            value: value,
            warning: !validatePincode(value),
          },
        },
      }));
    } else if (attrName[0] === "is_mailing_and_permanent_address_same") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: value,
      }));
    } else {
      attrName[2]((prev: any) => ({
        ...prev,
        [attrName[1]]: {
          ...prev[attrName[1]],
          [attrName[0]]: {
            value: value,
            warning:
              attrName[3] === false
                ? false
                : value.toString().length > 0
                ? false
                : true,
          },
        },
      }));
    }
  };

  useEffect(() => {
    if (addressDetails.permanent_address.pincode.value) {
      if (
        addressDetails.permanent_address.pincode.value.toString().length === 6
      ) {
        getCityStateOnBasisOfPincode(
          addressDetails.permanent_address.pincode.value,
          "PA"
        );
      } else {
        setAddressDetails((prev) => ({
          ...prev,
          permanent_address: { ...prev.permanent_address, city: "", state: "" },
        }));
      }
    }
  }, [addressDetails.permanent_address.pincode.value]);

  useEffect(() => {
    if (addressDetails.mailing_address.pincode.value) {
      if (
        addressDetails.mailing_address.pincode.value.toString().length === 6
      ) {
        getCityStateOnBasisOfPincode(
          addressDetails.mailing_address.pincode.value,
          "MA"
        );
      } else {
        setAddressDetails((prev) => ({
          ...prev,
          mailing_address: { ...prev.mailing_address, city: "", state: "" },
        }));
      }
    }
  }, [addressDetails.mailing_address.pincode.value]);

  const getCityStateOnBasisOfPincode = (pincode: any, type: string) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        if (type === "MA") {
          setAddressDetails((prev) => ({
            ...prev,
            mailing_address: {
              ...prev.mailing_address,
              city: response.city_name,
              state: response.state_name,
            },
          }));
        } else {
          setAddressDetails((prev) => ({
            ...prev,
            permanent_address: {
              ...prev.permanent_address,
              city: response.city_name,
              state: response.state_name,
            },
          }));
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getCityStateOnBasisOfPincode(onSuccess, onError, {
      pincode: pincode,
    });
  };

  const validate_data = () => {
    var address_details: TAddressDetails = { ...addressDetails };

    address_details = {
      ...address_details,
      permanent_address: {
        ...address_details.permanent_address,
        pincode: {
          ...address_details.permanent_address.pincode,
          warning: !validatePincode(
            address_details.permanent_address.pincode.value
          ),
        },
      },
    };
    address_details = {
      ...address_details,
      permanent_address: {
        ...address_details.permanent_address,
        house_no_building: {
          ...address_details.permanent_address.house_no_building,
          warning: address_details?.permanent_address?.house_no_building?.value
            ? address_details?.permanent_address?.house_no_building?.value.toString()
                .length > 0
              ? false
              : true
            : true,
        },
      },
    };
    address_details = {
      ...address_details,
      permanent_address: {
        ...address_details.permanent_address,
        area_town_locality: {
          ...address_details.permanent_address.area_town_locality,
          warning: address_details?.permanent_address?.area_town_locality?.value
            ? address_details?.permanent_address?.area_town_locality?.value.toString()
                .length > 0
              ? false
              : true
            : true,
        },
      },
    };

    if (!address_details.is_mailing_and_permanent_address_same) {
      address_details = {
        ...address_details,
        mailing_address: {
          ...address_details.mailing_address,
          pincode: {
            ...address_details.mailing_address.pincode,
            warning: !validatePincode(
              address_details.mailing_address.pincode.value
            ),
          },
        },
      };
      address_details = {
        ...address_details,
        mailing_address: {
          ...address_details.mailing_address,
          house_no_building: {
            ...address_details.mailing_address.house_no_building,
            warning: address_details?.mailing_address?.house_no_building?.value
              ? address_details?.mailing_address?.house_no_building?.value.toString()
                  .length > 0
                ? false
                : true
              : true,
          },
        },
      };
      address_details = {
        ...address_details,
        mailing_address: {
          ...address_details.mailing_address,
          area_town_locality: {
            ...address_details.mailing_address.area_town_locality,
            warning: address_details?.mailing_address?.area_town_locality?.value
              ? address_details?.mailing_address?.area_town_locality?.value.toString()
                  .length > 0
                ? false
                : true
              : true,
          },
        },
      };
    } else {
      address_details = {
        ...address_details,
        mailing_address: address_details.permanent_address,
      };
    }

    setAddressDetails(address_details);

    var hasError = false;
    if (
      address_details.permanent_address.pincode.warning === true ||
      address_details.permanent_address.house_no_building.warning === true ||
      address_details.permanent_address.area_town_locality.warning === true
    ) {
      hasError = true;
    }

    if (!addressDetails.is_mailing_and_permanent_address_same) {
      if (
        addressDetails.mailing_address.pincode.warning === true ||
        addressDetails.mailing_address.house_no_building.warning === true ||
        addressDetails.mailing_address.area_town_locality.warning === true
      ) {
      }
    }

    if (!hasError) {
      dispatch(TermSlice.actions.setAddressDetails(address_details));

      updateAddressDetailsData(address_details);
    }
    console.log("hasError", hasError);
  };

  const updateAddressDetailsData = (address_details: TAddressDetails) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;
      console.log(error);
      if (error === false) {
        if (proposerPage.update_status) {
          navigate(-1);
          dispatch(TermSlice.actions.updateUpdateStatus(false));
        } else {
          navigate(TERM_PATH.CKYC);
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: AddressDetailsAPIDTO = {
      buy_online_code: selectedQuote.buy_online_code,
      quote_no: btoa(selectedQuote.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.ADDRESS_DETAILS,
      details: {
        address_area:
          address_details.permanent_address.area_town_locality.value,
        address_city: address_details.permanent_address.city,
        address_state: address_details.permanent_address.state,
        address_pincode: address_details.permanent_address.pincode.value,
        house_address:
          address_details.permanent_address.house_no_building.value,
        address_marea:
          address_details.permanent_address.area_town_locality.value,
        address_mcity: address_details.permanent_address.city,
        address_mstate: address_details.permanent_address.state,
        address_mpincode: address_details.permanent_address.pincode.value,
        house_maddress:
          address_details.permanent_address.house_no_building.value,
        address_mailing_same:
          address_details.is_mailing_and_permanent_address_same === true
            ? "Yes"
            : "No",
      },
    };
    setLoader(true);
    if (selectedQuote.company_code === ETermCompanyCode.HDFC) {
      PROPOSAL_PAGE_SERVICES.updateProposalPageHDFCDetails(
        onSuccess,
        onError,
        data
      );
    } else {
      PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(
        onSuccess,
        onError,
        data
      );
    }
  };
  return (
    <>
      {isMobile ? (
        <MAddressDetails
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
          updateMasterState={updateMasterState}
          continueFunction={validate_data}
          loader={loader}
        />
      ) : (
        <AddressDetails
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
          updateMasterState={updateMasterState}
          continueFunction={validate_data}
          loader={loader}
        />
      )}
    </>
  );
}

export default AddressDetailsContainer;
