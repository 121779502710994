import { Box, Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import SearchDropdown from "../../../../../../Component/InputFields/SearchDropdown/SearchDropdown";
import Footer from "../../../../../../Component/Mobile/Term/ProposalForm/Common/Footer/Footer";
import UploadDocumentDetails from "../../../../../../Component/Mobile/Term/ProposalForm/UploadDocumentDetails/UploadDocumentDetails";

const MUploadDocuments = ({
  continueFunction,
}: {
  continueFunction: Function;
}) => {
  const [documentType, setDocumentType] = useState("");
  const documentType_data = [
    { label: "Passport", value: "Passport" },
    { label: "Driving Licence", value: "Driving Licence" },
    { label: "Pan Card", value: "Brother" },
    { label: "Aadhar Card/UUID", value: "Aadhar Card/UUID" },
    { label: "Voter ID Card", value: "Voter ID Card" },
    { label: "College ID Card", value: "College ID Card" },
  ];

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };
  return (
    <Box>
      <UploadDocumentDetails />
      <Grid container spacing={2}>
        <Grid xs={12}>
          <h6 className="question">
            Submitted documents (proof) should match with details entered while
            filling the form.
          </h6>
        </Grid>
        <Grid xs={12}>
          <SearchDropdown
            class_name="inputField mb-2"
            title="Document Type"
            value={documentType}
            attrName={setDocumentType}
            value_update={updateMasterState}
            data={documentType_data}
            warn_status={false}
            error_message={""}
          />
        </Grid>
        <Grid xs={9} className="pr-0">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Upload document"
            variant="outlined"
            className="uploadField"
          />
        </Grid>
        <Grid xs={2} className="pl-0">
          <Button variant="contained" className="browsebtn" component="label">
            Browse <input hidden accept="image/*" multiple type="file" />
          </Button>
        </Grid>
        <Grid xs={12} textAlign="center">
          <Button
            variant="contained"
            className="browsebtn uploadbtn"
            component="label"
          >
            Upload{" "}
          </Button>
        </Grid>
      </Grid>
      <Footer continueFunction={continueFunction} />
    </Box>
  );
};

export default MUploadDocuments;
