import { PayloadAction } from "@reduxjs/toolkit";
import { TInsuredMemberDetails } from "../../../Types/TInsuredMemberDetails";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";

function setInsuredDetails(
  state: TTermSlice,
  action: PayloadAction<TInsuredMemberDetails>
) {
  const data: TTermSlice = { ...state, insuredDetails: action.payload };
  return data;
}

export const TERM_INSURED_REDUCERS = {
  setInsuredDetails,
};
