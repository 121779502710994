import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProposerDetails from "../../../../../Page/Desktop/Term/MaxLife/Proposal/ProposerDetails/ProposerDetails";
import MProposerDetails from "../../../../../Page/Mobile/Term/MaxLife/Proposal/ProposerDetails/MProposerDetails";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { MAX_SERVICES } from "../../../../../Services/TermServices/MaxServices";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { EMasterDropdown } from "../../../../../State/Enum/EMasterDropdown";
import { ETermCompanyCode } from "../../../../../State/Enum/ETermCompanyCode";
import { ETermLandingPage } from "../../../../../State/Enum/ETermLandingPage";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";
import { TInsuredMemberDetails } from "../../../../../State/Types/TInsuredMemberDetails";
import {
  PROPOSAL_PAGE_SECTION_STATUS_API,
  TProposerDetails,
} from "../../../../../State/Types/TProposerDetails";
import { TSearchableDropdown } from "../../../../../State/Types/TSearchableDropdown";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  FORMAT_YYYY_MM_DD,
  calculateAge,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const ProposerDetailsContainer = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const { Term } = useAppSelector((state) => state);
  const { selectedQuote, dropdownData } = useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const state = useAppSelector((state) => state);
  const [proposerDetails, setProposerDetails] = useState<TProposerDetails>(
    state.Term.proposerDetails
  );
  const [insuredMemberDetails, setInsuredMemberDetails] =
    useState<TInsuredMemberDetails>(state.Term.insuredDetails);

  useEffect(() => {
    getOccupationList();
    getEducationMaster();
    getInsuranceCompanyMaster();
    getDropdownMaster(EMasterDropdown.MY_PROFESSION);
    getDropdownMaster(EMasterDropdown.ORG_TYPE);
    getDropdownMaster(EMasterDropdown.INDUSTRY_TYPE);
    getDropdownMaster(EMasterDropdown.OBJ_OF_BUYING_POLICY);

    getTermLandingPage(
      selectedQuote.quote_no,
      selectedQuote.buy_online_code,
      selectedQuote.company_code
    );

    setProposerDetails((prev) => ({
      ...prev,
      full_name: { value: selectedQuote.name, warning: false },
      dob: {
        value: `${selectedQuote?.dob_date}-${selectedQuote?.dob_month}-${selectedQuote?.dob_year}`,
        warning: false,
      },
      gender: { value: selectedQuote.gender, warning: false },
      mobile: { value: selectedQuote.mobile, warning: false },
      email: { value: selectedQuote.email, warning: false },
      // sum_assured: {
      //   value: {
      //     code: selectedQuote.sum_insured,
      //     label: selectedQuote.sum_insured,
      //   },
      //   warning: false,
      // },
      // annual_income: {
      //   value: {
      //     code: selectedQuote.income,
      //     label: selectedQuote.income,
      //   },
      //   warning: false,
      // },
    }));
  }, [selectedQuote.dob_date]);

  useEffect(() => {
    if (proposerDetails.occupation.value.label === "Housewife") {
      let data = dropdownData.EDUCATION_MASTER;
      const valuesToRemove = ["01", "08"];

      const filteredArray = data.filter(
        (item) => !valuesToRemove.includes(item.code)
      );

      setProposerDetails((prev) => ({
        ...prev,
        education: {
          value: { code: "03", label: "Graduate & Above" },
          warning: false,
        },
      }));
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          dropdownData: {
            ...Term.dropdownData,
            EDUCATION_MASTER: filteredArray,
          },
        })
      );
    } else {
      let data: TSearchableDropdown[] = [
        {
          code: "01",
          label: "10th Pass",
        },
        {
          code: "03",
          label: "Graduate & Above",
        },
        {
          code: "08",
          label: "12th Pass",
        },
      ];

      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          dropdownData: {
            ...Term.dropdownData,
            EDUCATION_MASTER: data,
          },
        })
      );
    }
  }, [proposerDetails.occupation]);

  useEffect(() => {
    if (
      !isEmpty(proposerDetails.education.value.label) &&
      !isEmpty(proposerDetails.occupation.value.label) &&
      !isEmpty(proposerDetails.annual_income.value.label) &&
      !isEmpty(proposerDetails.sum_assured.value.label)
    ) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          selectedQuote: {
            ...Term.selectedQuote,
            sum_insured: proposerDetails.sum_assured.value.label,
          },
          PREMIUM_LOADER: true,
        })
      );
      getMaxPremium();
    }
  }, [
    proposerDetails.education,
    proposerDetails.occupation,
    proposerDetails.annual_income,
    proposerDetails.sum_assured,
  ]);

  useEffect(() => {
    const occupation_data_local = {
      salaried: "07",
      self_employed: "08",
      house_wife: "01",
    };
    const education_data_local = {
      "10th_Pass": "01",
      "Graduate_&_Above": "03",
      "12th_Pass": "08",
    };
    const education = proposerDetails.education.value.code;
    const occupation = proposerDetails.occupation.value.code;
    const age = calculateAge(`${proposerDetails.dob.value}`);
    let min_annual_income = 2;
    const max_annual_income = 99;
    let data: TSearchableDropdown[] = [];

    if (education === education_data_local["Graduate_&_Above"]) {
      if (occupation === occupation_data_local.salaried) {
        if (age <= 40) {
          min_annual_income = 3;
        } else if (age > 40) {
          min_annual_income = 5;
        }
      } else if (
        occupation === occupation_data_local.self_employed ||
        occupation === occupation_data_local.house_wife
      ) {
        min_annual_income = 5;
      }
    } else if (education === education_data_local["12th_Pass"]) {
      min_annual_income = 5;
    } else if (education === education_data_local["10th_Pass"]) {
      min_annual_income = 10;
    }

    for (let i = min_annual_income; i <= max_annual_income; i++) {
      data.push({ code: `${i}00000`, label: `${i} Lakh` });
    }

    setProposerDetails((prev) => ({
      ...prev,
      annual_income: { value: { code: "", label: "" }, warning: false },
      sum_assured: { value: { code: "", label: "" }, warning: false },
    }));

    dispatch(
      TermSlice.actions.SET_DROPDOWN_MASTER_DATA_BY_NAME({
        key: "MAX_ANNUAL_INCOME",
        value: data,
      })
    );
  }, [
    proposerDetails.education,
    proposerDetails.occupation,
    proposerDetails.dob,
  ]);

  useEffect(() => {
    let min_sum_assured = 25;
    let max_sum_assured = 5000000;
    const age = calculateAge(`${proposerDetails.dob.value}`);
    const annual_income = parseInt(proposerDetails.annual_income.value.code);

    if (annual_income >= 500000) {
      if (age >= 18 && age <= 35) {
        max_sum_assured = annual_income * 25;
      } else if (age >= 36 && age <= 40) {
        max_sum_assured = annual_income * 20;
      } else if (age >= 41 && age <= 45) {
        max_sum_assured = annual_income * 20;
      } else if (age >= 46 && age <= 50) {
        max_sum_assured = annual_income * 15;
      } else if (age >= 51 && age <= 60) {
        max_sum_assured = annual_income * 10;
      }
    } else {
      if (age >= 18 && age <= 35) {
        max_sum_assured = annual_income * 17;
      } else if (age >= 36 && age <= 40) {
        max_sum_assured = annual_income * 12;
      }
    }

    max_sum_assured = max_sum_assured / 100000;

    if (max_sum_assured > 50 && annual_income < 500000) max_sum_assured = 50;

    if (
      proposerDetails.occupation.value.label === "Housewife" &&
      max_sum_assured > 50
    )
      max_sum_assured = 50;

    let data: TSearchableDropdown[] = [];
    for (let i = min_sum_assured; i <= max_sum_assured; i++) {
      const label = `${i >= 100 ? i / 100 : i} ${i >= 100 ? "Cr" : "Lakh"}`;
      data.push({ code: `${i}00000`, label: label });
    }

    dispatch(
      TermSlice.actions.SET_DROPDOWN_MASTER_DATA_BY_NAME({
        key: "MAX_SUM_ASSURED",
        value: data,
      })
    );
  }, [
    proposerDetails.dob,
    proposerDetails.annual_income,
    proposerDetails.occupation,
  ]);

  const getMaxPremium = () => {
    const onSuccess = (response: any) => {
      const results = response.results;
      const error = results.error;
      const res = results.response;

      let finalPremium = "0";

      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          selectedQuote: {
            ...Term.selectedQuote,
            premium: finalPremium,
            sum_insured: res?.sum_assured,
          },
          RIDERS_BENEFITS: {
            ...Term.RIDERS_BENEFITS,
            riders_benefits_response: {
              ...Term.RIDERS_BENEFITS.riders_benefits_response,
              finalPremium: res.finalPremium,
            },
          },
          PREMIUM_LOADER: false,
        })
      );
    };

    const onError = () => {
      dispatch(TermSlice.actions.SET_LOADER_STATUS(false));
    };

    const params = {
      quote_no: selectedQuote.quote_no,
      product_id: selectedQuote.id,
      product_code: selectedQuote.product_code,
      company_code: selectedQuote.company_code,
      term: selectedQuote.term,
      pay_term: selectedQuote.pay_term,
      frequency: selectedQuote.frequency,
      smoker: selectedQuote.smoker,
      gender: selectedQuote.gender,
      age: selectedQuote.age,
      dob: `${selectedQuote.dob_date}-${selectedQuote.dob_month}-${selectedQuote.dob_year}`,
      state: selectedQuote.state,
      city: selectedQuote.city,
      sum_assured: proposerDetails.sum_assured.value.code,
      annual_income: proposerDetails.annual_income.value.code,
      modal_factor: selectedQuote.modal_factor,
      tax_calc_method: selectedQuote.tax_calc_method,
      discount_calc_method: selectedQuote.discount_calc_method,
      product_desc: selectedQuote.product_desc,
      buy_online_code: selectedQuote.buy_online_code,
      monthly_payout: selectedQuote.monthly_payout,
      policy_covers: selectedQuote.policy_covers,
      increasing_level: "",
      income_period: "",
      cover_upto: selectedQuote.cover_upto,
      cover_pay_upto: selectedQuote.cover_pay_upto,
      tax_calc_action: selectedQuote.tax_calc_action,
      discount_calc_action: selectedQuote.discount_calc_action,
      name: selectedQuote.name,
      request_for: "PREMREC",
      occupation: proposerDetails.occupation.value.code,
      education: proposerDetails.education.value.code,
    };

    PROPOSAL_PAGE_SERVICES.getPremiumMax(onSuccess, onError, params);
  };

  const getTermLandingPage = (
    quote_no: string,
    buy_online_code: string,
    company_code: string
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (response.currentPage) {
          case ETermLandingPage.PROPOSER_DETAILS:
            break;
          case ETermLandingPage.NOMINEE_DETAILS:
            navigate(TERM_PATH.NOMINEE_DETAILS);
            break;
          case ETermLandingPage.ADDRESS_DETAILS:
            navigate(TERM_PATH.ADDRESS_DETAILS);
            break;
          case ETermLandingPage.CKYC_DETAILS:
            if (company_code === ETermCompanyCode.HDFC) {
              navigate(TERM_PATH.CKYC);
            } else if (company_code === ETermCompanyCode.ICICI) {
              navigate(TERM_PATH.CKYC);
            }
            break;
          case ETermLandingPage.MEDICAL_PAGE:
            if (company_code === ETermCompanyCode.HDFC) {
              navigate(TERM_PATH.MEDICAL_PAGE);
            } else {
              navigate(TERM_PATH.MEDICAL_PAGE);
            }
            break;
          case ETermLandingPage.PERVIEW_PAGE:
            navigate(TERM_PATH.PREVIEW_PAGE);
            break;
          case ETermLandingPage.UPLOAD_DOCUMENT:
            navigate(TERM_PATH.UPLOAD_DOCUMENT);
            break;
          case ETermLandingPage.POLICY_CONFIRMATION:
            navigate(TERM_PATH.POLICY_CONFIRMATION);
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getTermLandingPage(onSuccess, onError, {
      quote_no: btoa(quote_no),
      buy_online_code: buy_online_code,
    });
  };

  const getOccupationList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let occupationData: Array<TSearchableDropdown> = response.map(
          (obj: any) => {
            return {
              code: obj.code,
              label: obj.title,
            };
          }
        );

        if (state.Term.selectedQuote.gender === "M") {
          occupationData = occupationData.filter((item) => item.code !== "01");
        } else {
          if (
            state.Term.selectedQuote.age >= 18 &&
            state.Term.selectedQuote.age <= 50
          ) {
            occupationData = occupationData.filter(
              (item) => item.code !== "01"
            );
          }
        }

        if (state.Term.selectedQuote.product_code === "LTM051") {
          occupationData = occupationData.filter((item) => item.code !== "01");
        }

        dispatch(TermSlice.actions.setOccupationData(occupationData));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getOccupationList(onSuccess, onError, {
      product_code: selectedQuote.product_code,
      product_type_code: selectedQuote.product_type_code,
      buy_online_code: selectedQuote.buy_online_code,
    });
  };

  const getEducationMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const data: Array<TSearchableDropdown> = response.map((obj: any) => {
          return {
            code: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermSlice.actions.setEducationMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.GET_EDUCATION_MASTER(onSuccess, onError, {
      product_type_code: selectedQuote.product_type_code,
      product_code: selectedQuote.product_code,
      buy_online_code: selectedQuote.buy_online_code,
    });
  };

  const getDropdownMaster = (type: EMasterDropdown) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (type) {
          case EMasterDropdown.MY_PROFESSION:
            const data_prof: Array<TSearchableDropdown> = response.map(
              (obj: any) => {
                return {
                  code: obj.code,
                  label: obj.title,
                };
              }
            );
            dispatch(TermSlice.actions.setMyProfession(data_prof));
            break;
          case EMasterDropdown.ORG_TYPE:
            const data_org_type: Array<TSearchableDropdown> = response.map(
              (obj: any) => {
                return {
                  code: obj.code,
                  label: obj.title,
                };
              }
            );
            dispatch(TermSlice.actions.setICICIOrgType(data_org_type));
            break;
          case EMasterDropdown.INDUSTRY_TYPE:
            const data_ind_type: Array<TSearchableDropdown> = response.map(
              (obj: any) => {
                return {
                  code: obj.code,
                  label: obj.title,
                };
              }
            );
            dispatch(TermSlice.actions.setICICIIndustryType(data_ind_type));
            break;
          case EMasterDropdown.OBJ_OF_BUYING_POLICY:
            const data__buying_policy: Array<TSearchableDropdown> =
              response.map((obj: any) => {
                return {
                  key: obj.code,
                  value: obj.title,
                };
              });
            dispatch(TermSlice.actions.setObjBuyingPolicy(data__buying_policy));
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getDropdownDataMaster(
      onSuccess,
      onError,
      selectedQuote.product_code,
      type,
      selectedQuote.buy_online_code
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "is_proposer_is_insured_member") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: value,
      }));
    } else if (attrName[0] === "full_name") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName[0] === "mobile") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "emergency_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "email") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateEmail(value),
        },
      }));
    } else if (attrName[0] === "aadhar_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateAadharNumber(value),
        },
      }));
    } else if (attrName[0] === "occupation") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
      if (value === "BSEM") {
        attrName[1]((prev: any) => ({
          ...prev,
          ["org_name"]: {
            value: "Others",
            warning: false,
          },
        }));
      }
    } else {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
    }
  };

  const validateForm = () => {
    let proposer_details_data: TProposerDetails = { ...proposerDetails };
    // let insured_member_data: TInsuredMemberDetails = {
    //   ...insuredMemberDetails,
    // };

    proposer_details_data = {
      ...proposer_details_data,
      full_name: {
        ...proposer_details_data.full_name,
        warning: !validateFullName(proposer_details_data.full_name.value),
      },
      dob: {
        ...proposer_details_data.dob,
        warning: isEmpty(proposer_details_data?.dob?.value),
      },
      gender: {
        ...proposer_details_data.gender,
        warning: isEmpty(proposer_details_data?.gender?.value),
      },
      mobile: {
        ...proposer_details_data.mobile,
        warning: !validateMobileNumber(proposer_details_data.mobile.value),
      },
      email: {
        ...proposer_details_data.email,
        warning: !validateEmail(proposer_details_data.email.value),
      },
      aadhar_number: {
        ...proposer_details_data.aadhar_number,
        warning: !validateAadharNumber(
          proposer_details_data.aadhar_number.value
        ),
      },
      emergency_number: {
        ...proposer_details_data.emergency_number,
        warning: !validateMobileNumber(
          proposer_details_data.emergency_number.value
        ),
      },
      annual_income: {
        value: proposer_details_data.annual_income.value,
        warning: isEmpty(proposer_details_data?.annual_income?.value?.label),
      },
      sum_assured: {
        value: proposer_details_data.sum_assured.value,
        warning: isEmpty(proposer_details_data?.sum_assured?.value?.label),
      },
      occupation: {
        ...proposer_details_data.occupation,
        warning: isEmpty(proposer_details_data?.occupation?.value?.label),
      },
      education: {
        ...proposer_details_data.education,
        warning: isEmpty(proposer_details_data?.education?.value?.label),
      },
    };

    // if (!proposer_details_data.is_proposer_is_insured_member) {
    //   insured_member_data = {
    //     ...insured_member_data,
    //     full_name: {
    //       ...insured_member_data.full_name,
    //       warning: !validateFullName(insured_member_data.full_name.value),
    //     },
    //   };

    //   insured_member_data = {
    //     ...insured_member_data,
    //     dob: {
    //       ...insured_member_data.dob,
    //       warning: isEmpty(insured_member_data?.dob?.value),
    //     },
    //   };
    //   insured_member_data = {
    //     ...insured_member_data,
    //     gender: {
    //       ...insured_member_data.gender,
    //       warning: isEmpty(insured_member_data?.gender?.value),
    //     },
    //   };
    //   insured_member_data = {
    //     ...insured_member_data,
    //     aadhar_number: {
    //       ...insured_member_data.aadhar_number,
    //       warning: !validateAadharNumber(
    //         insured_member_data.aadhar_number.value
    //       ),
    //     },
    //   };

    //   insured_member_data = {
    //     ...insured_member_data,
    //     occupation: {
    //       ...insured_member_data.occupation,
    //       warning: isEmpty(insured_member_data?.occupation?.value?.label),
    //     },
    //   };
    // } else {
    //   insured_member_data = {
    //     education: proposerDetails.education,
    //     full_name: proposer_details_data.full_name,
    //     email: proposer_details_data.email,
    //     dob: proposer_details_data.dob,
    //     gender: proposer_details_data.gender,
    //     mobile: proposer_details_data.mobile,
    //     aadhar_number: proposer_details_data.aadhar_number,
    //     marital_status: proposer_details_data.marital_status,
    //     occupation: proposer_details_data.occupation,
    //     org_desc: proposer_details_data.org_description,
    //     org_name: proposer_details_data.org_name,
    //   };
    // }
    setProposerDetails(proposer_details_data);
    // setInsuredMemberDetails(insured_member_data);

    let hasError = false;

    if (
      proposer_details_data.full_name.warning ||
      proposer_details_data.dob.warning ||
      proposer_details_data.gender.warning ||
      proposer_details_data.mobile.warning ||
      proposer_details_data.email.warning ||
      proposer_details_data.aadhar_number.warning ||
      proposer_details_data.annual_income.warning ||
      proposer_details_data.occupation.warning
    ) {
      hasError = true;
    }

    if (
      proposer_details_data.mobile.value ===
      proposer_details_data.emergency_number.value
    ) {
      toast.error("Mobile no. and emergency no. should be different.");
      hasError =
        hasError ||
        proposer_details_data.mobile.value ===
          proposer_details_data.emergency_number.value;
    }
    // if (proposer_details_data.is_proposer_is_insured_member === false) {
    //   if (
    //     insured_member_data.full_name.warning ||
    //     insured_member_data.email.warning ||
    //     insured_member_data.dob.warning ||
    //     insured_member_data.gender.warning ||
    //     insured_member_data.mobile.warning ||
    //     insured_member_data.aadhar_number.warning ||
    //     insured_member_data.occupation.warning
    //   ) {
    //     hasError = true;
    //   }
    // }

    if (
      (state.Term.RIDERS_BENEFITS.local_value.tf001Status ||
        state.Term.RIDERS_BENEFITS.local_value.tf004Status) &&
      proposer_details_data.occupation.value.label === "Housewife"
    ) {
      if (
        state.Term.RIDERS_BENEFITS.local_value.tf001Status &&
        state.Term.RIDERS_BENEFITS.local_value.tf004Status
      ) {
        toast.error(
          "Critical Illness and Disability & Waiver of Premium Rider is not allowed for housewife"
        );
      }
      if (state.Term.RIDERS_BENEFITS.local_value.tf001Status) {
        toast.error(
          "Critical Illness and Disability Rider is not allowed for housewife"
        );
      }
      if (state.Term.RIDERS_BENEFITS.local_value.tf004Status) {
        toast.error("Waiver of Premium Rider is not allowed for housewife");
      }

      return;
    }

    if (!hasError) {
      dispatch(TermSlice.actions.setProposerDetails(proposer_details_data));
      // dispatch(TermSlice.actions.setInsuredDetails(insured_member_data));

      updateProposerDetailsData(insuredMemberDetails, proposer_details_data);
    }
  };

  const updateProposerDetailsData = (
    insuredMemberDetails: TInsuredMemberDetails,
    proposerDetails: TProposerDetails
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;

      if (!error) {
        CREATE_LEAD_API();
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data = {
      buy_online_code: selectedQuote.buy_online_code,
      quote_no: btoa(selectedQuote.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.PROPOSAL_DETAILS,
      details: {
        proposer_relationship: proposerDetails.is_proposer_is_insured_member
          ? "Yes"
          : "No",
        aadhar_number: proposerDetails.aadhar_number.value,
        insured_aadhar: insuredMemberDetails.aadhar_number.value,
        insured_dob: FORMAT_YYYY_MM_DD(insuredMemberDetails.dob.value),
        insured_email: insuredMemberDetails.email.value,
        insured_gender: insuredMemberDetails.gender.value,
        insured_marital_status: insuredMemberDetails.marital_status.value,
        insured_mobile: insuredMemberDetails.mobile.value,
        insured_name: insuredMemberDetails.full_name.value,
        insured_occupation: insuredMemberDetails.occupation.value,
        premium: selectedQuote.premium,
        proposer_annual_income: proposerDetails.annual_income.value,
        proposer_dob: FORMAT_YYYY_MM_DD(proposerDetails.dob.value),
        proposer_email: proposerDetails.email.value,
        proposer_emergency_no: proposerDetails.emergency_number.value,
        proposer_gender: proposerDetails.gender.value,
        proposer_marital_status: proposerDetails.marital_status.value,
        proposer_mobile: proposerDetails.mobile.value,
        proposer_name: proposerDetails.full_name.value,
        proposer_occupation: proposerDetails.occupation.value,
        proposer_education: proposerDetails.education.value,
        proposer_occDesc: proposerDetails.occupation_desc.value,
        proposer_diabetes: proposerDetails.diabetic ? "01" : "02",
      },
    };
    setLoader(true);

    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  const CREATE_LEAD_API = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;

      if (!error) {
        CREATE_SAVE_QUOTE();
      } else {
        toast.error("There are some technical error. Please contact to admin.");
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      toast.error("There are some technical error. Please contact to admin.");
      console.log(err);
    };

    MAX_SERVICES.CREATE_LEAD(onSuccess, onError, {
      quote_no: btoa(selectedQuote.quote_no),
      product_id: `${selectedQuote.id}`,
    });
  };

  const CREATE_SAVE_QUOTE = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      if (!error) {
        const response = results.response;
        const redirectionUrl = response.redirectionUrl;
        window.location.href = redirectionUrl;
      } else {
        toast.error(`${message}`);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      toast.error("There are some technical error. Please contact to admin.");
      console.log(err);
    };

    MAX_SERVICES.SAVE_QUOTE(onSuccess, onError, {
      quote_no: btoa(selectedQuote.quote_no),
      product_id: `${selectedQuote.id}`,
    });
  };

  const getInsuranceCompanyMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TSearchableDropdown[] = response.map((obj: any) => {
          return {
            code: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermSlice.actions.setInsuranceCompany(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getInsuranceCompanyMaster(
      onSuccess,
      onError,
      selectedQuote.company_code,
      selectedQuote.product_type_code
    );
  };

  return (
    <>
      {isMobile ? (
        <MProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <ProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default ProposerDetailsContainer;
