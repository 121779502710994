import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { TAddonsValueType } from "../../../../../Services/Type/Car/TAddonsValueType";
import { TCarAddonStatus } from "../../../../../Services/Type/Car/TCarAddonStatus";
import { CarSlice } from "../../../../../State/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import CustomCheckbox from "../../../../InputFields/CustomCheckbox/CustomCheckbox";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../../InputFields/SelectDropdown/SelectDropdown";

function MCarAddons({
  open,
  setOpen,
  get_product_list,
  fieldData,
  setFieldData,
  updateMasterState,
  updateAddonAndValue,
  accessories_value_quote_load,
  cng_kit_quote_load,
}: {
  open: boolean;
  setOpen: any;
  get_product_list: Function;
  fieldData: TAddonsValueType;
  setFieldData: Function;
  updateMasterState: Function;
  updateAddonAndValue: Function;
  accessories_value_quote_load: Function;
  cng_kit_quote_load: Function;

}) {
  const [quoteFields, setQuoteFields] = useState<{
    planType: { value: string; warning: boolean };
    sortBy: { value: string; warning: boolean };
    cngKitPrice: { value: string; warning: boolean };
    electrical: { value: string; warning: boolean };
    nonElectrical: { value: string; warning: boolean };
  }>({
    planType: { value: "Comprehensive", warning: false },
    sortBy: { value: "Premium Low-High", warning: false },
    cngKitPrice: { value: "₹ 20,000", warning: false },
    electrical: { value: "", warning: false },
    nonElectrical: { value: "", warning: false },
  });
  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  const addonCovers = [
    { label: "Zero Depreciation" },
    { label: "24x7 Roadside Assistance" },
    { label: "PA Owner Cover" },
    { label: "Invoice Cover" },
    { label: "Engine Protector" },
    { label: "Key Replacement" },
    { label: "NCB Protection" },
    { label: "Tyre Secure" },
    { label: "Consumable" },
  ];
  const additionalCovers = [
    { label: "CPA" },
    { label: "Driver Cover " },
    { label: "Passenger Cover (₹) " },
  ];
  let [accessories, setAccessories] = useState([
    { addon_name: "Electrical (₹)", check_status: false },
    { addon_name: "Non-Electrical (₹)", check_status: false },
    { addon_name: "External CNG Kit (₹)", check_status: false },
  ]);

  const updateStateFunction = (attrName: any, value: any) => {
    let dataArr = [...accessories];
    if (attrName[0] == "data") {
      dataArr[attrName[1]].check_status = value;
    } else {
      attrName(value);
    }
    setAccessories([...dataArr]);
  };
  const dispatch = useAppDispatch();
  const {
    QUOTE_LOADER,
    ADDON_STATUS,
    ADD_FORM,
    DROPDOWN_DATA,
    PLAN_TYPE,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Car);
  return (
    <>
      {open == true ? (
        <Box className="popupOverlay" onClick={() => setOpen(false)}>
          <Box
            className={`contentWrapper ${open ? "open" : ""}`}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            {/* <Box className="addonHeader">
              <h4>Addon Covers</h4>
            </Box> */}
            <Grid container columnSpacing={3}>
              <Grid xs={12}>
                <h4 className="mb-3">Addon Covers</h4>
              </Grid>
            </Grid>
            <hr />
            <Grid container columnSpacing={3}>
              <Grid xs={12}>
                <ul>
                  {Object.entries(ADDON_STATUS).map(([key, value], index) =>
                    PLAN_TYPE === "Third Party" &&
                    (value.label == "PA Owner Cover" ||
                      value.label === "Accessories Cover (₹)" ||
                      value.label == "Driver Cover" ||
                      value.label == "Passenger Cover (₹)" ||
                      value.label == "CNG Kit (₹)") ? (
                      <li key={key}>
                        <CustomCheckbox
                          label={value.label}
                          defaultChecked={value.value}
                          value={value.value}
                          value_update={(e: any, a: any) => {
                            const addOnKey = key as keyof TCarAddonStatus;
                            dispatch(
                              CarSlice.actions.UPDATE_ADDON_STATUS([
                                {
                                  key: addOnKey,
                                  value: a,
                                },
                              ])
                            );
                            updateAddonAndValue(value.label, a);
                          }}
                        />
                        {ADDON_STATUS.passenger_cover.value &&
                        value.label === "Passenger Cover (₹)" ? (
                          <div className="accessoryBox my-2">
                            <SelectDropdown
                              class_name="inputField "
                              title="Select Value"
                              value={fieldData.passengerCover.value}
                              attrName={[
                                "passengerCover",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              data={DROPDOWN_DATA.PASSENGER_COVER_DATA}
                              warn_status={fieldData.passengerCover.warning}
                              error_message={"Select amount"}
                            />
                          </div>
                        ) : ADDON_STATUS.accessories_cover.value &&
                          value.label === "Accessories Cover (₹)" ? (
                          <div className="accessoryBox">
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Electrical"}
                              value={fieldData.electricalAcessories.value}
                              attrName={[
                                "electricalAcessories",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                fieldData.electricalAcessories.warning
                              }
                              error_message="Enter between ₹ 1 - ₹ 50,000"
                            />
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Non-Electrical"}
                              value={fieldData.nonElectricalAcessories.value}
                              attrName={[
                                "nonElectricalAcessories",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                fieldData.nonElectricalAcessories.warning
                              }
                              error_message={" "}
                            />
                            <Button
                              onClick={() =>
                                updateAddonAndValue("Accessories Cover")
                              }
                              className="apply-Btn ml-2"
                            >
                              Apply
                            </Button>
                          </div>
                        ) : ADDON_STATUS.cng_kit.value &&
                          value.label === "CNG Kit (₹)" ? (
                          <div className="accessoryBox">
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Kit Price"}
                              value={fieldData.cngKitValue.value}
                              attrName={[
                                "cngKitValue",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={fieldData.cngKitValue.warning}
                              error_message={" "}
                            />
                            <Button
                              onClick={() => {
                                cng_kit_quote_load();
                              }}
                              className="apply-Btn ml-2"
                            >
                              Apply
                            </Button>
                          </div>
                        ) : null}
                      </li>
                    ) : PLAN_TYPE !== "Third Party" &&
                      PLAN_TYPE === "SAOD" &&
                      !(key === "driver_cover" || key === "pa_owner_cover") ? (
                      <li key={key}>
                        <CustomCheckbox
                          label={value.label}
                          defaultChecked={value.value}
                          value={value.value}
                          value_update={(e: any, a: any) => {
                            const addOnKey = key as keyof TCarAddonStatus;
                            dispatch(
                              CarSlice.actions.UPDATE_ADDON_STATUS([
                                {
                                  key: addOnKey,
                                  value: a,
                                },
                              ])
                            );
                            updateAddonAndValue(value.label, a);
                          }}
                        />
                        {ADDON_STATUS.passenger_cover.value &&
                        value.label === "Passenger Cover (₹)" ? (
                          <div className="accessoryBox my-2">
                            <SelectDropdown
                              class_name="inputField "
                              title="Select Value"
                              value={fieldData.passengerCover.value}
                              attrName={[
                                "passengerCover",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              data={DROPDOWN_DATA.PASSENGER_COVER_DATA}
                              warn_status={fieldData.passengerCover.warning}
                              error_message={"Select amount"}
                            />
                          </div>
                        ) : ADDON_STATUS.accessories_cover.value &&
                          value.label === "Accessories Cover (₹)" ? (
                          <div className="accessoryBox">
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Electrical"}
                              value={fieldData.electricalAcessories.value}
                              attrName={[
                                "electricalAcessories",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                fieldData.electricalAcessories.warning
                              }
                              error_message={"Enter between ₹ 1 - ₹ 50,000"}
                            />
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Non-Electrical"}
                              value={fieldData.nonElectricalAcessories.value}
                              attrName={[
                                "nonElectricalAcessories",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                fieldData.nonElectricalAcessories.warning
                              }
                              error_message={"Enter between ₹ 1 - ₹ 50,000 "}
                            />
                            <Button
                              onClick={() => {
                                updateAddonAndValue("Accessories Cover", value);
                                accessories_value_quote_load();
                              }}
                              className="apply-Btn ml-2"
                            >
                              Apply
                            </Button>
                          </div>
                        ) : ADDON_STATUS.cng_kit.value &&
                          value.label === "CNG Kit (₹)" ? (
                          <div className="accessoryBox">
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Kit Price"}
                              value={fieldData.cngKitValue.value}
                              attrName={[
                                "cngKitValue",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={fieldData.cngKitValue.warning}
                              error_message={" Enter between ₹ 1 - ₹ 50,000"}
                            />
                            <Button
                              onClick={() => {
                                cng_kit_quote_load();
                              }}
                              className="apply-Btn ml-2"
                            >
                              Apply
                            </Button>
                          </div>
                        ) : null}
                      </li>
                    ) : PLAN_TYPE === "Comprehensive" ||
                      PLAN_TYPE === "Bundle Policy" ? (
                      <li key={key}>
                        <CustomCheckbox
                          label={value.label}
                          defaultChecked={value.value}
                          value={value.value}
                          value_update={(e: any, a: any) => {
                            const addOnKey = key as keyof TCarAddonStatus;
                            dispatch(
                              CarSlice.actions.UPDATE_ADDON_STATUS([
                                {
                                  key: addOnKey,
                                  value: a,
                                },
                              ])
                            );
                            updateAddonAndValue(value.label, a);
                          }}
                        />
                        {ADDON_STATUS.passenger_cover.value &&
                        value.label === "Passenger Cover (₹)" ? (
                          <div className="accessoryBox my-2">
                            <SelectDropdown
                              class_name="inputField "
                              title="Select Value"
                              value={fieldData.passengerCover.value}
                              attrName={[
                                "passengerCover",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              data={DROPDOWN_DATA.PASSENGER_COVER_DATA}
                              warn_status={fieldData.passengerCover.warning}
                              error_message={"Select amount"}
                            />
                          </div>
                        ) : ADDON_STATUS.accessories_cover.value &&
                          value.label === "Accessories Cover (₹)" ? (
                          <div className="accessoryBox">
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Electrical"}
                              value={fieldData.electricalAcessories.value}
                              attrName={[
                                "electricalAcessories",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                fieldData.electricalAcessories.warning
                              }
                              error_message={"Enter between ₹ 1 - ₹ 50,000"}
                            />
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Non-Electrical"}
                              value={fieldData.nonElectricalAcessories.value}
                              attrName={[
                                "nonElectricalAcessories",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={
                                fieldData.nonElectricalAcessories.warning
                              }
                              error_message={"Enter between ₹ 1 - ₹ 50,000 "}
                            />
                            <Button
                              onClick={() => {
                                updateAddonAndValue("Accessories Cover", value);
                                accessories_value_quote_load();
                              }}
                              className="apply-Btn ml-2"
                            >
                              Apply
                            </Button>
                          </div>
                        ) : ADDON_STATUS.cng_kit.value &&
                          value.label === "CNG Kit (₹)" ? (
                          <div className="accessoryBox">
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Kit Price"}
                              value={fieldData.cngKitValue.value}
                              attrName={[
                                "cngKitValue",
                                "value",
                                fieldData,
                                setFieldData,
                              ]}
                              value_update={updateMasterState}
                              warn_status={fieldData.cngKitValue.warning}
                              error_message={" Enter between ₹ 1 - ₹ 50,000"}
                            />
                            <Button
                              onClick={() => {
                                cng_kit_quote_load();
                              }}
                              className="apply-Btn ml-2"
                            >
                              Apply
                            </Button>
                          </div>
                        ) : null}
                      </li>
                    ) : null
                  )}
                </ul>

                {/* </ul> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MCarAddons;
