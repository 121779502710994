import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LeftSidebar from "../../../Component/Desktop/Term/ProposalForm/LeftSidebar/LeftSidebar";
import Navbar from "../../../Component/Desktop/Term/ProposalForm/Navbar/Navbar";
import MNavbar from "../../../Component/Mobile/Term/ProposalForm/Navbar/MNavbar";
import PaymentConfirmationPageContainer from "../../../Container/Term/ICICI/PaymentConfirmationPage/PaymentConfirmationPageContainer";
import PolicyConfirmationPageContainer from "../../../Container/Term/ICICI/PolicyConfirmationPage/PolicyConfirmationPageContainer";
import AddressDetailsContainer from "../../../Container/Term/ICICI/ProposalPage/AddressDetailsContainer/AddressDetailsContainer";
import CKYCContainer from "../../../Container/Term/ICICI/ProposalPage/CKYCContainer/CKYCContainer";
import JourneyRequirementContainer from "../../../Container/Term/ICICI/ProposalPage/JourneyRequirementContainer/JourneyRequirementContainer";
import MedicalPageContainer from "../../../Container/Term/ICICI/ProposalPage/MedicalPageContainer/MedicalPageContainer";
import NRIDetailsContainer from "../../../Container/Term/ICICI/ProposalPage/NRIDetailsContainer/NRIDetailsContainer";
import NomineeDetailsContainer from "../../../Container/Term/ICICI/ProposalPage/NomineeDetailsConatiner/NomineeDetailsContainer";
import PreviewPageContainer from "../../../Container/Term/ICICI/ProposalPage/PreviewPageContainer/PreviewPageContainer";
import ProposerDetailsContainer from "../../../Container/Term/ICICI/ProposalPage/ProposerDetailsContainer/ProposerDetailsContainer";
import UploadDocumentContainer from "../../../Container/Term/ICICI/ProposalPage/UploadDocumentContainer/UploadDocumentContainer";
import { TERM_PATH } from "../../../RoutesPath/TermPath";
import { TW_ROUTES } from "../../../Shared/Path/TWRoutes";
import { TermSlice } from "../../../State/Slice_Reducer/Term/TermSlice";
import { TTermMedicalQuestionDetails } from "../../../State/Types/TTermMedicalQuestionDetails";
import { useAppDispatch, useAppSelector } from "../../../State/hooks";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import TWRoutes from "../../TW/TWRoutes";

const ICICI_ROUTES = () => {
  return (
    <Routes>
      <Route path={TW_ROUTES.OWNER_DETAILS} element={<TWRoutes />} />
      <Route
        path={TERM_PATH.PROPOSER_DETAILS}
        element={<ProposerDetailsContainer />}
      />
      <Route
        path={TERM_PATH.COFIRMATION_PAGE}
        element={<PaymentConfirmationPageContainer />}
      />
      <Route
        path={TERM_PATH.JOURNEY_REQUIREMENT}
        element={<JourneyRequirementContainer />}
      />
      <Route
        path={TERM_PATH.NOMINEE_DETAILS}
        element={<NomineeDetailsContainer />}
      />
      <Route
        path={TERM_PATH.ADDRESS_DETAILS}
        element={<AddressDetailsContainer />}
      />
      <Route path={TERM_PATH.CKYC} element={<CKYCContainer />} />
      <Route
        path={TERM_PATH.UPLOAD_DOCUMENT}
        element={<UploadDocumentContainer />}
      />
      <Route path={TERM_PATH.MEDICAL_PAGE} element={<MedicalPageContainer />} />
      <Route path={TERM_PATH.PREVIEW_PAGE} element={<PreviewPageContainer />} />
      <Route
        path={TERM_PATH.POLICY_CONFIRMATION}
        element={<PolicyConfirmationPageContainer />}
      />
      <Route path={TERM_PATH.NRI_DETAILS} element={<NRIDetailsContainer />} />
    </Routes>
  );
};

function ICICIRoutes({
  health_details,
  covid_details,
  question_details,
}: {
  health_details: any;
  covid_details: any;
  question_details: any;
}) {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { TermICICIMedicalQuestion } = useAppSelector((state) => state.Term);

  useEffect(() => {
    setICICIMedicalQuestion(health_details, covid_details, question_details);
  }, [health_details, covid_details, question_details]);

  function isObjectBlank(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  const setICICIMedicalQuestion = (
    health_details: any,
    covid_details: any,
    question_details: any
  ) => {
    if (
      !isObjectBlank(health_details) &&
      !isObjectBlank(covid_details) &&
      !isObjectBlank(question_details)
    ) {
      let data: TTermMedicalQuestionDetails = { ...TermICICIMedicalQuestion };
      data = {
        ...data,
        question: [
          {
            main_question: {
              code: "PQ03",
              icon_class: "tobaco",
              title: "Do you have an Electronic Insurance Account?",
              status: question_details.isEIAOpted !== "No",
              field_data: [
                {
                  code: "PQ03-1",
                  title: "Account Number",
                  value: question_details.EIAAccountNumber,
                  warning: false,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "PQ01",
              icon_class: "tobaco",
              title:
                "Are you engaged in any of the mentioned industries(Export, Jewellery, Real Estate, Scrap Dealing, Shipping, Stock Broking & Agriculture)",
              status: question_details.industryTypeQuestion !== "No",
              field_data: [
                {
                  code: "PQ01-1",
                  title: "Industry Type",
                  value: question_details.indsType,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "PQ02",
              icon_class: "tobaco",
              title: "Are you a Politically Exposed Person?",
              status: question_details.pltclyExpsd !== "No",
            },
          },

          {
            main_question: {
              code: "PQ06",
              icon_class: "tobaco",
              title: "I/we are a tax resident of ONLY India",
              status: question_details.nriTaxResidentUS !== "No",
            },
          },
          {
            main_question: {
              code: "PQ07",
              icon_class: "tobaco",
              title: "My birth country is India",
              status: question_details.nriOtherResCountry !== "No",
            },
          },
          {
            main_question: {
              code: "PQ08",
              icon_class: "tobaco",
              title:
                "Have you ever been or currently being investigated, charge sheeted, prosecuted or convicted or acquittal or having pending charges in respect of any criminal/civil offences in any court of law in India or abroad?",
              status: question_details.currentlyInvestigated !== "No",
              field_data: [
                {
                  code: "PQ08-1",
                  title: "Investigation Details",
                  value: question_details.currentlyInvestigatedDesc,
                  warning: false,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "PQ09",
              icon_class: "tobaco",
              title: "Have you travelled abroad in last 1 year?",
              status: question_details.travelInLast1Year !== "No",
            },
          },
          {
            main_question: {
              code: "HQ05",
              icon_class: "tobaco",
              title: "Do you consume or have ever consumed tobacco?",
              status: health_details.tobacco !== "No",
              field_data: [
                {
                  code: "HQ05-1",
                  title: "Tobacco consumed as",
                  value: health_details.tobaccoConsumed,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "HQ05-2",
                  title: "Quantity per day",
                  value: health_details.tobaccoQuantity,
                  warning: false,
                  field_type: "textField",
                  numbers_only: true,
                },
                {
                  code: "HQ05-3",
                  title: "Since How many years",
                  value: health_details.tobaccoYear,
                  warning: false,
                  field_type: "textField",
                  numbers_only: true,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ06",
              icon_class: "alcohol",
              title: "Do you consume or have ever consumed Alcohol?",
              status: health_details.alcohol !== "No",
              field_data: [
                {
                  code: "HQ06-1",
                  title: "Alcohol consumed as",
                  value: health_details.alcoholConsumed,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "HQ06-2",
                  title: "Quantity per day",
                  value: health_details.alcoholQuantity,
                  warning: false,
                  field_type: "textField",
                  numbers_only: true,
                },
                {
                  code: "HQ06-3",
                  title: "Since How many years",
                  value: health_details.alcoholYear,
                  warning: false,
                  field_type: "textField",
                  numbers_only: true,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ07",
              icon_class: "intoxication",
              title: "Do you consume or have ever consumed Narcotics?",
              status: health_details.narcotics !== "No",
            },
          },
          {
            main_question: {
              code: "HQ09",
              icon_class: "hazardousactivity",
              title:
                "Is your occupation associated with any specific hazard or do you take part in activities or have hobbies that could be dangerous in any way ? (eg - occupation - Chemical factory, mines, explosives, radiation, corrosive chemicals j - aviation other than as a fare paying passenger, diving, mountaineering, any form of racing, etc )",
              status: health_details.occupationAssociated !== "No",
              field_data: [
                {
                  code: "HQ09-1",
                  title: "Remarks",
                  value: health_details.occupationAssociatedText,
                  warning: false,
                  column: 12,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ125",
              icon_class: "army",
              title:
                "Are you employed in the armed, para military or police forces? (If yes, please provide Rank, Department/Division, Date of last medical & category after medical exam).",
              status: health_details.employeeArmed !== "No",
              field_data: [
                {
                  code: "HQ125-1",
                  title: "Remarks",
                  value: health_details.employeeArmedText,
                  warning: false,
                  field_type: "textField",
                  numbers_only: false,
                  column: 12,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ144",
              icon_class: "healthcheckup",
              title:
                "Have you lost weight of 10 kgs or more in the last six months?",
              status: health_details.lostWeight !== "No",
              field_data: [
                {
                  code: "HQ144-1",
                  title: "Remarks",
                  value: health_details.lostWeightText,
                  warning: false,
                  column: 12,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ165",
              icon_class: "heartdisease",
              title:
                "Family details of the life assured (include parents/sibling) Are any of your family members suffering from /have suffered from/have died of heart disease, Diabetes Mellitus, cancer or any other hereditary/familial disorder, before 55 years of age. If yes please provide details below.",
              status: health_details.familyLifeAssured !== "No",
              field_data: [
                {
                  code: "HQ165-1",
                  title: "Remarks",
                  value: health_details.familyLifeAssuredText,
                  warning: false,
                  column: 12,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ166",
              icon_class: "healthcheckups",
              title:
                "Have you undergone or been advised to undergo any tests/investigations or any surgery or hospitalized for observation or treatment in the past?",
              status: health_details.pastTreatment !== "No",
              field_data: [
                {
                  code: "HQ166-1",
                  title: "Remarks",
                  value: health_details.pastTreatmentText,
                  warning: false,
                  column: 12,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ167",
              icon_class: "medicalhistory",
              title: `Have you ever suffered or being diagnosed with or been treated for any of the following?`,
              status: health_details.sufferedDiagnosedHQ167 !== "No",
              field_data: [
                {
                  code: "HQ167-1",
                  title: "Remarks",
                  value: "",
                  warning: false,
                  column: 12,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
            sub_question: [
              {
                code: "HQ167-SQ1",
                icon_class: "medicalhistory",
                title: `Hypertension/ High BP/ high cholesterol`,
                status: health_details.hypertensionHQ167 !== "No",
              },
              {
                code: "HQ167-SQ2",
                icon_class: "medicalhistory",
                title: `Chest Pain/ Heart Attack/ any other heart disease or problem`,
                status: health_details.heartDiseaseHQ167 !== "No",
              },
              {
                code: "HQ167-SQ3",
                icon_class: "medicalhistory",
                title: `Undergone angioplasty, bypass surgery, heart surgery`,
                status: health_details.bypassSurgeryHQ167 !== "No",
              },
              {
                code: "HQ167-SQ4",
                icon_class: "medicalhistory",
                title: `Diabetes/ High Blood Sugar/ Sugar in Urine`,
                status: health_details.sugarHQ167 !== "No",
              },
              {
                code: "HQ167-SQ5",
                icon_class: "medicalhistory",
                title: `Asthma, Tuberculosis or any other respiratory disorder`,
                status: health_details.respiratoryDisorderHQ167 !== "No",
              },
              {
                code: "HQ167-SQ6",
                icon_class: "medicalhistory",
                title: `Nervous disorders/ stroke/ paralysis/ epilepsy`,
                status: health_details.strokeHQ167 !== "No",
              },
              {
                code: "HQ167-SQ7",
                icon_class: "medicalhistory",
                title: `Any GastroIntestinal disorders like Pancreatitis, Colitis etc.`,
                status: health_details.colitisHQ167 !== "No",
              },
              {
                code: "HQ167-SQ8",
                icon_class: "medicalhistory",
                title: `Liver disorders/ Jaundice/ Hepatitis B or C`,
                status: health_details.liverDisordersHQ167 !== "No",
              },
              {
                code: "HQ167-SQ9",
                icon_class: "medicalhistory",
                title: `Genitourinary disorders related to kidney, prostate, urinary system`,
                status: health_details.urinarySystemHQ167 !== "No",
              },
              {
                code: "HQ167-S10",
                icon_class: "medicalhistory",
                title: `Cancer, Tumor, Growth or Cyst of any Kind`,
                status: health_details.cancerHQ167 !== "No",
              },
              {
                code: "HQ167-S11",
                icon_class: "medicalhistory",
                title: `HIV infection AIDS or positive test for HIV`,
                status: health_details.hivHQ167 !== "No",
              },
              {
                code: "HQ167-SQ12",
                icon_class: "medicalhistory",
                title: `Any blood disorders like Anaemeia, Thalassemia etc`,
                status: health_details.bloodDisordersHQ167 !== "No",
              },
              {
                code: "HQ167-SQ13",
                icon_class: "medicalhistory",
                title: `Psychiatric or mental disorders`,
                status: health_details.mentalDisoredersHQ167 !== "No",
              },
              {
                code: "HQ167-SQ14",
                icon_class: "medicalhistory",
                title: `Any other disorder not mentioned above`,
                status: health_details.anyOtherDisorderHQ167 !== "No",
                field_data: [
                  {
                    code: "HQ167-SQ14-1",
                    title: "Remarks ",
                    value: health_details.anyOtherDisorderTextHQ167,
                    warning: false,
                    column: 6,
                    field_type: "textField",
                    numbers_only: false,
                  },
                ],
              },
              {
                code: "HQ188",
                icon_class: "insurancepolicy",
                title: "Would you like to fill the Health Declaration Form?",
                status: health_details.healthDeclarationHQ188 !== "No",
                field_data: [
                  {
                    code: "HQ188-1",
                    title: "What is the name of the medical condition?",
                    value: health_details.medicalConditionHQ188,
                    warning: false,
                    column: 6,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "HQ188-2",
                    title: "What was the date of diagnosis?",
                    value: health_details.dateDiagnosisHQ188,
                    warning: false,
                    column: 6,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                  {
                    code: "HQ188-3",
                    title: "How long did the treatment go on for?",
                    value: health_details.treatmentGOHQ188,
                    warning: false,
                    column: 6,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "HQ188-4",
                    title:
                      "What is the current state of the medical condition?",
                    value: health_details.currentMedicalConditionHQ188,
                    warning: false,
                    column: 6,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "HQ188-5",
                    title: "Please provide details of the treatment",
                    value: health_details.treatmentDetailsHQ188,
                    warning: false,
                    column: 6,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "HQ188-6",
                    title: "What tests have you undergone for treatment?",
                    value: health_details.undergoneTreatmentHQ188,
                    warning: false,
                    column: 6,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "HQ188-7",
                    title: "Any other details you want to provide",
                    value: health_details.anyOtherDetailsHQ188,
                    warning: false,
                    column: 6,
                    field_type: "textField",
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            main_question: {
              code: "HQ168",
              icon_class: "uterus",
              title:
                "Have you ever suffered/are suffering from or have undergone investigations or treatment for any gynecological complications such as disorders of cervix, uterus, ovaries, breast, breast lump, cyst etc.",
              status: health_details.gynecologicalComplications !== "No",
              field_data: [
                {
                  code: "HQ168-1",
                  title: "Remarks",
                  value: health_details.gynecologicalComplicationsText,
                  warning: false,
                  column: 12,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ301",
              icon_class: "handicaped",
              title:
                "Do you have any congenital defect/abnormality/physical deformity/handicap?",
              status: health_details.congenitalDefect !== "No",
              field_data: [
                {
                  code: "HQ301-1",
                  title: "Remarks",
                  value: health_details.congenitalDefectText,
                  warning: false,
                  column: 12,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ24",
              icon_class: "slippeddisc",
              title:
                "Did you have any ailment/injury/accident requiring treatment/medication for more than a week or have you availed leave for more than 5 days on medical grounds in the last two years?",
              status: health_details.ailment !== "No",
              field_data: [
                {
                  code: "HQ24-1",
                  title: "Remarks",
                  value: health_details.ailmentText,
                  warning: false,
                  column: 12,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          },
          {
            main_question: {
              code: "HQ61",
              icon_class: "pregnancy",
              title: "Are you pregnant at present?",
              status: health_details.pragnant !== "No",
              field_data: [
                {
                  code: "HQ61-1",
                  title: "Confirm the Duration (in weeks)",
                  value: health_details.pragnantWeeks,
                  warning: false,
                  field_type: "textField",
                  numbers_only: true,
                },
              ],
            },
          },
          {
            main_question: {
              code: "CHQ14",
              icon_class: "testpolitive",
              title: "Have you ever been tested positive for COVID-19?",
              status: covid_details.positiveCovid19CHQ14 !== "No",
            },
            sub_question: [
              {
                code: "CHQ14-SQ1",
                icon_class: "hopital",
                title: "Are you fully recovered?",
                status: covid_details.fullyCoveredCHQ15 !== "No",
                field_data: [
                  {
                    code: "CHQ14-SQ1-1",
                    title: "Date of diagnosis",
                    value: covid_details.dateDiagnoseCHQ16,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ14-SQ1-2",
                    title: "Date of recovery",
                    value: covid_details.dateRecoveryCHQ17,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                ],
              },
              {
                code: "CHQ14-SQ2",
                icon_class: "treatment",
                title: "Were you hospitalized for COVID-19 treatment?",
                status: covid_details.hospitalizedCovid19CHQ18 !== "No",
              },
            ],
          },
          {
            main_question: {
              code: "CHQ58",
              icon_class: "testpolitive",
              title:
                "In the last 3 months have you been tested positive for COVID-19?",
              status:
                covid_details.last3MonthsTestedPositiveCHQ1 === "No"
                  ? false
                  : true,
            },
          },

          {
            main_question: {
              code: "CHQ57",
              icon_class: "preexisting",
              title:
                "In the last 3 months have you been self-isolated with symptoms on medical advice?",
              status: covid_details.last3MonthsIsolatedCHQ2 !== "No",
            },
          },
          {
            main_question: {
              code: "CHQ19",
              icon_class: "preexisting",
              title:
                "In the last 1 months have you or any of your family member been self-isolated with symptoms on medical advice? (excluding mandatory government orders to remain at home)",
              status:
                covid_details.last1MonthsAdvisedIsolatedCHQ3 === "No"
                  ? false
                  : true,
            },
          },
          {
            main_question: {
              code: "CHQ59",
              icon_class: "cough",
              title:
                "In the last 1 month did you have persistent cough, fever, raised temperature or been in contact with an individual suspected or confirmed to have COVID-19?",
              status:
                covid_details.last1MonthsConfirmedCovid19CHQ4 === "No"
                  ? false
                  : true,
            },
          },
          {
            main_question: {
              code: "CHQ20",
              icon_class: "cough",
              title:
                "In the last 1 month did you have persistent cough, fever, sore throat, nausea, vomiting, diarrhea, difficulty in breathing, loss of smell and taste any other symptoms of coronavirus (COVID-19) and advised to do a Covid test or you/your family member have been in contact with an individual suspected or confirmed to have COVID-19?",
              status: covid_details.advisedCovidTestCHQ20 !== "No",
            },
          },
          {
            main_question: {
              code: "CHQ60",
              icon_class: "doctor",
              title:
                "Do you work in an occupation, where you have a higher risk to get in close contact with COVID-19 patients or with coronavirus contaminated material?",
              status: covid_details.occupationCovid19CHQ5 !== "No",
            },
          },
          {
            main_question: {
              code: "CHQ21",
              icon_class: "doctor",
              title:
                "Do you work in an occupation like health care worker/Corona warrior Include (General Practitioners, Doctors, Hospital Doctors, Surgeons, Therapists, Nurses, Pathologist, paramedics, Pharmacist, Ward helpers, Individuals working in Hospitals/ Clinics having novel coronavirus (SARS-CoV-2/COVID-19) Ward?) where you have a higher risk to get in close contact with COVID-19 patients or with coronavirus contaminated material?",
              status: covid_details.occupationHealthCareCHQ21 !== "No",
            },
          },
          {
            main_question: {
              code: "CHQ30",
              icon_class: "insulin",
              title: "Have you been vaccinated for COVID-19?",
              status: covid_details.VaccinatedCovide19CHQ30 !== "No",
              field_data: [
                {
                  code: "CHQ30-1",
                  title: "Date of First dose",
                  value: covid_details.fisrtDoseCHQ31,
                  warning: false,
                  field_type: "datePicker",
                  numbers_only: false,
                },
                {
                  code: "CHQ30-2",
                  title: "Name of vaccine",
                  value: covid_details.nameVaccineCHQ32,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
              ],
            },
            sub_question: [
              {
                code: "CHQ33",
                icon_class: "insulin",
                title: "Have you been vaccinated with Second dose?",
                status: covid_details.vaccinatedSecondDoseCHQ33 !== "No",
                field_data: [
                  {
                    code: "CHQ33-1",
                    title: "Date of Second dose",
                    value: covid_details.secondDoseCHQ34,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ33-2",
                    title: "Name of vaccine",
                    value: covid_details.secondNameVaccineCHQ35,
                    warning: false,
                    field_type: "dropdown",
                    numbers_only: false,
                  },
                ],
              },
              {
                code: "CHQ36",
                icon_class: "headache",
                title:
                  "Have you experienced any adverse reaction post vaccination?",
                status: covid_details.postVaccinationCHQ36 !== "No",
                field_data: [
                  {
                    code: "CHQ36-1",
                    title:
                      "Please share details including treatment taken for the same and date of complete recovery",
                    value: covid_details.detailsCompleteRecoveryCHQ37,
                    warning: false,
                    field_type: "textField",
                    numbers_only: false,
                    column: 12,
                  },
                ],
              },
            ],
          },
          {
            main_question: {
              code: "CHQ38",
              icon_class: "abroad",
              title: "Are your currently residing outside of India?",
              status: covid_details.residingOustSideIndiaCHQ38 !== "No",
              field_data: [
                {
                  code: "CHQ38-1",
                  title: "Name of country",
                  value: covid_details.nameCountryCHQ39,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "CHQ38-2",
                  title: "City",
                  value: covid_details.cityCHQ40,
                  warning: false,
                  field_type: "textField",
                  numbers_only: false,
                },
                {
                  code: "CHQ38-3",
                  title: "Date of travel",
                  value: covid_details.dateTravelCHQ41,
                  warning: false,
                  field_type: "datePicker",
                  numbers_only: false,
                },
                {
                  code: "CHQ38-4",
                  title: "Intended duration (in Months)",
                  value: covid_details.intendedDurationCHQ42,
                  warning: false,
                  field_type: "textField",
                  numbers_only: true,
                },
              ],
            },
          },
          {
            main_question: {
              code: "CHQ43",
              icon_class: "abroad",
              title: "Have you travelled abroad in past 14 days?",
              status: covid_details.travelledAbroadCHQ43 !== "No",
              field_data: [
                {
                  code: "CHQ43-1",
                  title: "Name of country",
                  value: covid_details.nameCountryCHQ44,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "CHQ43-2",
                  title: "City",
                  value: covid_details.cityCHQ45,
                  warning: false,
                  field_type: "textField",
                  numbers_only: false,
                },
                {
                  code: "CHQ43-3",
                  title: "Date Arrived",
                  value: covid_details.dateArrivedCHQ46,
                  warning: false,
                  field_type: "datePicker",
                  numbers_only: false,
                },
                {
                  code: "CHQ43-4",
                  title: "Date Departed",
                  value: covid_details.dateDepartedCHQ47,
                  warning: false,
                  field_type: "datePicker",
                  numbers_only: false,
                },
              ],
            },
            sub_question: [
              {
                code: "CHQ43-SQ1",
                icon_class: "abroad",
                title: "2nd country details",
                status: covid_details.travelledAbroadCHQ43 !== "No",
                field_data: [
                  {
                    code: "CHQ43-SQ1-1",
                    title: "Name of country",
                    value: covid_details.nameCountryCHQ48,
                    warning: false,
                    field_type: "dropdown",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ43-SQ1-2",
                    title: "City",
                    value: covid_details.cityCHQ49,
                    warning: false,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ43-SQ1-3",
                    title: "Date Arrived",
                    value: covid_details.dateArrivedCHQ50,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ43-SQ1-4",
                    title: "Date Departed",
                    value: covid_details.dateDepartedCHQ51,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                ],
              },
              {
                code: "CHQ43-SQ2",
                icon_class: "abroad",
                title: "2nd country details",
                status: covid_details.travelledAbroadCHQ43 !== "No",
                field_data: [
                  {
                    code: "CHQ43-SQ2-1",
                    title: "Name of country",
                    value: covid_details.nameCountryCHQ52,
                    warning: false,
                    field_type: "dropdown",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ43-SQ2-2",
                    title: "City",
                    value: covid_details.cityCHQ53,
                    warning: false,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ43-SQ2-3",
                    title: "Date Arrived",
                    value: covid_details.dateArrivedCHQ54,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ43-SQ2-4",
                    title: "Date Departed",
                    value: covid_details.dateDepartedCHQ55,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          {
            main_question: {
              code: "CHQ56",
              icon_class: "abroad",
              title: "Do you intend to travel abroad in next 3 month?",
              status: covid_details.travelAbroadCHQ56 !== "No",
              field_data: [
                {
                  code: "CHQ56-1",
                  title: "Name of country",
                  value: covid_details.nameCountryCHQ57,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "CHQ56-2",
                  title: "City",
                  value: covid_details.cityCHQ58,
                  warning: false,
                  field_type: "textField",
                  numbers_only: false,
                },
                {
                  code: "CHQ56-3",
                  title: "Date Arrived",
                  value: covid_details.dateTravelCHQ59,
                  warning: false,
                  field_type: "datePicker",
                  numbers_only: false,
                },
                {
                  code: "CHQ56-4",
                  title: "Date Departed",
                  value: covid_details.intendedDurationCHQ60,
                  warning: false,
                  field_type: "datePicker",
                  numbers_only: false,
                },
              ],
            },
            sub_question: [
              {
                code: "CHQ56-SQ1",
                icon_class: "abroad",
                title: "2nd country details",
                status: covid_details.travelledAbroadCHQ56 !== "No",
                field_data: [
                  {
                    code: "CHQ56-SQ1-1",
                    title: "Name of country",
                    value: covid_details.nameCountryCHQ61,
                    warning: false,
                    field_type: "dropdown",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ56-SQ1-2",
                    title: "City",
                    value: covid_details.cityCHQ62,
                    warning: false,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ56-SQ1-3",
                    title: "Date Arrived",
                    value: covid_details.dateArrivedCHQ63,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ56-SQ1-4",
                    title: "Date Departed",
                    value: covid_details.dateDepartedCHQ64,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                ],
              },
              {
                code: "CHQ56-SQ2",
                icon_class: "abroad",
                title: "2nd country details",
                status: covid_details.travelledAbroadCHQ56 !== "No",
                field_data: [
                  {
                    code: "CHQ56-SQ2-1",
                    title: "Name of country",
                    value: covid_details.nameCountryCHQ65,
                    warning: false,
                    field_type: "dropdown",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ56-SQ2-2",
                    title: "City",
                    value: covid_details.cityCHQ66,
                    warning: false,
                    field_type: "textField",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ56-SQ2-3",
                    title: "Date Arrived",
                    value: covid_details.dateArrivedCHQ67,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                  {
                    code: "CHQ56-SQ2-4",
                    title: "Date Departed",
                    value: covid_details.dateDepartedCHQ68,
                    warning: false,
                    field_type: "datePicker",
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
        ],
      };

      dispatch(TermSlice.actions.SET_ICICI_MEDICAL_QUESTION(data));
    }
  };

  return (
    <>
      {isMobile ? (
        <BrowserRouter>
          <Box className="proposalWrapperMobile">
            <MNavbar />
            <Grid container spacing={0} className="d-non">
              <Grid xs={12} className="proposalContentSection">
                {ICICI_ROUTES()}
              </Grid>
            </Grid>
          </Box>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Box className="proposalWrapper">
            <Navbar />
            <Grid container spacing={3} className="pb-0">
              <LeftSidebar class_name="termIcon" />
              <Grid xs className="proposalContentSection">
                {ICICI_ROUTES()}
              </Grid>
            </Grid>
          </Box>
        </BrowserRouter>
      )}
    </>
  );
}

export default ICICIRoutes;
