import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { TW_SERVICES } from "../../../../../Services/TW/TWServices";
import { TTWProduct } from "../../../../../Services/Type/TwoWheeler/TTWProduct";
import { TBUYACTION } from "../../../../../Services/Type/TwoWheeler/TWBuyaction";
import { TWSlice } from "../../../../../State/Slice_Reducer/TW/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import { CURRENCY_FORMAT } from "../../../../../SupportingFiles/HelpingFunction";
import {
  TW_PREMIUM_CALC,
  shouldDisplayAddonTW,
} from "../../../../../SupportingFiles/TWPremiumCal";
import TWPolicyDetails from "../TWPolicyDetail/TWPolicyDetail";
import "./TWQuoteCard.scss";
import { EMotorCompCode } from "../../../../../State/Enum/EMotorCompCode";
import { CLIENTS } from "../../../../../Shared/Clients";
import { TW_PATH } from "../../../../../RoutesPath/TWPath";
import { CKYC_ROUTES } from "../../../../../RoutesPath/CKYCRoutes";

const TWQuoteCard = ({ quote }: { quote: TTWProduct }) => {
  const [openPolicyDetails, setOpenPolicyDetails] = useState(false);
  const dispatch = useAppDispatch();
  const { ADDON_STATUS, PLAN_TYPE, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.TW
  );
  const [premium, setPremium] = useState<number>(0);

  useEffect(() => {
    const premium_details = quote.quotationDetail;

    if (premium_details) {
      const premium_with_tax = TW_PREMIUM_CALC(premium_details, ADDON_STATUS);
      setPremium(premium_with_tax);
    }
  }, [ADDON_STATUS, quote]);

  const Buyaction = () => {
    const onSuccess = () => {
      if (
        quote.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.BAJAJ ||
        quote.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.ACKO
      ) {
        window.location.href = `https://${CLIENTS.MOTILAL}${TW_PATH.PROPOSAL_PAGE}/${TW_PATH.OWNER_DETAILS}?type=CAR&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote.quotationDetail?.productDetails.id}`;
      } else {
        window.location.href = `https://${CLIENTS.MOTILAL}${TW_PATH.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=CAR&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote.quotationDetail?.productDetails.id}`;
      }
    };

    const onError = () => {};

    const Params: TBUYACTION = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "TW",
      product_code: `${quote.quotationDetail?.productDetails.product_code}`,
      productId: quote.quotationDetail?.productDetails.id,
      company_code: `${quote.quotationDetail?.CompanyDetails.company_code}`,
      IDV: quote.quotationDetail?.premiumDetails.IDV,
      finalPremium: premium,
    };

    TW_SERVICES.BUY_ACTION(onSuccess, onError, Params);
    dispatch(TWSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
  };
  return (
    <>
      <TWPolicyDetails
        open={openPolicyDetails}
        setOpen={setOpenPolicyDetails}
        quote={quote}
      />
      {quote.loader ? (
        <Box className="quotation skeleton">
          <Box className="quotation_tags">
            <Grid container alignItems="center" spacing={0} padding={0}>
              <Grid xs={6}>
                <img className="insurerLogo" src={quote.logo} />
                <Link className="viewDetails">Plan Details</Link>
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="idv_p">
                    Vehicle IDV
                    <span>
                      ₹ <div className="animatebar" />
                    </span>
                  </p>
                )}
                <Button className="buyBtn">
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span className="rs-icon">₹</span>
                    <img
                      src="../images/loader.svg"
                      alt=""
                      style={{
                        height: "20px",
                        width: "100%",
                        padding: "5px 0px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                  </span>{" "}
                  Buy Policy
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box className="addonsBox">
            <ul>
              <li>
                <div className="animatebar" />
              </li>
              <li>
                <div className="animatebar" />
              </li>
            </ul>
          </Box>
        </Box>
      ) : (
        <Box className="quotation">
          <Box className="quotation_tags">
            {!PLAN_TYPE ? null : (
              <span className="product_tag">{PLAN_TYPE}</span>
            )}
            <Grid container alignItems="center" spacing={0} padding={0}>
              <Grid xs={6}>
                <img
                  className="insurerLogo"
                  src={quote?.quotationDetail?.CompanyDetails.logo}
                />
                <Link
                  className="viewDetails"
                  onClick={() => {
                    setOpenPolicyDetails(true);
                    dispatch(TWSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
                  }}
                >
                  Plan Details
                </Link>
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="idv_p">
                    Vehicle IDV
                    <span>
                      {" "}
                      {CURRENCY_FORMAT(
                        parseFloat(
                          `${quote.quotationDetail?.premiumDetails.IDV}`
                        )
                      )}
                    </span>
                  </p>
                )}
                <Button
                  className="buyBtn"
                  onClick={() => {
                    Buyaction();
                  }}
                >
                  <span> {CURRENCY_FORMAT(premium)}</span> Buy Policy
                </Button>
              </Grid>
            </Grid>
          </Box>

          {ADDON_STATUS.driver_cover.value ||
          ADDON_STATUS.pa_owner_cover.value ||
          (PLAN_TYPE !== "Third Party" &&
            (ADDON_STATUS.zero_depreciation.value ||
              ADDON_STATUS.rsa.value)) ? (
            <Box
              className="addonsBox"
              display={"flex"}
              justifyContent={"space-between"}
            >
              <ul>
                {Object.entries(ADDON_STATUS).map(([key, value]) => {
                  const status = value.value;
                  const included =
                    quote.quotationDetail?.premiumDetails
                      ?.includedPAOwnerDriver === 1;
                  if (
                    (key === "zero_depreciation" || key === "rsa") &&
                    PLAN_TYPE === "Third Party"
                  ) {
                    return;
                  }
                  if (key === "pa_owner_cover" && PLAN_TYPE === "SAOD") {
                    return null;
                  }
                  if (key === "driver_cover" && PLAN_TYPE === "SAOD") {
                    return null;
                  }
                  return status || included ? (
                    <li key={key}>
                      <p
                        className={
                          included
                            ? "include"
                            : shouldDisplayAddonTW(
                                value.label,
                                quote?.quotationDetail?.premiumDetails
                              )
                            ? "yes"
                            : "no"
                        }
                      >
                        {value.label}
                      </p>
                    </li>
                  ) : null;
                })}
              </ul>
            </Box>
          ) : (
            <Box
              className="addonsBox"
              display={"flex"}
              justifyContent={"space-between"}
            >
              <ul>
                {Object.entries(ADDON_STATUS).map(([key, value]) => {
                  const status = value.value;
                  const included =
                    quote.quotationDetail?.premiumDetails
                      ?.includedPAOwnerDriver === 1;

                  return status || included ? (
                    <li key={key}>
                      <p
                        className={
                          included
                            ? "include"
                            : shouldDisplayAddonTW(
                                value.label,
                                quote?.quotationDetail?.premiumDetails
                              )
                            ? "yes"
                            : "no"
                        }
                      >
                        {value.label}
                      </p>
                    </li>
                  ) : null;
                })}
              </ul>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default TWQuoteCard;
