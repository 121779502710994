import { Canceler } from "axios";
import AxiosApiInstance from "./AxiosAPIInstance";
import { toast } from "react-toastify";

export class PostAPI {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .post(url, dto, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        toast.error("Something went wrong");
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class GetAPI {
  static call(
    url: string,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .get(url, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        toast.error("Something went wrong");
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class PutAPI {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .put(url, dto, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class PatchAPI {
  static call(
    url: string,
    dto: any,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .patch(url, dto, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}

export class DeleteAPI {
  static call(
    url: string,
    onSuccess: Function = () => {},
    config: Object = {},
    onError: Function = () => {},
    onFinal: Function = () => {}
  ): Canceler {
    const api = new AxiosApiInstance();
    api.api
      .delete(url, config)
      .then((res: any) => {
        onSuccess(res);
      })
      .catch((err: any) => {
        onError(err);
      })
      .finally(() => {
        onFinal();
      });
    return api.cancelApi;
  }
}
