import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "react-circular-progressbar/dist/styles.css";
import "../../../../../CommonSCSS/ModalPopup.scss";
import { CAR_SERVICES } from "../../../../../Services/CAR/CarServices";
import { TAddonsValueType } from "../../../../../Services/Type/Car/TAddonsValueType";
import { TCARBUYACTION } from "../../../../../Services/Type/Car/TCARBUYACTION";
import { TCarProduct } from "../../../../../Services/Type/Car/TCarProduct";
import { CarSlice } from "../../../../../State/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  cal_gst,
  calculate_premium_for_car,
} from "../../../../../SupportingFiles/CarPermiumCal";
import {
  CURRENCY_FORMAT,
  calculate_passenger_cover,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import CarPolicyDetailsGoDigit from "./CarPolicyDetailsGoDigit";
import { CLIENTS } from "../../../../../Shared/Clients";
import { CAR_PATH } from "../../../../../RoutesPath/CarPath";
import { CKYC_ROUTES } from "../../../../../RoutesPath/CKYCRoutes";
import { EMotorCompCode } from "../../../../../State/Enum/EMotorCompCode";

const CarPolicyDetails = ({
  open,
  setOpen,
  addon_values,
  quote,
}: {
  open: boolean;
  setOpen: any;
  addon_values: TAddonsValueType;
  quote: TCarProduct;
}) => {
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_STATUS,
    SELECTED_QUOTE_DATA,
    PLAN_TYPE,
    ADDON_USER_VALUE,
  } = useAppSelector((state) => state.Car);
  const [value, setValue] = React.useState("1");
  const dispatch = useAppDispatch();

  const selectedQuote: any = SELECTED_QUOTE_DATA;

  const addon_tag_name: any = {
    pa_owner_cover: "paOwnerDriver",
    rsa: "roadSideAssistance",
    depreciation_cover: "depreciationCover",
    driver_cover: "driverCover",
    consumable_cover: "consumablesCover",
    engine_cover: "engineGearBoxProtection",
    invoice_cover: "invoicePriceCover",
    key_replacement: "keyReplacementCover",
    ncb_cover: "noClaimBonusSameSlab",
    tyre_cover: "tyreSecureCover",
    accessories_cover: "accessoryCoverCharge",
    cng_kit: "cngKitOD",
    passenger_cover: "passengerCover",
    electrical: "electricalCharge",
    non_electrical: "nonElecCharge",
  };

  const Buyaction = () => {
    const onSuccess = () => {
      if (
        quote.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.BAJAJ ||
        quote.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.ACKO ||
        quote.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.TATA
      ) {
        window.location.href = `https://${CLIENTS.MOTILAL}${CAR_PATH.PROPOSAL_PAGE}/${CAR_PATH.OWNER_DETAILS}?type=CAR&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote.quotationDetail?.productDetails.id}`;
      } else {
        window.location.href = `https://${CLIENTS.MOTILAL}${CAR_PATH.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=CAR&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote.quotationDetail?.productDetails.id}`;
      }
    };

    const onError = () => {};

    const Params: TCARBUYACTION = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "CAR",
      product_code: `${quote.quotationDetail?.productDetails.product_code}`,
      productId: `${quote.quotationDetail?.productDetails.id}`,
      company_code: `${quote.quotationDetail?.CompanyDetails.company_code}`,
      IDV: quote.quotationDetail?.premiumDetails.IDV,
      finalPremium: quote.quotationDetail?.premiumDetails.netPremium,
    };
    CAR_SERVICES.BUY_ACTION(onSuccess, onError, Params);
    dispatch(CarSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Grid
            className="title mx-0"
            container
            columnSpacing={3}
            alignItems="center"
          >
            <Button className="closePopup" onClick={() => setOpen(false)} />
            <Grid xs={12}>
              <h3>Policy Details</h3>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={0}
            className="quotepolicyDetail mx-3 mb-6"
          >
            <Grid xs={3}>
              <img
                src={SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails?.logo}
                alt="Insurer Logo"
                className="insurerLogo"
              />
              <p className="mb-4">Car Insurance</p>

              {PLAN_TYPE === "Third Party" ? null : (
                <h5 className="mb-4">
                  <p className="tag_p">Vehicle IDV</p>
                  {""}
                  {CURRENCY_FORMAT(
                    parseInt(
                      `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV}`
                    )
                  )}
                  {!isEmpty(ADDON_USER_VALUE.cngKitValue.value) &&
                  ADDON_STATUS?.cng_kit?.value ? (
                    <p className="tag_p mt-1">(Incl. CNG)</p>
                  ) : null}
                </h5>
              )}

              <p className="tag_p">Premium (Incl. GST)</p>
              <h5 className="mb-4">
                {CURRENCY_FORMAT(
                  calculate_premium_for_car(
                    SELECTED_QUOTE_DATA.quotationDetail,
                    ADDON_STATUS,
                    addon_values.passengerCover.value,
                    PLAN_TYPE
                  )
                )}
              </h5>

              {PLAN_TYPE !== "Third Party" &&
                ADD_FORM.business_type === "Rollover" && (
                  <Grid xs>
                    <p className="tag_p">
                      New NCB{" "}
                      <span>
                        {ADD_FORM.claimed === "No" &&
                          ADD_FORM.business_type === "Rollover" && (
                            <span>{`${ADD_FORM.new_ncb.value}%`}</span>
                          )}
                        {ADD_FORM.claimed === "Yes" &&
                          ADD_FORM.business_type === "Rollover" && (
                            <span>0 %</span>
                          )}
                      </span>
                    </p>
                  </Grid>
                )}
              <Box className="ctaBtn mb-4">
                <CustomButton
                  onClickFunction={() => {
                    Buyaction();
                  }}
                  class_name="primaryMdBtn"
                  text_name={"Buy Now"}
                />
              </Box>
              {/* <Link className="garageLink mb-2">
                <BuildIcon /> Cashless Garage
              </Link> */}
              {/* <Link className="emailLink mb-4">
                <EmailIcon /> Send on Email
              </Link> */}
            </Grid>
            <Grid xs={9}>
              <TabContext value={value}>
                <TabPanel value="1" className="tabPanel">
                  <Grid container spacing={3}>
                    <Grid xs={6}>
                      <h4>Vehicle Own Damage</h4>
                      {quote.quotationDetail?.CompanyDetails.company_code ===
                      "G033" ? (
                        <CarPolicyDetailsGoDigit quote={quote} />
                      ) : (
                        <ul className="addonList mt-4">
                          <li>
                            Basic OD:{" "}
                            <span>
                              {CURRENCY_FORMAT(
                                parseFloat(
                                  `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicOD}`
                                )
                              )}
                            </span>
                          </li>
                          <li>
                            NCB Discount (-):{" "}
                            <span>
                              {CURRENCY_FORMAT(
                                parseFloat(
                                  `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.ncbDiscount}`
                                )
                              )}
                            </span>
                          </li>
                          {(ADDON_STATUS.personalBelonging.value ||
                            quote?.quotationDetail?.premiumDetails
                              .includedPersonalBelonging === 1) &&
                          parseInt(
                            `${quote?.quotationDetail?.premiumDetails.personalBelonging}`
                          ) > 0 ? (
                            <li>
                              Loss of Personal Belongings:{" "}
                              <span>
                                {CURRENCY_FORMAT(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.personalBelonging}`
                                  )
                                )}
                              </span>
                            </li>
                          ) : null}
                          {Object.entries(ADDON_STATUS).map(([key, value]) => {
                            if (
                              value.value &&
                              addon_tag_name.hasOwnProperty(key)
                            ) {
                              const addonKey = addon_tag_name[key];
                              const addonValue =
                                selectedQuote?.quotationDetail
                                  ?.premiumDetails?.[addonKey] || 0;
                              if (
                                addonKey !== "paOwnerDriver" &&
                                addonKey !== "driverCover" &&
                                addonKey !== "passengerCover"
                              ) {
                                if (addonValue > 0) {
                                  return (
                                    <li key={key}>
                                      {value.label === "CNG Kit (₹)"
                                        ? "Bi-fuel OD Premium"
                                        : value.label}
                                      :{" "}
                                      <span>
                                        {CURRENCY_FORMAT(
                                          parseFloat(
                                            `${
                                              selectedQuote?.quotationDetail
                                                ?.premiumDetails?.[addonKey]
                                                ? selectedQuote?.quotationDetail
                                                    ?.premiumDetails?.[addonKey]
                                                : 0
                                            }`
                                          )
                                        )}
                                      </span>
                                    </li>
                                  );
                                }
                              }
                            }
                          })}
                        </ul>
                      )}
                    </Grid>
                    <Grid xs={6}>
                      <h4>Third Party Damage</h4>
                      <ul className="addonList mt-4">
                        <li>
                          Third party liability premium:
                          <span>
                            {CURRENCY_FORMAT(
                              parseFloat(
                                `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicTP}`
                              )
                            )}
                          </span>
                        </li>
                        {SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
                          .cngKitTP &&
                        SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
                          .cngKitTP > 0 &&
                        ADDON_STATUS.cng_kit.value ? (
                          <li>
                            Bi-fuel Third Party Premium:
                            <span>
                              {CURRENCY_FORMAT(
                                parseFloat(
                                  `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.cngKitTP}`
                                )
                              )}
                            </span>
                          </li>
                        ) : null}
                        {ADDON_STATUS.passenger_cover.value ? (
                          <li>
                            Passenger Cover:
                            <span>
                              {CURRENCY_FORMAT(
                                calculate_passenger_cover(
                                  parseInt(
                                    `${ADDON_USER_VALUE.passengerCover.value}`
                                  ),
                                  Number(
                                    quote.quotationDetail?.premiumDetails
                                      .seatingCapacity
                                  ),
                                  PLAN_TYPE
                                )
                              )}
                            </span>
                          </li>
                        ) : null}

                        {Object.entries(ADDON_STATUS).map(([key, value]) => {
                          if (
                            value.value &&
                            addon_tag_name.hasOwnProperty(key)
                          ) {
                            const addonKey = addon_tag_name[key];
                            const addonValue =
                              selectedQuote?.quotationDetail?.premiumDetails?.[
                                addonKey
                              ] || 0;
                            if (
                              addonKey === "paOwnerDriver" ||
                              addonKey === "driverCover"
                            ) {
                              if (addonValue > 0) {
                                return (
                                  <li key={key}>
                                    {value.label}:{" "}
                                    <span>
                                      {CURRENCY_FORMAT(
                                        parseFloat(
                                          `${
                                            selectedQuote?.quotationDetail
                                              ?.premiumDetails?.[addonKey]
                                              ? selectedQuote?.quotationDetail
                                                  ?.premiumDetails?.[addonKey]
                                              : 0
                                          }`
                                        )
                                      )}
                                    </span>
                                  </li>
                                );
                              }
                            }
                          }
                        })}
                      </ul>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container spacing={3}>
                    <Grid xs={6}></Grid>
                    <Grid xs={6}>
                      <ul className="addonList mt-4">
                        <li>
                          Net Premium:
                          <span>
                            {CURRENCY_FORMAT(
                              calculate_premium_for_car(
                                SELECTED_QUOTE_DATA.quotationDetail,
                                ADDON_STATUS,
                                addon_values.passengerCover.value,
                                PLAN_TYPE
                              ) -
                                cal_gst(
                                  calculate_premium_for_car(
                                    SELECTED_QUOTE_DATA.quotationDetail,
                                    ADDON_STATUS,
                                    addon_values.passengerCover.value,
                                    PLAN_TYPE
                                  )
                                )
                            )}
                          </span>
                        </li>
                        <li>
                          GST 18%:
                          <span>
                            {CURRENCY_FORMAT(
                              cal_gst(
                                calculate_premium_for_car(
                                  SELECTED_QUOTE_DATA.quotationDetail,
                                  ADDON_STATUS,
                                  addon_values.passengerCover.value,
                                  PLAN_TYPE
                                )
                              )
                            )}
                          </span>
                        </li>
                      </ul>
                      <h4>
                        Total Premium:
                        <span style={{ float: "right" }}>
                          {CURRENCY_FORMAT(
                            calculate_premium_for_car(
                              SELECTED_QUOTE_DATA.quotationDetail,
                              ADDON_STATUS,
                              addon_values.passengerCover.value,
                              PLAN_TYPE
                            )
                          )}
                        </span>
                      </h4>
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default CarPolicyDetails;
