import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import CarRoutes from "./Routes/Car/CarRoutes";
import TWRoutes from "./Routes/TW/TWRoutes";
import TERM_ROUTES from "./Routes/Term/TermRoutes";
import { AUTH_SERVICE } from "./Services/AuthServices/AuthService";

function App() {
  const [isTokenGenerated, setIsTokenGenerated] = useState(false);
  useEffect(() => {
    const onSuccess = (data: any) => {
      setIsTokenGenerated(true);
    };

    // Call the method to get the access token
    AUTH_SERVICE.getAccessTokken(onSuccess);
  }, []);

  return (
    <>
      {isTokenGenerated && (
        <>
          <TERM_ROUTES />
          <CarRoutes />
          <TWRoutes />
        </>
      )}
    </>
  );
}

export default App;
