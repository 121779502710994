import { PayloadAction } from "@reduxjs/toolkit";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";
import { PROPOSER_PAGE_STATE_STATUS } from "../../../Types/TProposerPage";

function setProposalPage(
  state: TTermSlice,
  action: PayloadAction<PROPOSER_PAGE_STATE_STATUS>
) {
  const data: TTermSlice = {
    ...state,
    proposerPage: { ...state.proposerPage, page_name: action.payload },
  };
  return data;
}

function updateUpdateStatus(state: TTermSlice, action: PayloadAction<boolean>) {
  const data: TTermSlice = {
    ...state,
    proposerPage: { ...state.proposerPage, update_status: action.payload },
  };
  return data;
}

function SAVE_PAYMENT_URL(state: TTermSlice, action: PayloadAction<string>) {
  const data: TTermSlice = {
    ...state,
    proposerPage: { ...state.proposerPage, PAYMENT_URL: action.payload },
  };
  return data;
}

export const TERM_PROPOSER_PAGE_REDUCERS = {
  setProposalPage,
  updateUpdateStatus,
  SAVE_PAYMENT_URL,
};
