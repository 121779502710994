import moment from "moment";
import { useEffect, useState } from "react";
import CarModifyDetails from "../../Component/Desktop/Car/QuotePage/CarModifyDetails/CarModifyDetails";
import MCarModifyDetails from "../../Component/Mobile/Car/QuotePage/MCarModifyDetails/MCarModifyDetails";
import { CAR_ADD_FORM_SERVICES } from "../../Services/CAR/CarAddFormServices";
import { CAR_SERVICES } from "../../Services/CAR/CarServices";
import { UpdateCarDetailsDTO } from "../../Services/DTO/CarDTO/UpdateCarDeatilsDTO";
import { TCarAddForm } from "../../Services/Type/TCarSlice";
import { CarSlice } from "../../State/Slice_Reducer/Car/CarSlice";
import { TSearchableDropdown } from "../../State/Types/TSearchableDropdown";
import { useAppDispatch, useAppSelector } from "../../State/hooks";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";

export const CarModifyDetailsContainer = ({
  open,
  setOpen,
  get_product_list,
}: {
  open: boolean;
  setOpen: Function;
  get_product_list: Function;
}) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { ADD_FORM, ADD_FORM_RESPONSE } = useAppSelector((state) => state.Car);
  const [formData, setFormData] = useState<TCarAddForm>(ADD_FORM);

  useEffect(() => {
    GET_MAKE_MODEL_LIST();
    GET_PREVIOUS_INS();
  }, []);

  useEffect(() => {
    GET_FUEL_TYPE_LIST();
  }, [formData.make_model.value]);

  useEffect(() => {
    GET_VARIANT_LIST();
  }, [formData.make_model.value, formData.fuel_type.value]);

  const GET_MAKE_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const results = res.data.results;
      const error = results.error;

      let data: TSearchableDropdown[] = [];
      if (error === false) {
        const response = results.response;
        data = Object.values(response).map((item: any) => ({
          code: item,
          label: item,
        }));
      }

      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "MAKE_MODEL_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_MAKE_MODEL_LIST(onError, onSuccess);
  };
  const GET_PREVIOUS_INS = () => {
    const onSuccess = (res: any) => {
      let dropdown_data: TSearchableDropdown[] = [];
      Object.entries(res.data.results.response).map(([brand, value]) =>
        dropdown_data.push({ code: `${value}`, label: `${value}` })
      );
      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "PREVIOUS_INSURER_LIST",
          value: dropdown_data,
        })
      );
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    CAR_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };
  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      let data: TSearchableDropdown[] = [];
      const response = res.results.response;
      const response_array = Object.entries(response);

      response_array.forEach((elment) => {
        data.push({
          code: `${elment[1]}`,
          label: `${elment[1]}`,
        });
      });

      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "FUEL_TYPE_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onSuccess,
      onError,
      isEmpty(formData.make_model.value.label)
        ? `${formData.model.value}`
        : `${formData.make_model.value}`
    );
  };

  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      let data = Object.values(res.results.response).map((item: any) => ({
        code: item,
        label: item,
      }));

      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "VARIANT_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onSuccess,
      onError,
      `${formData.make_model.value.label}`,
      `${formData.fuel_type.value.label}`
    );
  };

  const validateForm = () => {
    let data: TCarAddForm = formData;

    data = {
      ...data,
      variant_cc: {
        ...data.variant_cc,
        warning: isEmpty(formData.variant_cc.value.label),
      },
    };

    setFormData({ ...data });
    dispatch(CarSlice.actions.ADD_FORM_DATA(data));

    if (
      // data.make_model.warning === false &&
      // data.fuel_type.warning === false &&
      data.variant_cc.warning === false
      // data.policy_expiry_date.warning === false &&
      // data.reg_date.warning === false &&
      // data.previous_insurer.warning === false
    ) {
      UPDATE_FORM_DETAILS();
    }
  };

  const UPDATE_FORM_DETAILS = () => {
    const onSuccess = (res: any) => {
      setOpen(false);
      get_product_list();
    };

    const onError = (err: any) => {
      console.log("GGGGGGG", err);
    };

    let param: UpdateCarDetailsDTO = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "CAR",
      variant_cc: `${formData.variant_cc.value.label}`,
    };

    CAR_SERVICES.UPDATE_DETAILS(onSuccess, onError, param);
  };

  const fieldsUpdateState = (attrName: string, value: any) => {
    if (attrName === "claimed") {
      if (value === "Yes") {
        setFormData((prev) => ({
          ...prev,
          [attrName]: value,
          prev_ncb: "0",
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [attrName]: value,
        }));
      }
    } else if (attrName === "prev_ncb" || attrName === "business_type") {
      setFormData((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (
      attrName === "make" ||
      attrName === "model" ||
      attrName === "make_model" ||
      attrName === "variant_cc" ||
      attrName === "fuel_type" ||
      attrName === "rto"
    ) {
      if (attrName === "make_model") {
        setFormData((prev) => ({
          ...prev,
          fuel_type: { value: { code: "", label: "" }, warning: false },
          variant_cc: { value: { code: "", label: "" }, warning: false },
        }));
      } else if (attrName === "fuel_type") {
        setFormData((prev) => ({
          ...prev,
          variant_cc: { value: { code: "", label: "" }, warning: false },
        }));
      }
      if (value === null) {
        setFormData((prev) => ({
          ...prev,
          [attrName]: { value: "", warning: isEmpty(value) },
        }));
        return;
      } else {
        setFormData((prev) => ({
          ...prev,
          [attrName]: { value: value, warning: isEmpty(value) },
        }));
      }
    } else {
      if (attrName === "reg_date" || attrName === "policy_expiry_date") {
        setFormData((prev) => ({
          ...prev,
          [attrName]: {
            value: moment(value).format("DD-MM-YYYY"),
            warning: isEmpty(value),
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [attrName]: { value: value, warning: isEmpty(value) },
        }));
      }
    }
  };

  return (
    <>
      {isMobile ? (
        <MCarModifyDetails
          open={open}
          setOpen={setOpen}
          fieldsUpdateState={fieldsUpdateState}
          formData={formData}
          validateForm={validateForm}
        />
      ) : (
        <CarModifyDetails
          open={open}
          setOpen={setOpen}
          fieldsUpdateState={fieldsUpdateState}
          formData={formData}
          validateForm={validateForm}
        />
      )}
    </>
  );
};
