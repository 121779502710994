import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../../../../../Component/Desktop/Term/ProposalForm/Navbar/Navbar";
import { ETermCompanyCode } from "../../../../../State/Enum/ETermCompanyCode";
import { useAppSelector } from "../../../../../State/hooks";
import { FORMAT_NUMBER } from "../../../../../SupportingFiles/HelpingFunction";
import "./../../../../../Component/Desktop/Common/Buttons/Buttons.scss";
import "./PaymentConfirmation.scss";

function PaymentConfirmation({
  payment_status,
  continue_function,
}: {
  payment_status: "SUCCESS" | "FAIL" | "PENDING";
  continue_function: Function;
}) {
  const { selectedQuote, proposerDetails } = useAppSelector(
    (state) => state.Term
  );
  return (
    <Box className="paymentWrapper">
      {/* Navbar */}
      <Navbar />

      <Grid container spacing={3}>
        <Grid xs={12} className="my-4" textAlign="center">
          <img src="./images/policy-confirmation.svg" alt="" height="140px" />
        </Grid>
        <Grid xs={12} textAlign="center">
          <h3>Payment Confirmation</h3>
          <p className="mb-0">
            {payment_status === "SUCCESS"
              ? "Great !! Congratulations on buying your investment."
              : "Payment received! We apologize for any inconvenience caused by the issue you encountered."}
          </p>
        </Grid>
        <Grid xs={12} textAlign="center">
          <p className="mb-0 paymentDetail_p">
            Hi, {`${proposerDetails.full_name.value}`}
          </p>
          <p className="paymentDetail_p">
            {`${proposerDetails.gender.value}` === "M" ? "Male" : "Female"},{" "}
            {selectedQuote.age} Years
          </p>
        </Grid>
        <Grid xs={12}>
          <Box>
            <Grid container className="paymentPolicy_box">
              <Grid xs={6}>
                <p style={{ textAlign: "left" }}>
                  {selectedQuote.product_name}
                </p>
              </Grid>
              <Grid xs={6}>
                <p style={{ textAlign: "right" }}>
                  Transaction ID :-{" "}
                  {selectedQuote.transaction_id.length > 0
                    ? selectedQuote.transaction_id
                    : selectedQuote.appno}
                </p>
              </Grid>
              <Grid xs={2.4} textAlign="left">
                <img
                  src={selectedQuote.logo}
                  alt=""
                  style={{ width: "90px" }}
                />
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Sum Assured
                  <span>{FORMAT_NUMBER(selectedQuote.sum_insured)}</span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Policy Term
                  <span>{selectedQuote.term} Years</span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Pay Term
                  <span>{selectedQuote.pay_term} Years</span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p style={{ textAlign: "right" }}>
                  Application No.
                  <span>{selectedQuote.appno}</span>
                </p>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} className="mt-6">
          <ul className="steps">
            <li className="activeLi">
              <span className="activebg">1</span>Basic Details
            </li>
            <li className="activeLi">
              <span className="activebg">2</span>Payment Done
            </li>
            <li>
              <span>3</span>Nominee Details
            </li>
            <li>
              <span>4</span>Address Details
            </li>
            <li>
              <span>5</span>CKYC Details
            </li>
            <li>
              <span>6</span>Underwriting Details
            </li>
            <li>
              <span>7</span>Preview Details
            </li>
            {selectedQuote.company_code === ETermCompanyCode.HDFC ? (
              <li>
                <span>8</span>Upload Document
              </li>
            ) : null}
          </ul>
        </Grid>
        <Grid xs={12} textAlign="center">
          <div className="ctaBtn">
            {payment_status === "SUCCESS" ? (
              <Button
                className="regularSecondaryBtn"
                onClick={() => continue_function()}
              >
                Continue Application
              </Button>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentConfirmation;
