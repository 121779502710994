import { createSlice } from "@reduxjs/toolkit";
import { THealthSlice } from "../../../Services/Type/Health/THealthSlice";
import { HEALTH_REDUCERS } from "./HealthReducer";

const initialState: THealthSlice = {
  QUOTE_LOADER: false,
  DROPDOWN_DATA: {
    TERM: [
      { key: "1", value: "1 Year" },
      { key: "2", value: "2 Years" },
      { key: "3", value: "3 Years" },
    ],
    SORT_BY: [
      { key: "Low-High", value: "Low-High" },
      { key: "High-Low", value: "High-Low" },
    ],
    AGE: [],
    SUM_INSURED: [
      { value: "1 Lakh", key: "100000" },
      { value: "2 Lakh", key: "200000" },
      { value: "4 Lakh", key: "400000" },
      { value: "5 Lakh", key: "500000" },
      { value: "6 Lakh", key: "600000" },
      { value: "7 Lakh", key: "700000" },
      { value: "8 Lakh", key: "800000" },
      { value: "9 Lakh", key: "900000" },
      { value: "10 Lakh", key: "1000000" },
      { value: "12 Lakh", key: "1200000" },
      { value: "15 Lakh", key: "1500000" },
      { value: "16 Lakh", key: "1600000" },
      { value: "17 Lakh", key: "1700000" },
      { value: "18 Lakh", key: "1800000" },
      { value: "19 Lakh", key: "1900000" },
      { value: "20 Lakh", key: "2000000" },
      { value: "25 Lakh", key: "2500000" },
      { value: "30 Lakh", key: "3000000" },
      { value: "40 Lakh", key: "4000000" },
      { value: "50 Lakh", key: "5000000" },
      { value: "60 Lakh", key: "6000000" },
      { value: "75 Lakh", key: "7500000" },
      { value: "1 Crore", key: "10000000" },
      { value: "1.5 Crore", key: "15000000" },
    ],
    OCCUPATION: [],
    MARTIAL: [],
    RELATIONSHIP: [],
  },
  ADD_FORM: {
    age: { value: "", warning: false },
    children: "",
    gender: { value: "", warning: false },
    pincode: { value: "", warning: false },
    whom_to_insure: "One Adult",
    mobile: { value: "", warning: false },
    name: { value: "", warning: false },
  },
  ADD_FORM_RESPONSE: {
    sum_insured: 0,
    quote_no: "",
    module: "",
    insurance_type: "",
    term: 0,
    cover: "",
    city: "",
    state: "",
    child: 0,
    adult: 0,
    sort_by: "Low-High",
  },
  ALL_QUOTES_DATA: [
    {
      CashlessHospitalLists: [],
      sumInsured: "",
      productDetails: {
        brochure: "",
        buy_online_code: "",
        product_type_code: "",
        company_product_map: { company_code: "" },
        discount_calc_action: "",
        health_form_validation: "",
        id: 0,
        is_online: "",
        pre_existing_cover: "",
        prem_calc_action: "",
        product_code: "",
        product_desc: "",
        product_name: "",
        proposal_method: "",
        rate_calc_method: "",
        rating: "",
        room_cover: "",
      },
      premiumDetails: {
        basePremium: 0,
        finalPremium: 0,
        quoteId: "",
        serviceTax: 0,
      },
      SpecialFeatureLists: [
        { code: "", description: "", show_desc: 0, title: "" },
      ],
      CompanyDetails: {
        claim_ratio: "",
        company_code: "",
        logo: "https://devstatic.masuraksha.com/images/Go-Digit-General-Insurance.svg",
        name: "",
        short_desc: "",
      },
      loading: true,
    },
    {
      CashlessHospitalLists: [],
      sumInsured: "",
      productDetails: {
        product_type_code: "",
        brochure: "",
        buy_online_code: "",
        company_product_map: { company_code: "" },
        discount_calc_action: "",
        health_form_validation: "",
        id: 0,
        is_online: "",
        pre_existing_cover: "",
        prem_calc_action: "",
        product_code: "",
        product_desc: "",
        product_name: "",
        proposal_method: "",
        rate_calc_method: "",
        rating: "",
        room_cover: "",
      },
      premiumDetails: {
        basePremium: 0,
        finalPremium: 0,
        quoteId: "",
        serviceTax: 0,
      },
      SpecialFeatureLists: [
        { code: "", description: "", show_desc: 0, title: "" },
      ],
      CompanyDetails: {
        claim_ratio: "",
        company_code: "",
        logo: "https://devstatic.masuraksha.com/images/Star-Health-and-Allied-Insurance-Company-Limited.svg",
        name: "",
        short_desc: "",
      },
      loading: true,
    },
    {
      CashlessHospitalLists: [],
      sumInsured: "",
      productDetails: {
        product_type_code: "",
        brochure: "",
        buy_online_code: "",
        company_product_map: { company_code: "" },
        discount_calc_action: "",
        health_form_validation: "",
        id: 0,
        is_online: "",
        pre_existing_cover: "",
        prem_calc_action: "",
        product_code: "",
        product_desc: "",
        product_name: "",
        proposal_method: "",
        rate_calc_method: "",
        rating: "",
        room_cover: "",
      },
      premiumDetails: {
        basePremium: 0,
        finalPremium: 0,
        quoteId: "",
        serviceTax: 0,
      },
      SpecialFeatureLists: [
        { code: "", description: "", show_desc: 0, title: "" },
      ],
      CompanyDetails: {
        claim_ratio: "",
        company_code: "",
        logo: "https://devstatic.masuraksha.com/images/IFFCO-Tokio-General-Insurance-Co-Ltd.svg",
        name: "",
        short_desc: "",
      },
      loading: true,
    },
  ],
  POLICY_TENURE: 1,
  SELECTED_QUOTE_DATA: {
    CashlessHospitalLists: [],
    sumInsured: "",
    productDetails: {
      brochure: "",
      buy_online_code: "",

      company_product_map: { company_code: "" },
      discount_calc_action: "",
      health_form_validation: "",
      id: 0,
      is_online: "",
      pre_existing_cover: "",
      prem_calc_action: "",
      product_code: "",
      product_type_code: "",
      product_desc: "",
      product_name: "",
      proposal_method: "",
      rate_calc_method: "",
      rating: "",
      room_cover: "",
    },
    premiumDetails: {
      basePremium: 0,
      finalPremium: 0,
      quoteId: "",
      serviceTax: 0,
    },
    SpecialFeatureLists: [
      { code: "", description: "", show_desc: 0, title: "" },
    ],
    CompanyDetails: {
      claim_ratio: "",
      company_code: "",
      logo: "",
      name: "",
      short_desc: "",
    },
    loading: true,
  },
  BUYACTION_RESPONSE_DATA: {
    quote_no: "",
    product_code: "",
    product_id: "",
    company_code: "",
    sum_insured: "",
    premium: "",
    insurance_type: "",
    quoteId: "",
    FinalPremiumm: "",
    ProductDetails: {
      id: 0,
      product_name: "",
      brochure: "",
      product_code: "",
      rate_calc_method: "",
      proposal_method: "",
      tax_calc_method: "",
      discount_calc_method: "",
      rating: 2,
      product_desc: "",
      buy_online_code: "",
      prem_calc_action: "",
      tax_calc_action: "",
      discount_calc_action: "0",
      health_form_validation: {
        self_min_age: 0,
        self_max_age: 0,
        spouse_min_age: 0,
        spouse_max_age: 0,
        child_min_age: 0,
        child_max_age: 0,
      },
    },
    CompanyDetails: {
      company_code: "",
      name: "",
      short_desc: "",
      logo: "",
      claim_ratio: "",
    },
    amountWithGst: "",
    term: "",
    ppterm: "",
    income: "",
    incomeOne: "",
    policyTenure: "",
  },
  INSURED_MEMBER_DETAILS: {
    NOMINEE__DETAILS: {
      full_name: { value: "", warning: false },
      dob: { value: "", warning: false },
      relationship: { value: "", warning: false },
    },
    ADULT_ONE_DETAILS: {
      name: { value: "", warning: false },
      occupation: { value: "", warning: false },
      dob: { value: "", warning: false },
      mobile: { value: "", warning: false },
      emergencyPhone: { value: "", warning: false },
      email: { value: "", warning: false },
      aadhar: { value: "", warning: false },
      gender: { value: "", warning: false },
      maritalStatus: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    ADULT_TWO_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "Spouse", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_ONE_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_TWO_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_THREE_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_FOUR_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "Spouse", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
  },
  NOMINEE_RELATIONSHIP_STATUS: "OTHER",
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    city: "",
    state: "",
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    isMailingAddressSame: true,
    mailingPinCode: { value: "", warning: false },
    mailingCity: "",
    mailingState: "",
    mailingAddressLineOne: { value: "", warning: false },
    mailingAddressLineTwo: { value: "", warning: false },
  },
  MEDICAL_QUESTION_DATA: {
    HQ01: {
      main_question: {
        id: "HQ01",
        desc: "Does any person(s) to be insured has any Pre-Existing diseases?",
        status: false,
        icon_class: "preexisting",
        title: "Pre-Exisiting Diseases",
      },
      sub_question: {
        SQ01: {
          id: "SQ01",
          desc: "blood pressure",
          status: false,
          icon_class: "",
          title: "High or low blood pressure",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
          },
        },
        SQ02: {
          id: "SQ02",
          desc: "blood pressure",
          status: false,
          icon_class: "diabates",
          title: "Diabetes",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
          },
        },
        SQ03: {
          id: "SQ03",
          desc: "endocrine disorder",
          status: false,
          icon_class: "",
          title: "Thyroid disorder or any other endocrine disorder ?",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
          },
        },
        SQ04: {
          id: "SQ04",
          desc: "insulin",
          status: false,
          icon_class: "MO25",
          title: "Do you depend on insulin ?",
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: "",
              image_class: "self",
              memberId: "MO7",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            adult_two: {
              keyName: "adult_two",
              name: "",
              image_class: "spouse",
              memberId: "MO8",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_four: {
              keyName: "child_four",
              name: "",
              image_class: "son",
              memberId: "MO9",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_one: {
              keyName: "child_one",
              name: "",
              image_class: "son",
              memberId: "MO10",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_three: {
              keyName: "child_three",
              name: "",
              image_class: "son",
              memberId: "MO11",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
            child_two: {
              keyName: "child_two",
              name: "",
              image_class: "son",
              memberId: "MO12",
              selection_status: false,
              field_data: [
                {
                  field_type: "TEXTFIELD",
                  dropdown_data: [{ key: "", value: "" }],
                },
              ],
            },
          },
        },
      },
    },
    HQ02: {
      main_question: {
        id: "HQ02",
        desc: "Do any of the members smoke?",
        status: false,
        title: "Somking",
        icon_class: "smoking",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO7",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO8",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO9",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO10",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO11",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO12",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
        },
      },
    },
    HQ03: {
      main_question: {
        id: "HQ03",
        desc: "Do any of the members chew Pan Masala? (per week)",
        status: false,
        title: "Tobacco",
        icon_class: "tobaco",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO7",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO8",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO9",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO10",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO11",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO12",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
        },
      },
    },
    HQ04: {
      main_question: {
        id: "HQ04",
        desc: "Do you drink Alcohol (per week)?",
        status: false,
        icon_class: "alcohol",
        title: "Alcohol",
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "self",
            memberId: "MO7",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "spouse",
            memberId: "MO8",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "son",
            memberId: "MO9",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "son",
            memberId: "MO10",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "son",
            memberId: "MO11",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "son",
            memberId: "MO12",
            selection_status: false,
            field_data: [
              {
                field_type: "TEXTFIELD",
                dropdown_data: [{ key: "", value: "" }],
              },
            ],
          },
        },
      },
    },
  },
  PAGE_STATUS: false,
};

const ageData = [];
for (let i = 18; i <= 110; i++) {
  ageData.push({
    value: `${i} Years`,
    key: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.AGE = ageData;

export const HealthSlice = createSlice({
  name: "Health",
  initialState,
  reducers: HEALTH_REDUCERS,
});

export default HealthSlice.reducer;
