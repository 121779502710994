import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import "./MNavbar.scss";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";

const MNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { update_status } = useAppSelector((state) => state.Term.proposerPage);
  const back = () => {
    navigate(-1);
    if (update_status) {
      dispatch(TermSlice.actions.updateUpdateStatus(false));
    }
  };
  return (
    <Box className="proposalNavbarMobile">
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Link
            className="backStep"
            onClick={() => {
              back();
            }}
          >
            <ArrowBackIcon />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MNavbar;
