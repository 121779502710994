// import { URL_CONSTANTS } from "../../Shared/URLS";
import { URL_CONSTANTS } from "../../Shared/URLS";
import { PostAPI } from "../AxiosAPIInstance/API";

const SEND_OTP = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    name: string;
    mobile: string;
  }
) => {
  PostAPI.call(
    URL_CONSTANTS.ICICI_CONSENT_OTP_SEND,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const RESEND_OTP = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    name: string;
    mobile: string;
  }
) => {
  PostAPI.call(
    URL_CONSTANTS.ICICI_CONSENT_OTP_RESEND,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const VERIFY_OTP = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    receivedOTP: string;
    mobile: string;
  }
) => {
  PostAPI.call(
    URL_CONSTANTS.ICICI_CONSENT_OTP_VERIFY,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

export const ICICI_PROPOSAL_SERVICES = {
  SEND_OTP,
  RESEND_OTP,
  VERIFY_OTP,
};
