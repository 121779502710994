import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./State/Store";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer theme="colored" />
    <App />
  </Provider>,
  document.getElementById("root") as HTMLElement
);

reportWebVitals();
