import * as React from "react";
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import { FormControl, InputLabel, NativeSelect, Tooltip } from "@mui/material";
// import NativeSelect from '@mui/material/NativeSelect';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {
  title: string;
  value: any;
  attrName: any;
  value_update: Function;
  data: Array<any>;
  warn_status?: boolean;
  class_name?: string;
  error_message?: string;
  info_status?: boolean;
  info_message?: string;
  disabled?: boolean;
}

const defaultProps: Props = {
  title: "",
  value: "",
  attrName: "",
  warn_status: false,
  value_update: () => {},
  data: [],
  error_message: "select",
  info_status: false,
  info_message: "This is dummy text for select dropdown.",
  disabled: false,
};

const NativeSelectDropdown: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  data,
  warn_status,
  class_name,
  error_message,
  info_status,
  info_message,
  disabled,
}) => {
  return (
    <div className={class_name}>
      <FormControl fullWidth>
        <InputLabel>{title}</InputLabel>
        <NativeSelect
          value={value}
          onChange={(e) => value_update(attrName, e.target.value)}
          disabled={disabled}
        >
          <option value=""></option>
          {/* <option value={10}>Ten</option>
          <option value={20}>Twenty</option>
          <option value={30}>Thirty</option> */}
          {data.map((data) => (
            <option value={data.key}>{data.value}</option>
          ))}
        </NativeSelect>
      </FormControl>
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status == true ? (
        <span className="error">{error_message}</span>
      ) : null}
    </div>
  );
};
export default NativeSelectDropdown;
