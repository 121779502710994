import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import "../../../../../CommonSCSS/ModalPopup.scss";
import { TCarAddForm } from "../../../../../Services/Type/TCarSlice";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import DatePicker from "../../../../InputFields/DatePicker/DatePicker";
import SearchDropdown from "../../../../InputFields/SearchDropdown/SearchDropdown";
import { calculateAgeInDays } from "../../../../../SupportingFiles/HelpingFunction";

const CarModifyDetails = ({
  open,
  setOpen,
  formData,
  fieldsUpdateState,
  validateForm,
}: {
  open: boolean;
  setOpen: Function;
  formData: TCarAddForm;
  fieldsUpdateState: Function;
  validateForm: Function;
}) => {
  const { DROPDOWN_DATA, ADD_FORM } = useAppSelector((state) => state.Car);

  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<boolean>(false);

  const [previousNcb, setPreviousNcb] = React.useState<string | null>("no");
  const [lastClaimed, setLastClaimed] = React.useState<string | null>("0");

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Grid
            className="title mx-0"
            container
            columnSpacing={3}
            alignItems="center"
          >
            <Button className="closePopup" onClick={() => setOpen(false)} />
            <Grid xs={12}>
              {ADD_FORM.business_type === "New" ? (
                <h3>New</h3>
              ) : (
                <h3>{`${ADD_FORM.reg_no.value}`}</h3>
              )}
            </Grid>
          </Grid>
          <Grid container rowSpacing={0} columnSpacing={3} className="mx-3">
            <Grid xs={12}>
              <>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs>
                    <SearchDropdown
                      class_name="inputField"
                      title="Make and Model"
                      value={`${formData.make_model.value.label}`}
                      attrName={"make_model"}
                      value_update={fieldsUpdateState}
                      data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                      warn_status={formData.make_model.warning}
                      error_message={"Select Make and Model"}
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs={3}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Fuel Type"
                      value={`${formData.fuel_type.value.label}`}
                      attrName={"fuel_type"}
                      value_update={fieldsUpdateState}
                      data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                      warn_status={formData.fuel_type.warning}
                      error_message="Select Fuel Type"
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs>
                    <SearchDropdown
                      class_name="inputField"
                      title="Variant"
                      value={`${formData.variant_cc.value.label}`}
                      attrName={"variant_cc"}
                      value_update={fieldsUpdateState}
                      data={DROPDOWN_DATA.VARIANT_LIST}
                      warn_status={formData.variant_cc.warning}
                      error_message="Select Variant"
                    />
                  </Grid>
                </Grid>
                {/* <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs={3}>
                    <DatePicker
                      class_name="inputField"
                      title={"Reg. Date"}
                      value={formData.reg_date.value}
                      attrName={"reg_date"}
                      value_update={fieldsUpdateState}
                      error_message="Enter Reg. Date"
                      warn_status={formData.reg_date.warning}
                      min_date={18}
                      max_date={0}
                      date_validation_type="YEARS"
                      dateValidation={true}
                    />
                  </Grid>
                  <Grid xs={3}>
                    <DatePicker
                      class_name="inputField"
                      title={"Policy Expiry"}
                      value={formData.policy_expiry_date.value}
                      attrName={"policy_expiry_date"}
                      value_update={fieldsUpdateState}
                      error_message="Enter PolicyExpiry Date"
                      warn_status={formData.policy_expiry_date.warning}
                      min_date={
                        calculateAgeInDays(
                          `${formData.policy_expiry_date.value}`
                        ) - 90
                      }
                      max_date={-60}
                      dateValidation={true}
                      date_validation_type="DAYS"
                    />
                  </Grid>
                  <Grid xs>
                    <SearchDropdown
                      class_name="inputField"
                      title="Previous Insurer"
                      value={`${formData.previous_insurer.value}`}
                      attrName={"previous_insurer"}
                      value_update={fieldsUpdateState}
                      data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                      warn_status={formData.previous_insurer.warning}
                      error_message={"Select Previous Insurer"}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={0}
                  alignItems="center"
                  marginBottom={"24px"}
                >
                  <Grid xs={6} textAlign="right">
                    <h4 className="claim_txt">Claimed in the last year?</h4>
                  </Grid>
                  <Grid xs={6}>
                    <ToggleButtonGroup
                      className="radioBox"
                      value={formData.claimed}
                      exclusive
                      onChange={(e, new_value) =>
                        fieldsUpdateState("claimed", new_value)
                      }
                    >
                      <ToggleButton value="No">No</ToggleButton>
                      <ToggleButton value="yes">Yes</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                {formData.claimed === "No" ? (
                  <>
                    <Grid
                      container
                      columnSpacing={2}
                      rowSpacing={0}
                      marginBottom={"24px"}
                    >
                      <Grid xs={12} textAlign="center">
                        <h4 className="claim_txt">
                          Previous No Claim Bonus (NCB)
                        </h4>
                        <p className="lighttxt_p">
                          (Check it in your last policy.)
                        </p>
                        <ToggleButtonGroup
                          className="radioBox mt-4"
                          value={formData.prev_ncb}
                          exclusive
                          onChange={(e, new_value) =>
                            fieldsUpdateState("prev_ncb", new_value)
                          }
                        >
                          <ToggleButton value="0">0%</ToggleButton>
                          <ToggleButton value="20">20%</ToggleButton>
                          <ToggleButton value="25">25%</ToggleButton>
                          <ToggleButton value="35">35%</ToggleButton>
                          <ToggleButton value="45">45%</ToggleButton>
                          <ToggleButton value="50">50%</ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    </Grid>
                  </>
                ) : null} */}

                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton
                      onClickFunction={validateForm}
                      text_name={"Update Details"}
                      class_name="primaryLgBtn"
                    />
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default CarModifyDetails;
