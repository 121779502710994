import BuildIcon from "@mui/icons-material/Build";
import { Box, Button, Link, Menu } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import SearchBox from "../../../../../Component/InputFields/SearchBar/SearchBox";
import { CAR_SERVICES } from "../../../../../Services/CAR/CarServices";
import { TAddonsValueType } from "../../../../../Services/Type/Car/TAddonsValueType";
import { TCARBUYACTION } from "../../../../../Services/Type/Car/TCARBUYACTION";
import { TCarProduct } from "../../../../../Services/Type/Car/TCarProduct";
import { CarSlice } from "../../../../../State/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  SHOULD_DISPLAY_ADDON,
  calculate_premium_for_car,
} from "../../../../../SupportingFiles/CarPermiumCal";
import { CURRENCY_FORMAT } from "../../../../../SupportingFiles/HelpingFunction";
import CarPolicyDetails from "../CarPolicyDetail/CarPolicyDetail";
import "./CarQuoteCard.scss";
import CarAddonGoDigit from "./CarAddonGoDigit";
import { CLIENTS } from "../../../../../Shared/Clients";
import { CAR_PATH } from "../../../../../RoutesPath/CarPath";
import { CKYC_ROUTES } from "../../../../../RoutesPath/CKYCRoutes";
import { EMotorCompCode } from "../../../../../State/Enum/EMotorCompCode";

const CarQuoteCard = ({
  quote,
  addon_values,
}: {
  quote: TCarProduct;
  addon_values: TAddonsValueType;
}) => {
  const dispatch = useAppDispatch();
  const { ADDON_STATUS, PLAN_TYPE, ADD_FORM_RESPONSE, ADDON_USER_VALUE } =
    useAppSelector((state) => state.Car);
  const [premium, setPremium] = useState<number>(0);
  const [planDetailsStatus, setPlanDetailsStatus] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const premium_details = quote.quotationDetail;

    if (premium_details) {
      const premium_with_tax = calculate_premium_for_car(
        premium_details,
        ADDON_STATUS,
        addon_values.passengerCover.value,
        PLAN_TYPE
      );
      setPremium(premium_with_tax);
    }
  }, [ADDON_STATUS, quote, addon_values]);
  const Buyaction = () => {
    const onSuccess = () => {
      if (
        quote.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.BAJAJ ||
        quote.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.ACKO ||
        quote.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.TATA
      ) {
        window.location.href = `https://${CLIENTS.MOTILAL}${CAR_PATH.PROPOSAL_PAGE}/${CAR_PATH.OWNER_DETAILS}?type=CAR&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote.quotationDetail?.productDetails.id}`;
      } else {
        window.location.href = `https://${CLIENTS.MOTILAL}${CAR_PATH.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=CAR&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${quote.quotationDetail?.productDetails.id}`;
      }
    };

    const onError = () => {};

    const Params: TCARBUYACTION = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "CAR",
      product_code: `${quote.quotationDetail?.productDetails.product_code}`,
      productId: `${quote.quotationDetail?.productDetails.id}`,
      company_code: `${quote.quotationDetail?.CompanyDetails.company_code}`,
      IDV: quote.quotationDetail?.premiumDetails.IDV,
      finalPremium: quote.quotationDetail?.premiumDetails.netPremium,
    };
    CAR_SERVICES.BUY_ACTION(onSuccess, onError, Params);
    dispatch(CarSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
  };

  return (
    <>
      {planDetailsStatus ? (
        <CarPolicyDetails
          open={planDetailsStatus}
          setOpen={setPlanDetailsStatus}
          addon_values={addon_values}
          quote={quote}
        />
      ) : null}
      {quote.loader ? (
        <Box className="quotation skeleton">
          <Box className="quotation_tags">
            <Grid container alignItems="center" spacing={0} padding={0}>
              <Grid xs={6}>
                <img className="insurerLogo" src={quote.logo} />
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="cg_p mt-2">
                    <span>
                      <BuildIcon />
                    </span>
                    <div className="animatebar" />
                  </p>
                )}
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="idv_p">
                    Vehicle IDV
                    <span>
                      ₹ <div className="animatebar" />
                    </span>
                  </p>
                )}
                <Button
                  onClick={() => {
                    Buyaction();
                  }}
                  className="buyBtn"
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span className="rs-icon">₹</span>
                    <img
                      src="../images/loader.svg"
                      alt=""
                      style={{
                        height: "18px",
                        padding: "5px 0px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                  </span>{" "}
                  Buy Policy
                </Button>
                <Link className="viewDetails">Plan Details</Link>
              </Grid>
            </Grid>
          </Box>
          <Box className="addonsBox">
            <ul>
              <li>
                <div className="animatebar" />
              </li>
              <li>
                <div className="animatebar" />
              </li>
            </ul>
          </Box>
        </Box>
      ) : (
        <Box className="quotation">
          <Box className="quotation_tags">
            {PLAN_TYPE === "Comprehensive" ? null : (
              <span className="product_tag">{PLAN_TYPE}</span>
            )}
            <Grid container alignItems="center" spacing={0} padding={0}>
              <Grid xs={6}>
                <img
                  className="insurerLogo"
                  src={quote.quotationDetail?.CompanyDetails?.logo}
                />
                {PLAN_TYPE === "Third Party" ? null : (
                  <>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className="ch-list"
                    ></Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="dchlist_popup"
                    >
                      <SearchBox />
                    </Menu>
                  </>
                )}
                <Link
                  className="viewDetails"
                  onClick={() => {
                    dispatch(CarSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
                    setPlanDetailsStatus(true);
                  }}
                >
                  Plan Details
                </Link>
              </Grid>
              <Grid xs={6} textAlign={"right"}>
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="idv_p">
                    Vehicle IDV
                    <span>
                      {CURRENCY_FORMAT(
                        `${quote.quotationDetail?.premiumDetails.IDV}`
                      )}
                    </span>
                  </p>
                )}

                <Button
                  className="buyBtn"
                  onClick={() => {
                    Buyaction();
                  }}
                >
                  <span>
                    <span className="rs-icon"> </span>{" "}
                    {CURRENCY_FORMAT(premium)}
                  </span>{" "}
                  Buy Policy
                </Button>
              </Grid>
            </Grid>
          </Box>
          {quote?.quotationDetail?.premiumDetails &&
          (ADDON_STATUS.pa_owner_cover.value ||
            ADDON_STATUS.depreciation_cover.value ||
            ADDON_STATUS.rsa.value ||
            ADDON_STATUS.driver_cover.value ||
            ADDON_STATUS.consumable_cover.value ||
            ADDON_STATUS.engine_cover.value ||
            ADDON_STATUS.invoice_cover.value ||
            ADDON_STATUS.key_replacement.value ||
            ADDON_STATUS.ncb_cover.value ||
            ADDON_STATUS.tyre_cover.value ||
            ADDON_STATUS.personalBelonging.value ||
            quote.quotationDetail?.premiumDetails.includedPAOwnerDriver == 1 ||
            quote.quotationDetail?.premiumDetails.freeRoadSideAssistance ===
              1 ||
            quote.quotationDetail?.premiumDetails.includedPersonalBelonging ===
              1) ? (
            quote.quotationDetail?.CompanyDetails.company_code === "G033" ? (
              <CarAddonGoDigit quote={quote} />
            ) : (
              <Box
                className="addonsBox"
                display={"flex"}
                justifyContent={"space-between"}
              >
                <ul>
                  {quote.product_type === "SAOD" ? null : (
                    <>
                      {ADDON_STATUS.pa_owner_cover.value ||
                      quote.quotationDetail?.premiumDetails
                        ?.includedPAOwnerDriver === 1 ? (
                        <li>
                          <p
                            className={SHOULD_DISPLAY_ADDON(
                              quote?.quotationDetail?.premiumDetails
                                .paOwnerDriver,
                              quote?.quotationDetail?.premiumDetails
                                .includedPAOwnerDriver,
                              0
                            )}
                          >
                            {ADDON_STATUS.pa_owner_cover.label}
                          </p>
                        </li>
                      ) : null}
                    </>
                  )}
                  {PLAN_TYPE === "Third Party" ? null : (
                    <>
                      {ADDON_STATUS.personalBelonging.value ||
                      quote.quotationDetail?.premiumDetails
                        .includedPersonalBelonging === 1 ? (
                        <li>
                          <p
                            className={SHOULD_DISPLAY_ADDON(
                              quote?.quotationDetail?.premiumDetails
                                .personalBelonging,
                              quote?.quotationDetail?.premiumDetails
                                .includedPersonalBelonging,
                              0
                            )}
                          >
                            LOB
                          </p>
                        </li>
                      ) : null}
                    </>
                  )}

                  {ADDON_STATUS.depreciation_cover.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails
                            .depreciationCover,
                          0,
                          0
                        )}
                      >
                        {ADDON_STATUS.depreciation_cover.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.rsa.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails
                            .roadSideAssistance,
                          0,
                          quote?.quotationDetail?.premiumDetails
                            .freeRoadSideAssistance
                        )}
                      >
                        {ADDON_STATUS.rsa.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.driver_cover.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails.driverCover,
                          0,
                          0
                        )}
                      >
                        {ADDON_STATUS.driver_cover.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.consumable_cover.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails
                            .consumablesCover,
                          0,
                          0
                        )}
                      >
                        {ADDON_STATUS.consumable_cover.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.engine_cover.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails
                            .engineGearBoxProtection,
                          0,
                          0
                        )}
                      >
                        {ADDON_STATUS.engine_cover.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.invoice_cover.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails
                            .invoicePriceCover,
                          0,
                          0
                        )}
                      >
                        {ADDON_STATUS.invoice_cover.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.key_replacement.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails
                            .keyReplacementCover,
                          0,
                          0
                        )}
                      >
                        {ADDON_STATUS.key_replacement.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.ncb_cover.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails
                            .noClaimBonusSameSlab,
                          0,
                          0
                        )}
                      >
                        {ADDON_STATUS.ncb_cover.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.tyre_cover.value ? (
                    <li>
                      <p
                        className={SHOULD_DISPLAY_ADDON(
                          quote?.quotationDetail?.premiumDetails
                            .tyreSecureCover,
                          0,
                          0
                        )}
                      >
                        {ADDON_STATUS.tyre_cover.label}
                      </p>
                    </li>
                  ) : null}
                  {ADDON_STATUS.passenger_cover.value &&
                  parseInt(ADDON_USER_VALUE.passengerCover.value) > 0 ? (
                    <li>
                      <p className={SHOULD_DISPLAY_ADDON(1000, 0, 0)}>
                        {ADDON_STATUS.passenger_cover.label}
                      </p>
                    </li>
                  ) : null}
                </ul>
              </Box>
            )
          ) : null}
        </Box>
      )}
    </>
  );
};

export default CarQuoteCard;
