import EditIcon from "@mui/icons-material/Edit";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./PreviewDetailBox.scss";

const PreviewDetailBox = ({
  title,
  data,
  editFunction,
  hideEditButton = false,
}: {
  editFunction: Function;
  title: string;
  hideEditButton?: boolean;
  data: Array<{
    label: string;
    label_value: string | Date | null | undefined;
  }>;
}) => {
  return (
    <Box className="previewBox">
      <Grid container spacing={0}>
        <Grid xs={8}>
          <p className="previewBoxTitle">{title}</p>
        </Grid>
        <Grid xs={4} textAlign="right">
          {hideEditButton === false ? (
            <Link
              className="previewBoxEdit"
              onClick={() => {
                editFunction();
              }}>
              <EditIcon /> Edit
            </Link>
          ) : null}
        </Grid>
        <Grid xs={12}>
          <ul>
            {data.map((data) => (
              <li>
                <p>
                  {data.label}: <span>{`${data.label_value}`}</span>
                </p>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreviewDetailBox;
