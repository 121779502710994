import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CarQuoteContainer } from "../../Container/Car/QuotePage/CarQuoteContainer";
import { CAR_PATH } from "../../RoutesPath/CarPath";

const CarRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={CAR_PATH.QUOTE_PAGE} element={<CarQuoteContainer />} />
      </Routes>
    </BrowserRouter>
  );
};

export default CarRoutes;
