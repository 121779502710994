import { useEffect, useState } from "react";
import CarQuotePage from "../../../Page/Desktop/Car/QuotePage/CarQuotePage";
import MCarQuotePage from "../../../Page/Mobile/Car/MQuotePage/MCarQuotePage";
import { CAR_SERVICES } from "../../../Services/CAR/CarServices";
import { TAddonsValueType } from "../../../Services/Type/Car/TAddonsValueType";
import { TCarProduct } from "../../../Services/Type/Car/TCarProduct";
import { TCarSingleQuoteDetail } from "../../../Services/Type/Car/TCarSingleQuoteDetail";
import { TProductType } from "../../../Services/Type/Common/TProductType";
import {
  TCarAddForm,
  TClaimStatus,
  TNCB,
} from "../../../Services/Type/TCarSlice";
import { CarSlice } from "../../../State/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../State/hooks";
import { calculate_premium_for_car } from "../../../SupportingFiles/CarPermiumCal";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateDateDifferenceInDays,
  isEmpty,
  validateInput,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { toast } from "react-toastify";

export const CarQuoteContainer = () => {
  const isMobile = useIsMobile();
  const { Car } = useAppSelector((state) => state);
  const {
    QUOTE_LOADER,
    ADDON_STATUS,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    PLAN_TYPE,
    SORT_BY,
    IDV_VALUE,
    IDVApply_Status,
    ADDON_USER_VALUE,
  } = useAppSelector((state) => state.Car);
  const [fieldData, setFieldData] = useState<TAddonsValueType>({
    cngKitValue: { value: "", warning: false },
    electricalAcessories: { value: "", warning: false },
    nonElectricalAcessories: { value: "", warning: false },
    passengerCover: { value: "", warning: false },
  });
  const [quoteNo, setQuoteNo] = useState<string>("");
  const [allProducts, setAllProducts] = useState<TCarSingleQuoteDetail[]>([]);
  const [idv_value, set_idv_value] = useState<{
    value: any;
    warning: boolean;
  }>(IDV_VALUE);
  const [quote_list_data, set_quote_list_data] = useState<TCarProduct[]>([
    {
      buy_online_code: "",
      company_code: "",
      id: 0,
      loader: true,
      logo: "https://devstatic.masuraksha.com/images/Go-Digit-General-Insurance.svg",
      product_code: "",
      product_type: "Comprehensive",
    },
  ]);
  const [IDVApplyStatus, setIDVApplyStatus] =
    useState<boolean>(IDVApply_Status);
  const dispatch = useAppDispatch();
  const [min_max_value, setMin_max_value] = useState<{
    lowest: number;
    highest: number;
  }>({
    lowest: 0,
    highest: 0,
  });

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");

    if (id) {
      setQuoteNo(id);
      GET_DETAILS_BY_QUOTE_NO(id);
      dispatch(CarSlice.actions.updateQuoteLoaderStatus(true));
    }
  }, []);

  const RESET_ALL_ADDONS = () => {
    const onSuccess = () => {};
    const onError = () => {};

    CAR_SERVICES.BULK_UPDATE_ADDON_STATUS(onSuccess, onError, {
      quote_no: quoteNo,
      type: "CAR",
      driver_cover: 0,
      pa_owner_driver: 1,
      passernger_cover: 0,
      road_side_assistance: 0,
      cng_kit: 0,
      consumables_cover: 0,
      depreciation_cover: 0,
      electric_accessory: 0,
      engine_gear_box_protection: 0,
      invoice_cover: 0,
      key_replacement_cover: 0,
      no_claim_bonus_same_slab: 0,
      non_electric_accessory: 0,
      personal_belonging: 0,
      tyre_secure_cover: 0,
    });
  };

  useEffect(() => {
    setFieldData((prev) => ({
      ...prev,
      electricalAcessories: {
        value:
          parseInt(ADD_FORM.electric_accessory) === 0
            ? ""
            : ADD_FORM.electric_accessory,
        warning: false,
      },
      nonElectricalAcessories: {
        value:
          parseInt(ADD_FORM.non_electric_accessory) === 0
            ? ""
            : ADD_FORM.non_electric_accessory,
        warning: false,
      },
      cngKitValue: {
        value: parseInt(ADD_FORM.cng_kit) === 0 ? "" : ADD_FORM.cng_kit,
        warning: false,
      },
      passengerCover: { value: `${ADD_FORM.passernger_cover}`, warning: false },
    }));
  }, [
    ADD_FORM.electric_accessory,
    ADD_FORM.non_electric_accessory,
    ADD_FORM.cng_kit,
  ]);

  useEffect(() => {
    if (!isEmpty(quoteNo)) {
      const f = PLAN_TYPE;
      get_product_list(PLAN_TYPE);
    }
  }, [quoteNo, PLAN_TYPE]);

  useEffect(() => {
    if (!isEmpty(ADD_FORM.business_type) && !isEmpty(quoteNo)) {
      if (ADD_FORM.business_type === "New") {
        dispatch(
          CarSlice.actions.BULK_UPDATE({
            ...Car,
            PLAN_TYPE: "Bundle Policy",
            DROPDOWN_DATA: {
              ...Car.DROPDOWN_DATA,
              PLAN_TYPE: [{ key: "Bundle Policy", value: "Bundle Policy" }],
            },
          })
        );
      } else {
        const daysDifference = calculateDateDifferenceInDays(
          ADD_FORM.reg_date.value
        );

        if (daysDifference <= 730) {
          dispatch(
            CarSlice.actions.BULK_UPDATE({
              ...Car,
              PLAN_TYPE: "SAOD",
              DROPDOWN_DATA: {
                ...Car.DROPDOWN_DATA,
                PLAN_TYPE: [
                  { key: "SAOD", value: "SAOD" },
                  { key: "Third Party", value: "Third Party" },
                ],
              },
            })
          );
        } else if (daysDifference > 730 && daysDifference < 1095) {
          dispatch(
            CarSlice.actions.BULK_UPDATE({
              ...Car,
              PLAN_TYPE: "SAOD",
              DROPDOWN_DATA: {
                ...Car.DROPDOWN_DATA,
                PLAN_TYPE: [
                  { key: "SAOD", value: "SAOD" },
                  { key: "Third Party", value: "Third Party" },
                  { key: "Comprehensive", value: "Comprehensive" },
                ],
              },
            })
          );
        } else {
          dispatch(
            CarSlice.actions.BULK_UPDATE({
              ...Car,
              PLAN_TYPE: "Comprehensive",
              DROPDOWN_DATA: {
                ...Car.DROPDOWN_DATA,
                PLAN_TYPE: [
                  { key: "Comprehensive", value: "Comprehensive" },
                  { key: "Third Party", value: "Third Party" },
                ],
              },
            })
          );
        }
      }
    }
  }, [ADD_FORM]);

  useEffect(() => {
    if (!isEmpty(quoteNo)) {
      // dispatch(CarSlice.actions.updateQuoteLoaderStatus(true));

      filter_product_list(allProducts, PLAN_TYPE);
    }
  }, [PLAN_TYPE, ADD_FORM_RESPONSE]);

  const GET_DETAILS_BY_QUOTE_NO = (id: string) => {
    const onSuccess = (res: any) => {
      const response = res.data.response;
      const manipulated_data_add_form: TCarAddForm = {
        name: { value: "", warning: false },
        business_type: response.business_type,
        claimed: response.claim_made as TClaimStatus,
        make_model: {
          value: { code: response.make_model, label: response.make_model },
          warning: false,
        },
        fuel_type: {
          value: { code: response.fuel_type, label: response.fuel_type },
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: { value: "", warning: false },
        model: { value: "", warning: false },
        prev_ncb: response.previous_ncb as TNCB,
        previous_insurer: { value: response.previous_insurer, warning: false },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(response.registration_date),
          warning: false,
        },
        reg_no: { value: response.registration_no, warning: false },
        variant_cc: {
          value: { code: response.variant_cc, label: response.variant_cc },
          warning: false,
        },
        rto: { value: response.rto_code, warning: false },
        new_ncb: { value: response.new_ncb, warning: false },
        policy_expiry_date: {
          value: FORMAT_DATE_DD_MM_YYYY(response.policy_expiry_date),
          warning: false,
        },
        anti_theft_device: "No",
        automobile_association: "0",
        deductible: "0",
        vehicle_owned: response.vehicle_owned,
        cng_kit: response.cng_kit,
        consumables_cover: response.consumables_cover,
        depreciation_cover: response.depreciation_cover,
        driver_cover: response.driver_cover,
        electric_accessory: response.electric_accessory,
        engine_gear_box_protection: response.engine_gear_box_protection,
        invoice_cover: response.invoice_cover,
        key_replacement_cover: response.key_replacement_cover,
        no_claim_bonus_same_slab: response.no_claim_bonus_same_slab,
        non_electric_accessory: response.non_electric_accessory,
        pa_owner_driver: response.pa_owner_driver,
        passernger_cover: response.passernger_cover,
        road_side_assistance: response.road_side_assistance,
        tyre_secure_cover: response.tyre_secure_cover,
        personalBelonging: response?.personal_belonging,
        Idv: response.idv,
      };

      dispatch(
        CarSlice.actions.BULK_UPDATE({
          ...Car,
          ADDON_STATUS: {
            pa_owner_cover: {
              ...Car.ADDON_STATUS.pa_owner_cover,
              value: response.pa_owner_driver !== 0,
            },
            personalBelonging: {
              ...Car.ADDON_STATUS.personalBelonging,
              value: response.personal_belonging !== 0,
            },
            depreciation_cover: {
              ...Car.ADDON_STATUS.depreciation_cover,
              value: response.depreciation_cover !== 0,
            },
            rsa: {
              ...Car.ADDON_STATUS.rsa,
              value: response.road_side_assistance !== 0,
            },
            driver_cover: {
              ...Car.ADDON_STATUS.driver_cover,
              value: response.driver_cover !== 0,
            },
            consumable_cover: {
              ...Car.ADDON_STATUS.consumable_cover,
              value: response.consumables_cover !== 0,
            },
            engine_cover: {
              ...Car.ADDON_STATUS.engine_cover,
              value: response.engine_gear_box_protection !== 0,
            },
            invoice_cover: {
              ...Car.ADDON_STATUS.invoice_cover,
              value: response.invoice_cover !== 0,
            },
            key_replacement: {
              ...Car.ADDON_STATUS.key_replacement,
              value: response.key_replacement_cover !== 0,
            },
            ncb_cover: {
              ...Car.ADDON_STATUS.ncb_cover,
              value: response.no_claim_bonus_same_slab !== 0,
            },
            tyre_cover: {
              ...Car.ADDON_STATUS.tyre_cover,
              value: response.tyre_secure_cover !== 0,
            },
            accessories_cover: {
              ...Car.ADDON_STATUS.accessories_cover,
              value:
                response.electric_accessory || response.non_electric_accessory,
            },
            cng_kit: {
              ...Car.ADDON_STATUS.cng_kit,
              value: response.cng_kit !== 0,
            },
            passenger_cover: {
              ...Car.ADDON_STATUS.passenger_cover,
              value: response.passernger_cover !== 0,
            },
          },
          ADD_FORM: { ...manipulated_data_add_form },
          ADD_FORM_RESPONSE: {
            ...Car.ADD_FORM_RESPONSE,
            quote_no: id,
          },
          IDV_VALUE: response.idv === 0 ? "" : response.idv,
          ADDON_USER_VALUE: {
            ...Car.ADDON_USER_VALUE,
            passengerCover: {
              value: response.passernger_cover,
              warning: false,
            },
          },
        })
      );
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CAR_SERVICES.GET_DETAILS_BY_QUOTE_NO(onError, onSuccess, id);
  };

  const filter_product_list = (
    data: TCarSingleQuoteDetail[],
    plan_type?: string
  ) => {
    const planType = isEmpty(plan_type) ? PLAN_TYPE : plan_type;
    if (data.length === 0) {
      return;
    }

    const array_products: TCarProduct[] = [];

    const is_rollover_or_expired =
      ADD_FORM.business_type === "Rollover" ||
      ADD_FORM.business_type === "Expired";
    const isBundlePolicy = ADD_FORM.business_type === "New";

    data.forEach((dataValue: TCarSingleQuoteDetail) => {
      const { product_code, id, buy_online_code } = dataValue.productDetails;
      const { company_code, logo } = dataValue.CompanyDetails;

      const add = (product_type: TProductType) => {
        array_products.push({
          company_code,
          logo,
          id,
          product_code,
          buy_online_code,
          loader: true,
          product_type,
        });
      };

      if (
        is_rollover_or_expired &&
        product_code.startsWith("SA") &&
        planType === "SAOD"
      ) {
        add("SAOD");
      } else if (
        is_rollover_or_expired &&
        planType === "Third Party" &&
        product_code.startsWith("TP")
      ) {
        add("Third Party");
      } else if (
        is_rollover_or_expired &&
        planType === "Comprehensive" &&
        !product_code.startsWith("TP") &&
        !product_code.startsWith("SA")
      ) {
        add("Comprehensive");
      } else if (
        !is_rollover_or_expired &&
        isBundlePolicy &&
        !product_code.startsWith("TP") &&
        !product_code.startsWith("SA")
      ) {
        add("Bundle Policy");
      }
    });

    set_quote_list_data(array_products);
    let chunkSize = 3;
    for (let i = 0; i < array_products.length; i += chunkSize) {
      let chunk = array_products.slice(i, i + chunkSize).map((item) => item.id);
      single_quote_detail(chunk, planType);
    }
  };

  // single quote detail
  const single_quote_detail = (product_ids: number[], plan_type?: string) => {
    const planType = isEmpty(plan_type) ? PLAN_TYPE : plan_type;
    const onSuccess = (res: any) => {
      const response: TCarSingleQuoteDetail[] = res.quoteLists;

      setQuoteDataInArray(product_ids, response);
    };

    const onError = (err: any) => {
      console.log("----false", err);

      setQuoteDataInArray(product_ids);
    };

    let param = {
      product_ids: product_ids,
      quote_no: quoteNo,
    };

    switch (planType) {
      case "Comprehensive":
        CAR_SERVICES.GET_SINGLE_QUOTE_COMPREHENSIVE(onSuccess, onError, param);
        break;
      case "Third Party":
        CAR_SERVICES.GET_SINGLE_QUOTE_TP(onSuccess, onError, param);
        break;
      case "SAOD":
        CAR_SERVICES.GET_SINGLE_QUOTE_SAOD(onSuccess, onError, param);
        break;
      case "Bundle Policy":
        CAR_SERVICES.GET_SINGLE_QUOTE_COMPREHENSIVE(onSuccess, onError, param);
        break;
      default:
        break;
    }
  };

  // quotation data in an array
  const setQuoteDataInArray = (
    product_ids: number[],
    quoteResponses?: TCarSingleQuoteDetail[]
  ) => {
    set_quote_list_data((prev_quote_data: TCarProduct[]) => {
      let updatedQuoteData = [...prev_quote_data];

      product_ids.forEach((product_id, index) => {
        const quoteResponse = quoteResponses && quoteResponses[index];
        const existingProductIndex = updatedQuoteData.findIndex(
          (product) => product.id === product_id
        );

        // Check if the existing product index is valid
        if (existingProductIndex !== -1) {
          // Check if quoteResponse and netPremium property exist and netPremium is not zero
          if (
            quoteResponse?.premiumDetails?.netPremium !== undefined &&
            quoteResponse.premiumDetails.netPremium !== 0
          ) {
            // Update the existing product in updatedQuoteData array with quoteResponse details
            updatedQuoteData[existingProductIndex] = {
              ...updatedQuoteData[existingProductIndex],
              loader: false,
              quotationDetail: quoteResponse,
            };
          } else {
            // If netPremium is zero or not available, remove the existing product from updatedQuoteData array
            updatedQuoteData.splice(existingProductIndex, 1);
          }
        }
      });

      return updatedQuoteData;
    });
  };

  useEffect(() => {
    let lowest = Number.MAX_SAFE_INTEGER;
    let highest = 0;

    quote_list_data.forEach((data: TCarProduct) => {
      if (!data.loader) {
        const { premiumDetails } = data.quotationDetail || {};
        const { minimumIDV, maximumIDV } = premiumDetails || {};

        if (minimumIDV !== undefined && minimumIDV < lowest) {
          lowest = minimumIDV;
        }

        if (maximumIDV !== undefined && maximumIDV > highest) {
          highest = maximumIDV;
        }
      }
    });

    setMin_max_value({ lowest, highest });
  }, [quote_list_data]);

  /// get product list
  const get_product_list = (plan_type?: string) => {
    const onSuccess = (res: any) => {
      let responseArr = res?.data?.quoteLists;

      setAllProducts(responseArr);
      filter_product_list(responseArr, plan_type);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CAR_SERVICES.GET_PRODUCT_LIST(onError, onSuccess);
  };

  // const get_accessories = () => {
  //   const onSuccess = (res: any) => {};
  //   const onError = (err: any) => {
  //     console.log("err", err);
  //   };
  //   let Param: TCARACCESSEROIS = {
  //     quote_no: ADD_FORM_RESPONSE.quote_no,
  //     electric_accessory: fieldData.electricalAcessories.value,
  //     non_electric_accessory: fieldData.nonElectricalAcessories.value,
  //     type: "CAR",
  //   };
  //   CAR_SERVICES.UPDATE_ACCESSORIES_AMOUNT(onError, onSuccess, Param);
  // };

  const updateMasterState = (attrName: any, value: any) => {
    const numericRegex = /^[0-9]*$/;
    if (attrName[0] === "passengerCover") {
      dispatch(
        CarSlice.actions.UPDATE_ADDON_USER_VALUE([
          {
            key: "passengerCover",
            value: { value: value, warning: false },
          },
        ])
      );
      updatePassengerCoverValue(value);
    }
    if (numericRegex.test(value) || value === "") {
      setFieldData((prev) => ({
        ...prev,
        [attrName[0]]: { value: validateInput(value), warning: false },
      }));
    }
  };

  const updatePassengerCoverValue = (value: string) => {
    const onSuccess = () => {
      // get_product_list();
    };
    const onError = () => {};
    const params = {
      quote_no: quoteNo,
      type: "CAR",
      passenger_cover: value,
    };

    CAR_SERVICES.UPDATE_PASSENGER_COVER(onSuccess, onError, params);
  };

  // check idv value for valid or not
  const check_valid_idv = () => {
    if (
      idv_value.value >= min_max_value.lowest &&
      idv_value.value <= min_max_value.highest
    ) {
      setIDVApplyStatus(true);
      dispatch(CarSlice.actions.IDVAPPLY_STATUS(true));
      update_idv(idv_value.value);
      set_idv_value((prev) => ({ ...prev, warning: false }));
      dispatch(
        CarSlice.actions.IDV_UPDATE({
          value: `${idv_value.value}`,
          warning: false,
        })
      );
    } else {
      set_idv_value((prev) => ({ ...prev, warning: true }));
    }
  };
  const update_idv = (idv_value: any) => {
    const onSuccess = (res: any) => {
      get_product_list();
    };

    const onError = () => {};
    CAR_SERVICES.UPDATE_IDV(onSuccess, onError, {
      cusIDV: idv_value === "reset" ? "0" : `${idv_value}`,
      quote_no: quoteNo,
      type: "CAR",
    });
  };

  //sort quote by premium
  useEffect(() => {
    sort_quotation();
  }, [ADDON_STATUS, SORT_BY]);

  const sort_quotation = () => {
    const sortedData: TCarProduct[] = [...quote_list_data].sort((a, b) => {
      if (a.quotationDetail && !b.quotationDetail) {
        return -1; // Move object 'a' to a lower index (before 'b')
      } else if (!a.quotationDetail && b.quotationDetail) {
        return 1; // Move object 'a' to a higher index (after 'b')
      } else if (!a.quotationDetail && !b.quotationDetail) {
        return 0; // Keep the order of 'a' and 'b' unchanged
      } else {
        if (a.quotationDetail && b.quotationDetail) {
          const premiumA = calculate_premium_for_car(
            a.quotationDetail,
            ADDON_STATUS,
            fieldData.passengerCover.value,
            PLAN_TYPE
          );
          const premiumB = calculate_premium_for_car(
            b.quotationDetail,
            ADDON_STATUS,
            fieldData.passengerCover.value,
            PLAN_TYPE
          );

          if (SORT_BY === "HIGH_TO_LOW") {
            return premiumB - premiumA; // Sort in high to low order
          } else if (SORT_BY === "LOW_TO_HIGH") {
            return premiumA - premiumB; // Sort in low to high order
          } else {
            return 0; // Keep the order of 'a' and 'b' unchanged
          }
        } else {
          return 0; // Keep the order of 'a' and 'b' unchanged
        }
      }
    });

    set_quote_list_data(sortedData);
  };

  useEffect(() => {
    const quote_loader: boolean = quote_list_data.some(
      (item: TCarProduct) => item.loader
    );
    if (quote_list_data.length > 0) {
      if (quote_loader === false) {
        dispatch(CarSlice.actions.SAVE_ALL_QUOTES_DATA(quote_list_data));
      }
      dispatch(CarSlice.actions.updateQuoteLoaderStatus(false));
    }
    dispatch(CarSlice.actions.updateQuoteLoaderStatus(quote_loader));
  }, [quote_list_data]);

  const updateAddOnStatus = (addon: string, value: any) => {
    const onSuccess = (res: any) => {};

    const onError = () => {};

    const Params: {
      quote_no: string;
      type: string;
      addon: string;
      value: "0" | "1" | any;
    } = {
      quote_no: quoteNo,
      type: "CAR",
      addon: addon,
      value: value ? "1" : "0",
    };

    if (addon === "passernger_cover") {
      if (value === false && !isEmpty(ADDON_USER_VALUE.passengerCover.value)) {
        dispatch(
          CarSlice.actions.UPDATE_ADDON_USER_VALUE([
            {
              key: "passengerCover",
              value: { value: "", warning: false },
            },
          ])
        );
        setFieldData((prev) => ({
          ...prev,
          passengerCover: { value: "", warning: false },
        }));
        updatePassengerCoverValue("0");
      }
    } else if (addon === "accessories_cover") {
      if (
        value === false &&
        (!isEmpty(ADDON_USER_VALUE.electricalAcessories.value) ||
          !isEmpty(ADDON_USER_VALUE.nonElectricalAcessories.value))
      ) {
        dispatch(
          CarSlice.actions.UPDATE_ADDON_USER_VALUE([
            {
              key: "electricalAcessories",
              value: { value: "", warning: false },
            },
            {
              key: "nonElectricalAcessories",
              value: { value: "", warning: false },
            },
          ])
        );
        setFieldData((prev) => ({
          ...prev,
          electricalAcessories: { value: "", warning: false },
          nonElectricalAcessories: { value: "", warning: false },
        }));
        get_accessories_value(
          isEmpty(ADDON_USER_VALUE.electricalAcessories.value)
            ? "0"
            : ADDON_USER_VALUE.electricalAcessories.value,
          isEmpty(ADDON_USER_VALUE.nonElectricalAcessories.value)
            ? "0"
            : ADDON_USER_VALUE.nonElectricalAcessories.value
        );
      }
    } else if (
      addon === "cng_kit" &&
      !isEmpty(ADDON_USER_VALUE.cngKitValue.value)
    ) {
      if (value === false) {
        dispatch(
          CarSlice.actions.UPDATE_ADDON_USER_VALUE([
            {
              key: "cngKitValue",
              value: { value: "", warning: false },
            },
          ])
        );
        setFieldData((prev) => ({
          ...prev,
          cngKitValue: { value: "", warning: false },
        }));
        get_cng_kit_value("0");
      }
    } else {
      CAR_SERVICES.UPDATE_ADDON_STATUS(onSuccess, onError, Params);
    }
  };

  const updateAddonAndValue = (addonKey: string, value: boolean) => {
    switch (addonKey) {
      case "Driver Cover":
        updateAddOnStatus("driver_cover", value);
        break;
      case "PA Owner Cover":
        break;
      case "24x7 Roadside Assistance":
        updateAddOnStatus("road_side_assistance", value);
        break;
      case "Zero Depreciation":
        updateAddOnStatus("depreciation_cover", value);
        break;
      case "Engine Cover":
        updateAddOnStatus("engine_gear_box_protection", value);
        break;
      case "Invoice Cover":
        updateAddOnStatus("invoice_cover", value);
        break;
      case "Key Replacement":
        updateAddOnStatus("key_replacement_cover", value);
        break;
      case "24x7 Roadside Assistance":
        updateAddOnStatus("rsa", value);
        break;
      case "Consumable Cover":
        updateAddOnStatus("consumables_cover", value);
        break;
      case "Tyre Cover":
        updateAddOnStatus("tyre_secure_cover", value);
        break;
      case "Personal Belonging":
        updateAddOnStatus("personal_belonging", value);
        break;
      case "CNG Kit":
        if (isEmpty(fieldData.cngKitValue.value)) {
          toast.error("Please enter value in CNG kit field");
        } else {
          updateAddOnStatus("cng_kit", fieldData.cngKitValue.value);
          dispatch(
            CarSlice.actions.UPDATE_ADDON_USER_VALUE([
              {
                key: "cngKitValue",
                value: {
                  value: fieldData.cngKitValue.value,
                  warning: false,
                },
              },
            ])
          );
        }
        break;
      case "CNG Kit (₹)":
        if (!value && !isEmpty(ADDON_USER_VALUE.cngKitValue.value)) {
          updateAddOnStatus("cng_kit", value);
        }
        break;
      case "Tyre Cover":
        updateAddOnStatus("tyre_cover", value);
        break;
      case "NCB Cover":
        updateAddOnStatus("no_claim_bonus_same_slab", value);
        break;
      case "Accessories Cover (₹)":
        updateAddOnStatus("accessories_cover", value);
        break;
      case "Passenger Cover (₹)":
        if (
          (value === false &&
            parseInt(ADDON_USER_VALUE.passengerCover.value) > 0) ||
          value === true
        ) {
          updateAddOnStatus("passernger_cover", value);
        }
        break;
      default:
        break;
    }
  };

  const accessories_value_quote_load = () => {
    let hasError = true;

    if (!isEmpty(fieldData.electricalAcessories.value)) {
      const electric = parseInt(fieldData.electricalAcessories.value);
      if (electric > 0 && electric <= 50000) {
        setFieldData((prev) => ({
          ...prev,
          electricalAcessories: {
            ...prev.electricalAcessories,
            warning: false,
          },
        }));
        hasError = false;
      } else {
        setFieldData((prev) => ({
          ...prev,
          electricalAcessories: {
            ...prev.electricalAcessories,
            warning: true,
          },
        }));
        hasError = true;
      }
    } else {
      setFieldData((prev) => ({
        ...prev,
        electricalAcessories: { value: "", warning: false },
      }));
    }

    if (!isEmpty(fieldData.nonElectricalAcessories.value)) {
      const non_electric = parseInt(fieldData.nonElectricalAcessories.value);
      if (non_electric > 0 && non_electric <= 50000) {
        setFieldData((prev) => ({
          ...prev,
          nonElectricalAcessories: {
            ...prev.nonElectricalAcessories,
            warning: false,
          },
        }));
        hasError = false;
      } else {
        setFieldData((prev) => ({
          ...prev,
          nonElectricalAcessories: {
            ...prev.nonElectricalAcessories,
            warning: true,
          },
        }));
        hasError = true;
      }
    } else {
      setFieldData((prev) => ({
        ...prev,
        nonElectricalAcessories: { value: "", warning: false },
      }));
    }

    if (
      isEmpty(fieldData.electricalAcessories.value) &&
      isEmpty(fieldData.nonElectricalAcessories.value)
    ) {
      toast.error("Please enter value in Electric / Non-Electric field.");
    }
    if (!hasError) {
      get_accessories_value();
    }
  };

  const cng_kit_quote_load = () => {
    let hasError = true;

    if (!isEmpty(fieldData.cngKitValue.value)) {
      const cngKitValue = parseInt(fieldData.cngKitValue.value);
      if (cngKitValue > 0 && cngKitValue <= 50000) {
        setFieldData((prev) => ({
          ...prev,
          cngKitValue: {
            ...prev.cngKitValue,
            warning: false,
          },
        }));
        hasError = false;
      } else {
        setFieldData((prev) => ({
          ...prev,
          cngKitValue: {
            ...prev.cngKitValue,
            warning: true,
          },
        }));
        hasError = true;
      }
    } else {
      setFieldData((prev) => ({
        ...prev,
        cngKitValue: { value: "", warning: false },
      }));
    }

    if (isEmpty(fieldData.cngKitValue.value)) {
      toast.error("Please enter value in cng_kit field.");
    }
    if (!hasError) {
      get_cng_kit_value();
    }
  };

  const get_accessories_value = (electric?: string, non_electric?: string) => {
    const onSuccess = (res: any) => {
      get_product_list();
    };
    const onError = (err: any) => {};
    let Param: {
      quote_no: string;
      type: string;
      electric_accessory: string;
      non_electric_accessory: string;
    } = {
      quote_no: quoteNo,
      type: "CAR",
      electric_accessory: !isEmpty(electric)
        ? `${electric}`
        : fieldData.electricalAcessories.value,
      non_electric_accessory: !isEmpty(non_electric)
        ? `${non_electric}`
        : fieldData.nonElectricalAcessories.value,
    };
    dispatch(
      CarSlice.actions.UPDATE_ADDON_USER_VALUE([
        {
          key: "electricalAcessories",
          value: {
            value: fieldData.electricalAcessories.value,
            warning: false,
          },
        },
        {
          key: "nonElectricalAcessories",
          value: {
            value: fieldData.nonElectricalAcessories.value,
            warning: false,
          },
        },
      ])
    );
    CAR_SERVICES.UPDATE_ACCESSORIES_AMOUNT(onSuccess, onError, Param);
  };

  const get_cng_kit_value = (value?: string) => {
    const onSuccess = (res: any) => {
      get_product_list();
    };
    const onError = (err: any) => {};
    let Param: {
      quote_no: string;
      type: string;
      cng_kit: string;
    } = {
      quote_no: quoteNo,
      type: "CAR",
      cng_kit: !isEmpty(value) ? `${value}` : fieldData.cngKitValue.value,
    };
    dispatch(
      CarSlice.actions.UPDATE_ADDON_USER_VALUE([
        {
          key: "cngKitValue",
          value: {
            value: !isEmpty(value) ? `${value}` : fieldData.cngKitValue.value,
            warning: false,
          },
        },
      ])
    );
    CAR_SERVICES.UPDATE_CNG_KIT(onSuccess, onError, Param);
  };

  return (
    <>
      {QUOTE_LOADER === true ? <div className="quoteoverlay" /> : null}
      {isMobile ? (
        <MCarQuotePage
          IDVApplyStatus={IDVApplyStatus}
          check_valid_idv={check_valid_idv}
          updateAddonAndValue={updateAddonAndValue}
          fieldData={fieldData}
          get_product_list={get_product_list}
          idv_value={idv_value}
          min_max_value={min_max_value}
          quote_list_data={quote_list_data}
          setFieldData={setFieldData}
          setIDVApplyStatus={setIDVApplyStatus}
          set_idv_value={set_idv_value}
          updateMasterState={updateMasterState}
          update_idv={update_idv}
          accessories_value_quote_load={accessories_value_quote_load}
          cng_kit_quote_load={cng_kit_quote_load}
        />
      ) : (
        <CarQuotePage
          IDVApplyStatus={IDVApplyStatus}
          updateAddonAndValue={updateAddonAndValue}
          check_valid_idv={check_valid_idv}
          fieldData={fieldData}
          get_product_list={get_product_list}
          idv_value={idv_value}
          min_max_value={min_max_value}
          quote_list_data={quote_list_data}
          setFieldData={setFieldData}
          setIDVApplyStatus={setIDVApplyStatus}
          set_idv_value={set_idv_value}
          update_idv={update_idv}
          updateMasterState={updateMasterState}
          accessories_value_quote_load={accessories_value_quote_load}
          cng_kit_quote_load={cng_kit_quote_load}
          RESET_ALL_ADDONS={RESET_ALL_ADDONS}
        />
      )}
    </>
  );
};
