import { InputAdornment, TextField } from "@mui/material";
import "../FieldTypes.scss";
import { useEffect, useState } from "react";

interface RKTextFieldProps {
  title?: string;
  value: any;
  attrName: any;
  value_update: Function;
  warn_status?: boolean;
  class_name?: string;
  error_message?: string;
  max_length?: number;
  number_only?: boolean;
  aadhar_format?: boolean;
  alphabet_only?: boolean;
  disabled?: boolean;
  address_field?: boolean;
  sx?: any;
  alphaNumeric?: boolean;
  validation_type?:
    | "NUMBER"
    | "NAME"
    | "ALPHANUMERIC"
    | "PASSWORD"
    | "ALL_CAPS"
    | "ALPHANUMERIC_ALL"
    | "REG_NO";
}

const RKTextField: React.FC<RKTextFieldProps> = ({
  title = "",
  value = "",
  attrName = "",
  value_update = () => {},
  warn_status = false,
  class_name = "inputField",
  error_message = "Please enter a valid value",
  max_length = 200,
  number_only = false,
  aadhar_format = false,
  alphabet_only = false,
  address_field = false,
  alphaNumeric = false,
  validation_type,
  disabled,
  sx,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState<string>("");
  useEffect(() => {
    setLocalValue("");
    setLocalValue(value);
  }, [value]);

  const isIndianVehicleNumber = (text: string): boolean => {
    const indianVehicleNumberRegex =
      /^[A-Z]{0,2}[0-9]{0,2}[A-Z]{0,4}[0-9]{0,4}$/;
    return indianVehicleNumberRegex.test(text);
  };
  const pattern = "^\\d*$";

  const handleValueChange = (newValue: string) => {
    if (alphabet_only) {
      const uppercasedValue = newValue.toUpperCase();
      if (
        uppercasedValue.match(/^[A-Z0-9]*$/) || // Uppercase alphabet and spaces
        uppercasedValue === ""
      ) {
        if (uppercasedValue.length <= (max_length === undefined ? 0 : 15)) {
          value_update(attrName, uppercasedValue);
        }
      }
    } else if (number_only) {
      if (newValue.match(pattern) || newValue === "") {
        if (newValue.length <= (max_length === undefined ? 0 : 15)) {
          value_update(attrName, newValue);
        }
      }
    } else if (address_field || alphaNumeric) {
      if (
        newValue.match(/^[a-zA-Z0-9 \/\-]*$/) || // Alphanumeric and spaces
        newValue === ""
      ) {
        if (newValue.length <= (max_length === undefined ? 0 : max_length)) {
          value_update(attrName, newValue);
        }
      }
    } else if (validation_type === "NAME") {
      let valid_value = newValue.replace(/(^\s+|[^a-zA-Z\s])/g, "");

      // Split the string into an array of words
      let words = valid_value.split(/\s+/);

      // Capitalize the first letter of each word
      words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

      // Join the words back into a string
      let capitalizedValue = words.join(" ");

      if (newValue.length <= (max_length === undefined ? 0 : max_length)) {
        value_update(attrName, capitalizedValue);
      }
    } else if (validation_type === "ALPHANUMERIC") {
      // Remove any characters that are not alphanumeric
      let valid_value = newValue.replace(/[^A-Za-z0-9]/g, "");

      value_update(attrName, `${valid_value}`.toUpperCase());
    } else if (validation_type === "ALPHANUMERIC_ALL") {
      // Allow capital letters, numbers, and specific special characters
      let valid_value = newValue.replace(
        /[^A-Za-z0-9!@#$%^&*()_+\-=\[\]{}|;:'",.<>?/]/g,
        ""
      );
      value_update(attrName, `${valid_value}`.toUpperCase());
    } else {
      value_update(attrName, newValue);
    }
  };

  return (
    <div className={class_name} style={{ ...sx }}>
      <TextField
        fullWidth
        label={title}
        variant="outlined"
        value={value}
        type={aadhar_format ? "password" : "text"}
        disabled={disabled}
        onChange={(e) => handleValueChange(e.target.value)}
        inputProps={{
          pattern: pattern,
          maxLength: max_length,
        }}
        InputProps={
          aadhar_format
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {value.length > 8 ? value.slice(-4) : ""}
                  </InputAdornment>
                ),
              }
            : {}
        }
        {...rest}
      />
      {warn_status === true ? (
        <span className="error">{error_message}</span>
      ) : null}
    </div>
  );
};

export default RKTextField;
