import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TNomineeDetails } from "../../../../../../State/Types/TNomineeDetails";
import { useAppSelector } from "../../../../../../State/hooks";
import RKTextField from "../../../../../../Component/InputFields/RKTextField/RKTextField";
import { ETermCompanyCode } from "../../../../../../State/Enum/ETermCompanyCode";
import SelectDropdown from "../../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../../../Component/InputFields/DatePicker/DatePicker";
import Footer from "../../../../../../Component/Desktop/Term/ProposalForm/Common/Footer/Footer";
import "../../../../../../CommonSCSS/ProposalForms.scss";
const NomineeDetails = ({
  continueFunction,
  nomineeDetails,
  setNomineeDetails,
  updateMasterState,
}: {
  nomineeDetails: TNomineeDetails;
  setNomineeDetails: Function;
  continueFunction: Function;
  updateMasterState: Function;
}) => {
  const { dropdownData, selectedQuote } = useAppSelector((state) => state.Term);
  return (
    <Box>
      <h5 className="sectionTitle">Nominee Details</h5>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            value={nomineeDetails.full_name.value}
            attrName={["full_name", setNomineeDetails, true]}
            value_update={updateMasterState}
            warn_status={nomineeDetails.full_name.warning}
            error_message="Please enter full name"
          />
        </Grid>
        {selectedQuote.company_code !== ETermCompanyCode.ICICI ? (
          <Grid xs={4}>
            <SelectDropdown
              class_name="inputField"
              title="Gender"
              value={nomineeDetails.gender.value}
              attrName={["gender", setNomineeDetails, true]}
              value_update={updateMasterState}
              data={dropdownData.genderData}
              warn_status={nomineeDetails.gender.warning}
              error_message="Please select gender"
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={nomineeDetails.dob.value}
            attrName={["dob", setNomineeDetails, true]}
            value_update={updateMasterState}
            error_message="Please select DOB"
            warn_status={nomineeDetails.dob.warning}
            min_date={60}
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Relationship"
            value={nomineeDetails.relationship.value}
            attrName={["relationship", setNomineeDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.nomineeRelationshipData}
            warn_status={nomineeDetails.relationship.warning}
            error_message="Please select relationship"
          />
        </Grid>
      </Grid>
      <Footer
        continueFunction={() => {
          continueFunction();
        }}
        backText="Back "
      />
    </Box>
  );
};

export default NomineeDetails;
