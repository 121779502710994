import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./PolicyConfirm.scss";
import { useAppSelector } from "../../../../../State/hooks";
import Navbar from "../../../../../Component/Desktop/Term/ProposalForm/Navbar/Navbar";
import { FORMAT_NUMBER } from "../../../../../SupportingFiles/HelpingFunction";

function PolicyConfirm() {
  const { proposerDetails, selectedQuote } = useAppSelector(
    (state) => state.Term
  );
  return (
    <Box className="paymentWrapper">
      {/* Navbar */}
      <Navbar />

      <Grid container spacing={3}>
        <Grid xs={12} className="my-4" textAlign="center">
          <img src="./images/policy-confirmation.svg" alt="" height="140px" />
        </Grid>
        <Grid xs={12} textAlign="center">
          <h3>Policy Confirmation</h3>
          <p className="mb-0">
            Great !! Congratulations on buying your investment.
          </p>
        </Grid>
        <Grid xs={12} textAlign="center">
          <p className="mb-0 paymentDetail_p">
            Hi, {`${proposerDetails.full_name.value}`}
          </p>
          <p className="paymentDetail_p">
            {" "}
            {`${proposerDetails.gender.value}` === "M"
              ? "Male"
              : "Female"}, {`${selectedQuote.age}`}
            Years
          </p>
        </Grid>
        <Grid xs={12}>
          <Box>
            <Grid container spacing={3} className="paymentPolicy_box">
              <Grid xs={6}>
                <p
                  style={{
                    textAlign: "left",
                  }}
                >{`${selectedQuote.product_name}`}</p>
              </Grid>
              <Grid xs={6}>
                <p style={{ textAlign: "right" }}>
                  Transaction ID :-{" "}
                  {selectedQuote.transaction_id.length !== 0
                    ? selectedQuote.transaction_id
                    : selectedQuote.appno}
                </p>
              </Grid>
              <Grid xs={2.4} textAlign="left">
                <img
                  src={selectedQuote.logo}
                  alt=""
                  style={{ width: "90px" }}
                />
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Sum Assured
                  <span>{FORMAT_NUMBER(selectedQuote.sum_insured)}</span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Policy Term
                  <span>{selectedQuote.term} Years</span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Policy Paying Term
                  <span>{selectedQuote.pay_term} Years</span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p style={{ textAlign: "right" }}>
                  Application No.
                  <span>
                    {selectedQuote.application_no || selectedQuote.appno}
                  </span>
                </p>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PolicyConfirm;
