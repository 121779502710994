import { Box, Checkbox, Radio, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../../../../Component/InputFields/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import Footer from "../../../../../../Component/Mobile/Term/ProposalForm/Common/Footer/Footer";
import { TInsuredMemberDetails } from "../../../../../../State/Types/TInsuredMemberDetails";
import { TProposerDetails } from "../../../../../../State/Types/TProposerDetails";
import { useAppSelector } from "../../../../../../State/hooks";
import {
  CURRENCY_FORMAT,
  FORMAT_NUMBER,
} from "../../../../../../SupportingFiles/HelpingFunction";

const MProposerDetails = ({
  updateMasterState,
  proposerDetails,
  setProposerDetails,
  insuredMemberDetails,
  setInsuredMemberDetails,
  validateForm,
  loader,
}: {
  updateMasterState: Function;
  proposerDetails: TProposerDetails;
  setProposerDetails: Function;
  insuredMemberDetails: TInsuredMemberDetails;
  setInsuredMemberDetails: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  const { dropdownData, selectedQuote, PREMIUM_LOADER } = useAppSelector(
    (state) => state.Term
  );

  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Box className="previewDetailBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid xs={6}>
            <img src={selectedQuote.logo} alt="logo" />
            <p className="mt-3 product_name">{selectedQuote.product_name}</p>
          </Grid>
          <Grid xs={6} textAlign="right">
            <p className="premium mb-2">
              Premium:{" "}
              {PREMIUM_LOADER ? (
                <img
                  src="images/loader.svg"
                  className="loader_img"
                  alt="Brand Logo"
                />
              ) : (
                <span>{CURRENCY_FORMAT(selectedQuote.premium)}</span>
              )}
            </p>
            <p className=" mb-2">
              Sum Assured:{" "}
              <span>{FORMAT_NUMBER(selectedQuote.sum_insured)}</span>
            </p>
            <p className="mb-2">
              Payment Mode: <span>{selectedQuote.payment_mode}</span>
            </p>
            <p>
              Policy Tenure: <span>{`${selectedQuote.term} Years`}</span>
            </p>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {/* <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper mr-0"
            control={
              <Checkbox
                defaultChecked={proposerDetails.is_proposer_is_insured_member}
                onClick={() =>
                  updateMasterState(
                    ["is_proposer_is_insured_member", setProposerDetails],
                    !proposerDetails.is_proposer_is_insured_member
                  )
                }
              />
            }
            label="Proposer is also an insured member?"
          />
        </Grid> */}
        <Grid xs={12}>
          <h6>
            {proposerDetails.is_proposer_is_insured_member
              ? "Proposer / Insured Details"
              : "Proposer Details"}
          </h6>
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Full Name"}
            value={proposerDetails.full_name.value}
            attrName={["full_name", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.full_name.warning}
            error_message="Please enter full name"
          />
        </Grid>
        <Grid xs={6}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={proposerDetails.dob.value}
            attrName={["dob", setProposerDetails, true]}
            value_update={updateMasterState}
            error_message="Please select DOB"
            warn_status={proposerDetails.dob.warning}
            min_date={60}
            disabled
            tooltip_status
            tooltip="You cannot change DOB on proposal page. If you would like to edit this . Please go back to the quote page"
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField mb-2"
            title="Gender"
            value={proposerDetails.gender.value}
            attrName={["gender", setProposerDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.genderData}
            warn_status={proposerDetails.gender.warning}
            error_message="Please select gender"
            disabled
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Mobile"}
            value={proposerDetails.mobile.value}
            attrName={["mobile", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.mobile.warning}
            error_message="Please enter mobile no."
            number_only={true}
            max_length={10}
            disabled={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Emergency Phone"}
            value={proposerDetails.emergency_number.value}
            attrName={["emergency_number", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.emergency_number.warning}
            error_message="Please enter emergency no."
            number_only={true}
            max_length={10}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Email"}
            value={proposerDetails.email.value}
            attrName={["email", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.email.warning}
            error_message="Please enter email"
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Aadhar"}
            value={proposerDetails.aadhar_number.value}
            attrName={["aadhar_number", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.aadhar_number.warning}
            error_message="Please enter aadhar no."
            number_only={true}
            max_length={12}
            aadhar_format={true}
          />
        </Grid>
        <Grid xs={12}>
          <SearchDropdown
            class_name="inputField"
            title="Education"
            value={proposerDetails.education.value.label}
            attrName={["education", setProposerDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.EDUCATION_MASTER}
            warn_status={proposerDetails.education.warning}
            error_message="Please select education"
          />
        </Grid>
        <Grid xs={12}>
          <SearchDropdown
            class_name="inputField"
            title="Occupation"
            value={proposerDetails.occupation.value.label}
            attrName={["occupation", setProposerDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.occupationData}
            warn_status={proposerDetails.occupation.warning}
            error_message="Please select occupation"
          />
        </Grid>
        <Grid xs={12}>
          <SearchDropdown
            class_name="inputField"
            title="Annual Income"
            value={proposerDetails.annual_income.value.label}
            attrName={["annual_income", setProposerDetails, false]}
            value_update={updateMasterState}
            data={dropdownData.MAX_ANNUAL_INCOME}
            warn_status={proposerDetails.annual_income.warning}
            error_message="Please select annual income"
          />
        </Grid>
        <Grid xs={12}>
          <SearchDropdown
            class_name="inputField"
            title="Sum Assured"
            value={proposerDetails.sum_assured.value.label}
            attrName={["sum_assured", setProposerDetails, false]}
            value_update={updateMasterState}
            data={dropdownData.MAX_SUM_ASSURED}
            warn_status={proposerDetails.sum_assured.warning}
            error_message="Please select sum assured"
          />
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper mr-0"
            control={
              <Checkbox
                defaultChecked={proposerDetails.diabetic}
                onClick={() =>
                  updateMasterState(
                    ["diabetic", setProposerDetails],
                    !proposerDetails.diabetic
                  )
                }
              />
            }
            label="Are you suffering from diabetes?"
          />
        </Grid>
      </Grid>
      {!proposerDetails.is_proposer_is_insured_member ? (
        <Box>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <hr />
              <h6>Insured Member Details</h6>
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Full Name"}
                value={insuredMemberDetails.full_name.value}
                attrName={["gender", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                warn_status={insuredMemberDetails.full_name.warning}
                error_message="Please enter full name"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={insuredMemberDetails.dob.value}
                attrName={["dob", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                error_message="Please select DOB"
                warn_status={insuredMemberDetails.dob.warning}
                min_date={60}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField mb-2"
                title="Gender"
                value={insuredMemberDetails.gender.value}
                attrName={["gender", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                data={dropdownData.genderData}
                warn_status={insuredMemberDetails.gender.warning}
                error_message="Please select gender"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Aadhar Number"}
                value={insuredMemberDetails.aadhar_number.value}
                attrName={["aadhar_number", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                warn_status={insuredMemberDetails.aadhar_number.warning}
                error_message="Please enter aadhar no."
                number_only={true}
                max_length={12}
                aadhar_format={true}
              />
            </Grid>

            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField mb-2"
                title="Occupation"
                value={insuredMemberDetails.occupation.value}
                attrName={["occupation", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                data={dropdownData.occupationData}
                warn_status={insuredMemberDetails.occupation.warning}
                error_message="Please select occupation"
              />
            </Grid>
            {insuredMemberDetails.occupation.value.code === "SPVT" ||
            insuredMemberDetails.occupation.value.code === "BSEM" ||
            insuredMemberDetails.occupation.value.code === "SPRO" ? (
              <>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Organisation"
                    value={insuredMemberDetails.org_name.value}
                    attrName={["org_name", setInsuredMemberDetails, true]}
                    value_update={updateMasterState}
                    data={dropdownData.insurance_company}
                    warn_status={insuredMemberDetails.org_name.warning}
                    error_message="Please select organisation"
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Organisation Description"}
                    value={insuredMemberDetails.org_desc.value}
                    attrName={["org_desc", setInsuredMemberDetails, true]}
                    value_update={updateMasterState}
                    warn_status={insuredMemberDetails.org_desc.warning}
                    error_message="Please enter organisation description"
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
      ) : null}
      <Footer continueFunction={validateForm} loader={loader} />
    </Box>
  );
};

export default MProposerDetails;
