import { default as Moment, default as moment } from "moment";
import { TNCB } from "../Services/Type/Common/TNCB";

const formats = [
  "YYYY-MM-DD",
  "MM-DD-YYYY",
  "DD-MM-YYYY",
  "YYYY/MM/DD",
  "MM/DD/YYYY",
  "DD/MM/YYYY",
  "MMM DD, YYYY",
  "MMMM DD, YYYY",
  "MMM DD YYYY",
  "MMMM DD YYYY",
  "DD-M-YYYY",
  "YYYY-MM-DD HH:MM:SS",
];

export function validateRegno(reg_no: string): boolean {
  var re = /^[A-Z]{2,2}[0-9]{2}[A-Z]{0,3}[0-9]{4,4}$/;
  var re_dl = /^[A-Z]{2,2}[0-9]{1,2}[A-Z]{0,3}[0-9]{4,4}$/;
  if (
    parseInt(
      reg_no.substring(reg_no.toString().length - 4, reg_no.toString().length)
    ) == 0
  ) {
    return false;
  }
  if (parseInt(reg_no.substring(2, 4)) == 0) {
    return false;
  }
  if (reg_no.toString().length == 7) {
    if (parseInt(reg_no.substring(2, 3)) == 0) {
      return false;
    }
  }
  if (reg_no.substring(0, 2) == "DL") {
    return re_dl.test(reg_no);
  }
  if (
    parseInt(
      reg_no.substring(reg_no.toString().length - 4, reg_no.toString().length)
    ) == undefined
  ) {
    return false;
  }

  return re.test(reg_no);
}

export const isDateInRange = (startDate: Date, endDate: Date): boolean => {
  const currentDate = new Date();
  return currentDate >= startDate && currentDate <= endDate;
};

export function FORMAT_NUMBER(num: any) {
  if (num >= 10000000) {
    return (
      (num / 10000000)
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace("₹", "₹ ") + " Crore"
    );
  } else if (num >= 100000) {
    return (
      (num / 100000)
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace("₹", "₹ ") + " Lakhs"
    );
  } else {
    return num
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
      .replace("₹", "₹ ");
  }
}

export function validateEmail(email: any): boolean {
  const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
  return emailRegex.test(email);
}

export function validateAadharNumber(aadharNumber: any): boolean {
  if (aadharNumber !== undefined) {
    const aadhar = aadharNumber.trim();
    if (aadhar.toString().length === 12) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function extractNumbersFromString(inputString: string) {
  // Use a regular expression to match and extract numbers from the input string
  const numbersArray = inputString.match(/\d+/g);

  if (numbersArray) {
    // Join the matched numbers together to form a single string or convert to numbers as needed
    const extractedNumbers = numbersArray.join(""); // This will give you a string of numbers
    // If you want a number, you can convert it like this:
    // const extractedNumbersAsNumber = parseInt(extractedNumbers, 10);
    return extractedNumbers;
  }

  return null; // Return null if no numbers are found in the input string
}

export function validatePAN(panNumber: any): boolean {
  const panRegex = /^[A-Z]{5,5}[0-9]{4,4}[A-Z]{1,1}$/;
  return panRegex.test(panNumber);
}

export function validatePincode(value: string | Date | null): boolean {
  const pincode: string = `${value}`;
  if (pincode === "111111" || pincode === "000000") {
    return false;
  } else if (pincode !== undefined) {
    if (pincode.toString().length === 6) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
 export function validateInput(inputValue : string) {
  if (inputValue.charAt(0) === "0") {
    return 
  } else {
    return inputValue;
  }
}

export function formatDate(date: Date) {
  try {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  } catch (error) {
    return "Invalid date format";
  }
}

export function formatDateFromyyymmdd(inputDate: string) {
  // Split the input string into an array using the "-" separator
  const dateParts = inputDate.split("-");

  // Rearrange the date parts to the desired format "dd-mm-yyyy"
  const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

  return formattedDate;
}

export function ADD_GST(value: string | number | undefined): number {
  try {
    // Convert the input to a number
    const local_value = Number(value);

    // Check if the converted value is NaN, undefined, or null
    if (
      isNaN(local_value) ||
      local_value === undefined ||
      local_value === null
    ) {
      throw new Error("Invalid input. Please provide a valid number.");
    }

    // Assuming value represents the base price without GST
    const gstAmount: number = (local_value / 100) * 18; // 18% GST
    const totalAmount: number = local_value + gstAmount;

    return totalAmount;
  } catch (error) {
    console.error("Error:", `${error}`);
    // You can choose to throw a custom error or handle it in any other way based on your use case.
    return 0; // Returning 0 in case of error
  }
}

export function validateMobileNumber(
  mobileNumber: string | Date | null
): boolean {
  if (typeof mobileNumber === "string") {
    if (mobileNumber !== undefined && mobileNumber !== null) {
      const mobile = mobileNumber.toString().trim().slice(0, 10);
      if (
        mobile.toString().length === 10 &&
        parseInt(mobile.substring(0, 1)) >= 6
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function validateMobileOtp(otp: string | null): boolean {
  if (typeof otp === "string") {
    if (otp !== undefined && otp !== null) {
      const receivedOtp = otp.toString().trim();
      if (receivedOtp.toString().length === 4) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function validateGST(gstNumber: string | number): boolean {
  // Updated regular expression to include both uppercase letters and numbers
  const gstRegex =
    /^[0-9]{2}[A-Z0-9]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}$/;

  const gstString = String(gstNumber);

  return gstRegex.test(gstString);
}

export const CHECK_IS_POLICY_ACTIVE = (date: string) => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  if (inputDate < currentDate) {
    return false;
  } else if (inputDate > currentDate) {
    return true;
  } else {
    return true;
  }
};

export function validateFullName(fullName: any): boolean {
  const name = fullName.trim();
  if (fullName !== undefined) {
    if (name.includes(" ")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function formatToCurrency(num: number): string {
  if (num === undefined || num === null) {
    return "";
  }
  if (num >= 10000000) {
    return (
      (num / 10000000)
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace("₹", "₹ ") + " Crore"
    );
  } else if (num >= 100000) {
    return (
      (num / 100000)
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace("₹", "₹ ") + " Lakh"
    );
  } else {
    return num
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
      .replace("₹", "₹ ");
  }
}

export function calculate_age(dateString: any) {
  var birthDate = moment(dateString, "DD-MM-YYYY");
  var today = moment();

  if (!birthDate.isValid()) {
    return NaN; // Return NaN if the date is invalid
  }

  var age = today.diff(birthDate, "years");
  return age;
}

export function uploadImage(
  file: File,
  callback: (base64Image: string, base64String: string) => void
): void {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    const base64String = reader.result as string;
    const base64Image = base64String.replace(
      /^data:(image\/[a-z]+|application\/pdf);base64,/,
      ""
    );
    callback(base64Image, base64String);
  };
}

export function addYearsToDate(date: string, years: number): string {
  const newDate = moment(date, ["DD-MM-YYYY", "YYYY-MM-DD"])
    .add(years, "year")
    .format("DD-MM-YYYY");
  return newDate;
}

export function addYearsToDateForonedaysubtract(
  date: Date,
  years: number
): string {
  const newDate = moment(date).add(years, "years");

  // Check for leap year and adjust the date
  if (
    moment(date).isLeapYear() &&
    moment(date).month() === 1 &&
    moment(date).date() === 29 &&
    !newDate.isLeapYear()
  ) {
    newDate.date(28); // Adjust to February 28th if it's a leap day
  }

  const finalDate = newDate.subtract(1, "day").format("DD-MM-YYYY");
  return finalDate;
}

export function FORMAT_DATE_DD_MM_YYYY(value: any): string {
  let formattedDate = "";

  for (const format of formats) {
    const date = new Date(value);
    const parsedDate = date.toString();
    if (parsedDate !== "Invalid Date") {
      formattedDate = `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
      break;
    } else {
      return value;
    }
  }

  return formattedDate;
}

export function FORMAT_YYYY_MM_DD(value: string | null | Date) {
  let formattedDate = "";

  for (const format of formats) {
    const date = moment(value, format, true);
    if (date.isValid()) {
      formattedDate = date.format("YYYY-MM-DD");
      break;
    }
  }

  if (formattedDate === "") {
    return value; // Return original value if no valid format is found
  }

  return formattedDate;
}

export function FORMAT_DD_MM_YYYY(value: string) {
  let formattedDate = "";

  for (const format of formats) {
    const date = moment(value, format, true);
    if (date.isValid()) {
      formattedDate = date.format("DD-MM-YYYY");
      break;
    }
  }

  if (formattedDate === "") {
    return value; // Return original value if no valid format is found
  }

  return formattedDate;
}

export function FORMAT_DD_MMM_YYYY_HH_MM_SS(value: any) {
  let formattedDate = "";

  for (const format of formats) {
    const date = moment(value, format, true);
    if (date.isValid()) {
      formattedDate = date.format("DD-MM-YYYY HH:MM:SS");
      break;
    }
  }

  if (formattedDate === "") {
    return value; // Return original value if no valid format is found
  }

  return formattedDate;
}

export function calculateDateDifferenceInDays(
  date2: string | Date | null
): number {
  const date1 = new Date().toLocaleDateString("en-GB");
  const formattedDate1 = Moment(date1, "DD-MM-YYYY").format("YYYY-MM-DD");
  const formattedDate2 = Moment(date2, "DD-MM-YYYY").format("YYYY-MM-DD");
  const msDiff =
    new Date(formattedDate1).getTime() - new Date(formattedDate2).getTime();
  const diff = Math.floor(msDiff / (1000 * 60 * 60 * 24));

  return diff;
}
export const CURRENCY_FORMAT = (value: number | string): string => {
  // Convert the input to a number if it's a string
  let numericValue: number;

  if (typeof value === "string") {
    // Remove leading and trailing whitespace
    const trimmedValue = value.trim();

    // Check if the trimmed value is empty
    if (trimmedValue === "") {
      return "Empty Value";
    }

    numericValue = parseFloat(trimmedValue);

    // Check if parsing failed
    if (isNaN(numericValue)) {
      return "Invalid Number";
    }
  } else if (typeof value === "number") {
    numericValue = value;
  } else {
    return "Invalid Type"; // Handle other data types
  }

  const nf = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedValue = nf.format(numericValue);

  // Add a space after the currency symbol
  return formattedValue.replace("₹", "₹ ");
};

export const minLength2 = (value: any) => {
  return value !== null && value !== undefined && `${value}`.trim().length >= 2;
};

export const minLength4 = (value: any) => {
  return value !== null && value !== undefined && `${value}`.trim().length >= 4;
};

export function add_years_in_date(date: any, years: any) {
  let new_date = moment(date, ["DD-MM-YYYY", "YYYY-MM-DD"])
    .add(years, "year")
    .format("DD-MM-YYYY");
  return new_date;
}

export const CAL_NEW_NCB = (
  value: TNCB,
  claim_made: "Yes" | "No",
  plan_type?: string
): TNCB | string => {
  if (claim_made === "Yes" || plan_type === "Third Party") {
    return "0";
  }

  switch (value) {
    case "0":
      return "20";
    case "20":
      return "25";
    case "25":
      return "35";
    case "35":
      return "45";
    case "45":
      return "50";
    case "50":
      return "50";
    default:
      return value;
  }
};

export function addDaysToDate(date: string, days: number): string {
  const newDate = moment(date, ["DD-MM-YYYY", "YYYY-MM-DD"])
    .add(days, "days")
    .format("DD-MM-YYYY");
  return newDate;
}

export function generateRandomNumberString(length: number): string {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function calculate_passenger_cover(
  user_value: number,
  seating_capacity: number,
  plan_type: string = "Comprehensive"
) {
  let calculatedPassengerCover = 0;
  if (plan_type === "Bundle Policy") {
    calculatedPassengerCover = (user_value / 10000) * 5 * seating_capacity * 3;
  } else {
    calculatedPassengerCover = (user_value / 10000) * 5 * seating_capacity;
  }

  return calculatedPassengerCover;
}

export const isEmpty = (value: string | Date | null | undefined | number) => {
  return (
    value === "null" ||
    value === "undefined" ||
    value === null ||
    value === undefined ||
    `${value}`.trim().length === 0
  );
};

export function formatNumberToLakhsOrCrores(number: number) {
  if (typeof number !== "number") {
    return "Invalid Input";
  }

  if (number >= 10000000) {
    const formatted = (number / 10000000).toFixed(2);
    return formatted.endsWith(".00")
      ? "₹ " + formatted.slice(0, -3) + " Crore"
      : "₹ " + formatted + " Crore";
  } else if (number >= 100000) {
    const formatted = (number / 100000).toFixed(2);
    return formatted.endsWith(".00")
      ? "₹ " + formatted.slice(0, -3) + " Lakh"
      : "₹ " + formatted + " Lakh";
  } else {
    return "₹ " + number.toString();
  }
}

export function removeLast5Zeros(inputString: string) {
  // Use regular expression to remove the last 5 zeros
  const trimmedString = inputString.replace(/0{5}$/, "");
  return trimmedString;
}

export const hasWarning = (details: any): boolean => {
  return Object.values(details).some(
    (property: any) => property?.warning === true
  );
};

export function calculateAge(dobString: string): number {
  const dobArray = dobString.split("-");

  if (dobArray.length !== 3) {
    console.error("Invalid date format");
    return 0;
  }

  const day = parseInt(dobArray[0], 10);
  const month = parseInt(dobArray[1], 10) - 1; // Month is zero-indexed
  const year = parseInt(dobArray[2], 10);

  const dob = new Date(year, month, day);

  if (isNaN(dob.getTime())) {
    console.error("Invalid date");
    return 0;
  }

  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();

  const hasBirthdayOccurred =
    today.getMonth() > dob.getMonth() ||
    (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate());

  if (!hasBirthdayOccurred) {
    age--;
  }

  return age;
}

export function calculateAgeInDays(birthdate: string): number {
  // Create Date objects for the current date and the birthdate
  const currentDate = new Date();
  const birthDate = new Date(birthdate);

  // Calculate the age in milliseconds
  const ageInMilliseconds = currentDate.getTime() - birthDate.getTime();

  // Convert milliseconds to days
  const ageInDays = ageInMilliseconds / (1000 * 3600 * 24);
  return Math.floor(ageInDays);
}

export function calculateDaysLeftInPolicyExpire(dateString: string): string {
  try {
    const today = new Date();
    const expireDate = new Date(dateString);
    const timeDiff = expireDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (daysLeft < 0) {
      return "0";
    } else {
      return `${daysLeft}`;
    }
  } catch (error: any) {
    if (error instanceof Error) {
      return `Error: ${error.message}`;
    }
    return `Error: ${String(error)}`;
  }
}

export function getMonthDifference(date2: any) {
  const d1 = new Date();
  const d2 = new Date(date2);

  const monthsDiff =
    (d2.getFullYear() - d1.getFullYear()) * 12 +
    (d2.getMonth() - d1.getMonth());

  return Math.abs(monthsDiff); // Use Math.abs to get the absolute difference
}

export function subtractYears(date: any, years: any) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}
