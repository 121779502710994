import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProposerDetails from "../../../../../Page/Desktop/Term/ICICI/Proposal/ProposerDetails/ProposerDetails";
import MProposerDetails from "../../../../../Page/Mobile/Term/ICICI/Proposal/ProposerDetails/MProposerDetails";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { CreatePaymentLinkDTO } from "../../../../../Services/DTO/Term/CreatePaymentLinkDTO";
import { ProposerDetailsAPIDTO } from "../../../../../Services/DTO/Term/ProposerDetailsAPIDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { ETermLandingPage } from "../../../../../State/Enum/ETermLandingPage";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";
import { TInsuredMemberDetails } from "../../../../../State/Types/TInsuredMemberDetails";
import {
  PROPOSAL_PAGE_SECTION_STATUS_API,
  TProposerDetails,
} from "../../../../../State/Types/TProposerDetails";
import { TSearchableDropdown } from "../../../../../State/Types/TSearchableDropdown";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  FORMAT_YYYY_MM_DD,
  calculate_age,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validatePAN,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
const ProposerDetailsContainer = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const selectedQuote = useAppSelector((state) => state.Term.selectedQuote);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const state = useAppSelector((state) => state);
  const [proposerDetails, setProposerDetails] = useState<TProposerDetails>({
    ...state.Term.proposerDetails,
  });
  const [insuredMemberDetails, setInsuredMemberDetails] =
    useState<TInsuredMemberDetails>(state.Term.insuredDetails);

  useEffect(() => {
    getOccupationList();
    getMaritalStatusList();
    getInsuranceCompanyMaster();
    getDropdownMaster();
    getEducationMaster();

    getTermLandingPage(selectedQuote.quote_no, selectedQuote.buy_online_code);

    setProposerDetails({
      ...state.Term.proposerDetails,
      full_name: { value: selectedQuote.name, warning: false },
      mobile: { value: selectedQuote.mobile, warning: false },
      email: { value: selectedQuote.email, warning: false },
      gender: { value: selectedQuote.gender, warning: false },
      dob: {
        value: `${selectedQuote?.dob_date}-${selectedQuote?.dob_month}-${selectedQuote?.dob_year}`,
        warning: false,
      },
    });
  }, [selectedQuote]);

  const getTermLandingPage = (quote_no: string, buy_online_code: string) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (response.currentPage) {
          case ETermLandingPage.PROPOSER_DETAILS:
            break;
          case ETermLandingPage.NOMINEE_DETAILS:
            navigate(TERM_PATH.NOMINEE_DETAILS);
            break;
          case ETermLandingPage.ADDRESS_DETAILS:
            navigate(TERM_PATH.ADDRESS_DETAILS);
            break;
          case ETermLandingPage.CKYC_DETAILS:
            navigate(TERM_PATH.CKYC);
            break;
          case ETermLandingPage.MEDICAL_PAGE:
            navigate(TERM_PATH.MEDICAL_PAGE);
            break;
          case ETermLandingPage.PERVIEW_PAGE:
            navigate(TERM_PATH.PREVIEW_PAGE);
            break;
          case ETermLandingPage.UPLOAD_DOCUMENT:
            navigate(TERM_PATH.UPLOAD_DOCUMENT);
            break;
          case ETermLandingPage.POLICY_CONFIRMATION:
            navigate(TERM_PATH.POLICY_CONFIRMATION);
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.getTermLandingPage(onSuccess, onError, {
      quote_no: btoa(quote_no),
      buy_online_code: buy_online_code,
    });
  };

  const getOccupationList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const occupationData: Array<TSearchableDropdown> = response.map(
          (obj: any) => {
            return {
              code: obj.code,
              label: obj.title,
            };
          }
        );

        dispatch(TermSlice.actions.setOccupationData(occupationData));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.getOccupationList(onSuccess, onError, {
      product_code: selectedQuote.product_code,
      product_type_code: selectedQuote.product_type_code,
      buy_online_code: selectedQuote.buy_online_code,
    });
  };

  const getDropdownMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      let response = results.response;

      if (error === false) {
        console.log(response);
        let buyingPolicy_reponse: Array<any> = response.buyingPolicy;
        let buyingPolicy_reponse_manupulated: TSearchableDropdown[] = [];
        buyingPolicy_reponse.forEach((element) => {
          buyingPolicy_reponse_manupulated.push({
            code: element.key,
            label: element.value,
          });
        });
        response = {
          ...response,
          buyingPolicy: buyingPolicy_reponse_manupulated,
        };
        dispatch(TermSlice.actions.UPDATE_DROPDOWN_MASTER_DATA(response));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.getDropdownDataMasterICICI(
      onSuccess,
      onError,
      selectedQuote.product_code,
      selectedQuote.buy_online_code
    );
  };

  const getEducationMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const data: Array<TSearchableDropdown> = response.map((obj: any) => {
          return {
            code: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermSlice.actions.setEducationMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.GET_EDUCATION_MASTER(onSuccess, onError, {
      product_type_code: selectedQuote.product_type_code,
      product_code: selectedQuote.product_code,
      buy_online_code: selectedQuote.buy_online_code,
    });
  };

  const getMaritalStatusList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const maritalStatusData: Array<TSearchableDropdown> = response.map(
          (obj: any) => {
            return {
              key: obj.code,
              label: obj.title,
            };
          }
        );

        dispatch(TermSlice.actions.setMaritalStatusData(maritalStatusData));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.getMaritalStatusList(onSuccess, onError, {
      product_code: selectedQuote.product_code,
      product_type_code: selectedQuote.product_type_code,
      buy_online_code: selectedQuote.buy_online_code,
    });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "is_proposer_is_insured_member") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: value,
      }));
    } else if (attrName[0] === "full_name") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName[0] === "mobile") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "emergency_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "email") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateEmail(value),
        },
      }));
    } else if (attrName[0] === "aadhar_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateAadharNumber(value),
        },
      }));
    } else if (attrName[0] === "occupation") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : value.toString().length <= 0,
        },
      }));
      if (value === "BSEM") {
        attrName[1]((prev: any) => ({
          ...prev,
          ["org_name"]: {
            value: "Others",
            warning: false,
          },
        }));
      }
    } else if (attrName[0] === "pan_no") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validatePAN(value),
        },
      }));
    } else if (attrName[0] === "nationality") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: value,
      }));
    } else if (
      attrName[0] === "marital_status" ||
      attrName[0] === "education" ||
      attrName[0] === "occupation" ||
      attrName[0] === "org_name" ||
      attrName[0] === "org_type" ||
      attrName[0] === "obj_of_buying_policy"
    ) {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: { value: value, warning: isEmpty(value?.label) },
      }));
    } else if (attrName[0] === "annual_incomr") {
      setProposerDetails((prev) => ({
        ...prev,
        annual_income: { value: value, warning: false },
      }));
    } else {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    }
    console.log(attrName, value);
  };

  const validateForm = () => {
    let proposer_details_data: TProposerDetails = { ...proposerDetails };
    let insured_member_data: TInsuredMemberDetails = {
      ...insuredMemberDetails,
    };

    proposer_details_data = {
      ...proposer_details_data,
      full_name: {
        ...proposer_details_data.full_name,
        warning: !validateFullName(proposer_details_data.full_name.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      dob: {
        ...proposer_details_data.dob,
        warning: isEmpty(proposer_details_data?.dob?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      gender: {
        ...proposer_details_data.gender,
        warning: isEmpty(proposer_details_data?.gender?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      mobile: {
        ...proposer_details_data.mobile,
        warning: !validateMobileNumber(proposer_details_data.mobile.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      email: {
        ...proposer_details_data.email,
        warning: !validateEmail(proposer_details_data.email.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      aadhar_number: {
        ...proposer_details_data.aadhar_number,
        warning: !validateAadharNumber(
          proposer_details_data.aadhar_number.value
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      emergency_number: {
        ...proposer_details_data.emergency_number,
        warning: !validateMobileNumber(
          proposer_details_data.emergency_number.value
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      marital_status: {
        ...proposer_details_data.marital_status,
        warning: isEmpty(proposer_details_data?.marital_status?.value?.label),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      annual_income: {
        ...proposer_details_data.annual_income,
        warning: isEmpty(proposer_details_data?.annual_income?.value?.label),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      education: {
        ...proposer_details_data.education,
        warning: isEmpty(proposer_details_data?.education?.value?.label),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      occupation: {
        ...proposer_details_data.occupation,
        warning: isEmpty(proposer_details_data?.occupation?.value?.label),
      },
    };

    proposer_details_data = {
      ...proposer_details_data,
      pan_no: {
        ...proposer_details_data.pan_no,
        warning: !validatePAN(proposer_details_data?.pan_no?.value),
      },
    };

    proposer_details_data = {
      ...proposer_details_data,
      obj_of_buying_policy: {
        ...proposer_details_data.obj_of_buying_policy,
        warning: isEmpty(
          proposer_details_data?.obj_of_buying_policy?.value?.label
        ),
      },
    };
    if (proposerDetails.occupation.value.code === "SPRO") {
      proposer_details_data = {
        ...proposer_details_data,
        occupation_desc: {
          ...proposer_details_data.occupation_desc,
          warning: isEmpty(proposer_details_data?.occupation_desc?.value),
        },
      };
    }
    if (
      proposerDetails.occupation.value.code === "SPVT" ||
      proposerDetails.occupation.value.code === "BSEM" ||
      proposerDetails.occupation.value.code === "SPRO"
    ) {
      proposer_details_data = {
        ...proposer_details_data,
        org_name: {
          ...proposer_details_data.org_name,
          warning: isEmpty(proposer_details_data?.org_name?.value?.label),
        },
      };
      if (proposerDetails.org_name.value.code === "NOT ANSWERED") {
        proposer_details_data = {
          ...proposer_details_data,
          org_description: {
            ...proposer_details_data.org_description,
            warning: isEmpty(proposer_details_data?.org_description?.value),
          },
        };
      }
      proposer_details_data = {
        ...proposer_details_data,
        org_type: {
          ...proposer_details_data.org_type,
          warning: isEmpty(proposer_details_data?.org_type?.value?.label),
        },
      };
      if (proposerDetails.org_type.value.code === "NOT ANSWERED") {
        proposer_details_data = {
          ...proposer_details_data,
          org_type_desc: {
            ...proposer_details_data.org_type_desc,
            warning: isEmpty(proposer_details_data?.org_type_desc?.value),
          },
        };
      }
    }

    if (proposerDetails.occupation.value.code === "PROF") {
      proposer_details_data = {
        ...proposer_details_data,
        my_profession: {
          ...proposer_details_data.my_profession,
          warning: isEmpty(proposer_details_data?.my_profession?.value?.label),
        },
      };
    }

    if (!proposer_details_data.is_proposer_is_insured_member) {
      insured_member_data = {
        ...insured_member_data,
        full_name: {
          ...insured_member_data.full_name,
          warning: !validateFullName(insured_member_data.full_name.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        dob: {
          ...insured_member_data.dob,
          warning: isEmpty(insured_member_data?.dob?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        gender: {
          ...insured_member_data.gender,
          warning: isEmpty(insured_member_data?.gender?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        aadhar_number: {
          ...insured_member_data.aadhar_number,
          warning: !validateAadharNumber(
            insured_member_data.aadhar_number.value
          ),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        marital_status: {
          ...insured_member_data.marital_status,
          warning: isEmpty(insured_member_data?.marital_status?.value?.label),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        occupation: {
          ...insured_member_data.occupation,
          warning: isEmpty(insured_member_data?.occupation?.value?.label),
        },
      };

      if (
        insuredMemberDetails.occupation.value.code === "SPVT" ||
        insuredMemberDetails.occupation.value.code === "BSEM" ||
        insuredMemberDetails.occupation.value.code === "SPRO" ||
        insuredMemberDetails.occupation.value.code === "PROF"
      ) {
        insured_member_data = {
          ...insured_member_data,
          org_name: {
            ...insured_member_data.org_name,
            warning: isEmpty(insured_member_data?.org_name?.value?.label),
          },
        };

        insured_member_data = {
          ...insured_member_data,
          org_desc: {
            ...insured_member_data.org_desc,
            warning: isEmpty(insured_member_data?.org_desc?.value),
          },
        };
      }
    } else {
      insured_member_data = {
        full_name: proposer_details_data.full_name,
        email: proposer_details_data.email,
        dob: proposer_details_data.dob,
        gender: proposer_details_data.gender,
        mobile: proposer_details_data.mobile,
        aadhar_number: proposer_details_data.aadhar_number,
        marital_status: proposer_details_data.marital_status,
        occupation: proposer_details_data.occupation,
        org_desc: proposer_details_data.org_description,
        org_name: proposer_details_data.org_name,
        education: proposer_details_data.education,
      };
    }
    setProposerDetails(proposer_details_data);
    setInsuredMemberDetails(insured_member_data);

    let hasError = false;

    if (
      proposer_details_data.full_name.warning === true ||
      proposer_details_data.dob.warning === true ||
      proposer_details_data.gender.warning === true ||
      proposer_details_data.mobile.warning === true ||
      proposer_details_data.email.warning === true ||
      proposer_details_data.aadhar_number.warning === true ||
      proposer_details_data.marital_status.warning === true ||
      proposer_details_data.annual_income.warning === true ||
      proposer_details_data.occupation.warning === true ||
      proposer_details_data.org_name.warning === true ||
      proposer_details_data.org_type.warning === true ||
      proposer_details_data.org_description.warning === true ||
      proposer_details_data.industry_type.warning === true ||
      proposer_details_data.industry_type_desc.warning === true ||
      proposer_details_data.obj_of_buying_policy.warning === true ||
      proposer_details_data.pan_no.warning === true
    ) {
      hasError = true;
    }
    if (proposer_details_data.is_proposer_is_insured_member === false) {
      if (
        insured_member_data.full_name.warning === true ||
        insured_member_data.email.warning === true ||
        insured_member_data.dob.warning === true ||
        insured_member_data.gender.warning === true ||
        insured_member_data.mobile.warning === true ||
        insured_member_data.aadhar_number.warning === true ||
        insured_member_data.marital_status.warning === true ||
        insured_member_data.occupation.warning === true ||
        insured_member_data.org_name.warning === true ||
        insured_member_data.org_desc.warning === true
      ) {
        hasError = true;
      }
    }

    if (!hasError) {
      dispatch(TermSlice.actions.setProposerDetails(proposer_details_data));
      dispatch(TermSlice.actions.setInsuredDetails(insured_member_data));

      ICICI_GET_PREMIUM(insured_member_data, proposer_details_data);
    }
  };

  const ICICI_GET_PREMIUM = (
    insuredMemberDetails: TInsuredMemberDetails,
    proposerDetails: TProposerDetails
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;

      if (error === false) {
        updateProposerDetailsData(insuredMemberDetails, proposerDetails);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    setLoader(true);
    const data = {
      quote_no: selectedQuote.quote_no,
      product_id: selectedQuote.id,
      product_code: selectedQuote.product_code,
      term: selectedQuote.term,
      pay_term: selectedQuote.pay_term,
      frequency: selectedQuote.frequency,
      smoker: selectedQuote.smoker,
      gender: proposerDetails.gender.value,
      age: calculate_age(proposerDetails.dob.value),
      state: selectedQuote.state,
      city: selectedQuote.city,
      sum_assured: selectedQuote.sum_insured,
      modal_factor: selectedQuote.modal_factor,
      product_desc: selectedQuote.product_desc,
      buy_online_code: selectedQuote.buy_online_code,
      policy_covers: selectedQuote.policy_covers,
      cover_upto: selectedQuote.cover_upto,
      cover_pay_upto: selectedQuote.cover_pay_upto,
      request_for: "PREMREC",
      monthly_payout: selectedQuote.monthly_payout,
    };

    PROPOSAL_PAGE_SERVICES.termGetPremiumICICI(onSuccess, onError, data);
  };

  const updateProposerDetailsData = (
    insuredMemberDetails: TInsuredMemberDetails,
    proposerDetails: TProposerDetails
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;

      if (error === false) {
        createPaymentLink();
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    const dob = `${FORMAT_YYYY_MM_DD(proposerDetails.dob.value)}`.split("-");
    const insured_dob = `${FORMAT_YYYY_MM_DD(
      insuredMemberDetails.dob.value
    )}`.split("-");
    const data: ProposerDetailsAPIDTO = {
      buy_online_code: selectedQuote.buy_online_code,
      quote_no: btoa(selectedQuote.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.PROPOSAL_DETAILS,
      details: {
        insured_date: insured_dob[1],
        insured_month: insured_dob[2],
        insured_year: insured_dob[0],
        proposer_date: dob[1],
        proposer_month: dob[2],
        proposer_year: dob[0],
        proposer_relationship:
          proposerDetails.is_proposer_is_insured_member === true ? "Yes" : "No",
        aadhar_number: proposerDetails.aadhar_number.value,
        insured_aadhar: insuredMemberDetails.aadhar_number.value,
        insured_dob: FORMAT_YYYY_MM_DD(insuredMemberDetails.dob.value),
        insured_email: insuredMemberDetails.email.value,
        insured_gender: insuredMemberDetails.gender.value,
        insured_marital_status: insuredMemberDetails.marital_status.value.code,
        insured_mobile: insuredMemberDetails.mobile.value,
        insured_name: insuredMemberDetails.full_name.value,
        insured_occupation: insuredMemberDetails.occupation.value.code,
        insured_nameOfOrg: insuredMemberDetails.org_name.value.code,
        insured_nameOfOrgDesc: insuredMemberDetails.org_desc.value,
        premium: selectedQuote.premium,
        proposer_annual_income: proposerDetails.annual_income.value.code,
        proposer_dob: FORMAT_YYYY_MM_DD(proposerDetails.dob.value),
        proposer_email: proposerDetails.email.value,
        proposer_emergency_no: proposerDetails.emergency_number.value,
        proposer_gender: proposerDetails.gender.value,
        proposer_marital_status: proposerDetails.marital_status.value.code,
        proposer_mobile: proposerDetails.mobile.value,
        proposer_name: proposerDetails.full_name.value,
        proposer_education: proposerDetails.education.value.code,
        proposer_occupation: proposerDetails.occupation.value.code,
        proposer_occDesc: proposerDetails.occupation_desc.value,
        proposer_nameOfOrg: proposerDetails.org_name.value.code,
        proposer_nameOfOrgDesc: proposerDetails.org_description.value,
        proposer_myProf: proposerDetails.my_profession.value.code,
        proposer_indsType: proposerDetails.industry_type.value.code,
        proposer_indsTypeDesc: proposerDetails.industry_type_desc.value,
        proposer_orgType: proposerDetails.org_type.value.code,
        proposer_orgTypeDesc: proposerDetails.org_type_desc.value,
        proposer_objBuyingPolicy:
          proposerDetails.obj_of_buying_policy.value.code,
        insured_education: insuredMemberDetails.education.value.code,
      },
    };
    setLoader(true);

    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  const createPaymentLink = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const PAYMENT_URL = response.paymentUrl;

        dispatch(TermSlice.actions.SAVE_PAYMENT_URL(PAYMENT_URL));
        window.location.href = PAYMENT_URL;
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    const data: CreatePaymentLinkDTO = {
      buy_online_code: selectedQuote.buy_online_code,
      product_id: `${selectedQuote.id}`,
      product_name: selectedQuote.product_name,
      quote_no: btoa(selectedQuote.quote_no),
    };
    PROPOSAL_PAGE_SERVICES.createPaymentLink(onSuccess, onError, data);
  };

  const getInsuranceCompanyMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TSearchableDropdown[] = response.map((obj: any) => {
          return {
            code: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermSlice.actions.setInsuranceCompany(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {};
    PROPOSAL_PAGE_SERVICES.getInsuranceCompanyMaster(
      onSuccess,
      onError,
      selectedQuote.company_code,
      selectedQuote.product_type_code
    );
  };

  return (
    <>
      {isMobile ? (
        <MProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <ProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default ProposerDetailsContainer;
