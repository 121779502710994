export enum TW_ROUTES {
  QUOTE = "/quote-page",
  POLICY_DETAILS = "/policy-details",
  PROPOSAL_PAGE = "/tw-proposal-page",
  CKYC = "ckyc-verification",
  OWNER_DETAILS = "/owner-details",
  VEHICLE_DETAILS = "/vehicle-details",
  NOMINEE_DETAILS = "/nominee-details",
  ADDRESS_DETAILS = "/address-details",
  REVIEW_PAGE = "/preview-details",
}
