import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const UploadDocumentDetails = () => {
  return (
    <Box>
      <h5 className="sectionTitle">Upload Documents</h5>
      <Grid container>
        <Grid xs={12}>
          <h6 className="question">Please note:</h6>
          <p className=" document_p">
            Upload your documents for quicker and hassle free processing.
          </p>
          <ul className="textGrey ml-5">
            <li>Maximum file size should be 5 MB.</li>
            <li>Supported Formats are JPEG, PDF, TIF and PNG..</li>
            <li>
              If you have proofs in any other language apart from English,
              please share / upload an attested English version along with your
              original proof.
            </li>
            <li>
              You can click photos with your mobile phone and upload them.
              Please ensure that the image is clear and easy to read.
            </li>
            <li>
              If certain information is present on the back side of the
              document, then upload back side image as well.
            </li>
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadDocumentDetails;
