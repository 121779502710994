import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MedicalQuestion.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

const MedicalQuestion = ({
  main_class,
  icon_class,
  question_name,
  value_update,
  toggle_button_status,
  attrName,
  sub_details,
  any_question_or_sub_question,
  hide_toggle_button = false,
  ref,
}: {
  main_class?: string;
  icon_class?: string;
  question_name: string;
  toggle_button_status?: boolean;
  value_update: Function;
  attrName: any;
  sub_details?: string;
  hide_toggle_button?: boolean;
  any_question_or_sub_question?: any;
  ref?: any;
}) => {
  return (
    <Box className={main_class} ref={ref}>
      <Grid container columnSpacing={2} rowSpacing={0} alignItems="center">
        <Grid xs={9} className="medicalQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{question_name}</h6>
            {sub_details}
          </div>
        </Grid>
        <Grid xs={3} textAlign="right" className="medicalQuestiontoggleSwitch">
          {hide_toggle_button === false ? (
            <ToggleSwitch
              toggle_status={toggle_button_status}
              value_update={value_update}
              attrName={attrName}
            />
          ) : null}
        </Grid>
      </Grid>
      {any_question_or_sub_question}
    </Box>
  );
};

export default MedicalQuestion;
