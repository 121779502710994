import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PreviewDetails from "../../../../../Page/Desktop/Term/ICICI/Proposal/PreviewDetails/PreviewDetails";
import MPreviewDetails from "../../../../../Page/Mobile/Term/ICICI/Proposal/PreviewDetails/MPreviewDetails";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { ProposerSubmissionDTO } from "../../../../../Services/DTO/Term/ProposalSubmissionDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { ETermLandingPage } from "../../../../../State/Enum/ETermLandingPage";
import { PROPOSAL_PAGE_SECTION_STATUS_API } from "../../../../../State/Types/TProposerDetails";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  FORMAT_DD_MMM_YYYY_HH_MM_SS,
  FORMAT_DD_MM_YYYY,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TOTPDetails } from "../../../../../State/Types/TOTPDetails";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";

function PreviewPageContainer() {
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { selectedQuote, OTP_DETAILS } = useAppSelector((state) => state.Term);
  const dropdownData = useAppSelector((state) => state.Term.dropdownData);
  const proposer_details = useAppSelector(
    (state) => state.Term.proposerDetails
  );
  const insured_member_details = useAppSelector(
    (state) => state.Term.insuredDetails
  );
  const nominee_details = useAppSelector((state) => state.Term.nomineeDetails);
  const address_details = useAppSelector((state) => state.Term.addressDetails);
  const previewDetails = [
    {
      title: "Proposer Details",
      editFunction: () => {
        navigate(TERM_PATH.PROPOSER_DETAILS);

        dispatch(TermSlice.actions.updateUpdateStatus(true));
      },
      detailView: [
        {
          label: "Full Name",
          label_value: proposer_details.full_name.value,
        },
        {
          label: "DOB",
          label_value: FORMAT_DD_MM_YYYY(`${proposer_details.dob.value}`),
        },
        {
          label: "Gender",
          label_value: dropdownData.genderData.find(
            (item) => item.key === proposer_details.gender.value
          )?.value,
        },
        {
          label: "Mobile",
          label_value: proposer_details.mobile.value,
        },
        {
          label: "Emergency Phone",
          label_value: proposer_details.emergency_number.value,
        },
        {
          label: "Email",
          label_value: proposer_details.email.value,
        },
        {
          label: "Aadhar",
          label_value:
            "XXXX XXXX " + `${proposer_details.aadhar_number.value}`.slice(-4),
        },
        {
          label: "Marital Status",
          label_value:
            proposer_details.marital_status.value?.label ??
            proposer_details.marital_status.value,
        },
        {
          label: "Occupation",
          label_value:
            proposer_details.occupation.value?.label ??
            proposer_details.occupation.value,
        },
      ],
    },
    {
      title: "Insured Member Details",
      editFunction: () => {
        navigate(TERM_PATH.PROPOSER_DETAILS);

        dispatch(TermSlice.actions.updateUpdateStatus(true));
      },
      detailView: [
        {
          label: "Full Name",
          label_value: insured_member_details.full_name.value,
        },
        {
          label: "DOB",
          label_value: FORMAT_DD_MM_YYYY(`${insured_member_details.dob.value}`),
        },
        {
          label: "Gender",
          label_value: dropdownData.genderData.find(
            (item) => item.key === insured_member_details.gender.value
          )?.value,
        },
        {
          label: "Mobile",
          label_value: insured_member_details.mobile.value,
        },
        {
          label: "Email",
          label_value: insured_member_details.email.value,
        },
        {
          label: "Aadhar",
          label_value:
            "XXXX XXXX " +
            `${insured_member_details.aadhar_number.value}`.slice(-4),
        },
        {
          label: "Marital Status",
          label_value:
            insured_member_details.marital_status.value?.label ??
            insured_member_details.marital_status.value,
        },
        {
          label: "Occupation",
          label_value:
            insured_member_details.occupation.value?.label ??
            insured_member_details.occupation.value,
        },
      ],
    },
    nominee_details.is_beneficiary === true
      ? {}
      : {
          title: "Nominee Details",
          editFunction: () => {
            navigate(TERM_PATH.NOMINEE_DETAILS);

            dispatch(TermSlice.actions.updateUpdateStatus(true));
          },
          detailView: [
            {
              label: "Full Name",
              label_value: nominee_details.full_name.value,
            },
            {
              label: "Relationship with Proposer",
              label_value: dropdownData.nomineeRelationshipData.find(
                (item) => item.key === nominee_details.relationship.value
              )?.value,
            },
            {
              label: "DOB",
              label_value: FORMAT_DD_MM_YYYY(`${nominee_details.dob.value}`),
            },
          ],
        },
  ];

  const permanentAddressDetails = {
    title: "Address Details",
    editFunction: () => {
      navigate(TERM_PATH.ADDRESS_DETAILS);

      dispatch(TermSlice.actions.updateUpdateStatus(true));
    },
    detailView: [
      {
        label: "H.No./Building",
        label_value: address_details.permanent_address.house_no_building.value,
      },
      {
        label: "Street/Area",
        label_value: address_details.permanent_address.area_town_locality.value,
      },
      {
        label: "Pincode",
        label_value: address_details.permanent_address.pincode.value,
      },
      {
        label: "City",
        label_value: address_details.permanent_address.city,
      },
      {
        label: "State",
        label_value: address_details.permanent_address.state,
      },
    ],
  };

  const mailingAddressDetails = {
    title: "Address Details",
    detailView: [
      {
        label: "H.No./Building",
        label_value: address_details.mailing_address.house_no_building.value,
      },
      {
        label: "Street/Area",
        label_value: address_details.mailing_address.area_town_locality.value,
      },
      {
        label: "Pincode",
        label_value: address_details.mailing_address.pincode.value,
      },
      {
        label: "City",
        label_value: address_details.mailing_address.city,
      },
      {
        label: "State",
        label_value: address_details.mailing_address.state,
      },
    ],
  };

  // useEffect(() => {
  //   if (`${OTP_DETAILS.otp}`.length === 6) {
  //     updateOTPDetailsData();
  //   }
  // }, [OTP_DETAILS.otp]);

  const updateOTPDetailsData = (OTP_data: TOTPDetails) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const response = results.response;
      const error = results.error;

      if (error === false) {
        proposalSubmissionICICI();
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    const data = {
      buy_online_code: selectedQuote.buy_online_code,
      quote_no: btoa(selectedQuote.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.OTP_DETAILS,
      details: {
        otpReqCreatedTime: FORMAT_DD_MMM_YYYY_HH_MM_SS(OTP_data.created),
        otpReqUpdatedTime: FORMAT_DD_MMM_YYYY_HH_MM_SS(OTP_data.updated),
        otpReqOtpNo: `${OTP_data.otp}`,
        otpReqStatus: "Verified",
        otpReqExpiryTimeInMin: "30",
      },
    };

    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  const proposalSubmissionICICI = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        dispatch(
          TermSlice.actions.updateQuoteData([
            { key: "application_no", value: response.transactionId },
            { key: "documentURL", value: response.documentURL },
          ])
        );
        navigate(TERM_PATH.POLICY_CONFIRMATION);
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: ProposerSubmissionDTO = {
      buy_online_code: selectedQuote.buy_online_code,
      product_id: `${selectedQuote.id}`,
      quote_no: btoa(selectedQuote.quote_no),
      product_name: selectedQuote.product_name,
    };
    setLoader(true);

    PROPOSAL_PAGE_SERVICES.investmentProposalSubmission(
      onSuccess,
      onError,
      data
    );
  };

  //HDFC Preview Submission

  useEffect(() => {
    getTermLandingPage(selectedQuote.quote_no, selectedQuote.buy_online_code);
  }, []);

  const getTermLandingPage = (quote_no: string, buy_online_code: string) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (response.currentPage) {
          case ETermLandingPage.UPLOAD_DOCUMENT:
            navigate(TERM_PATH.UPLOAD_DOCUMENT);
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getTermLandingPage(onSuccess, onError, {
      quote_no: btoa(quote_no),
      buy_online_code: buy_online_code,
    });
  };

  return (
    <>
      {isMobile ? (
        <MPreviewDetails
          loader={loader}
          PreviewDetails={previewDetails}
          permanentAddressDetails={permanentAddressDetails}
          mailingAddressDetails={mailingAddressDetails}
          address_details={address_details}
          continueFunction={updateOTPDetailsData}
        />
      ) : (
        <PreviewDetails
          loader={loader}
          PreviewDetails={previewDetails}
          permanentAddressDetails={permanentAddressDetails}
          mailingAddressDetails={mailingAddressDetails}
          address_details={address_details}
          continueFunction={updateOTPDetailsData}
        />
      )}
    </>
  );
}

export default PreviewPageContainer;
