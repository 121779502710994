import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LeftSidebar from "../../../Component/Desktop/Term/ProposalForm/LeftSidebar/LeftSidebar";
import Navbar from "../../../Component/Desktop/Term/ProposalForm/Navbar/Navbar";
import MNavbar from "../../../Component/Mobile/Term/ProposalForm/Navbar/MNavbar";
import PaymentConfirmationPageContainer from "../../../Container/Term/HDFC/PaymentConfirmationPage/PaymentConfirmationPageContainer";
import PolicyConfirmationPageContainer from "../../../Container/Term/HDFC/PolicyConfirmationPage/PolicyConfirmationPageContainer";
import AddressDetailsContainer from "../../../Container/Term/HDFC/ProposalPage/AddressDetailsContainer/AddressDetailsContainer";
import CKYCContainer from "../../../Container/Term/HDFC/ProposalPage/CKYCContainer/CKYCContainer";
import JourneyRequirementContainer from "../../../Container/Term/HDFC/ProposalPage/JourneyRequirementContainer/JourneyRequirementContainer";
import MedicalPageContainer from "../../../Container/Term/HDFC/ProposalPage/MedicalPageContainer/MedicalPageContainer";
import NomineeDetailsContainer from "../../../Container/Term/HDFC/ProposalPage/NomineeDetailsConatiner/NomineeDetailsContainer";
import PreviewPageContainer from "../../../Container/Term/HDFC/ProposalPage/PreviewPageContainer/PreviewPageContainer";
import ProposerDetailsContainer from "../../../Container/Term/HDFC/ProposalPage/ProposerDetailsContainer/ProposerDetailsContainer";
import UploadDocumentContainer from "../../../Container/Term/HDFC/ProposalPage/UploadDocumentContainer/UploadDocumentContainer";
import { TTermHDFCMedicalQuestionDetails } from "../../../State/Types/TTermMedicalQuestionDetails";
import { useAppDispatch } from "../../../State/hooks";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TERM_PATH } from "../../../RoutesPath/TermPath";
import RidersBenefitsContainer from "../../../Container/Term/HDFC/ProposalPage/RidersBenefitsContainer/RidersBenefitsContainer";
import { TermSlice } from "../../../State/Slice_Reducer/Term/TermSlice";

const HDFC_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={TERM_PATH.PROPOSER_DETAILS}
        element={<ProposerDetailsContainer />}
      />
      <Route
        path={TERM_PATH.RIDERS_BENEFITS}
        element={<RidersBenefitsContainer />}
      />
      <Route
        path={TERM_PATH.COFIRMATION_PAGE}
        element={<PaymentConfirmationPageContainer />}
      />
      <Route
        path={TERM_PATH.JOURNEY_REQUIREMENT}
        element={<JourneyRequirementContainer />}
      />
      <Route
        path={TERM_PATH.NOMINEE_DETAILS}
        element={<NomineeDetailsContainer />}
      />
      <Route
        path={TERM_PATH.ADDRESS_DETAILS}
        element={<AddressDetailsContainer />}
      />
      <Route path={TERM_PATH.CKYC} element={<CKYCContainer />} />
      <Route
        path={TERM_PATH.UPLOAD_DOCUMENT}
        element={<UploadDocumentContainer />}
      />
      <Route path={TERM_PATH.MEDICAL_PAGE} element={<MedicalPageContainer />} />
      <Route path={TERM_PATH.PREVIEW_PAGE} element={<PreviewPageContainer />} />
      <Route
        path={TERM_PATH.POLICY_CONFIRMATION}
        element={<PolicyConfirmationPageContainer />}
      />
    </Routes>
  );
};

function HDFCRoutes({ medicalData }: { medicalData: any }) {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setHDFCMedicalQuestion(medicalData);
  }, [medicalData]);

  const setHDFCMedicalQuestion = (medical_details: any) => {
    const data: TTermHDFCMedicalQuestionDetails = {
      height_ft: { value: medical_details.heightFeet, warning: false },
      height_inches: { value: "", warning: false },
      height_cm: { value: "", warning: false },
      weight_kg: { value: medical_details.weight, warning: false },

      question: [
        {
          main_question: {
            code: "PQ01",
            icon_class: "tobaco",
            title: "Existing cover",
            description:
              "Do you have any existing life insurance cover of premium paying and/ or paid-up policies?",
            status: medical_details.coverPremium === "Yes",
            field_data: [
              {
                code: "PQ01-1",
                title: "A) Sum assured payable on death",
                value: medical_details.uwDeathSumAssured,
                warning: false,
                field_type: "textField",
                numbers_only: false,
                column: 6,
              },
              {
                code: "PQ01-2",
                title:
                  "B) Sum assured payable on accidental death (excluding A)",
                value: medical_details.uwAccidentalSumAssured,
                warning: false,
                field_type: "textField",
                numbers_only: false,
                column: 6,
              },
              {
                code: "PQ01-3",
                title: "C) Benefits payable on disability/CI",
                value: medical_details.uwBenifit,
                warning: false,
                field_type: "textField",
                numbers_only: false,
                column: 6,
              },
              {
                code: "PQ01-4",
                title:
                  "D) How much of the cover A+B+C was taken out in last 12 months?",
                value: medical_details.uwCoverMonths,
                warning: false,
                field_type: "textField",
                numbers_only: false,
                column: 6,
              },
              {
                code: "PQ01-5",
                title:
                  "E) How much of the cover A was taken out during the last five years?",
                value: medical_details.uwCoverYears,
                warning: false,
                field_type: "textField",
                numbers_only: false,
                column: 6,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ02",
            icon_class: "tobaco",
            title: "Simultaneous applications",
            description:
              "Have you submitted any simultaneous application for life insurance company, which is still pending or are you likely to revive lapsed policies?",
            status: medical_details.simultaneous === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ02-1",
                title: "Please select any simultaneous",
                value: medical_details.uwSimultaneous,
                warning: false,
                field_type: "dropdown",
                numbers_only: false,
              },
              {
                code: "PQ02-2",
                title:
                  "Sum Assured payable on death (Already proposed/to be processed)",
                value: medical_details.uwSimaltaneousSumAssured,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ02-3",
                title: "Name of the company",
                value: "",
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ02-4",
                title: "Type of Product",
                value: "",
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ02-5",
                title: "Purpose of cover",
                value: "",
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ03",
            icon_class: "tobaco",
            title: "Decisions on previous applications",
            description:
              "Has any application for insurance on your life been postponed?",
            status: medical_details.lifePostponed === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ03-1",
                title: "Policy Number",
                value: medical_details.uwPostponedPolicyNumber,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ03-2",
                title: "Name of insurance company",
                value: medical_details.uwPostponedCompany,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ03-3",
                title: "Reason",
                value: medical_details.uwPostponedReason,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ04",
            icon_class: "tobaco",
            title:
              "Has any application for insurance on your life been accepted with extra premium?",
            status: medical_details.lifeExtraPremium === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ04-1",
                title: "Policy Number",
                value: medical_details.uwPremiumPolicyNumber,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ04-2",
                title: "Name of insurance company",
                value: medical_details.uwPremiumCompany,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ04-3",
                title: "Reason",
                value: medical_details.uwPremiumReason,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ05",
            icon_class: "tobaco",
            title:
              "Has any application for insurance on your life been accepted on other special terms?",
            status:
              medical_details.lifeSpecialTermPremium === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ05-1",
                title: "Policy Number",
                value: medical_details.uwSpecialTermPolicyNumber,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ05-2",
                title: "Name of insurance company",
                value: medical_details.uwSpecialTermCompany,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ05-3",
                title: "Reason",
                value: medical_details.uwSpecialTermReason,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ06",
            icon_class: "tobaco",
            title:
              "Has any application for insurance on your life been declined?",
            status: medical_details.lifeDeclined === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ06-1",
                title: "Policy Number",
                value: medical_details.uwDeclinedPolicyNumber,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ06-2",
                title: "Name of insurance company",
                value: medical_details.uwDeclinedCompany,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ06-3",
                title: "Reason",
                value: medical_details.uwDeclinedReason,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ07",
            icon_class: "tobaco",
            title:
              "Has any application for insurance on your life been withdrawn by you?",
            status: medical_details.lifeWithdrawn === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ07-1",
                title: "Policy Number",
                value: medical_details.uwWithdrawnPolicyNumber,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ07-2",
                title: "Name of insurance company",
                value: medical_details.uwWithdrawnCompany,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ07-3",
                title: "Reason",
                value: medical_details.uwWithdrawnReason,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ08",
            icon_class: "tobaco",
            title: "Travel",
            status: false,
          },
          sub_question: [
            {
              code: "PQ08-SQ01",
              icon_class: "tobaco",
              title:
                "Have you resided overseas for more than six months continuously during the last five years?",
              status: medical_details.lifeROverseas === "Yes" ? true : false,
              field_data: [
                {
                  code: "PQ08-SQ01-1",
                  title: "Name of countries for past travel",
                  value: medical_details.uwPastTravel,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "PQ08-SQ01-2",
                  title: "Duration of stay",
                  value: medical_details.uwStay,
                  warning: false,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
            {
              code: "PQ08-SQ02",
              icon_class: "tobaco",
              title: "Do you intend to reside overseas in the next six months?",
              status: false,
              field_data: [
                {
                  code: "PQ08-SQ02-1",
                  title: "Name of countries for future travel",
                  value: medical_details.uwFutureTravel,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "PQ08-SQ02-2",
                  title: "Duration of stay",
                  value: medical_details.uwFutureTravel,
                  warning: false,
                  field_type: "textField",
                  numbers_only: false,
                },
              ],
            },
          ],
        },
        {
          main_question: {
            code: "PQ09",
            icon_class: "tobaco",
            title: "Avocation",
            description:
              "Do you take part in any hobbies/ activities that could be considered dangerous in any way? E.g. aviation (other than as a fare-paying passenger), mountaineering, deep sea diving or any form of racing.",
            status: medical_details.avocation === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ09-1",
                title: "Please select hobbies",
                value: medical_details.uwAvocation,
                warning: false,
                field_type: "dropdown",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ20",
            icon_class: "tobaco",
            title: "Impairment questionnaire",
            status: false,
          },
          sub_question: [
            {
              code: "PQ20-SQ01",
              icon_class: "tobaco",
              title: "Have you ever suffered from",
              description:
                "Diabetes/ high blood sugar/ sugar in urine, High blood pressure/ hypertension, Heart disease, Stroke",
              status: medical_details.impairment1 === "Yes" ? true : false,
              field_data: [
                {
                  code: "PQ20-SQ01-1",
                  title: "Please select",
                  value: medical_details.uwimpairment1,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                  column: 8,
                },
              ],
            },
            {
              code: "PQ20-SQ02",
              icon_class: "tobaco",
              title: "Have you ever suffered from",
              description:
                "Respiratory disorders, Arthritis, Back problems, Tuberculosis, Any recurrent medical condition/ disability.",
              status: medical_details.impairment2 === "Yes" ? true : false,
              field_data: [
                {
                  code: "PQ09-SQ02-1",
                  title: "Please select",
                  value: medical_details.uwimpairment2,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                  column: 8,
                },
              ],
            },
            {
              code: "PQ20-SQ03",
              icon_class: "tobaco",
              title: "Have you ever suffered from",
              description:
                "Liver disorder, Kidney disorder, Disorder of the digestive system, Abnormality of thyroid, Blood disorder",
              status: medical_details.impairment3 === "Yes" ? true : false,
              field_data: [
                {
                  code: "PQ09-SQ03-1",
                  title: "Please select",
                  value: medical_details.uwimpairment3,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                  column: 8,
                },
              ],
            },
            {
              code: "PQ20-SQ04",
              icon_class: "tobaco",
              title: "Have you ever suffered from",
              description:
                "Epilepsy, Any nervous disorder or mental condition, Paralysis or multiple sclerosis, Depression or psychiatric disorder, Cancer or a tumor",
              status: medical_details.impairment4 === "Yes" ? true : false,
              field_data: [
                {
                  code: "PQ09-SQ04-1",
                  title: "Please select",
                  value: medical_details.uwimpairment4,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                  column: 8,
                },
              ],
            },
            {
              code: "PQ20-SQ05",
              icon_class: "tobaco",
              title: "Have you ever suffered from",
              description: "Dengue, Swine Flu, Encephalitis",
              status: medical_details.impairment5 === "Yes" ? true : false,
              field_data: [
                {
                  code: "PQ09-SQ05-1",
                  title: "Please select",
                  value: medical_details.uwimpairment5,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                  column: 8,
                },
              ],
            },
          ],
        },
        {
          main_question: {
            code: "PQ10",
            icon_class: "tobaco",
            title:
              "Do you have any physical disability which is affecting your day to day activities?",
            status: medical_details.physicalDisability === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ10-1",
                title: "Impairment",
                value: medical_details.uwPhysicalDisabilityImpairment,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ10-2",
                title: "Date of diagnosis / event",
                value: medical_details.uwPhysicalDisabilityDiagnosisDate,
                warning: false,
                field_type: "datePicker",
                numbers_only: false,
              },
              {
                code: "PQ10-3",
                title: "Name and address of the Doctor",
                value: medical_details.uwPhysicalDisabilityAddressDoctor,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ10-4",
                title: "Details of investigation done",
                value: medical_details.uwPhysicalDisabilityInvestigationDetails,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
          sub_question: [
            {
              code: "PQ10-SQ01",
              icon_class: "tobaco",
              title: "Under medication?",
              status:
                medical_details.uwPhysicalDisabilityUnderMedication === "Yes"
                  ? true
                  : false,
            },
            {
              code: "PQ10-SQ02",
              icon_class: "tobaco",
              title: "Fully recovered?",
              status:
                medical_details.uwPhysicalDisabilityFullyRecovered === "Yes"
                  ? true
                  : false,
            },
          ],
        },
        {
          main_question: {
            code: "PQ11",
            icon_class: "tobaco",
            title:
              "Are you currently suffering from any illness, impairment or taking any medication or pills or drugs?",
            status: medical_details.illnessDrugs === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ11-1",
                title: "Impairment",
                value: medical_details.uwillnessDrugsImpairment,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ11-2",
                title: "Date of diagnosis / event",
                value: medical_details.uwillnessDrugsDiagnosisDate,
                warning: false,
                field_type: "datePicker",
                numbers_only: false,
              },
              {
                code: "PQ11-3",
                title: "Name and address of the Doctor",
                value: medical_details.uwillnessDrugsAddressDoctor,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ11-4",
                title: "Details of investigation done",
                value: medical_details.uwillnessDrugsInvestigationDetails,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
          sub_question: [
            {
              code: "PQ11-SQ01",
              icon_class: "tobaco",
              title: "Under medication?",
              status:
                medical_details.uwillnessDrugsUnderMedication === "Yes"
                  ? true
                  : false,
            },
            {
              code: "PQ11-SQ02",
              icon_class: "tobaco",
              title: "Fully recovered?",
              status:
                medical_details.uwillnessDrugsFullyRecovered === "Yes"
                  ? true
                  : false,
            },
          ],
        },
        {
          main_question: {
            code: "PQ12",
            icon_class: "tobaco",
            title:
              "Have you ever been tested positive for HIV/ AIDS or Hepatitis B or C, or have you been tested/ treated for other sexually transmitted disease or are you awaiting the results of such a test?",
            status: medical_details.sexuallyDisease === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ12-1",
                title: "Impairment",
                value: medical_details.uwsexuallyDiseaseImpairment,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ12-2",
                title: "Date of diagnosis / event",
                value: medical_details.uwsexuallyDiseaseDiagnosisDate,
                warning: false,
                field_type: "datePicker",
                numbers_only: false,
              },
              {
                code: "PQ12-3",
                title: "Name and address of the Doctor",
                value: medical_details.uwsexuallyDiseaseAddressDoctor,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ12-4",
                title: "Details of investigation done",
                value: medical_details.uwsexuallyDiseaseInvestigationDetails,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
          sub_question: [
            {
              code: "PQ12-SQ01",
              icon_class: "tobaco",
              title: "Under medication?",
              status:
                medical_details.uwsexuallyDiseaseUnderMedication === "Yes"
                  ? true
                  : false,
            },
            {
              code: "PQ12-SQ02",
              icon_class: "tobaco",
              title: "Fully recovered?",
              status:
                medical_details.uwsexuallyDiseaseFullyRecovered === "Yes"
                  ? true
                  : false,
            },
          ],
        },
        {
          main_question: {
            code: "PQ13",
            icon_class: "tobaco",
            title:
              "Do you have/ had any recurrent medical condition or physical disability or illness or injury that has kept you from working for more than one week in last 5 years?",
            status: medical_details.recurrentMedical === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ13-1",
                title: "Impairment",
                value: medical_details.uwrecurrentMedicalImpairment,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ13-2",
                title: "Date of diagnosis / event",
                value: medical_details.uwrecurrentMedicalDiagnosisDate,
                warning: false,
                field_type: "datePicker",
                numbers_only: false,
              },
              {
                code: "PQ13-3",
                title: "Name and address of the Doctor",
                value: medical_details.uwrecurrentMedicalAddressDoctor,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ13-4",
                title: "Details of investigation done",
                value: medical_details.uwrecurrentMedicalInvestigationDetails,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
          sub_question: [
            {
              code: "PQ13-SQ01",
              icon_class: "tobaco",
              title: "Under medication?",
              status:
                medical_details.uwrecurrentMedicalUnderMedication === "Yes"
                  ? true
                  : false,
            },
            {
              code: "PQ13-SQ02",
              icon_class: "tobaco",
              title: "Fully recovered?",
              status:
                medical_details.uwrecurrentMedicalFullyRecovered === "Yes"
                  ? true
                  : false,
            },
          ],
        },
        {
          main_question: {
            code: "PQ14",
            icon_class: "tobaco",
            title:
              "During last 5 years have you undergone or been recommended to undergo hospitalisation?",
            status: medical_details.hospitalisation === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ14-1",
                title: "Impairment",
                value: medical_details.uwhospitalisationImpairment,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ14-2",
                title: "Date of diagnosis / event",
                value: medical_details.uwhospitalisationDiagnosisDate,
                warning: false,
                field_type: "datePicker",
                numbers_only: false,
              },
              {
                code: "PQ14-3",
                title: "Name and address of the Doctor",
                value: medical_details.uwhospitalisationAddressDoctor,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ14-4",
                title: "Details of investigation done",
                value: medical_details.uwhospitalisationInvestigationDetails,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
          sub_question: [
            {
              code: "PQ14-SQ01",
              icon_class: "tobaco",
              title: "Under medication?",
              status:
                medical_details.uwhospitalisationUnderMedication === "Yes"
                  ? true
                  : false,
            },
            {
              code: "PQ14-SQ02",
              icon_class: "tobaco",
              title: "Fully recovered?",
              status:
                medical_details.uwhospitalisationFullyRecovered === "Yes"
                  ? true
                  : false,
            },
          ],
        },
        {
          main_question: {
            code: "PQ15",
            icon_class: "tobaco",
            title:
              "During last 5 years have you undergone or been recommended to undergo operation?",
            status: medical_details.operation === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ15-1",
                title: "Impairment",
                value: medical_details.uwoperationImpairment,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ15-2",
                title: "Date of diagnosis / event",
                value: medical_details.uwoperationDiagnosisDate,
                warning: false,
                field_type: "datePicker",
                numbers_only: false,
              },
              {
                code: "PQ15-3",
                title: "Name and address of the Doctor",
                value: medical_details.uwoperationAddressDoctor,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ15-4",
                title: "Details of investigation done",
                value: medical_details.uwoperationInvestigationDetails,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
          sub_question: [
            {
              code: "PQ15-SQ01",
              icon_class: "tobaco",
              title: "Under medication?",
              status:
                medical_details.uwoperationUnderMedication === "Yes"
                  ? true
                  : false,
            },
            {
              code: "PQ15-SQ02",
              icon_class: "tobaco",
              title: "Fully recovered?",
              status:
                medical_details.uwoperationFullyRecovered === "Yes"
                  ? true
                  : false,
            },
          ],
        },
        {
          main_question: {
            code: "PQ16",
            icon_class: "tobaco",
            title:
              "During last 5 years have you undergone or been recommended to undergo X-ray any other investigation (excluding check-ups for employment/ insurance/ foreign visit)?",
            status:
              medical_details.investigationCheckup === "Yes" ? true : false,
            field_data: [
              {
                code: "PQ16-1",
                title: "Impairment",
                value: medical_details.uwinvestigationCheckupImpairment,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ16-2",
                title: "Date of diagnosis / event",
                value: medical_details.uwinvestigationCheckupDiagnosisDate,
                warning: false,
                field_type: "datePicker",
                numbers_only: false,
              },
              {
                code: "PQ16-3",
                title: "Name and address of the Doctor",
                value: medical_details.uwinvestigationCheckupAddressDoctor,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
              {
                code: "PQ16-4",
                title: "Details of investigation done",
                value:
                  medical_details.uwinvestigationCheckupInvestigationDetails,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
          sub_question: [
            {
              code: "PQ16-SQ01",
              icon_class: "tobaco",
              title: "Under medication?",
              status:
                medical_details.uwinvestigationCheckupUnderMedication === "Yes"
                  ? true
                  : false,
            },
            {
              code: "PQ16-SQ02",
              icon_class: "tobaco",
              title: "Fully recovered?",
              status:
                medical_details.uwinvestigationCheckupFullyRecovered === "Yes"
                  ? true
                  : false,
            },
          ],
        },
        {
          main_question: {
            code: "PQ17",
            icon_class: "tobaco",
            title: "Please provide the following Usual Doctor details",
            status: true,
            hide_toggle_button: true,
            field_data: [
              {
                code: "PQ17-1",
                title:
                  "We may require you to undergo medical examinations/tests",
                value: medical_details.uwMedicalLocation,
                warning: false,
                field_type: "dropdown",
                numbers_only: false,
              },
              {
                code: "PQ17-2",
                title: "State the name, address, and telephone number",
                value: medical_details.uwDoctorDetails,
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ18",
            icon_class: "tobaco",
            title: "Alcohol, tobacco and narcotics",
            status: false,
          },
          sub_question: [
            {
              code: "PQ18-SQ01",
              icon_class: "tobaco",
              title: "Do you consume Alcohol?",
              status: medical_details.alcohol === "Yes" ? true : false,
              field_data: [
                {
                  code: "PQ18-SQ01-1",
                  title: "How often do you consume alchohol?",
                  value: medical_details.consume_alcohal,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "PQ18-SQ01-2",
                  title: "How many units of others do you consume per week?",
                  value: medical_details.quantity,
                  warning: false,
                  field_type: "textField",
                  numbers_only: true,
                  column: 6,
                },
              ],
            },
            {
              code: "PQ18-SQ02",
              icon_class: "tobaco",
              title: "Do you tobacco products?",
              status: false,
              field_data: [
                {
                  code: "PQ18-SQ02-1",
                  title: "Type",
                  value: medical_details.consume_tobacco,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "PQ18-SQ02-2",
                  title: "How many sticks?",
                  value: medical_details.consume_tobacco,
                  warning: false,
                  field_type: "textField",
                  numbers_only: true,
                },
              ],
            },
            {
              code: "PQ18-SQ03",
              icon_class: "tobaco",
              title:
                "Are you currently consuming or have you ever consumed narcotics or any such other substance whether prescribed or not?",
              status: medical_details.uwnarcotics === "Yes" ? true : false,
            },
          ],
        },
        {
          main_question: {
            code: "PQ19",
            icon_class: "tobaco",
            title: "Family history",
            status: false,
          },
          sub_question: [
            {
              code: "PQ19-SQ01",
              icon_class: "tobaco",
              title:
                "Are any of your family members suffering from / have suffered from / have died of",
              description:
                "Heart disease?, High blood pressure?, Stroke?, Diabetes?, Kidney disease?, Cancer?, HIV/AIDS?",
              status: medical_details.familyHistory === "Yes" ? true : false,
              field_data: [
                {
                  code: "PQ19-SQ01-1",
                  title: "Relation to life to be assured",
                  value: medical_details.uwmagnumRelationShip,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "PQ19-SQ02-1",
                  title: "Disease",
                  value: medical_details.uwdisease,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "PQ19-SQ03-1",
                  title: "Age at diagnosis",
                  value: medical_details.uwDiagnoseAge,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "PQ19-SQ04-1",
                  title: "Alive/ Deceased",
                  value: medical_details.uwAlive,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
                {
                  code: "PQ19-SQ05-1",
                  title: "current age/ age when died",
                  value: medical_details.uwCurDiedAge,
                  warning: false,
                  field_type: "dropdown",
                  numbers_only: false,
                },
              ],
            },
          ],
        },
        {
          main_question: {
            code: "PQ20",
            icon_class: "tobaco",
            title: "Are you pregnant?",
            status: false,
            field_data: [
              {
                code: "PQ20-1",
                title: "How many weeks?",
                value: "",
                warning: false,
                field_type: "dropdown",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ21",
            icon_class: "tobaco",
            title:
              "Do you have a history of past Abortion, Miscarriage, Caesarian section or complications during pregnancy? Or have you given birth to a child with any congenital disorder like Down syndrome?",
            status: false,
            field_data: [
              {
                code: "PQ21-1",
                title: "Please give details",
                value: "",
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ22",
            icon_class: "tobaco",
            title:
              "Have you ever had any disease of uterus, cervix, or ovaries?",
            status: false,
            field_data: [
              {
                code: "PQ22-1",
                title: "Please give details",
                value: "",
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
        {
          main_question: {
            code: "PQ23",
            icon_class: "tobaco",
            title: "Have you ever undergone hysterectomy?",
            status: false,
            field_data: [
              {
                code: "PQ23-1",
                title: "Please give details",
                value: "",
                warning: false,
                field_type: "textField",
                numbers_only: false,
              },
            ],
          },
        },
      ],
      FormdataTermMedicalQuestionDetails: {
        designation: { value: medical_details.uwDesignation, warning: false },
        business_name: { value: medical_details.uwBuisness, warning: false },
        occupation_involve_status:
          medical_details.anyOccupation === "Y" ? true : false,
        occupation_involve_value: medical_details.uwOccupation,
        gross_annual_income: {
          value: medical_details.uwGrossIncome,
          warning: false,
        },
        nature_of_work: { value: medical_details.uwNatureWork, warning: false },
        industry_type: { value: medical_details.uwIndustry, warning: false },
        workplace_city: { value: medical_details.uwCity, warning: false },
        workplace_address: { value: medical_details.uwAddress, warning: false },
        identification_mark: { value: medical_details.uwName, warning: false },
      },
    };

    dispatch(TermSlice.actions.SET_HDFC_MEDICAL_QUESTION(data));
    dispatch(
      TermSlice.actions.SET_HDFC_FORM_DATA_MEDICAL(
        data.FormdataTermMedicalQuestionDetails
      )
    );
  };
  return (
    <>
      {isMobile ? (
        <BrowserRouter>
          <Box className="proposalWrapperMobile">
            <MNavbar />
            <Grid container spacing={0} className="d-non">
              <Grid xs={12} className="proposalContentSection">
                {HDFC_ROUTES()}
              </Grid>
            </Grid>
          </Box>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Box className="proposalWrapper">
            <Navbar />
            <Grid container spacing={3} className="pb-0">
              <LeftSidebar class_name="termIcon" />
              <Grid xs className="proposalContentSection">
                {HDFC_ROUTES()}
              </Grid>
            </Grid>
          </Box>
        </BrowserRouter>
      )}
    </>
  );
}

export default HDFCRoutes;
