// const DOMAIN = process.env.REACT_APP_BACKEND_URL;
// const NODE_DOMAIN = process.env.REACT_APP_NODE_BACKEND_URL;

const NODE_DOMAIN = "https://nodemofsl.evervent.in";
const DOMAIN = "https://dev.insurance.motilaloswal.com";

export const TW_URLs = {
  GET_DETAILS_BY_QUOTE_NO: `${NODE_DOMAIN}/api/v1/premium/quotedetail`,
  GET_SINGLE_QUOTE_COMPREHENSIVE: `${NODE_DOMAIN}/api/v1/premium/tw`,
  GET_SINGLE_QUOTE_SAOD: `${NODE_DOMAIN}/api/v1/premium/tw/saod`,
  GET_SINGLE_QUOTE_TP: `${NODE_DOMAIN}/api/v1/premium/tw/tp`,
  GET_CITY_STATE_BY_PINCODE: `${DOMAIN}/api/state-city-maps/get-details.json`,
  ADD_FORM_DATA: `${DOMAIN}/api/tw-insurances/add.json`,
  GET_PRODUCT_LIST: `${NODE_DOMAIN}/api/v1/premium/get-product-lists.json?type=TW`,
  GET_CASHLESS_GARAGE_LIST: `${DOMAIN}/api/cashless-hospital-details/get-cashless-garage-details.json`,
  GET_VEHICLE_NO_INFO: `${DOMAIN}/api/tw-insurances/get-vehicle-info.json`,
  GET_PREVIOUS_INSURER_LIST: `${DOMAIN}/api/previous-insurers/get-lists.json`,
  GET_VARIANT_LIST: `${DOMAIN}/api/tw-master-datas/get-variant-lists.json`,
  GET_FUEL_TYPE_LIST: `${DOMAIN}/api/tw-master-datas/get-fuel-lists.json?make_model=`,
  GET_MODEL_LIST: `${DOMAIN}/api/tw-master-datas/get-models-by-make.json`,
  GET_MAKE_LIST: `${DOMAIN}/api/tw-master-datas/get-lists.json`,
  GET_RTO_LIST: `${DOMAIN}/api/rto-product-maps/get-lists.json`,
  GET_MAKE_MODEL: `${DOMAIN}/api/tw-master-datas/get-lists.json`,
  UPDATE_DETAILS: `${NODE_DOMAIN}/api/v1/premium/update-policy-details`,
  GET_SINGLE_QUOTE: `${DOMAIN}/api/tw-insurances/get-single-quote.json`,
  UPDATE_VARIANTS_CC: `${NODE_DOMAIN}/api/v1/premium/update-variant`,
  UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-addon`,
  UPDATE_IDV: `${NODE_DOMAIN}/api/v1/premium/cusIDV`,
  SEND_QUOTE_VIA_EMAIL: `${DOMAIN}/api/tw-insurances/send-email-product-details.json`,
  PREMIUM_RECALCULATION: `${DOMAIN}/api/tw-insurances/recalculate-premium.json`,
  BUY_ACTION: `${NODE_DOMAIN}/api/v1/premium/buy-action`,
  BULK_UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-bulk-addon`,
};
