import "./ToggleSwitch.scss";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
const ToggleSwitch = ({
  toggle_status,
  value_update,
  attrName,
}: {
  toggle_status: boolean | undefined;
  value_update: Function;
  attrName: any;
}) => {
  return (
    <ToggleButtonGroup
      className="switch"
      value={toggle_status}
      exclusive
      onChange={(
        event: React.MouseEvent<HTMLElement>,
        newAlignment: boolean
      ) => {
        if (newAlignment !== null && newAlignment !== toggle_status) {
          if (value_update) {
            value_update(attrName, newAlignment);
          }
        }
      }}
      aria-label="text alignment"
    >
      <ToggleButton value={true}>Yes</ToggleButton>
      <ToggleButton value={false}>No</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleSwitch;
