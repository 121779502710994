import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { THDFCCKYCDetails } from "../../../Types/TCKYCDetails";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";

const SET_HDFC_CKYC_DETAILS = (
  state: TTermSlice,
  action: PayloadAction<THDFCCKYCDetails>
) => {
  const data: TTermSlice = { ...state, HDFCCKYCDetails: { ...action.payload } };
  return data;
};

export const TERM_HDFC_CKYC_REDUCER = {
  SET_HDFC_CKYC_DETAILS,
};
