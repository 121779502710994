import { PayloadAction } from "@reduxjs/toolkit";
import {
  TFormdataTermMedicalQuestionDetails,
  TTermHDFCMedicalQuestionDetails,
} from "../../../Types/TTermMedicalQuestionDetails";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";

function SET_HDFC_MEDICAL_QUESTION(
  state: TTermSlice,
  action: PayloadAction<TTermHDFCMedicalQuestionDetails>
) {
  const data: TTermSlice = {
    ...state,
    TermHDFCMedicalQuestion: { ...action.payload },
  };
  return data;
}

function SET_HDFC_FORM_DATA_MEDICAL(
  state: TTermSlice,
  action: PayloadAction<TFormdataTermMedicalQuestionDetails>
) {
  const data: TTermSlice = {
    ...state,
    TermHDFCMedicalQuestion: {
      ...state.TermHDFCMedicalQuestion,
      FormdataTermMedicalQuestionDetails: { ...action.payload },
    },
  };
  return data;
}

export const TERM_HDFC_MEDICAL_QUESTION_REDUCERS = {
  SET_HDFC_MEDICAL_QUESTION,
  SET_HDFC_FORM_DATA_MEDICAL,
};
