import axios from "axios";
import { GetAPI, PostAPI } from "../AxiosAPIInstance/API";
// import { URL_CONSTANTS } from "../../Shared/URLS";
import { LOCALSOTRAGE_KEYS_CONSTANTS } from "../../Shared/constants";
import { URL_CONSTANTS } from "../../Shared/URLS";

async function getAccessTokken(onSuccess: Function) {
  await axios
    .post(URL_CONSTANTS.GET_ACCESS_TOKKEN, {
      username: "jtalwar",
      password: "654320",
    })
    .then((response) => {
      localStorage.setItem(
        LOCALSOTRAGE_KEYS_CONSTANTS.AUTH_TOKEN,
        response.data.success.token
      );
      onSuccess(response);
    })
    .catch((error) => {
      console.log(error);
    });
}

// async function getRefreshToken(onSuccess: any, onError: any) {
//   try {
//     const response = await axios.post(
//       URL_CONSTANTS.REFRESH_TOKEN,
//       {},
//       { withCredentials: true }
//     );
//     return onSuccess(response.data);
//   } catch (ex) {
//     console.log({ ex });
//     onError(ex);
//   }
// }

export const AUTH_SERVICE = {
  getAccessTokken,
};
