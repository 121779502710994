import { Box, Button, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import { TAddonsValueType } from "../../../../../Services/Type/Car/TAddonsValueType";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";

const CarEmailPopup = ({
  open,
  setOpen,
  marginClass,
  // addon_values,
  fieldsUpdateState,
  validateForm,
  sendQuoteFields,
}: {
  open: boolean;
  setOpen: Function;
  marginClass? : string
  // addon_values: TAddonsValueType;
  fieldsUpdateState: Function;
  validateForm: any;
  sendQuoteFields: any;
}) => {
  const {
    ALL_QUOTES_DATA,
    ADD_FORM_RESPONSE,
    ADDON_STATUS,
    PLAN_TYPE,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Car);
  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className={`modalContent xsWidth ${marginClass}`}>
          <Grid
            className="title mx-0"
            container
            columnSpacing={3}
            alignItems="center"
          >
            <Button className="closePopup" onClick={() => setOpen(false)} />
            <Grid xs={12}>
              <h3>SEND EMAIL</h3>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            className="mx-0"
          >
            <Grid xs={12}>
              <Box>
                <Grid container columnSpacing={2} rowSpacing={0} className="mx-0">
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Mobile"}
                      value={sendQuoteFields?.mobile?.value.slice(0, 10)}
                      attrName={"mobile"}
                      value_update={fieldsUpdateState}
                      number_only={true}
                      warn_status={sendQuoteFields?.mobile?.warning}
                      error_message={
                        !sendQuoteFields?.mobile?.value
                          ? "Enter Mobile No."
                          : "Enter  Valid Mobile No."
                      }
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Email"}
                      value={sendQuoteFields?.email?.value}
                      attrName={"email"}
                      value_update={fieldsUpdateState}
                      warn_status={sendQuoteFields?.email?.warning}
                      error_message={
                        !sendQuoteFields?.email?.value
                          ? "Enter Email"
                          : "Enter Valid Email"
                      }
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn  mb-4">
                    <CustomButton
                      text_name={"Send Quotes"}
                      class_name="primaryLgBtn"
                      onClickFunction={() => validateForm()}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>

  );
};

export default CarEmailPopup;
