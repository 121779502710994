import TWModifyDetails from "../../../../Component/Desktop/TwoWheeler/QuotePage/TWModifyDetails/TWModifyDetails";
import MTWModifyDetails from "../../../../Component/Mobile/TwoWheeler/QuotePage/MTWModifyDetails/MTWModifyDetails";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
function TWModifyDetailsContainer({
  open,
  setOpen,
  UPDATE_FORM_DETAILS,
  fieldsUpdateState,
  formData,
  loader,
  validateForm,
  setFormData,
}: {
  open: boolean;
  loader: boolean;
  setOpen: Function;
  validateForm: Function;
  UPDATE_FORM_DETAILS: Function;
  setFormData: Function;
  fieldsUpdateState: Function;
  formData: any;
}) {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <MTWModifyDetails
          open={open}
          loader={loader}
          setOpen={setOpen}
          fieldsUpdateState={fieldsUpdateState}
          formData={formData}
          setFormData={setFormData}
          validateForm={validateForm}
        />
      ) : (
        <TWModifyDetails
          loader={loader}
          open={open}
          setOpen={setOpen}
          fieldsUpdateState={fieldsUpdateState}
          formData={formData}
          validateForm={validateForm}
          setFormData={setFormData}
        />
      )}
    </>
  );
}

export default TWModifyDetailsContainer;
