import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Box, Button, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import "../../../../CommonSCSS/QuotePage.scss";
import CarQuoteCard from "../../../../Component/Desktop/Car/QuotePage/CarQuoteCard/CarQuoteCard";
import CarquoteHeader from "../../../../Component/Desktop/Common/QuoteHeader/CarquoteHeader/CarquoteHeader";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import RKTextField from "../../../../Component/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import CarDiscountPopupContainer from "../../../../Container/CarDiscountPopup/CarDiscountPopupContainer";
import { CarModifyDetailsContainer } from "../../../../Container/CarModifyDetailsContainer/CarModifyDetailsContainer";
import { TAddonsValueType } from "../../../../Services/Type/Car/TAddonsValueType";
import { TCarProduct } from "../../../../Services/Type/Car/TCarProduct";
import { TProductType } from "../../../../Services/Type/Common/TProductType";
import { CarSlice } from "../../../../State/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../State/hooks";
import {
  CURRENCY_FORMAT,
  FORMAT_DD_MM_YYYY,
  calculateDateDifferenceInDays,
} from "../../../../SupportingFiles/HelpingFunction";

const CarQuotePage = ({
  get_product_list,
  fieldData,
  setFieldData,
  idv_value,
  set_idv_value,
  quote_list_data,
  min_max_value,
  check_valid_idv,
  IDVApplyStatus,
  setIDVApplyStatus,
  updateMasterState,
  updateAddonAndValue,
  accessories_value_quote_load,
  cng_kit_quote_load,
  update_idv,
  RESET_ALL_ADDONS,
}: {
  get_product_list: Function;
  update_idv: Function;
  fieldData: TAddonsValueType;
  updateAddonAndValue: Function;
  setFieldData: Function;
  idv_value: {
    value: number;
    warning: boolean;
  };
  set_idv_value: Function;
  quote_list_data: TCarProduct[];
  min_max_value: {
    lowest: number;
    highest: number;
  };
  check_valid_idv: Function;
  IDVApplyStatus: boolean;
  setIDVApplyStatus: Function;
  updateMasterState: Function;
  accessories_value_quote_load: Function;
  cng_kit_quote_load: Function;
  RESET_ALL_ADDONS: Function;
}) => {
  const [openModifyDetails, setOpenModifyDetails] = useState<boolean>(false);
  const [openDiscountPopup, setOpenDiscountPopup] = useState(false);
  const dispatch = useAppDispatch();
  const {
    QUOTE_LOADER,
    ADDON_STATUS,
    ADD_FORM,
    DROPDOWN_DATA,
    PLAN_TYPE,
    ADD_FORM_RESPONSE,
    IDV_VALUE,
    IDVApply_Status,
  } = useAppSelector((state) => state.Car);
  const { Car } = useAppSelector((state) => state);
  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    const numericRegex = /^[0-9]*$/;
    if (
      inputValue.length <= 12 &&
      (numericRegex.test(inputValue) || inputValue === "")
    ) {
      set_idv_value((prev: any) => ({
        ...prev,
        value: inputValue,
      }));
    }
  };

  return (
    <Box className="quoteWrapper">
      {openModifyDetails ? (
        <CarModifyDetailsContainer
          open={openModifyDetails}
          setOpen={setOpenModifyDetails}
          get_product_list={get_product_list}
        />
      ) : null}
      <CarDiscountPopupContainer
        open={openDiscountPopup}
        setOpen={setOpenDiscountPopup}
        get_product_list={get_product_list}
      />
      <CarquoteHeader />
      <Box className="detailSection">
        <Grid container columnSpacing={3}>
          <Grid xs={7}>
            <Box className="detailBox">
              <span className="icon">
                <img src="../images/carQuote-icon.svg" alt="car-icon" />
              </span>
              <Box className="vehicle_details">
                <h5 className="mb-2">
                  {`${ADD_FORM.make_model.value.label}`}{" "}
                  <span>
                    {`${ADD_FORM.fuel_type.value.label}, ${ADD_FORM.variant_cc.value.label}`}
                  </span>
                </h5>
                <p className="mb-1">
                  {ADD_FORM_RESPONSE.business_type === "New"
                    ? "Invoice Date:"
                    : "Reg. Date:"}
                  <span className="px-1">
                    {FORMAT_DD_MM_YYYY(`${ADD_FORM.reg_date.value}`)}
                  </span>
                </p>
                <p>
                  {PLAN_TYPE === "Bundle Policy" ? null : (
                    <>
                      Policy Expiry :
                      <span className="px-1">
                        <>
                          {FORMAT_DD_MM_YYYY(
                            `${ADD_FORM?.policy_expiry_date.value}`
                          )}
                        </>
                      </span>
                    </>
                  )}
                  {PLAN_TYPE === "Bundle Policy" ? null : (
                    <>
                      New NCB :
                      {PLAN_TYPE === "Third Party" ? (
                        <span className="px-1">0%</span>
                      ) : (
                        <>
                          {calculateDateDifferenceInDays(
                            ADD_FORM?.policy_expiry_date.value
                          ) > 90 ? (
                            <span className="px-1">0</span>
                          ) : (
                            <span className="px-1">{`${ADD_FORM.new_ncb.value}%`}</span>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {/* Claimed: <span>{ADD_FORM.claimed} </span>
                  {PLAN_TYPE !== "Third Party" && (
                    <>
                      New NCB:{" "}
                      {ADD_FORM.claimed === "No" &&
                        ADD_FORM.business_type === "Rollover" && (
                          <span>{`${ADD_FORM.new_ncb.value}`}%</span>
                        )}
                      {ADD_FORM.claimed === "No" &&
                        ADD_FORM.business_type === "Rollover" && (
                          <span>0 %</span>
                        )}
                    </>
                  )} */}
                </p>
                <Link
                  className="modify_link"
                  onClick={() => setOpenModifyDetails(true)}
                >
                  Edit Details
                </Link>
              </Box>
            </Box>
          </Grid>
          {PLAN_TYPE === "Third Party" ? null : (
            <Grid xs={5}>
              <Box className="idvbox">
                <Box className="input-field">
                  <p>Vehicle IDV</p>
                  <span className="rs">₹ </span>
                  <input
                    className="idvinput"
                    type="text"
                    placeholder="Enter IDV"
                    value={idv_value.value === 0 ? "" : idv_value.value}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  {idv_value.warning ? (
                    <span className="error">Enter correct IDV</span>
                  ) : null}

                  <Box className="action-Btns">
                    {idv_value.value && (
                      <Button
                        onClick={() => {
                          check_valid_idv();
                        }}
                        className="apply"
                        disabled={!idv_value.value}
                      >
                        Apply
                      </Button>
                    )}

                    {IDVApplyStatus && idv_value.value ? (
                      <Link
                        onClick={() => {
                          set_idv_value({
                            value: "",
                            warning: false,
                          });
                          update_idv("reset");
                          setIDVApplyStatus(false);
                          dispatch(CarSlice.actions.IDVAPPLY_STATUS(false));
                          dispatch(
                            CarSlice.actions.IDV_UPDATE({
                              value: `${""}`,
                              warning: false,
                            })
                          );
                        }}
                        className="apply"
                      >
                        Reset
                      </Link>
                    ) : null}
                  </Box>
                </Box>
                {QUOTE_LOADER ? (
                  <p className="range_p">
                    Choose vehicle IDV between ₹ ___ - ₹ ___
                  </p>
                ) : (
                  // <p className="range_p">
                  //   {" "}
                  //   {`Choose vehicle IDV between ${CURRENCY_FORMAT(
                  //     quote_list_data.length === 0
                  //       ? 0
                  //       : min_max_value.lowest
                  //       ? min_max_value.lowest
                  //       : 0
                  //   )} - ${CURRENCY_FORMAT(
                  //     min_max_value.highest ? min_max_value.highest : 0
                  //   )}`}
                  // </p>
                  <p className="range_p">
                    {"Choose vehicle IDV between "}
                    <span>
                      {CURRENCY_FORMAT(
                        quote_list_data.length === 0
                          ? 0
                          : min_max_value.lowest
                          ? min_max_value.lowest
                          : 0
                      )}
                    </span>
                    {" - "}
                    <span>
                      {CURRENCY_FORMAT(
                        min_max_value.highest ? min_max_value.highest : 0
                      )}
                    </span>
                  </p>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box className="planFound_sec">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="pf_box">
              {!QUOTE_LOADER ? (
                <Box className="pf_box_left py-1">
                  {quote_list_data.length >= 1 && !QUOTE_LOADER ? (
                    <>
                      <h5>
                        {quote_list_data.length} great plans found for you!{" "}
                      </h5>
                      <p>Price shown are inclusive of GST.</p>
                    </>
                  ) : (
                    <>
                      <h5>No Plans Found!</h5>
                    </>
                  )}
                </Box>
              ) : (
                <Box className="pf_box_left py-1">
                  <h5>Fetching Quotes... </h5>
                </Box>
              )}
              <Box className="pf_box_right">
                <Box className="plantype_box">
                  <SelectDropdown
                    class_name="inputField "
                    title="Plan Type"
                    value={PLAN_TYPE}
                    attrName={["PLAN_TYPE"]}
                    value_update={(key: string, value: string) => {
                      const a_value = value as TProductType;
                      RESET_ALL_ADDONS();
                      dispatch(
                        CarSlice.actions.BULK_UPDATE({
                          ...Car,
                          PLAN_TYPE: a_value,
                          ADDON_STATUS: {
                            ...CarSlice.getInitialState().ADDON_STATUS,
                          },
                          ADDON_USER_VALUE: {
                            ...CarSlice.getInitialState().ADDON_USER_VALUE,
                          },
                        })
                      );
                    }}
                    data={DROPDOWN_DATA.PLAN_TYPE}
                    warn_status={false}
                  />
                </Box>

                <Box className="dicount_box">
                  {/* {PLAN_TYPE === "Third Party" ? null : ( */}
                  {/* <div className="discount_box"> */}
                  <p>Discount</p>
                  <Link
                    className="apply"
                    onClick={() => {
                      setOpenDiscountPopup(true);
                    }}
                  >
                    Apply
                  </Link>
                  {/* </div> */}
                  {/* // )} */}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="quotation-section">
        <Grid container columnSpacing={3}>
          <Grid xs className="quoteLeftSidebar">
            <Box className="quote_number_box">
              <p>
                Quote No <span>{ADD_FORM_RESPONSE.quote_no}</span>
              </p>
            </Box>
            <h4>Additional Covers</h4>
            <ul>
              {PLAN_TYPE === "SAOD" ||
              ADD_FORM.vehicle_owned === "Organization" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.pa_owner_cover.label}
                    defaultChecked={ADDON_STATUS.pa_owner_cover.value}
                    value={ADDON_STATUS.pa_owner_cover.value}
                    value_update={(e: any, a: any) => {}}
                  />
                  <Tooltip
                    title={ADDON_STATUS.pa_owner_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.personalBelonging.label}
                    defaultChecked={ADDON_STATUS.personalBelonging.value}
                    value={ADDON_STATUS.personalBelonging.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "personalBelonging",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(
                        ADDON_STATUS.personalBelonging.label,
                        a
                      );
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.personalBelonging.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.depreciation_cover.label}
                    defaultChecked={ADDON_STATUS.depreciation_cover.value}
                    value={ADDON_STATUS.depreciation_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "depreciation_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(
                        ADDON_STATUS.depreciation_cover.label,
                        a
                      );
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.depreciation_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.rsa.label}
                    defaultChecked={ADDON_STATUS.rsa.value}
                    value={ADDON_STATUS.rsa.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "rsa",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(ADDON_STATUS.rsa.label, a);
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.rsa.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "SAOD" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.driver_cover.label}
                    defaultChecked={ADDON_STATUS.driver_cover.value}
                    value={ADDON_STATUS.driver_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "driver_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(ADDON_STATUS.driver_cover.label, a);
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.driver_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.consumable_cover.label}
                    defaultChecked={ADDON_STATUS.consumable_cover.value}
                    value={ADDON_STATUS.consumable_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "consumable_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(
                        ADDON_STATUS.consumable_cover.label,
                        a
                      );
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.consumable_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.engine_cover.label}
                    defaultChecked={ADDON_STATUS.engine_cover.value}
                    value={ADDON_STATUS.engine_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "engine_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(ADDON_STATUS.engine_cover.label, a);
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.engine_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.invoice_cover.label}
                    defaultChecked={ADDON_STATUS.invoice_cover.value}
                    value={ADDON_STATUS.invoice_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "invoice_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(ADDON_STATUS.invoice_cover.label, a);
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.invoice_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.key_replacement.label}
                    defaultChecked={ADDON_STATUS.key_replacement.value}
                    value={ADDON_STATUS.key_replacement.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "key_replacement",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(
                        ADDON_STATUS.key_replacement.label,
                        a
                      );
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.key_replacement.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.ncb_cover.label}
                    defaultChecked={ADDON_STATUS.ncb_cover.value}
                    value={ADDON_STATUS.ncb_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "ncb_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(ADDON_STATUS.ncb_cover.label, a);
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.ncb_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.tyre_cover.label}
                    defaultChecked={ADDON_STATUS.tyre_cover.value}
                    value={ADDON_STATUS.tyre_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "tyre_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(ADDON_STATUS.tyre_cover.label, a);
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.tyre_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                </li>
              )}
              {PLAN_TYPE === "Third Party" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.accessories_cover.label}
                    defaultChecked={ADDON_STATUS.accessories_cover.value}
                    value={ADDON_STATUS.accessories_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "accessories_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(
                        ADDON_STATUS.accessories_cover.label,
                        a
                      );
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.accessories_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                  {ADDON_STATUS.accessories_cover.value ? (
                    <div className="accessoryBox">
                      <RKTextField
                        class_name="inputField mb-4"
                        title={"Electrical"}
                        value={fieldData.electricalAcessories.value}
                        attrName={[
                          "electricalAcessories",
                          "value",
                          fieldData,
                          setFieldData,
                        ]}
                        max_length={5}
                        value_update={updateMasterState}
                        warn_status={fieldData.electricalAcessories.warning}
                        validation_type="NUMBER"
                        error_message="Enter between ₹ 1 - ₹ 50,000"
                      />
                      <RKTextField
                        class_name="inputField mb-4"
                        title={"Non-Electrical"}
                        value={fieldData.nonElectricalAcessories.value}
                        attrName={[
                          "nonElectricalAcessories",
                          "value",
                          fieldData,
                          setFieldData,
                        ]}
                        value_update={updateMasterState}
                        warn_status={fieldData.nonElectricalAcessories.warning}
                        max_length={5}
                        validation_type="NUMBER"
                        error_message="Enter between ₹ 1 - ₹ 50,000"
                      />
                      <Button
                        onClick={() => {
                          accessories_value_quote_load();
                        }}
                        className="apply-Btn ml-2"
                      >
                        Apply
                      </Button>
                    </div>
                  ) : null}
                </li>
              )}
              {`${ADD_FORM.fuel_type.value?.label}` === "Petrol" ? (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.cng_kit.label}
                    defaultChecked={ADDON_STATUS.cng_kit.value}
                    value={ADDON_STATUS.cng_kit.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "cng_kit",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(ADDON_STATUS.cng_kit.label, a);
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.cng_kit.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                  {ADDON_STATUS.cng_kit.value ? (
                    <div className="accessoryBox">
                      <RKTextField
                        class_name="inputField mb-4"
                        title={"Kit Price"}
                        value={fieldData.cngKitValue.value}
                        attrName={[
                          "cngKitValue",
                          "value",
                          fieldData,
                          setFieldData,
                        ]}
                        max_length={5}
                        value_update={updateMasterState}
                        warn_status={fieldData.cngKitValue.warning}
                        error_message={"Enter Cng Kit "}
                      />
                      <Button
                        onClick={() => {
                          updateAddonAndValue("CNG Kit");
                          cng_kit_quote_load();
                        }}
                        className="apply-Btn ml-2"
                      >
                        Apply
                      </Button>
                    </div>
                  ) : null}
                </li>
              ) : null}
              {PLAN_TYPE === "SAOD" ? null : (
                <li>
                  <CustomCheckbox
                    label={ADDON_STATUS.passenger_cover.label}
                    defaultChecked={ADDON_STATUS.passenger_cover.value}
                    value={ADDON_STATUS.passenger_cover.value}
                    value_update={(e: any, a: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_ADDON_STATUS([
                          {
                            key: "passenger_cover",
                            value: a,
                          },
                        ])
                      );
                      updateAddonAndValue(
                        ADDON_STATUS.passenger_cover.label,
                        a
                      );
                    }}
                  />
                  <Tooltip
                    title={ADDON_STATUS.passenger_cover.tooltip}
                    placement="bottom-start"
                    className="toolTip"
                    arrow
                  >
                    <ErrorOutlineRoundedIcon className="tooltip-icon" />
                  </Tooltip>
                  {ADDON_STATUS.passenger_cover.value ? (
                    <div className="accessoryBox my-2">
                      <SelectDropdown
                        class_name="inputField "
                        title="Select Values"
                        value={fieldData.passengerCover.value}
                        attrName={[
                          "passengerCover",
                          "value",
                          fieldData,
                          setFieldData,
                        ]}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.PASSENGER_COVER_DATA}
                        warn_status={fieldData.passengerCover.warning}
                        error_message={"Select amount"}
                      />
                    </div>
                  ) : null}
                </li>
              )}
            </ul>
          </Grid>
          <Grid xs className="quoteRightSection">
            <Box
              display={"flex"}
              gap={"24px"}
              alignItems={"flex-start"}
              flexWrap={"wrap"}
            >
              {quote_list_data.length != 0 &&
                quote_list_data.map((data) => (
                  <CarQuoteCard quote={data} addon_values={fieldData} />
                ))}
              {!QUOTE_LOADER && quote_list_data.length == 0 && (
                <div className="no-quote-box">
                  <img src="../images/no-quotes.svg" alt="" />
                  <h5>Sorry no quotes found!</h5>
                  <p>
                    We can't find the quotes as per your enterd details. Please
                    try again.
                  </p>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CarQuotePage;
