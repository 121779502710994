import { URL_CONSTANTS } from "../../Shared/URLS";
import { PostAPI } from "../AxiosAPIInstance/API";

const CREATE_LEAD = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    quote_no: string;
    product_id: string;
  }
) => {
  PostAPI.call(
    URL_CONSTANTS.MAX_CREATE_LEAD,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

const SAVE_QUOTE = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    quote_no: string;
    product_id: string;
  }
) => {
  PostAPI.call(
    URL_CONSTANTS.MAX_SAVE_QUOTE,
    dto,
    (res: any) => onSuccess(res.data),
    {},
    onError,
    () => {}
  );
};

export const MAX_SERVICES = {
  CREATE_LEAD,
  SAVE_QUOTE,
};
