import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RidersBenefits from "../../../../../Page/Desktop/Term/HDFC/Proposal/RidersBenefits/RidersBenefits";
import MRidersBenefits from "../../../../../Page/Mobile/Term/HDFC/Proposal/RidersBenefits/MRidersBenefits";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";
import { TDropdown } from "../../../../../State/Types/TDropdown";
import { TRidersBenefitsResponse } from "../../../../../State/Types/TRidersBenefits";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

function RidersBenefitsContainer() {
  const isMobile = useIsMobile();

  const dispatch = useAppDispatch();
  const [tf001Data, setTf001Data] = useState<TDropdown[]>([]);
  const [tf002Data, setTf002Data] = useState<TDropdown[]>([]);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [tf004Data, setTf004Data] = useState<TDropdown[]>([]);
  const [tf005Data, setTf005Data] = useState<TDropdown[]>([]);

  const { selectedQuote, proposerDetails, RIDERS_BENEFITS } = useAppSelector(
    (state) => state.Term
  );
  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);
  const [loaderTf004, setLoaderTf004] = useState<boolean>(false);
  const [loaderTf005, setLoaderTf005] = useState<boolean>(false);

  useEffect(() => {
    GET_RIDERS_PREMIUM("");
  }, []);

  useEffect(() => {
    GET_RIDERS_PREMIUM("RIDERREC");
  }, [RIDERS_BENEFITS.local_value]);

  const GET_RIDERS_PREMIUM = (REQUEST_FOR: "" | "RIDERREC") => {
    const onSuccess = (data: any) => {
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      setLoaderTf005(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response: TRidersBenefitsResponse = results.response;

      if (!error) {
        dispatch(TermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      setLoaderTf005(false);
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data = {
      buy_online_code: selectedQuote.buy_online_code,
      product_id: `${selectedQuote.id}`,
      quote_no: selectedQuote.quote_no,
      age: `${selectedQuote.age}`,
      city: selectedQuote.city,
      company_code: selectedQuote.company_code,
      cover_pay_upto: selectedQuote.cover_pay_upto,
      cover_upto: selectedQuote.cover_upto,
      frequency: `${selectedQuote.frequency}`,
      gender: selectedQuote.gender,
      pay_term: `${selectedQuote.pay_term}`,
      policy_covers: selectedQuote.policy_covers,
      product_code: selectedQuote.product_code,
      product_desc: selectedQuote.product_desc,
      smoker: selectedQuote.smoker,
      state: selectedQuote.state,
      sum_assured: selectedQuote.sum_insured,
      term: `${selectedQuote.term}`,
      name: `${proposerDetails.full_name.value}`,
      annual_income: `${proposerDetails.annual_income.value.code}`,
      dob: `${selectedQuote.dob_date}-${selectedQuote.dob_month}-${selectedQuote.dob_year}`,
      request_for: REQUEST_FOR,
      criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      personalAccSelected:
        selectedQuote.product_code === "LTH101"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf003Status
          ? "Yes"
          : "No",
      cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      accidentalDeaSelected:
        selectedQuote.product_code === "LTH101"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf005Status
          ? "Yes"
          : "No",
      criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
        ? RIDERS_BENEFITS.local_value.tf001Value
        : RIDERS_BENEFITS.local_value.tf004Value,
      accidentalDisValue: RIDERS_BENEFITS.local_value.tf002Value,
      accidentalDeathValue:
        selectedQuote.product_code === "LTH101"
          ? ""
          : RIDERS_BENEFITS.local_value.tf003Status
          ? RIDERS_BENEFITS.local_value.tf003Value
          : RIDERS_BENEFITS.local_value.tf005Value,
      adb: RIDERS_BENEFITS.local_value.BNF001Status ? "Yes" : "No",
      rop: RIDERS_BENEFITS.local_value.BNF002Status ? "Yes" : "No",
      wop: RIDERS_BENEFITS.local_value.BNF003Status ? "Yes" : "No",
    };

    PROPOSAL_PAGE_SERVICES.hdfcCreatePaymentLink(onSuccess, onError, data);
  };

  useEffect(() => {
    const start_tf001 = 5000;
    const end_tf001 = 5000000;

    let data_tf001: TDropdown[] = [];
    for (let i = start_tf001; i <= end_tf001; i += 5000) {
      data_tf001.push({ key: `${i}`, value: `${i}` });
    }

    setTf001Data(data_tf001);

    const start_tf002 = 100000;
    const end_tf002 = 20000000;

    let data_tf002: TDropdown[] = [];
    for (let i = start_tf002; i <= end_tf002; i += 100000) {
      data_tf002.push({ key: `${i}`, value: `${i}` });
    }

    setTf002Data(data_tf002);

    const start_tf003 = 5000;
    const end_tf003 = 5000000;

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 5000) {
      data_tf003.push({ key: `${i}`, value: `${i}` });
    }

    setTf003Data(data_tf003);

    const start_tf004 = 5000;
    const end_tf004 = 5000000;

    let data_tf004: TDropdown[] = [];
    for (let i = start_tf004; i <= end_tf004; i += 5000) {
      data_tf004.push({ key: `${i}`, value: `${i}` });
    }

    setTf004Data(data_tf004);

    const start_tf005 = 5000;
    const end_tf005 = 10000000;

    let data_tf005: TDropdown[] = [];
    for (let i = start_tf005; i <= end_tf005; i += 5000) {
      data_tf005.push({ key: `${i}`, value: `${i}` });
    }

    setTf005Data(data_tf005);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    setLoaderTf001(true);
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf001Value,
  ]);

  useEffect(() => {
    setLoaderTf002(true);
  }, [
    RIDERS_BENEFITS.local_value.tf002Status,
    RIDERS_BENEFITS.local_value.tf002Value,
  ]);

  useEffect(() => {
    setLoaderTf003(true);
  }, [
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf003Value,
  ]);

  useEffect(() => {
    setLoaderTf004(true);
  }, [
    RIDERS_BENEFITS.local_value.tf004Status,
    RIDERS_BENEFITS.local_value.tf004Value,
  ]);

  useEffect(() => {
    setLoaderTf005(true);
  }, [
    RIDERS_BENEFITS.local_value.tf005Status,
    RIDERS_BENEFITS.local_value.tf005Value,
  ]);

  return (
    <>
      {isMobile ? (
        <MRidersBenefits
          loaderTf001={loaderTf001}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          loaderTf005={loaderTf005}
          tf001Data={tf001Data}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          tf005Data={tf005Data}
        />
      ) : (
        <RidersBenefits
          loaderTf001={loaderTf001}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          loaderTf005={loaderTf005}
          tf001Data={tf001Data}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          tf005Data={tf005Data}
        />
      )}
    </>
  );
}

export default RidersBenefitsContainer;
