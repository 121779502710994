import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Box, Button, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../CommonSCSS/QuotePage.scss";
import QuoteHeader from "../../../../Component/Desktop/Common/QuoteHeader/QuoteHeader";
import TWQuoteCard from "../../../../Component/Desktop/TwoWheeler/QuotePage/TWQuoteCard/TWQuoteCard";
import CustomCheckbox from "../../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import SelectDropdown from "../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TWModifyDetailsContainer from "../../../../Container/TW/Quote/TWModifyContainer/TWModifyDetailsContainer";
import { TProductType } from "../../../../Services/Type/Common/TProductType";
import { TTWAddonStatus } from "../../../../Services/Type/TwoWheeler/TTWAddonStatus";
import { TTWProduct } from "../../../../Services/Type/TwoWheeler/TTWProduct";
import { TWSlice } from "../../../../State/Slice_Reducer/TW/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../../State/hooks";
import {
  CURRENCY_FORMAT,
  FORMAT_DATE_DD_MM_YYYY,
  FORMAT_DD_MM_YYYY,
} from "../../../../SupportingFiles/HelpingFunction";

const TWQuotePage = ({
  get_product_list,
  idv_value,
  set_idv_value,
  quote_list_data,
  min_max_value,
  check_valid_idv,
  IDVApplyStatus,
  setIDVApplyStatus,
  updateAddonAndValue,
  update_idv,
  allProducts,
  UPDATE_FORM_DETAILS,
  fieldsUpdateState,
  formData,
  loader,
  validateForm,
  setFormData,
  openModifyDetails,
  setOpenModifyDetails,
  RESET_ALL_ADDONS,
}: {
  updateAddonAndValue: Function;
  UPDATE_FORM_DETAILS: Function;
  get_product_list: Function;
  setOpenModifyDetails: Function;
  fieldsUpdateState: Function;
  setFormData: Function;
  validateForm: Function;
  loader: boolean;
  formData: any;
  openModifyDetails: any;
  idv_value: {
    value: number;
    warning: boolean;
  };
  set_idv_value: Function;
  quote_list_data: TTWProduct[];
  min_max_value: {
    lowest: number;
    highest: number;
  };
  check_valid_idv: Function;
  IDVApplyStatus: boolean;
  setIDVApplyStatus: Function;
  update_idv: Function;
  allProducts: any;
  RESET_ALL_ADDONS: Function;
}) => {
  const dispatch = useAppDispatch();
  const {
    QUOTE_LOADER,
    ADDON_STATUS,
    ADD_FORM,
    PLAN_TYPE,
    DROPDOWN_DATA,
    ADD_FORM_RESPONSE,
    IDV_VALUE,
  } = useAppSelector((state) => state.TW);
  const { TW } = useAppSelector((state) => state);
  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    const numericRegex = /^[0-9]*$/;
    if (
      (inputValue.length <= 12 && numericRegex.test(inputValue)) ||
      inputValue === ""
    ) {
      set_idv_value((prev: any) => ({
        ...prev,
        value: inputValue,
      }));
    }
  };

  return (
    <Box className="quoteWrapper">
      {openModifyDetails ? (
        <TWModifyDetailsContainer
          open={openModifyDetails}
          setOpen={setOpenModifyDetails}
          UPDATE_FORM_DETAILS={UPDATE_FORM_DETAILS}
          fieldsUpdateState={fieldsUpdateState}
          formData={formData}
          loader={loader}
          validateForm={validateForm}
          setFormData={setFormData}
        />
      ) : null}
      <QuoteHeader />
      <Box className="detailSection">
        <Grid container columnSpacing={3}>
          <Grid xs={7}>
            <Box className="detailBox">
              <span className="icon">
                <img src="../images/bikeQuote-icon.svg" alt="TW-icon" />
              </span>
              <Box className="vehicle_details">
                <h5 className="mb-2">
                  {`${ADD_FORM.make_model.value.label}`}{" "}
                  <span>
                    {`${ADD_FORM.fuel_type.value.label},
                      ${ADD_FORM.variant_cc.value.label}`}
                  </span>
                </h5>
                <p className="mb-1">
                  {ADD_FORM_RESPONSE.business_type === "New"
                    ? "Invoice Date :"
                    : "Reg. Date :"}
                  <span className="px-1">
                    {FORMAT_DATE_DD_MM_YYYY(ADD_FORM?.reg_date.value)}
                  </span>
                </p>
                <p>
                  {ADD_FORM.previous_insurer.value === "Do not remember" ? (
                    <>
                      Policy Expiry:
                      <span> Do not remember </span>
                    </>
                  ) : PLAN_TYPE === "Bundle Policy" ? (
                    <>
                      New NCB :<span className="px-1">0%</span>
                    </>
                  ) : (
                    <>
                      Policy Expiry :
                      <span className="px-1">
                        {FORMAT_DD_MM_YYYY(
                          `${ADD_FORM?.policy_expiry_date.value}`
                        )}
                      </span>
                    </>
                  )}
                  {PLAN_TYPE === "Bundle Policy" ? null : (
                    <>
                      {PLAN_TYPE === "Third Party" ? (
                        <>
                          New NCB: <span className="px-1">0% </span>
                        </>
                      ) : (
                        <>
                          New NCB:
                          <span className="px-1">
                            {`${ADD_FORM.new_ncb.value}`}%
                          </span>
                        </>
                      )}
                    </>
                  )}
                </p>

                <Link
                  className="modify_link"
                  onClick={() => setOpenModifyDetails(true)}
                >
                  Edit Details
                </Link>
              </Box>
            </Box>
          </Grid>
          {PLAN_TYPE === "Third Party" ? null : (
            <Grid xs={5}>
              <Box className="idvbox">
                <Box className="input-field">
                  <p>Vehicle IDV</p>
                  <span className="rs">₹ </span>
                  <input
                    className="idvinput"
                    type="text"
                    placeholder="Enter IDV"
                    value={idv_value.value === 0 ? "" : idv_value.value}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  {idv_value.warning ? (
                    <span className="error">Enter correct IDV</span>
                  ) : null}

                  <Box className="action-Btns">
                    {idv_value.value && (
                      <Button
                        className="apply"
                        onClick={() => {
                          check_valid_idv();
                          dispatch(TWSlice.actions.IDVAPPLY_STATUS(true));
                        }}
                        disabled={!idv_value.value}
                      >
                        Apply
                      </Button>
                    )}
                    {IDVApplyStatus && idv_value.value ? (
                      <Link
                        className="apply"
                        onClick={() => {
                          setIDVApplyStatus(false);
                          set_idv_value({
                            value: "",
                            warning: false,
                          });
                          dispatch(
                            TWSlice.actions.IDV_UPDATE({
                              value: `${""}`,
                              warning: false,
                            })
                          );
                          dispatch(TWSlice.actions.IDVAPPLY_STATUS(false));
                          update_idv("reset");
                        }}
                      >
                        Reset
                      </Link>
                    ) : null}
                  </Box>
                </Box>
                {QUOTE_LOADER ? (
                  <p className="range_p">
                    Choose vehicle IDV between ₹ ___ - ₹ ___
                  </p>
                ) : (
                  <p className="range_p">{`Choose vehicle IDV between ${CURRENCY_FORMAT(
                    quote_list_data.length === 0
                      ? 0
                      : min_max_value.lowest
                      ? min_max_value.lowest
                      : 0
                  )} - ${CURRENCY_FORMAT(
                    min_max_value.highest ? min_max_value.highest : 0
                  )}`}</p>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box className="planFound_sec">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="pf_box">
              <Box className="pf_box_left py-1">
                {!QUOTE_LOADER ? (
                  <>
                    <h5>
                      <span>{quote_list_data.length}</span> great plans found
                      for you!
                    </h5>
                    <p>Prices shown are inclusive of GST.</p>
                  </>
                ) : (
                  <h5>Fetching Quotes...</h5>
                )}
              </Box>
              <Box className="pf_box_right">
                <Box className="plantype_box">
                  <SelectDropdown
                    class_name="inputField "
                    title="Plan Type"
                    value={PLAN_TYPE}
                    attrName={"PLAN_TYPE"}
                    value_update={(key: string, value: string) => {
                      const a_value = value as TProductType;
                      RESET_ALL_ADDONS();
                      dispatch(
                        TWSlice.actions.BULK_UPDATE({
                          ...TW,
                          PLAN_TYPE: a_value,
                          ADDON_STATUS: {
                            ...TWSlice.getInitialState().ADDON_STATUS,
                          },
                        })
                      );
                    }}
                    data={DROPDOWN_DATA.PLAN_TYPE}
                    warn_status={false}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="quotation-section">
        <Grid container columnSpacing={3}>
          <Grid xs className="quoteLeftSidebar">
            <Box className="quote_number_box">
              <p>
                Quote No <span>{ADD_FORM_RESPONSE.quote_no}</span>
              </p>
            </Box>
            <h4>Additional Covers</h4>
            <ul>
              {Object.entries(ADDON_STATUS).map(([key, value]) =>
                PLAN_TYPE === "Third Party" &&
                (value.label === "PA Owner Cover" ||
                  value.label === "Driver Cover") ? (
                  <li key={key}>
                    <CustomCheckbox
                      label={value.label}
                      defaultChecked={value.value}
                      value={value.value}
                      value_update={(e: any, a: any) => {
                        const addOnKey = key as keyof TTWAddonStatus;
                        dispatch(
                          TWSlice.actions.UPDATE_ADDON_STATUS({
                            key: addOnKey,
                            value: key === "pa_owner_cover" ? true : a,
                          })
                        );
                        updateAddonAndValue(value.label, a);
                      }}
                    />
                    <Tooltip
                      title={value.ToolTipdata}
                      placement="bottom-start"
                      className="toolTip"
                      arrow
                    >
                      <ErrorOutlineRoundedIcon className="tooltip-icon" />
                    </Tooltip>
                  </li>
                ) : null
              )}
            </ul>
            <ul>
              {PLAN_TYPE !== "Third Party" &&
                Object.entries(ADDON_STATUS).map(([key, value]) =>
                  PLAN_TYPE == "SAOD" &&
                  (key === "pa_owner_cover" ||
                    key === "driver_cover") ? null : (
                    <li key={key}>
                      <CustomCheckbox
                        label={value.label}
                        defaultChecked={value.value}
                        value={value.value}
                        value_update={(e: any, a: any) => {
                          const addOnKey = key as keyof TTWAddonStatus;
                          dispatch(
                            TWSlice.actions.UPDATE_ADDON_STATUS({
                              key: addOnKey,
                              value: key === "pa_owner_cover" ? true : a,
                            })
                          );
                          if (value.label) {
                            updateAddonAndValue(value.label, a);
                          }
                        }}
                      />
                      <Tooltip
                        title={value.ToolTipdata}
                        placement="bottom-start"
                        className="toolTip"
                        arrow
                      >
                        <ErrorOutlineRoundedIcon className="tooltip-icon" />
                      </Tooltip>
                    </li>
                  )
                )}
            </ul>
          </Grid>
          <Grid xs className="quoteRightSection">
            <Box
              display={"flex"}
              gap={"24px"}
              alignItems={"flex-start"}
              flexWrap={"wrap"}
            >
              {quote_list_data.length != 0
                ? quote_list_data.map((data) => <TWQuoteCard quote={data} />)
                : null}
              {!QUOTE_LOADER && quote_list_data.length == 0 && (
                <div className="no-quote-box">
                  <img src="../images/no-quotes.svg" alt="" />
                  <h5>Sorry no quotes found!</h5>
                  <p>
                    We can't find the quotes as per your enterd details. Please
                    try again.
                  </p>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TWQuotePage;
