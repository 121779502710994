import React, { useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import CustomCheckbox from "../../../../InputFields/CustomCheckbox/CustomCheckbox";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import { TTWAddonStatus } from "../../../../../Services/Type/TwoWheeler/TTWAddonStatus";
import { TWSlice } from "../../../../../State/Slice_Reducer/TW/TWSlice";

function MTWAddons({
  open,
  setOpen,
  updateAddonAndValue,
}: {
  open: boolean;
  setOpen: any;
  updateAddonAndValue: Function;
}) {
  const dispatch = useAppDispatch();
  const { ADDON_STATUS, PLAN_TYPE } = useAppSelector((state) => state.TW);

  const [quoteFields, setQuoteFields] = useState();
  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  return (
    <>
      {open == true ? (
        <Box className="popupOverlay" onClick={() => setOpen(false)}>
          <Box
            className={`contentWrapper ${open ? "open" : ""}`}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            {/* <Box className="addonHeader">
              <h4>Addon Covers</h4>
            </Box> */}
            <Grid container columnSpacing={3}>
              <Grid xs={12}>
                <h4 className="mb-3">Addon Covers</h4>
              </Grid>
            </Grid>
            <hr />
            <Grid container columnSpacing={3}>
              <Grid xs={12}>
                <ul>
                  {Object.entries(ADDON_STATUS).map(([key, value]) =>
                    PLAN_TYPE === "Third Party" &&
                    (value.label === "PA Owner Cover" ||
                      value.label === "Driver Cover") ? (
                      <li key={key}>
                        <CustomCheckbox
                          label={value.label}
                          defaultChecked={value.value}
                          value={value.value}
                          value_update={(e: any, a: any) => {
                            const addOnKey = key as keyof TTWAddonStatus;
                            dispatch(
                              TWSlice.actions.UPDATE_ADDON_STATUS({
                                key: addOnKey,
                                value: key === "pa_owner_cover" ? true : a,
                              })
                            );
                            updateAddonAndValue(value.label, a);
                          }}
                        />
                      </li>
                    ) : null
                  )}
                  {PLAN_TYPE !== "Third Party" &&
                    Object.entries(ADDON_STATUS).map(([key, value]) =>
                      PLAN_TYPE == "SAOD" &&
                      (key === "pa_owner_cover" ||
                        key === "driver_cover") ? null : (
                        <li key={key}>
                          <CustomCheckbox
                            label={value.label}
                            defaultChecked={value.value}
                            value={value.value}
                            value_update={(e: any, a: any) => {
                              const addOnKey = key as keyof TTWAddonStatus;
                              dispatch(
                                TWSlice.actions.UPDATE_ADDON_STATUS({
                                  key: addOnKey,
                                  value: key === "pa_owner_cover" ? true : a,
                                })
                              );
                              if (value.label) {
                                updateAddonAndValue(value.label, a);
                              }
                            }}
                          />
                        </li>
                      )
                    )}
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default MTWAddons;
