import { useEffect } from "react";
import JourneyRequirements from "../../../../../Page/Desktop/Term/HDFC/Proposal/JourneyRequirement/JourneyRequirements";
import MJourneyRequirements from "../../../../../Page/Mobile/Term/HDFC/Proposal/JourneyRequirement/MJourneyRequirements";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

function JourneyRequirementContainer() {
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return <>{isMobile ? <MJourneyRequirements /> : <JourneyRequirements />}</>;
}

export default JourneyRequirementContainer;
