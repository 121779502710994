import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoopIcon from "@mui/icons-material/Loop";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "../../../../Common/Buttons/Buttons.scss";
import "../Footer/Footer.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../State/hooks";
import { TermSlice } from "../../../../../../State/Slice_Reducer/Term/TermSlice";
const Footer = ({
  continueFunction,
  backText = "Back",
  backStatus = false,
  continueText = "Continue",
  loading = false,
}: {
  continueFunction: Function;
  backStatus?: boolean;
  backText?: string;
  continueText?: string;
  loading?: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const proposerPage = useAppSelector((state) => state.Term.proposerPage);

  const back = () => {
    navigate(-1);
    if (proposerPage.update_status) {
      dispatch(TermSlice.actions.updateUpdateStatus(false));
    }
  };

  return (
    <Box className="proposalFooter">
      <Grid container spacing={3}>
        <Grid xs={12} className="footerInner" alignItems="center">
          <Link
            className="backStep"
            onClick={() => {
              back();
            }}
          >
            <ArrowBackIcon /> {backText}
          </Link>
          <div className="ctaBtn">
            <Button
              className="regularSecondaryBtn"
              disabled={loading}
              onClick={() => {
                continueFunction();
              }}
            >
              {loading && <LoopIcon />}
              {loading && <span>Loading</span>}
              {!loading && (
                <span>
                  {proposerPage.update_status ? "Update" : continueText}
                </span>
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
