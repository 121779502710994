import { PayloadAction } from "@reduxjs/toolkit";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";
import { TNRIDetails } from "../../../Types/TNRIDetails";

const setNRIDetails = (
  state: TTermSlice,
  action: PayloadAction<TNRIDetails>
) => {
  const data: TTermSlice = { ...state, NRIDetails: action.payload };
  return data;
};

export const TERM_NRI_DETAILS = {
  setNRIDetails,
};
