import { useEffect, useState } from "react";
import { TCarProduct } from "../../../../../Services/Type/Car/TCarProduct";
import { useAppSelector } from "../../../../../State/hooks";
import { DETERMINE_PLAN_TYPE_GO_DIGIT } from "../../../../../SupportingFiles/CarPremiumCalGoDigit";
import {
  CURRENCY_FORMAT,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";

function CarPolicyDetailsGoDigit({ quote }: { quote: TCarProduct }) {
  const { SELECTED_QUOTE_DATA, ADDON_STATUS } = useAppSelector(
    (state) => state.Car
  );

  const plan_name = {
    "": "",
    Pro: "Pro",
    DPro: "D Pro",
    DCPro: "DC Pro",
    DCEPro: "DCE Pro",
    DCTPro: "DCT Pro",
    DCETPro: "DCET Pro",
    DCRTIPro: "DC-RTI Pro",
    DCERTIPro: "DCE-RTI Pro",
    DCTRTIPro: "DCT-RTI Pro",
    DCETRTIPro: "DCET-RTI Pro",
  };
  const [planPremium, setPlanPremium] = useState("");
  const [planAddonList, setPlanAddonList] = useState<{ [key: string]: number }>(
    {}
  );

  const addon_names: { [key: string]: string } = {
    personalbelonging: "Personal Belonging",
    roadside: "24x7 Roadside Assistance",
    Key: "Key Replacement",
    zerodeps: "Zero Depreciation",
    consumable: "Consumable Cover",
    engine: "Engine Cover",
    tyre: "Tyre Cover",
    invoice: "Invoice Cover",
  };
  useEffect(() => {
    const premium_details: any =
      SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails;
    const plan_premium = `${
      premium_details[
        `GD_${DETERMINE_PLAN_TYPE_GO_DIGIT(ADDON_STATUS)}PlanPremium`
      ]
    }`;
    const plan_addon_list =
      premium_details[`GD_${DETERMINE_PLAN_TYPE_GO_DIGIT(ADDON_STATUS)}Plan`];
    setPlanPremium(plan_premium);
    setPlanAddonList(plan_addon_list);
  }, [ADDON_STATUS]);

  return (
    <ul className="addonList mt-4">
      <li>
        Basic OD:{" "}
        <span>
          {CURRENCY_FORMAT(
            parseFloat(
              `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicOD}`
            )
          )}
        </span>
      </li>
      <li>
        NCB Discount (-):{" "}
        <span>
          {CURRENCY_FORMAT(
            parseFloat(
              `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.ncbDiscount}`
            )
          )}
        </span>
      </li>
      {isEmpty(DETERMINE_PLAN_TYPE_GO_DIGIT(ADDON_STATUS)) ? null : (
        <li>
          {plan_name[DETERMINE_PLAN_TYPE_GO_DIGIT(ADDON_STATUS)]}:
          <span>{CURRENCY_FORMAT(parseFloat(planPremium))}</span>
        </li>
      )}
      {planAddonList &&
        Object.entries(planAddonList).map(([key, value]) =>
          value > 0 && addon_names[key] ? (
            <li>
              {addon_names[key]}:<span>{"Included"}</span>
            </li>
          ) : null
        )}
    </ul>
  );
}

export default CarPolicyDetailsGoDigit;
