import { PayloadAction } from "@reduxjs/toolkit";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";

function BULK_UPDATE(state: TTermSlice, action: PayloadAction<TTermSlice>) {
  const data: TTermSlice = { ...state, ...action.payload };

  return data;
}

export const TERM_REDUCERS = {
  BULK_UPDATE,
};
