import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CKYCDetails from "../../../../../Page/Desktop/Term/ICICI/Proposal/CKYCDetails/CKYCDetails";
import MCKYCDetails from "../../../../../Page/Mobile/Term/ICICI/Proposal/CKYCDetails/MCKYCDetails";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { CKYCDetailsDTO } from "../../../../../Services/DTO/Term/CkycDetailsDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { EMasterDropdown } from "../../../../../State/Enum/EMasterDropdown";
import { TCKYCDetails } from "../../../../../State/Types/TCKYCDetails";
import { TDropdown } from "../../../../../State/Types/TDropdown";
import { PROPOSAL_PAGE_SECTION_STATUS_API } from "../../../../../State/Types/TProposerDetails";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";

function CKYCContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const { selectedQuote, proposerPage, ckycDetails } = useAppSelector(
    (state) => state.Term
  );
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<TCKYCDetails>(ckycDetails);

  useEffect(() => {
    getDropdownData(EMasterDropdown.AGE_PROOF);
    getDropdownData(EMasterDropdown.IDENTITY_PROOF);
    getDropdownData(EMasterDropdown.ADDRESS_PROOF);
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [attrName]: { value: value, warning: isEmpty(value) },
    }));
  };

  const getDropdownData = (type: EMasterDropdown) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            key: obj.code,
            value: obj.title,
          };
        });
        if (type === EMasterDropdown.AGE_PROOF) {
          dispatch(TermSlice.actions.setAgeProof(data));
        } else if (type === EMasterDropdown.IDENTITY_PROOF) {
          dispatch(TermSlice.actions.SET_IDENTITY_PROOF_MASTER(data));
        } else if (type === EMasterDropdown.ADDRESS_PROOF) {
          dispatch(TermSlice.actions.setAddressProof(data));
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getDropdownDataMaster(
      onSuccess,
      onError,
      selectedQuote.product_code,
      type,
      selectedQuote.buy_online_code
    );
  };

  const validate_data = () => {
    var data: TCKYCDetails = { ...formData };

    data = {
      ...data,
      age_proof: {
        ...data.age_proof,
        warning: isEmpty(data.age_proof.value),
      },
    };
    if (data.age_proof.value !== "Adhr") {
      data = {
        ...data,
        age_proof_doc_no: {
          ...data.age_proof_doc_no,
          warning: isEmpty(data.age_proof_doc_no.value),
        },
      };
    } else {
      data = {
        ...data,
        age_proof_doc_no: {
          ...data.age_proof_doc_no,
          warning: false,
        },
      };
    }
    if (data.age_proof.value === "DL" || data.age_proof.value === "PSPT") {
      data = {
        ...data,
        age_proof_exp_date: {
          ...data.age_proof_exp_date,
          warning: isEmpty(data.age_proof_exp_date.value),
        },
      };
    } else {
      data = {
        ...data,
        age_proof_exp_date: {
          ...data.age_proof_exp_date,
          warning: false,
        },
      };
    }

    data = {
      ...data,
      address_proof: {
        ...data.address_proof,
        warning: isEmpty(data.address_proof.value),
      },
    };

    if (data.address_proof.value !== "Adhr") {
      data = {
        ...data,
        address_proof_doc_no: {
          ...data.address_proof_doc_no,
          warning: isEmpty(data.address_proof_doc_no.value),
        },
      };
    } else {
      data = {
        ...data,
        address_proof_doc_no: {
          ...data.address_proof_doc_no,
          warning: false,
        },
      };
    }
    if (
      data.address_proof.value === "DL" ||
      data.address_proof.value === "PSPT"
    ) {
      data = {
        ...data,
        address_proof_exp_date: {
          ...data.address_proof_exp_date,
          warning: isEmpty(data.address_proof_exp_date.value),
        },
      };
    } else {
      data = {
        ...data,
        address_proof_exp_date: {
          ...data.address_proof_exp_date,
          warning: false,
        },
      };
    }

    data = {
      ...data,
      identity_proof: {
        ...data.identity_proof,
        warning: isEmpty(data.identity_proof.value),
      },
    };

    if (data.identity_proof.value !== "Adhr") {
      data = {
        ...data,
        identity_proof_doc_no: {
          ...data.identity_proof_doc_no,
          warning: isEmpty(data.identity_proof_doc_no.value),
        },
      };
    } else {
      data = {
        ...data,
        identity_proof_doc_no: {
          ...data.identity_proof_doc_no,
          warning: false,
        },
      };
    }

    if (
      data.identity_proof.value === "DL" ||
      data.identity_proof.value === "PSPT"
    ) {
      data = {
        ...data,
        identity_proof_exp_date: {
          ...data.identity_proof_exp_date,
          warning: isEmpty(data.identity_proof_exp_date.value),
        },
      };
    } else {
      data = {
        ...data,
        identity_proof_exp_date: {
          ...data.identity_proof_exp_date,
          warning: false,
        },
      };
    }

    data = {
      ...data,
      it_proof: {
        ...data.it_proof,
        warning: isEmpty(data.it_proof.value),
      },
    };
    setFormData(data);

    const hasWarning = (details: any): boolean => {
      return Object.values(details).some(
        (property: any) => property?.warning === true
      );
    };

    const hasError: boolean = hasWarning(data);

    if (!hasError) {
      UpdateCKYCDetails(data);
    }
  };

  const UpdateCKYCDetails = (ckycDetails: TCKYCDetails) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;
      console.log(error);
      if (error === false) {
        dispatch(TermSlice.actions.SET_ICICI_CKYC_DETAILS(ckycDetails));
        if (proposerPage.update_status) {
          navigate(-1);
          dispatch(TermSlice.actions.updateUpdateStatus(false));
        } else {
          navigate(TERM_PATH.MEDICAL_PAGE);
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: CKYCDetailsDTO = {
      buy_online_code: selectedQuote.buy_online_code,
      quote_no: btoa(selectedQuote.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.CKYC_DETAILS,
      details: {
        address_proof: ckycDetails.address_proof.value,
        address_proof_doc_no: ckycDetails.address_proof_doc_no.value,
        address_proof_exp_date: ckycDetails.address_proof_exp_date.value,
        age_proof: ckycDetails.age_proof.value,
        age_proof_doc_no: ckycDetails.age_proof_doc_no.value,
        age_proof_exp_date: ckycDetails.age_proof_exp_date.value,
        identity_proof: ckycDetails.identity_proof.value,
        identity_proof_doc_no: ckycDetails.identity_proof_doc_no.value,
        identity_proof_exp_date: ckycDetails.identity_proof_exp_date.value,
        it_proof: ckycDetails.it_proof.value,
      },
    };
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  return (
    <>
      {isMobile ? (
        <MCKYCDetails
          continueFunction={validate_data}
          loader={loader}
          formData={formData}
          updateMasterState={updateMasterState}
        />
      ) : (
        <CKYCDetails
          continueFunction={validate_data}
          loader={loader}
          formData={formData}
          updateMasterState={updateMasterState}
        />
      )}
    </>
  );
}

export default CKYCContainer;
