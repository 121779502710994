import EditIcon from "@mui/icons-material/Edit";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const AddressDetailBox = ({
  permanentAddress,
  mailingAddress,
  permanent_mailing_address_same,
  editFunction,
}: {
  permanentAddress: Array<{
    label: string;
    label_value: string | Date | null;
  }>;
  mailingAddress: Array<{
    label: string;
    label_value: string | Date | null;
  }>;
  permanent_mailing_address_same: boolean;
  editFunction: Function;
}) => {
  return (
    <Box className="previewBox">
      <Grid container spacing={0}>
        <Grid xs={8}>
          <p className="previewBoxTitle">Address Details</p>
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            className="previewBoxEdit"
            onClick={() => {
              editFunction();
            }}>
            <EditIcon /> Edit
          </Link>
        </Grid>
        <Grid xs={8}>
          <p className="">Permanent Address :-</p>
        </Grid>
        <Grid xs={12}>
          <ul>
            {permanentAddress.map((data) => (
              <li>
                <p>
                  {data.label}: <span>{`${data.label_value}`}</span>
                </p>
              </li>
            ))}
          </ul>
        </Grid>
        {permanent_mailing_address_same === true ? (
          <Grid xs={10}>
            <p className="previewBoxTitle">
              <LocationOnIcon style={{ fontSize: 14 }} /> Mailing address is
              same as permnanent address.
            </p>
          </Grid>
        ) : (
          <>
            <Grid xs={8}>
              <p className="">Mailing Address :-</p>
            </Grid>
            <Grid xs={12}>
              <ul>
                {mailingAddress.map((data) => (
                  <li>
                    <p>
                      {data.label}: <span>{`${data.label_value}`}</span>
                    </p>
                  </li>
                ))}
              </ul>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AddressDetailBox;
