import { useState } from "react";
import DiscountPopup from "../../Component/Desktop/Car/QuotePage/DiscountPopup/DiscountPopup";
import MDiscountPopup from "../../Component/Mobile/Car/QuotePage/MDiscountPopup/MDiscountPopup";
import { CAR_SERVICES } from "../../Services/CAR/CarServices";
import { UpdateCarDiscountDTO } from "../../Services/DTO/CarDTO/UpdateCarDiscountDTO";
import { CarSlice } from "../../State/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../State/hooks";
import useIsMobile from "../../SupportingFiles/MobileProvider";

export default function CarDiscountPopupContainer({
  open,
  setOpen,
  get_product_list,
}: {
  open: boolean;
  setOpen: Function;
  get_product_list: Function;
}) {
  const { ADD_FORM, ADD_FORM_RESPONSE } = useAppSelector((state) => state.Car);
  const isMobile = useIsMobile();
  const { Car } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [update, setUpdate] = useState<"Individual" | "Organization">(ADD_FORM.vehicle_owned);
  console.log("update4", update);
  const UPDATE_CAR_DISCOUNT = () => {
    const onSuccess = (res: any) => {
      setOpen(false);
      get_product_list();
      dispatch(
        CarSlice.actions.BULK_UPDATE({
          ...Car,
          ADD_FORM: { ...Car.ADD_FORM, vehicle_owned: update },
          ADDON_STATUS: {
            ...Car.ADDON_STATUS,
            pa_owner_cover: {
              ...Car.ADDON_STATUS.pa_owner_cover,
              value: update === "Individual" ? true : false,
            },
          },
        })
      );
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    const param: UpdateCarDiscountDTO = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      deductible: ADD_FORM.deductible,
      anti_theft_device: ADD_FORM.anti_theft_device,
      type: "CAR",
      automobile_association: ADD_FORM.automobile_association,
      vehicleOwned: update,
    };
    CAR_SERVICES.UPDATE_DISCOUNT_DETAILS(onSuccess, onError, param);
  };

  return (
    <>
      {isMobile ? (
        <MDiscountPopup
          UPDATE_CAR_DISCOUNT={UPDATE_CAR_DISCOUNT}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        <DiscountPopup
          UPDATE_CAR_DISCOUNT={UPDATE_CAR_DISCOUNT}
          open={open}
          setOpen={setOpen}
          setUpdate={setUpdate}
          update={update}
        />
      )}
    </>
  );
}
