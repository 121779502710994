import { PayloadAction } from "@reduxjs/toolkit";
import {
  DROPDOWN_DATA,
  IDV_VALUE,
  TAddressDetails,
  TKycDetails,
  TNomineeDetails,
  TOwnerDetails,
  TTWAddForm,
  TTWAddFormResponse,
  TTWSlice,
  TVehicleDetails,
} from "../../../Services/Type/TTWSlice";
import { TTWAddonStatus } from "../../../Services/Type/TwoWheeler/TTWAddonStatus";
import { TDropdown } from "../../Types/TDropdown";
import { TSearchableDropdown } from "../../Types/TSearchableDropdown";

function BULK_UPDATE(state: TTWSlice, action: PayloadAction<TTWSlice>) {
  let data: TTWSlice = { ...state };

  data = { ...data, ...action.payload };

  return data;
}

function ADD_FORM_DATA(state: TTWSlice, action: PayloadAction<TTWAddForm>) {
  let data: TTWSlice = { ...state };

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, ...action.payload } };

  return data;
}

type keyTTWAddForm = keyof TTWAddForm;
function UPDATE_FORM_DATA(
  state: TTWSlice,
  action: PayloadAction<{ key: keyTTWAddForm; value: any }>
) {
  const { key, value } = action.payload;
  let data: TTWSlice = { ...state };

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, [key]: value } };

  return data;
}

function ADD_FORM_RESPONSE(
  state: TTWSlice,
  action: PayloadAction<TTWAddFormResponse>
) {
  let data: TTWSlice = { ...state };

  data = {
    ...data,
    ADD_FORM_RESPONSE: { ...data.ADD_FORM_RESPONSE, ...action.payload },
  };

  return data;
}

type valueTypeDropdown = keyof DROPDOWN_DATA;
function SET_DROPDOWN_DATA(
  state: TTWSlice,
  action: PayloadAction<{
    key: valueTypeDropdown;
    value: TSearchableDropdown[];
  }>
) {
  const { key, value } = action.payload;
  let data: TTWSlice = { ...state };

  data = {
    ...data,
    DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value },
  };

  return data;
}

type valueTypeAddonStatus = keyof TTWAddonStatus;
function UPDATE_ADDON_STATUS(
  state: TTWSlice,
  action: PayloadAction<{ key: valueTypeAddonStatus; value: boolean }>
) {
  const { key, value } = action.payload;
  let data: TTWSlice = { ...state };

  data = {
    ...data,
    ADDON_STATUS: {
      ...data.ADDON_STATUS,
      [key]: { ...data.ADDON_STATUS[key], value: value },
    },
  };

  return data;
}

function UPDATE_QUOTE_LOADER(state: TTWSlice, action: PayloadAction<boolean>) {
  let data: TTWSlice = { ...state };

  data = { ...data, QUOTE_LOADER: action.payload };

  return data;
}
function UPDATE_QUOTE_STATUS(state: TTWSlice, action: PayloadAction<boolean>) {
  let data: TTWSlice = { ...state };

  data = { ...data, QUOTE_STATUS: action.payload };

  return data;
}
type KEY_DROPDOWN_MASTER = keyof DROPDOWN_DATA;
const UPDATE_DROPDOWN_MASTER = (
  state: TTWSlice,
  action: PayloadAction<{ key: KEY_DROPDOWN_MASTER; value: TDropdown[] }>
) => {
  const { key, value } = action.payload;
  const data = {
    ...state,
    DROPDOWN_DATA: {
      ...state.DROPDOWN_DATA,
      [key]: value,
    },
  };

  return data;
};

const UPDATE_PLAN_TYPE = (
  state: TTWSlice,
  action: PayloadAction<
    "Third Party" | "Comprehensive" | "Bundle Policy" | "SAOD"
  >
) => {
  const data = {
    ...state,
    PLAN_TYPE: action.payload,
  };

  return data;
};

function OWNER_DETAILS(state: TTWSlice, action: PayloadAction<TOwnerDetails>) {
  let data: TTWSlice = { ...state };
  data = {
    ...data,
    OWNER_DETAILS: { ...data.OWNER_DETAILS, ...action.payload },
  };

  return data;
}

function UPDATE_OWNER_DETAILS(
  state: TTWSlice,
  action: PayloadAction<{ key: keyof TOwnerDetails; value: any }>
) {
  let data: TTWSlice = { ...state };
  const { key, value } = action.payload;
  data = {
    ...data,
    OWNER_DETAILS: { ...data.OWNER_DETAILS, [key]: value },
  };

  return data;
}

function VEHICLE_DETAILS(
  state: TTWSlice,
  action: PayloadAction<TVehicleDetails>
) {
  let data: TTWSlice = { ...state };

  data = {
    ...data,
    VEHICLE_DETAILS: { ...data.VEHICLE_DETAILS, ...action.payload },
  };

  return data;
}

type ValueTypes = keyof TVehicleDetails;

function UPDATE_VEHICLE_DEATILS_INDIVISUAL(
  state: TTWSlice,
  action: PayloadAction<{ key: ValueTypes; value: any }[]>
) {
  const updatedState: TTWSlice = {
    ...state,
    VEHICLE_DETAILS: {
      ...state.VEHICLE_DETAILS,
    },
  };

  if (Array.isArray(action.payload)) {
    for (const { key, value } of action.payload) {
      updatedState.VEHICLE_DETAILS[key] = value;
    }
  } else {
    console.error("Invalid payload format. Expected an array.");
  }

  return updatedState;
}

type CKYCValueTypes = keyof TKycDetails;

function CKYC_DETAILS(
  state: TTWSlice,
  action: PayloadAction<{ key: CKYCValueTypes; value: any }[]>
) {
  const updatedState: TTWSlice = {
    ...state,
    CKYC_DETAILS: {
      ...state.CKYC_DETAILS,
    },
  };

  if (Array.isArray(action.payload)) {
    for (const { key, value } of action.payload) {
      updatedState.CKYC_DETAILS[key] = value;
    }
  } else {
    console.error("Invalid payload format. Expected an array.");
  }

  return updatedState;
}
type NomineeValueTypes = keyof TNomineeDetails;

function NOMINEE_DETAILS(
  state: TTWSlice,
  action: PayloadAction<{ key: NomineeValueTypes; value: any }[]>
) {
  const updatedState: TTWSlice = {
    ...state,
    NOMINEE_DETAILS: {
      ...state.NOMINEE_DETAILS,
    },
  };

  if (Array.isArray(action.payload)) {
    for (const { key, value } of action.payload) {
      updatedState.NOMINEE_DETAILS[key] = value;
    }
  } else {
    console.error("Invalid payload format. Expected an array.");
  }

  return updatedState;
}

type AddressValueTypes = keyof TAddressDetails;

function ADDRESS_DETAILS(
  state: TTWSlice,
  action: PayloadAction<{ key: AddressValueTypes; value: any }[]>
) {
  const updatedState: TTWSlice = {
    ...state,
    ADDRESS_DETAILS: {
      ...state.ADDRESS_DETAILS,
    },
  };

  if (Array.isArray(action.payload)) {
    for (const { key, value } of action.payload) {
      updatedState.ADDRESS_DETAILS[key] = value;
    }
  } else {
    console.error("Invalid payload format. Expected an array.");
  }
  return updatedState;
}
const CHANGE_PAGE_STATUS = (
  state: TTWSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    PAGE_STATUS: action.payload,
  };

  return data;
};
const UPDATE_QUOTE_NO = (state: TTWSlice, action: PayloadAction<string>) => {
  const data = {
    ...state,
    ADD_FORM_RESPONSE: { ...state.ADD_FORM_RESPONSE, quote_no: action.payload },
  };

  return data;
};
const IDV_UPDATE = (state: TTWSlice, action: PayloadAction<IDV_VALUE>) => {
  console.log("action.payload ", action.payload);
  const data = {
    ...state,
    IDV_VALUE: {
      ...action.payload,
    },
  };

  return data;
};
const IDVAPPLY_STATUS = (state: TTWSlice, action: PayloadAction<boolean>) => {
  const data = {
    ...state,
    IDVApply_Status: action.payload,
  };
  return data;
};
export const TW_REDUCERS = {
  BULK_UPDATE,
  UPDATE_QUOTE_NO,
  ADD_FORM_DATA,
  UPDATE_FORM_DATA,
  ADD_FORM_RESPONSE,
  SET_DROPDOWN_DATA,
  UPDATE_ADDON_STATUS,
  UPDATE_QUOTE_LOADER,
  IDV_UPDATE,
  IDVAPPLY_STATUS,
  UPDATE_DROPDOWN_MASTER,
  UPDATE_PLAN_TYPE,
  UPDATE_QUOTE_STATUS,
  OWNER_DETAILS,
  UPDATE_OWNER_DETAILS,
  VEHICLE_DETAILS,
  UPDATE_VEHICLE_DEATILS_INDIVISUAL,
  CKYC_DETAILS,
  ADDRESS_DETAILS,
  NOMINEE_DETAILS,
  CHANGE_PAGE_STATUS,
};

export type TWReducers = typeof TW_REDUCERS;
