import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import { URL_CONSTANTS } from "../../../../../Shared/URLS";
import "../Navbar/Navbar.scss";
import { URL_CONSTANTS } from "../../../../../Shared/URLS";

const Navbar = () => {
  return (
    <Box className="proposalNavbar">
      <Grid container columnSpacing={2} rowSpacing={0}>
        <Grid xs={12}>
          <Link
            onClick={() => {
              window.location.href = URL_CONSTANTS.HOME_PAGE_URL;
            }}
          >
            <img src="./images/motilalOswal.svg" height="36px" alt="Brand Logo" />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
