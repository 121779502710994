import { Box, colors } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../../../../Component/Mobile/Term/ProposalForm/Common/Footer/Footer";
import SelectDropdown from "../../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../../Component/InputFields/RKTextField/RKTextField";
import { THDFCCKYCDetails } from "../../../../../../State/Types/TCKYCDetails";
import { useAppSelector } from "../../../../../../State/hooks";

const MCKYCDetails = ({
  loader,
  continueFunction,
  updateMasterState,
  formData,
}: {
  loader: boolean;
  continueFunction: Function;
  updateMasterState: Function;
  formData: THDFCCKYCDetails;
}) => {
  const { dropdownData, proposerDetails } = useAppSelector(
    (state) => state.Term
  );

  return (
    <Box>
      <h5 className="sectionTitle">CKYC/eKYC Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6 className="mb-4">
            {" "}
            Central KYC (CKYC) registry is a centralized repository of your KYC
            records.
          </h6>
          <p className="textGrey">
            <span className="textBold">Advantages of CKYC are:-</span>
          </p>
          <ul style={{ color: colors.grey[500] }}>
            <li>
              A single KYC for all your financial transactions i.e. no
              repetitive submission of documents for verification.
            </li>
            <li>
              It is secure, hassle free and saves documentation procession time.
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"CKYC no. (optional)"}
            value={formData.ckyc_no.value}
            attrName={["ckyc_no", "value", formData, "setFormData"]}
            value_update={updateMasterState}
            warn_status={formData.ckyc_no.warning}
            error_message="Please enter valid CKYC no."
            number_only
            max_length={14}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Father's full name"}
            value={formData.father_full_name.value}
            attrName={["father_full_name", "value", formData, "setFormData"]}
            value_update={updateMasterState}
            warn_status={formData.father_full_name.warning}
            error_message="Please enter father's full name"
            max_length={30}
            alphabet_only={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Mother's full name"}
            value={formData.mother_full_name.value}
            attrName={["mother_full_name", "value", formData, "setFormData"]}
            value_update={updateMasterState}
            warn_status={formData.mother_full_name.warning}
            error_message="Please enter mother's full name"
            max_length={30}
            alphabet_only={true}
          />
        </Grid>
        {proposerDetails.gender.value === "F" &&
        ((typeof proposerDetails.marital_status.value === "string" &&
          proposerDetails.marital_status.value === "MAR_MRD") ||
          proposerDetails.marital_status.value.code === "MAR_MRD") ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Spouse full name"}
              value={formData.spouse_name.value}
              attrName={["spouse_name", "value", formData, "setFormData"]}
              value_update={updateMasterState}
              warn_status={formData.spouse_name.warning}
              error_message="Please enter spouse full name"
              max_length={61}
              validation_type="NAME"
            />
          </Grid>
        ) : null}
        {proposerDetails.occupation.value.code === "OCCT_SALR" ||
        proposerDetails.occupation.value.code === "OCCT_SEBS" ||
        (typeof proposerDetails.occupation.value === "string" &&
          (proposerDetails.occupation.value === "OCCT_SALR" ||
            proposerDetails.occupation.value === "OCCT_SEBS")) ? (
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Occupation"
              value={formData.occupation.value}
              attrName={["occupation", "value", formData, "setFormData"]}
              value_update={updateMasterState}
              data={dropdownData.HDFC_CKYC_OCCUPATION}
              warn_status={formData.occupation.warning}
            />
          </Grid>
        ) : null}
        {proposerDetails.occupation.value.code === "OCCT_SALR" ||
        (typeof proposerDetails.occupation.value === "string" &&
          proposerDetails.occupation.value === "OCCT_SALR") ? (
          <>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Employer name"}
                value={formData.employer_name.value}
                attrName={["employer_name", "value", formData, "setFormData"]}
                value_update={updateMasterState}
                warn_status={formData.employer_name.warning}
                error_message="Please enter employer name"
                max_length={50}
                validation_type="NAME"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Employer address"}
                value={formData.employer_address.value}
                attrName={[
                  "employer_address",
                  "value",
                  formData,
                  "setFormData",
                ]}
                value_update={updateMasterState}
                warn_status={formData.employer_address.warning}
                error_message="Please enter employer address"
                max_length={100}
                validation_type="NAME"
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Footer continueFunction={continueFunction} />
    </Box>
  );
};

export default MCKYCDetails;
