import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Box, Button, colors } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../../../../../Component/Desktop/Term/ProposalForm/Common/Footer/Footer";
import UploadDocumentDetails from "../../../../../../Component/Desktop/Term/ProposalForm/UploadDocumentDetails/UploadDocumentDetails";
import RKTextField from "../../../../../../Component/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Component/InputFields/SelectDropdown/SelectDropdown";
import { HDFC_PROPOSAL_PAGE_SERVICES } from "../../../../../../Services/TermServices/HDFCProposalPageSubmissionService";
import { TermSlice } from "../../../../../../State/Slice_Reducer/Term/TermSlice";
import { useAppSelector } from "../../../../../../State/hooks";
import { uploadImage } from "../../../../../../SupportingFiles/HelpingFunction";

const UploadDocument = ({
  continueFunction,
}: {
  continueFunction: Function;
}) => {
  const { HDFCUploadDocDetails, selectedQuote } = useAppSelector(
    (state) => state.Term
  );

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const updateMasterState = (attrName: any, value: any) => {
    dispatch(
      TermSlice.actions.SET_HDFC_UPLOAD_DOC_TYPE({
        index: attrName,
        value: value,
      })
    );
  };

  const upload_document = (index: number) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        dispatch(
          TermSlice.actions.SET_HDFC_IMAGE_UPLOAD_STATUS({
            index: index,
            value: true,
          })
        );
      } else {
        dispatch(
          TermSlice.actions.SET_HDFC_IMAGE_UPLOAD_STATUS({
            index: index,
            value: false,
          })
        );
        console.log({ error: error, message: message });
      }

      setLoader(false);
    };
    const onError = (err: any) => {
      console.log(err);
      setLoader(false);
    };

    const data = HDFCUploadDocDetails.data[index];
    const api_data: any = {
      quote_no: btoa(selectedQuote.quote_no),
      product_id: selectedQuote.id,
      appnum: selectedQuote.appno,
      docnm: data.image_name,
      bytearray: data.image_base64,
      doccd: data.document_cd,
      reqcd: data.requirement_cd,
    };

    setLoader(true);

    if (data.document_type && data.image_base64) {
      HDFC_PROPOSAL_PAGE_SERVICES.uploda_doc_image(
        onSuccess,
        onError,
        api_data
      );
    } else {
      toast.error("Please select document type/image.");
    }
  };

  return (
    <Box>
      <UploadDocumentDetails />
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6 className="question">
            Submitted documents (proof) should match with details entered while
            filling the form.
          </h6>
        </Grid>
        <Grid xs={12}>
          <>
            {HDFCUploadDocDetails.data.map((data, index) => (
              <Grid container spacing={1} key={index}>
                <Grid xs={3}>
                  <SelectDropdown
                    class_name="inputField"
                    title={data.requirement_desc}
                    value={data.document_type}
                    attrName={index}
                    value_update={updateMasterState}
                    data={[{ key: data.document_nm, value: data.document_nm }]}
                    warn_status={false}
                    error_message="Please select value."
                  />
                </Grid>
                <Grid xs={4} display={"flex"}>
                  <RKTextField
                    sx={{ width: "100%" }}
                    class_name="inputField"
                    title={data.document_nm}
                    value={data.image_name}
                    attrName={""}
                    value_update={() => {}}
                    warn_status={false}
                    disabled
                    error_message="Please select image."
                  />
                  <Button
                    variant="contained"
                    className="browsebtn"
                    component="label"
                  >
                    Browse
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      style={{ zIndex: "99" }}
                      onChange={(e: any) => {
                        const file = e.target.files[0];
                        const fileSizeInMB = file.size / (1024 * 1024);

                        //check file is not more than 50mb
                        if (fileSizeInMB > 6) {
                          toast.error(
                            "File size exceeds the maximum limit of 5 MB."
                          );
                        } else {
                          uploadImage(
                            file,
                            (base64Image: string, base64String: string) => {
                              dispatch(
                                TermSlice.actions.SET_HDFC_IMAGE_BASE64({
                                  index: index,
                                  value: base64String,
                                })
                              );
                              dispatch(
                                TermSlice.actions.SET_HDFC_IMAGE_NAME({
                                  index: index,
                                  value: file.name,
                                })
                              );
                            }
                          );
                        }
                      }}
                    />
                  </Button>
                </Grid>
                {data.image_base64 && !data.upload_status ? (
                  <Grid
                    xs={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loader ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Button
                        variant="contained"
                        className="browsebtn uploadbtn"
                        onClick={() => {
                          upload_document(index);
                        }}
                      >
                        Upload
                      </Button>
                    )}
                  </Grid>
                ) : null}
                {data.upload_status ? (
                  <Grid
                    xs={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DoneAllIcon sx={{ color: colors.green[700] }} />
                  </Grid>
                ) : null}
              </Grid>
            ))}
          </>
        </Grid>
      </Grid>
      <Footer continueFunction={continueFunction} />
    </Box>
  );
};

export default UploadDocument;
