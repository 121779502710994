import { PayloadAction } from "@reduxjs/toolkit";

import {
  COMMON_DROPDOWN_DATA,
  INSURED_MEMBER_DETAILS,
  THealthAddForm,
  THealthNomineeDetails,
  THealthSlice,
} from "../../../Services/Type/Health/THealthSlice";
import { THealthFormResponse } from "../../../Services/Type/Health/Form/THealthFormResponse";
import { THealthQuote } from "../../../Services/Type/Health/HealthQuotation/THealthQuote";
import { THealthBuyActionResopnse } from "../../../Services/Type/Health/HealthQuotation/THealthBuyActionResponse";
import {
  TAddressDetails,
  TAdultOneDetails,
  TOtherMemberDetails,
} from "../../../Services/Type/Health/ProposalDeatail/TProposalDetail";
import { TDropdown } from "../../Types/TDropdown";

function setAddFormData(
  state: THealthSlice,
  action: PayloadAction<THealthAddForm>
) {
  return {
    ...state,
    ADD_FORM: { ...action.payload },
  };
}

function setAddFormResponse(
  state: THealthSlice,
  action: PayloadAction<THealthFormResponse>
) {
  return { ...state, ADD_FORM_RESPONSE: action.payload };
}

function setAddFormResponseUpdateOneKey(
  state: THealthSlice,
  action: PayloadAction<{ key: keyof THealthFormResponse; value: any }>
) {
  const { key, value } = action.payload;
  return {
    ...state,
    ADD_FORM_RESPONSE: {
      ...state.ADD_FORM_RESPONSE,
      [key]: value,
    },
  };
}

const SAVE_ALL_QUOTES_DATA = (
  state: THealthSlice,
  action: PayloadAction<THealthQuote[]>
) => {
  const data = {
    ...state,
    ALL_QUOTES_DATA: action.payload,
  };

  return data;
};

const SAVE_SELECTED_QUOTE_DATA = (
  state: THealthSlice,
  action: PayloadAction<THealthQuote>
) => {
  const data = {
    ...state,
    SELECTED_QUOTE_DATA: action.payload,
  };

  return data;
};

const SAVE_BUYACTION_RESPONSE_DATA = (
  state: THealthSlice,
  action: PayloadAction<THealthBuyActionResopnse>
) => {
  const data = {
    ...state,
    BUYACTION_RESPONSE_DATA: action.payload,
  };

  return data;
};

type KEY_MEMBER_FORM_DATA = keyof INSURED_MEMBER_DETAILS;
const SAVE_INSURED_MEMBER_DETAILS = (
  state: THealthSlice,
  action: PayloadAction<{
    key: KEY_MEMBER_FORM_DATA;
    value: TOtherMemberDetails | TAdultOneDetails | THealthNomineeDetails;
  }>
) => {
  const { key, value } = action.payload;
  const data: THealthSlice = {
    ...state,
    INSURED_MEMBER_DETAILS: { ...state.INSURED_MEMBER_DETAILS, [key]: value },
  };
  return data;
};

const SAVE_ADDRESS_DETAILS = (
  state: THealthSlice,
  action: PayloadAction<TAddressDetails>
) => {
  const data: THealthSlice = {
    ...state,
    ADDRESS_DETAILS: { ...action.payload },
  };
  return data;
};

const SAVE_MEDICAL_QUESTION_DATA = (
  state: THealthSlice,
  action: PayloadAction<any>
) => {
  const data = {
    ...state,
    MEDICAL_QUESTION_DATA: action.payload,
  };

  return data;
};

const UPDATE_QUOTE_LOADER_STATUS = (
  state: THealthSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    QUOTE_LOADER: action.payload,
  };

  return data;
};
const CHANGE_PAGE_STATUS = (
  state: THealthSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    PAGE_STATUS: action.payload,
  };

  return data;
};

type COMMON_DROPDOWN_DATA_API = keyof COMMON_DROPDOWN_DATA;
const HEALTH_DROPDOWNDATA = (
  state: THealthSlice,
  action: PayloadAction<{ key: COMMON_DROPDOWN_DATA_API; value: TDropdown[] }>
) => {
  const { key, value } = action.payload;
  let data = { ...state };
  data = {
    ...state,
    DROPDOWN_DATA: {
      ...state.DROPDOWN_DATA,
      [key]: value,
    },
  };
  return data;
};

const CHANGE_NOMINEE_STATUS = (
  state: THealthSlice,
  action: PayloadAction<"ADULT_2" | "OTHER">
) => {
  let data = { ...state };
  data = {
    ...state,
    NOMINEE_RELATIONSHIP_STATUS: action.payload,
  };
  return data;
};

export const HEALTH_REDUCERS = {
  setAddFormData,
  setAddFormResponse,
  SAVE_ALL_QUOTES_DATA,
  SAVE_BUYACTION_RESPONSE_DATA,
  SAVE_INSURED_MEMBER_DETAILS,
  SAVE_ADDRESS_DETAILS,
  SAVE_MEDICAL_QUESTION_DATA,
  HEALTH_DROPDOWNDATA,
  SAVE_SELECTED_QUOTE_DATA,
  CHANGE_PAGE_STATUS,
  CHANGE_NOMINEE_STATUS,
  UPDATE_QUOTE_LOADER_STATUS,
  setAddFormResponseUpdateOneKey,
};

export type HealthReducers = typeof HEALTH_REDUCERS;
