import EmailIcon from "@mui/icons-material/Email";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Tab } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { TAddonsValueType } from "../../../../../Services/Type/Car/TAddonsValueType";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  cal_gst,
  calculate_premium_for_car,
} from "../../../../../SupportingFiles/CarPermiumCal";
import { formatToCurrency } from "../../../../../SupportingFiles/HelpingFunction";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import "./../../../../../CommonSCSS/ModalPopup.scss";
import { TCARBUYACTION } from "../../../../../Services/Type/Car/TCARBUYACTION";
import { TCarProduct } from "../../../../../Services/Type/Car/TCarProduct";
import { CAR_SERVICES } from "../../../../../Services/CAR/CarServices";
import { CarSlice } from "../../../../../State/Slice_Reducer/Car/CarSlice";

const MCarPolicyDetail = ({
  open,
  setOpen,
  addon_values,
  quote,
}: {
  open: boolean;
  setOpen: any;
  addon_values: TAddonsValueType;
  quote: TCarProduct;
}) => {
  const [value, setValue] = React.useState("1");
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_STATUS,
    SELECTED_QUOTE_DATA,
    PLAN_TYPE,
  } = useAppSelector((state) => state.Car);
  const plasticpercentage = 46;
  const metalpercentage = 23;
  const fibrepercentage = 30;
  const glasspercentage = 12;
  const paintpercentage = 5;

  const selectedQuote: any = SELECTED_QUOTE_DATA;
  const formatKey = (key: string) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str: string) => str.toUpperCase());
  };
  const addon_tag_name: any = {
    pa_owner_cover: "paOwnerDriver",
    rsa: "roadSideAssistance",
    depreciation_cover: "depreciationCover",
    driver_cover: "driverCover",
    consumable_cover: "consumablesCover",
    engine_cover: "engineGearBoxProtection",
    invoice_cover: "invoicePriceCover",
    key_replacement: "keyReplacementCover",
    ncb_cover: "noClaimBonusSameSlab",
    tyre_cover: "tyreSecureCover",
    accessories_cover: "accessoryCoverCharge",
    cng_kit: "cngKitOD",
    passenger_cover: "passerngerCover",
    electrical: "electricalCharge",
    non_electrical: "nonElecCharge",
  };
  const Buyaction = () => {
    const onSuccess = () => {
      const redirection_url = `https://dev.insurance.motilaloswal.com/car-insurance/ckyc-verification/${btoa(
        `${quote.quotationDetail?.productDetails.id}-${ADD_FORM_RESPONSE.quote_no}`
      )}`;
      window.location.href = redirection_url;
    };

    const onError = () => {};

    const Params: TCARBUYACTION = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "CAR",
      product_code: quote.product_code,
      productId: quote.id,
      company_code: quote.company_code,
      IDV: quote.quotationDetail?.premiumDetails.IDV,
      finalPremium: quote.quotationDetail?.premiumDetails.netPremium,
    };
    CAR_SERVICES.BUY_ACTION(onSuccess, onError, Params);
    dispatch(CarSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
  };
  return (
    <Modal open={open}>
      <Box className="modalWrapperMobile">
        <Box className="modalContent">
          <Grid className="title" container alignItems="center">
            <Grid xs={10} className="ctaBtn">
              <h3>
                Policy Details
                <CustomButton
                  onClickFunction={() => {
                    Buyaction();
                  }}
                  text_name={"Buy Now"}
                  class_name="primarySmBtn"
                />
                <CustomButton
                  text_name={<EmailIcon />}
                  class_name="primarySmBtn"
                />
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Button className="closePopup" onClick={() => setOpen(false)} />
            </Grid>
          </Grid>
          <Box className="policyDetail_Popup">
            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid xs={12}>
                <Box className="policyDetailBox">
                  <Grid container spacing={0} alignItems={"center"}>
                    <Grid xs={6}>
                      <img
                        src={
                          SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails
                            ?.logo
                        }
                        alt=""
                        className="logo"
                      />
                      <p className="type_tag">Private Car</p>
                    </Grid>
                    <Grid xs={6} textAlign={"right"}>
                      <p className="idv">
                        IDV <span className="grey_txt">(Incl. CNG)</span>{" "}
                        <span className="blue_txt">
                          {formatToCurrency(
                            parseInt(
                              `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV}`
                            )
                          )}
                        </span>
                      </p>
                      <p className="premium">
                        Premium <span className="grey_txt">(Incl. GST)</span>
                        <span className="red_txt">
                          {" "}
                          {formatToCurrency(
                            calculate_premium_for_car(
                              SELECTED_QUOTE_DATA.quotationDetail,
                              ADDON_STATUS,
                              addon_values.passengerCover.value,
                              PLAN_TYPE
                            )
                          )}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box className="tabs-section">
                  <TabContext value={value}>
                    <TabPanel value="1" className="tabPanel">
                      <Grid container columnSpacing={0} rowSpacing={0}>
                        <Grid xs={12}>
                          <h4>Vehicle Own Damage</h4>
                          <ul className="addonList mt-3">
                            <li>
                              Your Basic Premium:{" "}
                              <span>
                                {formatToCurrency(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicOD}`
                                  )
                                )}
                              </span>
                            </li>
                            <li>
                              NCB Discount:{" "}
                              <span>
                                {formatToCurrency(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.ncbDiscount}`
                                  )
                                )}
                              </span>
                            </li>

                            {Object.entries(ADDON_STATUS).map(
                              ([key, value]) => {
                                if (
                                  value.value &&
                                  addon_tag_name.hasOwnProperty(key)
                                ) {
                                  const addonKey = addon_tag_name[key];
                                  const formattedAddonKey = formatKey(addonKey);
                                  const addonValue =
                                    selectedQuote?.quotationDetail
                                      ?.premiumDetails?.[addonKey] || 0;
                                  if (
                                    addonKey !== "paOwnerDriver" &&
                                    addonKey !== "driverCover" &&
                                    addonKey !== "passerngerCover"
                                  ) {
                                    if (addonValue > 0) {
                                      return (
                                        <li key={key}>
                                          {value.label}:{" "}
                                          <span>
                                            {formatToCurrency(
                                              parseFloat(
                                                `${
                                                  selectedQuote?.quotationDetail
                                                    ?.premiumDetails?.[addonKey]
                                                    ? selectedQuote
                                                        ?.quotationDetail
                                                        ?.premiumDetails?.[
                                                        addonKey
                                                      ]
                                                    : 0
                                                }`
                                              )
                                            )}
                                          </span>
                                        </li>
                                      );
                                    }
                                  }
                                }
                              }
                            )}
                          </ul>
                        </Grid>
                      </Grid>
                      <hr />
                      <Grid container columnSpacing={0} rowSpacing={0}>
                        <Grid xs={12}>
                          <h4>Third Party Damage</h4>
                          <ul className="addonList mt-3">
                            <li>
                              Third party liability premium:{" "}
                              <span>
                                {" "}
                                {formatToCurrency(
                                  parseFloat(
                                    `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.basicTP}`
                                  )
                                )}
                              </span>
                            </li>
                            {Object.entries(ADDON_STATUS).map(
                              ([key, value]) => {
                                if (
                                  value.value &&
                                  addon_tag_name.hasOwnProperty(key)
                                ) {
                                  const addonKey = addon_tag_name[key];
                                  const addonValue =
                                    selectedQuote?.quotationDetail
                                      ?.premiumDetails?.[addonKey] || 0;
                                  if (
                                    addonKey === "paOwnerDriver" ||
                                    addonKey === "driverCover" ||
                                    addonKey === "passerngerCover"
                                  ) {
                                    if (addonValue > 0) {
                                      return (
                                        <li key={key}>
                                          {value.label}:{" "}
                                          <span>
                                            {formatToCurrency(
                                              parseFloat(
                                                `${
                                                  selectedQuote?.quotationDetail
                                                    ?.premiumDetails?.[addonKey]
                                                    ? selectedQuote
                                                        ?.quotationDetail
                                                        ?.premiumDetails?.[
                                                        addonKey
                                                      ]
                                                    : 0
                                                }`
                                              )
                                            )}
                                          </span>
                                        </li>
                                      );
                                    }
                                  }
                                }
                              }
                            )}
                          </ul>
                        </Grid>
                      </Grid>
                      <hr />
                      <Grid container columnSpacing={0} rowSpacing={0}>
                        <Grid xs={12}>
                          <ul className="addonList">
                            <li>
                              Your Total Net Premium:{" "}
                              <span>
                                {" "}
                                {formatToCurrency(
                                  calculate_premium_for_car(
                                    SELECTED_QUOTE_DATA.quotationDetail,
                                    ADDON_STATUS,
                                    addon_values.passengerCover.value,
                                    PLAN_TYPE
                                  ) -
                                    cal_gst(
                                      calculate_premium_for_car(
                                        SELECTED_QUOTE_DATA.quotationDetail,
                                        ADDON_STATUS,
                                        addon_values.passengerCover.value,
                                        PLAN_TYPE
                                      )
                                    )
                                )}
                              </span>
                            </li>
                            <li>
                              GST 18%:{" "}
                              <span>
                                {" "}
                                {formatToCurrency(
                                  cal_gst(
                                    calculate_premium_for_car(
                                      SELECTED_QUOTE_DATA.quotationDetail,
                                      ADDON_STATUS,
                                      addon_values.passengerCover.value,
                                      PLAN_TYPE
                                    )
                                  )
                                )}
                              </span>
                            </li>
                            <li style={{fontSize:"14px",fontWeight:700}}>
                              Total Premium:{" "}
                              <span>
                                {" "}
                                {formatToCurrency(
                                  calculate_premium_for_car(
                                    SELECTED_QUOTE_DATA.quotationDetail,
                                    ADDON_STATUS,
                                    addon_values.passengerCover.value,
                                    PLAN_TYPE
                                  )
                                )}
                              </span>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabList
                      className="tabFooter"
                      centered
                      onChange={handleChange}
                    >
                      <Tab label="Premium Breakup" value="1" />
                      {/* <Tab label="Policy Coverage" value="2" /> */}
                    </TabList>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MCarPolicyDetail;
