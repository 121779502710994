import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import MTWPolicyDetail from "../MTWPolicyDetail/MTWPolicyDetail";
import "./MTWQuoteCard.scss";

import { useNavigate } from "react-router-dom";
import { TW_PATH } from "../../../../../RoutesPath/TWPath";
import { TTWProduct } from "../../../../../Services/Type/TwoWheeler/TTWProduct";
import { TWSlice } from "../../../../../State/Slice_Reducer/TW/TWSlice";
import { CURRENCY_FORMAT } from "../../../../../SupportingFiles/HelpingFunction";
import {
  TW_PREMIUM_CALC,
  shouldDisplayAddonTW,
} from "../../../../../SupportingFiles/TWPremiumCal";
import { TBUYACTION } from "../../../../../Services/Type/TwoWheeler/TWBuyaction";
import { TW_SERVICES } from "../../../../../Services/TW/TWServices";

const MTWQuoteCard = ({ quote }: { quote: TTWProduct }) => {
  const dispatch = useAppDispatch();
  const [premium, setPremium] = useState<number>(0);
  const [openPolicyDetails, setOpenPolicyDetails] = useState<boolean>(false);
  const { PLAN_TYPE, ADDON_STATUS, QUOTE_LOADER, ADD_FORM_RESPONSE } =
    useAppSelector((state) => state.TW);

  useEffect(() => {
    const premium_details = quote.quotationDetail;

    if (premium_details) {
      const premium_with_tax = TW_PREMIUM_CALC(premium_details, ADDON_STATUS);
      setPremium(premium_with_tax);
    }
  }, [ADDON_STATUS, quote]);
  const navigate = useNavigate();
  const Buyaction = () => {
    const onSuccess = () => {
      window.location.href = `https://dev.insurance.motilaloswal.com/two-wheeler-insurance/ckyc-verification/${btoa(
        `${quote.quotationDetail?.productDetails.id}-${
          ADD_FORM_RESPONSE.business_type === "New" ? "3" : "1"
        }-${ADD_FORM_RESPONSE.quote_no}`
      )}`;
    };

    const onError = () => {};

    const Params: TBUYACTION = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "TW",
      product_code: quote.product_code,
      productId: quote.id,
      company_code: quote.company_code,
      IDV: quote.quotationDetail?.premiumDetails.IDV,
      finalPremium: quote.quotationDetail?.premiumDetails.netPremium,
    };
    TW_SERVICES.BUY_ACTION(onSuccess, onError, Params);
    dispatch(TWSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
  };
  return (
    <>
      {QUOTE_LOADER ? (
        <Box className="Mquotation mskeleton">
          <Box className="mquotation_tags">
            <Grid container alignItems="center" spacing={0} padding={0}>
              <Grid xs={5}>
                <img className="insurerLogo" src={quote?.logo} />
                <Link className="viewDetails">Plan Details</Link>
              </Grid>
              <Grid xs={7} textAlign={"right"}>
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="idv_p">
                    Vehicle IDV
                    <span>
                      ₹ <div className="animatebar" />
                    </span>
                  </p>
                )}

                <Button className="buyBtn">
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span className="rs-icon">₹</span>
                    <img
                      src="../images/loader.svg"
                      alt=""
                      style={{
                        height: "20px",
                        width: "100%",
                        padding: "5px 0px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                  </span>{" "}
                  Buy Policy
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box className="maddonsBox">
            <ul>
              <li>
                <div className="animatebar" />
              </li>
              <li>
                <div className="animatebar" />
              </li>
            </ul>
          </Box>
        </Box>
      ) : (
        <Box className="Mquotation">
          <MTWPolicyDetail
            open={openPolicyDetails}
            setOpen={setOpenPolicyDetails}
            quote={quote}
          />
          <Box className="mquotation_tags">
            {PLAN_TYPE === null ? null : (
              <span className="product_tag">{PLAN_TYPE}</span>
            )}
            <Grid
              container
              alignItems="center"
              rowSpacing={3}
              columnSpacing={0}
              padding={"0"}
            >
              <Grid xs={5}>
                <img className="insurerLogo" src={quote?.logo} />
                <Link
                  className="viewDetails"
                  onClick={() => {
                    setOpenPolicyDetails(true);
                    dispatch(TWSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
                  }}
                >
                  Plan Details
                </Link>
              </Grid>
              <Grid xs={7} textAlign={"right"}>
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="idv_p">
                    Vehicle IDV
                    <span>
                      {CURRENCY_FORMAT(
                        parseFloat(
                          `${quote.quotationDetail?.premiumDetails.IDV}`
                        )
                      )}
                    </span>
                  </p>
                )}

                <Button
                  className="buyBtn"
                  onClick={() => {
                    Buyaction();
                  }}
                >
                  <span> {CURRENCY_FORMAT(premium)}</span> Buy Policy
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box className="maddonsBox">
            {ADDON_STATUS.driver_cover.value ||
            ADDON_STATUS.pa_owner_cover.value ? (
              <Box
                className="addonsBox"
                display={"flex"}
                justifyContent={"space-between"}
              >
                <ul>
                  {Object.entries(ADDON_STATUS).map(([key, value]) => {
                    if (
                      PLAN_TYPE === "Third Party" &&
                      (value.label === "PA Owner Cover" ||
                        value.label === "Driver Cover")
                    ) {
                      const status = value.value;
                      const included =
                        quote.quotationDetail?.premiumDetails
                          ?.includedPAOwnerDriver === 1;

                      return status || included ? (
                        <li key={key}>
                          <p
                            className={
                              included
                                ? "include"
                                : shouldDisplayAddonTW(
                                    value.label,
                                    quote?.quotationDetail?.premiumDetails
                                  )
                                ? "yes"
                                : "no"
                            }
                          >
                            {value.label}
                          </p>
                        </li>
                      ) : null;
                    }
                    return null;
                  })}
                </ul>
              </Box>
            ) : null}
            {ADDON_STATUS.zero_depreciation.value ||
            ADDON_STATUS.rsa.value ||
            ADDON_STATUS.driver_cover.value ||
            ADDON_STATUS.pa_owner_cover.value ? (
              <Box
                className="addonsBox"
                display={"flex"}
                justifyContent={"space-between"}
              >
                <ul>
                  {Object.entries(ADDON_STATUS).map(([key, value]) => {
                    if (PLAN_TYPE !== "Third Party") {
                      const status = value.value;
                      const included =
                        quote.quotationDetail?.premiumDetails
                          .includedPAOwnerDriver === 1;
                      if (key === "pa_owner_cover" && PLAN_TYPE === "SAOD") {
                        return null;
                      }
                       if (key === "driver_cover" && PLAN_TYPE === "SAOD") {
                         return null;
                       }
                      return status || included ? (
                        <li key={key}>
                          <p
                            className={
                              included
                                ? "include"
                                : shouldDisplayAddonTW(
                                    value.label,
                                    quote?.quotationDetail?.premiumDetails
                                  )
                                ? "yes"
                                : "no"
                            }
                          >
                            {value.label}
                          </p>
                        </li>
                      ) : null;
                    }
                  })}
                </ul>
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
    </>
  );
};

export default MTWQuoteCard;
