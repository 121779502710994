import React, { useState } from "react";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../CommonSCSS/ModalPopup.scss";
import { toast } from "react-toastify";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../InputFields/RKTextField/RKTextField";
import { useAppDispatch, useAppSelector } from "../../../../State/hooks";
import {
  validateEmail,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import {
  EmailQuotesDTO,
  TWEmailQuoteData,
} from "../../../../Services/DTO/TWDTO/EmailQuotesDTO";
import { TW_SERVICES } from "../../../../Services/TW/TWServices";
import { TW_PREMIUM_CALC } from "../../../../SupportingFiles/TWPremiumCal";
import { TWSlice } from "../../../../State/Slice_Reducer/TW/TWSlice";
interface EmailFields {
  mobile: { value: string; warning: boolean };
  email: { value: string; warning: boolean };
}
const EmailPopup = ({
  open,
  setOpen,
  marginClass,
}: {
  open: boolean;
  setOpen: Function;
  marginClass?: string;
}) => {
  const [emailFields, setEmailFields] = useState<EmailFields>({
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
  });

  const dispatch = useAppDispatch();
  const {
    ALL_QUOTES_DATA,
    ADD_FORM_RESPONSE,
    ADDON_STATUS,
    PLAN_TYPE,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.TW);
  const [sendQuoteFields, setSendQuoteFields] = useState<EmailFields>({
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
  });
  const [showThanksMessage, setShowThanksMessage] = useState(false);
  const handleShowMessage = () => {
    setShowThanksMessage(true);
    setTimeout(() => {
      dispatch(TWSlice.actions.UPDATE_QUOTE_LOADER(false));
      setShowThanksMessage(false);
      setOpen(false);
    }, 2000);
  };

  const fieldsUpdateState = (attrName: keyof EmailFields, value: any) => {
    setSendQuoteFields({
      ...sendQuoteFields,
      [attrName]: {
        ...sendQuoteFields[attrName],
        value: value,
        warning: !(validateEmail(value) || validateMobileNumber(value)),
      },
    });
  };

  const validateForm = () => {
    let emailQuoteFieldData = { ...sendQuoteFields };
    emailQuoteFieldData.email.warning = !(
      validateMobileNumber(emailQuoteFieldData.mobile.value) &&
      validateEmail(emailQuoteFieldData.email.value)
    );
    let data = emailQuoteFieldData;
    data = {
      ...data,
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
    };

    setSendQuoteFields({
      ...data,
    });
    if (emailQuoteFieldData.email.warning === false) {
      onSubmit();
    }
  };

  let emailSendData: TWEmailQuoteData[] = ALL_QUOTES_DATA.map((quoteData) => {
    const calculatedPrice = `${TW_PREMIUM_CALC(
      quoteData.quotationDetail,
      ADDON_STATUS
    )}`;
    const idv = quoteData.quotationDetail?.premiumDetails.IDV?.toString() || "";
    const product_id =
      quoteData.quotationDetail?.productDetails?.id?.toString() || "";
    const logo = quoteData.quotationDetail?.CompanyDetails?.logo || "";

    return {
      price: calculatedPrice,
      logo: logo,
      idv: idv,
      product_id: product_id,
    };
  });
  let param: EmailQuotesDTO = {
    quotes: emailSendData,
    mobile: validateMobileNumber(sendQuoteFields.mobile.value)
      ? sendQuoteFields.mobile.value
      : "",
    email: validateEmail(sendQuoteFields.email.value)
      ? sendQuoteFields.email.value
      : "",
    quote_no: ADD_FORM_RESPONSE.quote_no,
  };
  const onSubmit = () => {
    const onSuccess = (res: any) => {
      toast.success("Email Sent");
      handleShowMessage();
    };
    const onError = (err: any) => {};
    dispatch(TWSlice.actions.UPDATE_QUOTE_LOADER(true));
    TW_SERVICES.SEND_QUOTE_VIA_EMAIL(onSuccess, onError, param);
  };

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className={`modalContent xsWidth ${marginClass}`}>
          <Grid
            className="title mx-0"
            container
            columnSpacing={3}
            alignItems="center"
          >
            <Button className="closePopup" onClick={() => setOpen(false)} />
            <Grid xs={12}>
              <h3>SEND EMAIL</h3>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={0}
            columnSpacing={3}
            style={{ margin: "0" }}
          >
            <Grid xs={12}>
              <>
                <Grid container columnSpacing={2} rowSpacing={0}>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Mobile"}
                      value={sendQuoteFields.mobile.value.slice(0, 10)}
                      attrName={"mobile"}
                      value_update={fieldsUpdateState}
                      number_only={true}
                      warn_status={sendQuoteFields.mobile.warning}
                      error_message={
                        !sendQuoteFields.mobile.value
                          ? "Enter Mobile No."
                          : "Enter  Valid Mobile No."
                      }
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Email"}
                      value={sendQuoteFields.email.value}
                      attrName={"email"}
                      value_update={fieldsUpdateState}
                      warn_status={sendQuoteFields.email.warning}
                      error_message={
                        !sendQuoteFields.email.value
                          ? "Enter Email"
                          : "Enter Valid Email"
                      }
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn  mb-4">
                    <CustomButton
                      text_name={"Send Quotes"}
                      class_name="primaryLgBtn"
                      onClickFunction={() => validateForm()}
                    />
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailPopup;
