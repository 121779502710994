import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CKYCDetails from "../../../../../Page/Desktop/Term/HDFC/Proposal/CKYCDetails/CKYCDetails";
import MCKYCDetails from "../../../../../Page/Mobile/Term/HDFC/Proposal/CKYCSection/MCKYCDetails";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { HDFCCKYCDetailsDTO } from "../../../../../Services/DTO/Term/CkycDetailsDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermServices/ProposalPageServices";
import { TermSlice } from "../../../../../State/Slice_Reducer/Term/TermSlice";
import { THDFCCKYCDetails } from "../../../../../State/Types/TCKYCDetails";
import { PROPOSAL_PAGE_SECTION_STATUS_API } from "../../../../../State/Types/TProposerDetails";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

function CKYCContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const { selectedQuote, proposerPage, HDFCCKYCDetails, proposerDetails } =
    useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();
  const [HDFCFormData, setHDFCFormData] =
    useState<THDFCCKYCDetails>(HDFCCKYCDetails);

  const updateMasterState = (attrName: any, value: any) => {
    if (
      attrName[0] === "father_full_name" ||
      attrName[0] === "mother_full_name" ||
      attrName[0] === "spouse_name"
    ) {
      setHDFCFormData({
        ...attrName[2],
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      });
    } else if (attrName[0] === "ckyc_no") {
      setHDFCFormData({
        ...attrName[2],
        [attrName[0]]: {
          value: value,
          warning: !(
            value.toString().length === 14 || value.toString().length === 0
          ),
        },
      });
    } else {
      setHDFCFormData({
        ...attrName[2],
        [attrName[0]]: {
          value: value,
          warning: isEmpty(value),
        },
      });
    }
  };

  const validate_data = () => {
    let data: THDFCCKYCDetails = { ...HDFCFormData };
    let hasError = false;

    data = {
      ...data,
      father_full_name: {
        ...data.father_full_name,
        warning: isEmpty(data.father_full_name.value),
      },
    };
    data = {
      ...data,
      mother_full_name: {
        ...data.mother_full_name,
        warning: isEmpty(data.mother_full_name.value),
      },
    };
    if (proposerDetails.occupation.value.code === "OCCT_SALR") {
      data = {
        ...data,
        occupation: {
          ...data.occupation,
          warning: isEmpty(data.occupation.value),
        },
        employer_name: {
          ...data.employer_name,
          warning: isEmpty(data.employer_name.value),
        },
        employer_address: {
          ...data.employer_address,
          warning: isEmpty(data.employer_address.value),
        },
      };
    }

    if (
      proposerDetails.gender.value === "F" &&
      ((typeof proposerDetails.marital_status.value === "string" &&
        proposerDetails.marital_status.value === "MAR_MRD") ||
        proposerDetails.marital_status.value.code === "MAR_MRD")
    ) {
      data = {
        ...data,
        spouse_name: {
          ...data.spouse_name,
          warning: !validateFullName(data.spouse_name.value),
        },
      };
    }

    setHDFCFormData(data);

    if (
      data.father_full_name.warning === true ||
      data.mother_full_name.warning === true
    ) {
      hasError = true;
    }

    if (proposerDetails.occupation.value.code === "OCCT_SALR") {
      if (
        data.occupation.warning ||
        data.employer_name.warning ||
        data.employer_address.warning
      ) {
        hasError = true;
      }
    }

    if (
      proposerDetails.gender.value === "F" &&
      ((typeof proposerDetails.marital_status.value === "string" &&
        proposerDetails.marital_status.value === "MAR_MRD") ||
        proposerDetails.marital_status.value.code === "MAR_MRD")
    ) {
      hasError = hasError || data.spouse_name.warning;
    }

    if (!hasError) {
      UpdateCKYCDetails(data);
    }
  };

  const UpdateCKYCDetails = (ckycDetails: THDFCCKYCDetails) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;

      if (!error) {
        dispatch(TermSlice.actions.SET_HDFC_CKYC_DETAILS(ckycDetails));
        if (proposerPage.update_status) {
          navigate(-1);
          dispatch(TermSlice.actions.updateUpdateStatus(false));
        } else {
          navigate(TERM_PATH.MEDICAL_PAGE);
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: HDFCCKYCDetailsDTO = {
      buy_online_code: selectedQuote.buy_online_code,
      quote_no: btoa(selectedQuote.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.CKYC_DETAILS,
      details: {
        appno: selectedQuote.appno,
        ckyc_no: ckycDetails.ckyc_no.value,
        cat_occupation: ckycDetails.occupation.value,
        father_first_name: ckycDetails.father_full_name.value.split(" ")[0],
        father_last_name: ckycDetails.father_full_name.value.split(" ")[1],
        insured_occupation: `${proposerDetails.occupation.value}`,
        mother_first_name: ckycDetails.mother_full_name.value.split(" ")[0],
        mother_last_name: ckycDetails.mother_full_name.value.split(" ")[1],
        product_id: `${selectedQuote.id}`,
        spouse_first_name: isEmpty(ckycDetails.spouse_name.value.split(" ")[0])
          ? ""
          : ckycDetails.spouse_name.value.split(" ")[0],
        spouse_last_name: isEmpty(ckycDetails.spouse_name.value.split(" ")[1])
          ? ""
          : ckycDetails.spouse_name.value.split(" ")[1],
        employer_name: ckycDetails.employer_name.value,
        employer_address: ckycDetails.employer_address.value,
      },
    };
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  return (
    <>
      {isMobile ? (
        <MCKYCDetails
          continueFunction={validate_data}
          loader={loader}
          formData={HDFCFormData}
          updateMasterState={updateMasterState}
        />
      ) : (
        <CKYCDetails
          continueFunction={validate_data}
          loader={loader}
          formData={HDFCFormData}
          updateMasterState={updateMasterState}
        />
      )}
    </>
  );
}

export default CKYCContainer;
