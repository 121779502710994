import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";
import { CURRENCY_FORMAT } from "../../../../../SupportingFiles/HelpingFunction";
import ToggleSwitch from "../../../Common/ToggleSwitchTerm/ToggleSwitch";
import "./AddonQuestion.scss";

interface Props {
  addon_amount?: number;
  main_class?: string;
  question_name: string;
  sub_details?: any;
  value_update: Function;
  attrName?: string;
  toggle_status: boolean | undefined;
  loader?: boolean;
  show_premium?: boolean;
  desc?: string;
  free?: boolean;
}
const AddonQuestion: React.FC<Props> = ({
  addon_amount = 0,
  main_class,
  question_name,
  sub_details,
  value_update,
  attrName,
  toggle_status,
  show_premium = true,
  loader = false,
  desc,
  free = false,
}) => {
  useEffect(() => {
    console.log(question_name, addon_amount);
  }, [addon_amount]);
  return (
    <Box className={"addonQuestion borderBottom " + main_class}>
      <Grid container spacing={2} alignItems="center">
        <Grid xs={9} className="addonQuestionInner">
          <div>
            <h6>{question_name}</h6>
            <p>{desc}</p>
          </div>
        </Grid>
        <Grid xs={3} textAlign="right">
          <Box sx={{ minHeight: "28px" }}>
            {show_premium ? (
              toggle_status ? (
                loader ? (
                  <img src="images/loader.svg" height="10px" alt="Brand Logo" />
                ) : (
                  <p className="amount mb-2">{CURRENCY_FORMAT(addon_amount)}</p>
                )
              ) : null
            ) : null}
            {free ? <p className="amount mb-2">Free</p> : ""}
          </Box>
          {free ? null : (
            <ToggleSwitch
              toggle_status={toggle_status}
              value_update={value_update}
              attrName={attrName}
            />
          )}
        </Grid>
      </Grid>
      {toggle_status ? sub_details : null}
    </Box>
  );
};

export default AddonQuestion;
