import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Modal from "@mui/material/Modal";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import "./../../../../../CommonSCSS/ModalPopup.scss";
import SelectDropdown from "../../../../InputFields/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../InputFields/DatePicker/DatePicker";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import SearchDropdown from "../../../../InputFields/SearchDropdown/SearchDropdown";
import NativeSelectDropdown from "../../../../InputFields/NativeSelectDropdown/NativeSelectDropdown";
import { TCarAddForm } from "../../../../../Services/Type/TCarSlice";
import { useAppSelector } from "../../../../../State/hooks";
import { calculateAgeInDays } from "../../../../../SupportingFiles/HelpingFunction";

const MCarModifyDetails = ({
  open,
  setOpen,
  formData,
  fieldsUpdateState,
  validateForm,
}: {
  open: boolean;
  setOpen: any;
  formData: TCarAddForm;
  fieldsUpdateState: Function;
  validateForm: Function;
}) => {
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Car);

  const [previousNcb, setPreviousNcb] = React.useState<string | null>("left");
  const [lastClaimed, setLastClaimed] = React.useState<string | null>("left");

  const handlePreviousNcb = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setPreviousNcb(newAlignment);
  };
  return (
    <Modal open={open}>
      <Box className="modalWrapperMobile">
        <Box className="modalContent">
          <Grid className="title" container alignItems="center">
            <Grid xs={10}>
              <h3>Modify Vehicle details</h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Button className="closePopup" onClick={() => setOpen(false)} />
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={3}
            marginBottom={"16px"}
          >
            <Grid xs={12}>
              <SearchDropdown
                class_name="inputField"
                title="Make and Model"
                value={`${formData.make_model.value.label}`}
                attrName={"make_model"}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                warn_status={formData.make_model.warning}
                error_message={"Select Make and Model"}
                disabled={true}
              />
            </Grid>
            <Grid xs={12}>
              <SearchDropdown
                class_name="inputField"
                title="Fuel Type"
                value={formData.fuel_type.value.label}
                attrName={"fuel_type"}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                warn_status={formData.fuel_type.warning}
                error_message="Select Fuel Type"
                disabled={true}
              />
            </Grid>
            <Grid xs={12}>
              <SearchDropdown
                class_name="inputField"
                title="Variant"
                value={formData.variant_cc.value.label}
                attrName={"variant_cc"}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.VARIANT_LIST}
                warn_status={formData.variant_cc.warning}
                error_message="Select Variant"
              />
            </Grid>
            {/* <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"Reg. Date"}
                value={formData.reg_date.value}
                attrName={"reg_date"}
                value_update={fieldsUpdateState}
                error_message="Enter Reg. Date"
                warn_status={formData.reg_date.warning}
                min_date={18}
                max_date={0}
                date_validation_type="YEARS"
              />
            </Grid> */}
            {/* <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"Policy Expiry"}
                value={formData.policy_expiry_date.value}
                attrName={"policy_expiry_date"}
                value_update={fieldsUpdateState}
                error_message="Enter PolicyExpiry Date"
                warn_status={formData.policy_expiry_date.warning}
                min_date={
                  calculateAgeInDays(`${formData.policy_expiry_date.value}`) -
                  90
                }
                max_date={-60}
                date_validation_type="DAYS"
              />
            </Grid> */}
            {/* <Grid xs>
              <NativeSelectDropdown
                class_name="inputField nativeSelect"
                title="Previous Insurer"
                value={formData.previous_insurer.value}
                attrName={"previous_insurer"}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                warn_status={formData.previous_insurer.warning}
              />
            </Grid> */}
          </Grid>
          {/* <Grid
            container
            columnSpacing={3}
            rowSpacing={3}
            alignItems="center"
            marginBottom={"16px"}
          >
            <Grid xs={12} textAlign="center">
              <h3 className="mb-3">Claimed in the last year?</h3>
              <ToggleButtonGroup
                className="radioBox"
                value={formData.claimed}
                exclusive
                onChange={(e, new_value) =>
                  fieldsUpdateState("claimed", new_value)
                }
              >
                <ToggleButton value="No">No</ToggleButton>
                <ToggleButton value="yes">Yes</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid> */}
          {/* {formData.claimed === "No" ? (
            <>
              <Grid
                container
                columnSpacing={3}
                rowSpacing={3}
                marginBottom={"16px"}
              >
                <Grid xs={12} textAlign="center">
                  <h3 className="mb-2">Previous No Claim Bonus (NCB)</h3>
                  <p>(Check it in your last policy.)</p>
                  <ToggleButtonGroup
                    className="radioBox mt-4"
                    value={formData.prev_ncb}
                    exclusive
                    // onChange={handlePreviousNcb}
                    onChange={(e, new_value) =>
                      fieldsUpdateState("prev_ncb", new_value)
                    }
                    sx={{ justifyContent: "center" }}
                  >
                    <ToggleButton value="0">0%</ToggleButton>
                    <ToggleButton value="20">20%</ToggleButton>
                    <ToggleButton value="25">25%</ToggleButton>
                    <ToggleButton value="35">35%</ToggleButton>
                    <ToggleButton value="45">45%</ToggleButton>
                    <ToggleButton value="50">50%</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </>
          ) : null} */}
          <Grid
            container
            columnSpacing={3}
            rowSpacing={3}
            marginBottom={"16px"}
          >
            <Grid xs={12} textAlign="center" className="ctaBtn">
              <CustomButton
                onClickFunction={validateForm}
                text_name={"Update Details"}
                class_name="primaryMdBtn"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default MCarModifyDetails;
