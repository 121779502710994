import Masonry from "@mui/lab/Masonry";
import { Box } from "@mui/material";
import "../../../../../../CommonSCSS/ProposalForms.scss";
import { TAddressDetails } from "../../../../../../State/Types/TAddressDetails";
import Footer from "../../../../../../Component/Desktop/Term/ProposalForm/Common/Footer/Footer";
import PreviewDetailBox from "../../../../../../Component/Desktop/Term/PreviewDetails/PreviewDetailBox/PreviewDetailBox";
import AddressDetailBox from "../../../../../../Component/Desktop/Term/PreviewDetails/AddressDetailsBox/AddressDetailsBox";

const PreviewDetails = ({
  PreviewDetails,
  permanentAddressDetails,
  mailingAddressDetails,
  address_details,
  continueFunction,
  loader,
}: {
  PreviewDetails: Array<any>;
  permanentAddressDetails: any;
  mailingAddressDetails: any;
  address_details: TAddressDetails;
  continueFunction: Function;
  loader: boolean;
}) => {
  return (
    <Box className="proposalPreview">
      <h5 className="sectionTitle">Just make sure, all details are correct</h5>
      <Masonry columns={2} spacing={3} className="px-0 mx-0">
        {PreviewDetails.map((data, index) => (
          <PreviewDetailBox
            editFunction={data.editFunction}
            title={data.title}
            data={data.detailView}
            hideEditButton={index === 0 || index === 1 ? true : false}
          />
        ))}
        <AddressDetailBox
          editFunction={permanentAddressDetails.editFunction}
          permanentAddress={permanentAddressDetails.detailView}
          mailingAddress={mailingAddressDetails.detailView}
          permanent_mailing_address_same={
            address_details.is_mailing_and_permanent_address_same
          }
        />
      </Masonry>
      <Footer
        loading={loader}
        continueFunction={() => {
          continueFunction();
        }}
      />
    </Box>
  );
};

export default PreviewDetails;
