import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TERM_PATH } from "../../../../../RoutesPath/TermPath";
import { ECompanyCode } from "../../../../../State/Enum/ECompanyCode";
import { useAppSelector } from "../../../../../State/hooks";
import {
  CURRENCY_FORMAT,
  FORMAT_NUMBER,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import "./LeftSidebar.scss";

const LeftSidebar = ({ class_name }: { class_name: string }) => {
  const location = useLocation();
  const { selectedQuote, PREMIUM_LOADER, RIDERS_BENEFITS, proposerDetails } =
    useAppSelector((state) => state.Term);
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    // Use setTimeout to set loader back to false after 4 seconds
    const timer = setTimeout(() => {
      setLoader(false);
    }, 4000);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  if (
    location.pathname === TERM_PATH.COFIRMATION_PAGE ||
    location.pathname === TERM_PATH.POLICY_CONFIRMATION
  ) {
    return <></>;
  }

  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={class_name} />
      <img className="insurerLogo" src={selectedQuote.logo} />
      <h6 className="mb-8">{selectedQuote.product_name}</h6>
      <h5>{FORMAT_NUMBER(selectedQuote.sum_insured)}</h5>
      <p className="mb-8">Sum Assured</p>
      <h5>{`${selectedQuote.term} Years`}</h5>
      <p className="mb-8">Policy Tenure</p>
      <h5>{selectedQuote.payment_mode}</h5>
      <p className="mb-8">Payment Mode</p>
      <h5 className="premium">
        {loader || PREMIUM_LOADER ? (
          <img src="images/loader.svg" height="10px" alt="Brand Logo" />
        ) : selectedQuote.company_code === ECompanyCode.HDFC ? (
          CURRENCY_FORMAT(RIDERS_BENEFITS.riders_benefits_response.finalPremium)
        ) : selectedQuote.company_code === ECompanyCode.MAX_LIFE ? (
          `${selectedQuote.frequency}` === "5" ? (
            CURRENCY_FORMAT(selectedQuote.premium)
          ) : (
            CURRENCY_FORMAT(
              RIDERS_BENEFITS.riders_benefits_response.finalPremium
            )
          )
        ) : (
          CURRENCY_FORMAT(selectedQuote.premium)
        )}
      </h5>
      <p className="mb-8">Premium (Incl. GST)</p>
    </Grid>
  );
};

export default LeftSidebar;
