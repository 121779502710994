import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { TWSlice } from "../../../../State/Slice_Reducer/TW/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../../State/hooks";
import EmailPopup from "../EmailPopup/EmailPopup";
import "./QuoteHeader.scss";

const QuoteHeader = () => {
  const [openEmailQuotes, setOpenEmailQuotes] = useState<boolean>(false);
  const { ADDON_STATUS } = useAppSelector((state) => state.Car);
  const dispatch = useAppDispatch();
  const handleImageClick = () => {
    dispatch(
      TWSlice.actions.IDV_UPDATE({
        value: `${""}`,
        warning: false,
      })
    );

    dispatch(TWSlice.actions.UPDATE_ADDON_STATUS({ key: "rsa", value: false }));
    dispatch(
      TWSlice.actions.UPDATE_ADDON_STATUS({
        key: "zero_depreciation",
        value: false,
      })
    );
    dispatch(
      TWSlice.actions.UPDATE_ADDON_STATUS({ key: "driver_cover", value: false })
    );

    dispatch(TWSlice.actions.IDVAPPLY_STATUS(false));
    window.location.href =
      "https://dev.insurance.motilaloswal.com/car-insurance/ckyc-verification";
  };

  return (
    <Box className="quoteNavbar">
      <EmailPopup
        open={openEmailQuotes}
        setOpen={setOpenEmailQuotes}
        marginClass=""
      />
      <Grid container columnSpacing={3} alignItems="center">
        <Grid xs={6}>
          <img
            onClick={handleImageClick}
            src="/images/motilalOswal.svg"
            className="logo"
            alt="logo"
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid xs={6} textAlign="right">
          {/* <Button
            className="sendQuoteBtn"
            onClick={() => setOpenEmailQuotes(true)}
          >
            <MailOutlineIcon /> Email Quotes{" "}
          </Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuoteHeader;
