import { PayloadAction } from "@reduxjs/toolkit";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";
import { TNomineeDetails } from "../../../Types/TNomineeDetails";

function setNomineeDetails(
  state: TTermSlice,
  action: PayloadAction<TNomineeDetails>
) {
  const data: TTermSlice = { ...state, nomineeDetails: action.payload };
  return data;
}

export const TERM_NOMINEE_DETAILS_REDUCERS = {
  setNomineeDetails,
};
