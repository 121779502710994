import { PayloadAction } from "@reduxjs/toolkit";
import { TSelectedQuote } from "../../../Types/TSelectedQuote";
import { TTermSlice } from "../../../../Services/Type/TTermSlice";

function setQuoteDetails(
  state: TTermSlice,
  action: PayloadAction<TSelectedQuote>
) {
  const data: TTermSlice = { ...state, selectedQuote: action.payload };
  return data;
}

function setAppNo(state: TTermSlice, action: PayloadAction<string>) {
  const data: TTermSlice = {
    ...state,
    selectedQuote: { ...state.selectedQuote, application_no: action.payload },
  };
  return data;
}

type keyOfTSelectedQuote = keyof TSelectedQuote;
function updateQuoteData(
  state: TTermSlice,
  action: PayloadAction<{ key: keyOfTSelectedQuote; value: string }[]>
) {
  let data = { ...state };
  let updatedState: TSelectedQuote = { ...data.selectedQuote };

  action.payload.forEach(({ key, value }) => {
    updatedState = { ...updatedState, [key]: value };
  });
  data.selectedQuote = updatedState;

  return data;
}

export const TERM_SELECTED_QUOTE_REDUCERS = {
  setQuoteDetails,
  setAppNo,
  updateQuoteData,
};
