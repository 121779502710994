import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../../CommonSCSS/ModalPopup.scss";
import { TTWAddForm } from "../../../../../Services/Type/TTWSlice";
import { useAppSelector } from "../../../../../State/hooks";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import SearchDropdown from "../../../../InputFields/SearchDropdown/SearchDropdown";

const TWModifyDetails = ({
  open,
  setOpen,
  formData,
  fieldsUpdateState,
  validateForm,
  setFormData,
  loader,
}: {
  open: boolean;
  setOpen: Function;
  loader: boolean;
  formData: TTWAddForm;
  fieldsUpdateState: Function;
  validateForm: Function;
  setFormData: Function;
}) => {
  const { DROPDOWN_DATA, ADD_FORM } = useAppSelector((state) => state.TW);
  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Grid
            className="title mx-0"
            container
            columnSpacing={3}
            alignItems="center"
          >
            <Button className="closePopup" onClick={() => setOpen(false)} />
            <Grid xs={12}>
              {ADD_FORM.business_type === "New" ? (
                <h3>New</h3>
              ) : (
                <h3>{`${ADD_FORM.reg_no.value}`}</h3>
              )}
            </Grid>
          </Grid>
          <Grid container rowSpacing={0} columnSpacing={3} className="mx-3">
            <Grid xs={12}>
              <>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs>
                    <SearchDropdown
                      class_name="inputField"
                      disabled={true}
                      title="Make and Model"
                      value={`${formData?.make_model?.value.label}`}
                      attrName={"make_model"}
                      value_update={fieldsUpdateState}
                      data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                      warn_status={formData?.make_model?.warning}
                      error_message={"select Make and Model"}
                    />
                  </Grid>
                  <Grid xs={3}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Fuel Type"
                      disabled={true}
                      value={`${formData?.fuel_type?.value.label}`}
                      attrName={"fuel_type"}
                      value_update={fieldsUpdateState}
                      data={[{ key: "Petrol", value: "Petrol" }]}
                      warn_status={formData?.fuel_type?.warning}
                      error_message={"select fuelType"}
                    />
                  </Grid>
                  <Grid xs>
                    <SearchDropdown
                      class_name="inputField"
                      title="Variant"
                      value={`${formData?.variant_cc?.value.label}`}
                      attrName={"variant_cc"}
                      value_update={fieldsUpdateState}
                      data={DROPDOWN_DATA.VARIANT_LIST}
                      warn_status={formData?.variant_cc?.warning}
                      error_message={"select variant"}
                    />
                  </Grid>
                </Grid>
                {/* <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs={3}>
                    <DatePicker
                      class_name="inputField"
                      title={"Reg. Date"}
                      value={formData?.reg_date?.value}
                      attrName={"reg_date"}
                      value_update={fieldsUpdateState}
                      error_message={"select Reg. Date"}
                      warn_status={formData?.reg_date?.warning}
                      date_validation_type="YEARS"
                      min_date={15}
                      max_date={0}
                    />
                  </Grid>
                  {formData?.previous_insurer?.value ===
                  "Do not remember" ? null : (
                    <Grid xs={3}>
                      <DatePicker
                        class_name="inputField"
                        title={"Policy Expiry"}
                        value={formData?.policy_expiry_date.value}
                        attrName={"policy_expiry_date"}
                        value_update={fieldsUpdateState}
                        error_message={"select Policy Expiry"}
                        warn_status={formData?.policy_expiry_date?.warning}
                        min_date={
                          calculateAgeInDays(
                            `${formData.policy_expiry_date.value}`
                          ) <= 0
                            ? 0
                            : calculateAgeInDays(`${formData.reg_date.value}`) -
                              90
                        }
                        max_date={
                          calculateAgeInDays(
                            `${formData.policy_expiry_date.value}`
                          ) <= 0
                            ? -60
                            : 1
                        }
                        date_validation_type="DAYS"
                      />
                    </Grid>
                  )}
                  <Grid xs>
                    <SearchDropdown
                      class_name="inputField"
                      title="Previous Insurer"
                      value={`${formData?.previous_insurer?.value}`}
                      attrName={"previous_insurer"}
                      value_update={fieldsUpdateState}
                      data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                      warn_status={formData?.previous_insurer?.warning}
                      error_message={"select previousInsurer"}
                    />
                  </Grid>
                </Grid> */}
                {/* {formData?.previous_insurer?.value === "Do not remember" ? (
                  ""
                ) : (
                  <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={0}
                    alignItems="center"
                    marginBottom={"24px"}
                  >
                    <Grid xs={6} textAlign="right">
                      <h4 className="claim_txt">Claimed in the last year?</h4>
                    </Grid>
                    <Grid xs={6}>
                      <ToggleButtonGroup
                        className="radioBox"
                        value={formData.claimed}
                        exclusive
                        onChange={(e, new_value) =>
                          fieldsUpdateState("claimed", new_value)
                        }
                      >
                        <ToggleButton value="No">No</ToggleButton>
                        <ToggleButton value="Yes">Yes</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                )} */}
                {/* {formData?.previous_insurer?.value ===
                "Do not remember" ? null : formData.claimed === "No" ? (
                  <>
                    <Grid
                      container
                      columnSpacing={2}
                      rowSpacing={0}
                      marginBottom={"24px"}
                    >
                      <Grid xs={12} textAlign="center">
                        <h4 className="claim_txt">
                          Previous No Claim Bonus (NCB)
                        </h4>
                        <p className="lighttxt_p">
                          (Check it in your last policy.)
                        </p>
                        <ToggleButtonGroup
                          className="radioBox mt-4"
                          value={formData.prev_ncb}
                          exclusive
                          onChange={(e, new_value) => {
                            fieldsUpdateState("prev_ncb", new_value);
                          }}
                        >
                          <ToggleButton value="0">0%</ToggleButton>
                          <ToggleButton value="20">20%</ToggleButton>
                          <ToggleButton value="25">25%</ToggleButton>
                          <ToggleButton value="35">35%</ToggleButton>
                          <ToggleButton value="45">45%</ToggleButton>
                          <ToggleButton value="50">50%</ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    </Grid>
                  </>
                ) : null} */}

                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={0}
                  marginBottom={"24px"}
                >
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton
                      text_name={"Update Details"}
                      class_name="primaryLgBtn"
                      onClickFunction={validateForm}
                    />
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default TWModifyDetails;
