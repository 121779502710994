import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { CAR_SERVICES } from "../../../../../Services/CAR/CarServices";
import { TAddonsValueType } from "../../../../../Services/Type/Car/TAddonsValueType";
import { TCARBUYACTION } from "../../../../../Services/Type/Car/TCARBUYACTION";
import { TCarProduct } from "../../../../../Services/Type/Car/TCarProduct";
import { CarSlice } from "../../../../../State/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../../State/hooks";
import {
  calculate_premium_for_car,
  shouldDisplayAddon,
} from "../../../../../SupportingFiles/CarPermiumCal";
import {
  CURRENCY_FORMAT,
  formatToCurrency,
} from "../../../../../SupportingFiles/HelpingFunction";
import MCarPolicyDetail from "../MCarPolicyDetail/MCarPolicyDetail";
import "./MCarQuoteCard.scss";

const MCarQuoteCard = ({
  quote,
  addon_values,
}: {
  quote: TCarProduct;
  addon_values: TAddonsValueType;
}) => {
  const { ADDON_STATUS, PLAN_TYPE, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Car
  );
  const dispatch = useAppDispatch();
  const [openPolicyDetails, setOpenPolicyDetails] = useState<boolean>(false);
  const [premium, setPremium] = useState<number>(0);

  useEffect(() => {
    const premium_details = quote.quotationDetail;

    if (premium_details) {
      const premium_with_tax = calculate_premium_for_car(
        premium_details,
        ADDON_STATUS,
        addon_values.passengerCover.value,
        PLAN_TYPE
      );
      setPremium(premium_with_tax);
    }
  }, [ADDON_STATUS, quote, addon_values]);
  const Buyaction = () => {
    const onSuccess = () => {
      const redirection_url = `https://dev.insurance.motilaloswal.com/car-insurance/ckyc-verification/${btoa(
        `${quote.quotationDetail?.productDetails.id}-${ADD_FORM_RESPONSE.quote_no}`
      )}`;
      window.location.href = redirection_url;
    };

    const onError = () => {};

    const Params: TCARBUYACTION = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "CAR",
      product_code: quote.product_code,
      productId: quote.id,
      company_code: quote.company_code,
      IDV: quote.quotationDetail?.premiumDetails.IDV,
      finalPremium: quote.quotationDetail?.premiumDetails.netPremium,
    };
    CAR_SERVICES.BUY_ACTION(onSuccess, onError, Params);
    dispatch(CarSlice.actions.SET_SELECTED_QUOTE_DATA(quote));
  };
  return (
    <>
      {quote.loader ? (
        <Box className="Mquotation mskeleton">
          <Box className="mquotation_tags">
            <Grid container alignItems="center" spacing={0} padding={0}>
              <Grid xs={5}>
                <img className="insurerLogo" src={quote.logo} />
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="cg_p mt-2">
                    <span>{/* <BuildIcon /> */}</span>
                    <div className="animatebar" />
                  </p>
                )}
              </Grid>
              <Grid xs={7} textAlign={"right"}>
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="idv_p">
                    Vehicle IDV
                    <span>
                      ₹ <div className="animatebar" />
                    </span>
                  </p>
                )}
                <Link className="viewDetails">Plan Details</Link>
                <Button className="buyBtn">
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span className="rs-icon">₹</span>
                    <img
                      src="../images/loader.svg"
                      alt=""
                      style={{
                        height: "18px",
                        padding: "5px 0px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                  </span>{" "}
                  Buy Policy
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box className="maddonsBox">
            <ul>
              <li>
                <div className="animatebar" />
              </li>
              <li>
                <div className="animatebar" />
              </li>
            </ul>
          </Box>
        </Box>
      ) : (
        <Box className="Mquotation">
          <MCarPolicyDetail
            open={openPolicyDetails}
            setOpen={setOpenPolicyDetails}
            addon_values={addon_values}
            quote={quote}
          />
          <Box className="mquotation_tags">
            {PLAN_TYPE === "Comprehensive" ? null : (
              <span className="product_tag">{PLAN_TYPE}</span>
            )}
            <Grid container rowSpacing={3} columnSpacing={0} padding={"0"}>
              <Grid xs={5}>
                <img
                  className="insurerLogo"
                  src={quote.quotationDetail?.CompanyDetails?.logo}
                />
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="cg_p">
                    <span>
                      {/* <BuildIcon /> */}
                      {/* {cg_value} */}
                    </span>
                    {/* Cashless Garages */}
                  </p>
                )}
              </Grid>
              <Grid xs={7} textAlign={"right"}>
                {PLAN_TYPE === "Third Party" ? null : (
                  <p className="idv_p">
                    Vehicle IDV
                    <span>
                      {CURRENCY_FORMAT(
                        parseFloat(
                          `${quote.quotationDetail?.premiumDetails.IDV}`
                        )
                      )}
                    </span>
                  </p>
                )}
                <Link
                  className="viewDetails"
                  onClick={() => setOpenPolicyDetails(true)}
                >
                  Plan Details
                </Link>
                <Button
                  onClick={() => {
                    Buyaction();
                  }}
                  className="buyBtn"
                >
                  {CURRENCY_FORMAT(premium)} Buy Policy
                </Button>
              </Grid>
            </Grid>
          </Box>
          {ADDON_STATUS.depreciation_cover.value ||
          ADDON_STATUS.rsa.value ||
          ADDON_STATUS.driver_cover.value ||
          ADDON_STATUS.consumable_cover.value ||
          ADDON_STATUS.engine_cover.value ||
          ADDON_STATUS.invoice_cover.value ||
          ADDON_STATUS.key_replacement.value ||
          ADDON_STATUS.ncb_cover.value ||
          ADDON_STATUS.tyre_cover.value ||
          quote.quotationDetail?.premiumDetails.includedPAOwnerDriver == 1 ||
          quote.quotationDetail?.premiumDetails.freeRoadSideAssistance === 1 ? (
            <Box
              className="maddonsBox"
              display={"flex"}
              justifyContent={"space-between"}
            >
              <ul>
                {Object.entries(ADDON_STATUS).map(([key, value]) => {
                  const status =
                    value.value ||
                    (value.label === "24x7 Roadside Assistance" &&
                      quote?.quotationDetail?.premiumDetails
                        .freeRoadSideAssistance === 1);
                        if( value.label === "PA Owner Cover" && PLAN_TYPE ==="SAOD"){
                          return null
                        }
                        if( value.label === "Driver Cover" && PLAN_TYPE ==="SAOD"){
                          return null
                        }
                  if (
                    value.label === "PA Owner Cover" &&
                    quote?.quotationDetail?.premiumDetails
                      .includedPAOwnerDriver === 1
                  ) {
                    return (
                      <li key={key}>
                        <p
                          className={
                            value.label === "PA Owner Cover" &&
                            quote?.quotationDetail?.premiumDetails
                              .includedPAOwnerDriver === 1
                              ? "include"
                              : shouldDisplayAddon(
                                  value.label,
                                  quote?.quotationDetail?.premiumDetails
                                )
                          }
                        >
                          {value.label}
                        </p>
                      </li>
                    );
                  } else if (
                    value.label === "24x7 Roadside Assistance" &&
                    quote?.quotationDetail?.premiumDetails
                      .freeRoadSideAssistance === 1
                  ) {
                    return (
                      <li key={key}>
                        <p
                          className={
                            value.label === "24x7 Roadside Assistance" &&
                            quote?.quotationDetail?.premiumDetails
                              .freeRoadSideAssistance === 1
                              ? "free"
                              : shouldDisplayAddon(
                                  value.label,
                                  quote?.quotationDetail?.premiumDetails
                                )
                          }
                        >
                          {value.label}
                        </p>
                      </li>
                    );
                  } else if (status) {
                    return (
                      <li key={key}>
                        <p
                          className={
                            value.label === "PA Owner Cover" &&
                            quote?.quotationDetail?.premiumDetails
                              .includedPAOwnerDriver === 1
                              ? "include"
                              : value.label === "24x7 Roadside Assistance" &&
                                quote?.quotationDetail?.premiumDetails
                                  .freeRoadSideAssistance === 1
                              ? "free"
                              : shouldDisplayAddon(
                                  value.label,
                                  quote?.quotationDetail?.premiumDetails
                                )
                          }
                        >
                          {value.label}
                        </p>
                      </li>
                    );
                  }
                })}
              </ul>
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default MCarQuoteCard;
